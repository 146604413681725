import { useMemo, useRef, useState } from "react";
import { MidExpensesItemType, MidExpensesRefType } from "../type";
import { FleetNumType } from "@/types/common";

const useIndex = () => {
	const [hide, setHide] = useState(true);
	const [dataSource, setDataSource] = useState<MidExpensesItemType[]>([]);
	const activeIndex = useRef<number>(null);

	const summary = useMemo(() => {
		return dataSource?.reduce(
			(prev, curr) => {
				return {
					expenses: +prev?.expenses + +curr?.expenses
				};
			},
			{ expenses: 0 }
		);
	}, [dataSource]);

	const add = () => {
		setDataSource((prev) => [...prev, { expenses: "" }]);
	};

	const remove = (currentIndex: number) => {
		setDataSource((prev) => {
			prev?.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const change = (currentIndex: number, value: number) => {
		setDataSource((prev) => {
			prev?.splice(currentIndex, 1, { expenses: value });
			return [...prev];
		});
	};

	const closeModal = () => {
		activeIndex.current = null;
		setHide(true);
	};

	const openModal: MidExpensesRefType["openModal"] = (currentIndex, items) => {
		activeIndex.current = currentIndex;
		setDataSource(items?.filter((item) => !!item)?.map((expenses) => ({ expenses })));
		setHide(false);
	};

	return {
		activeIndex,
		hide,
		dataSource,
		summary,
		setHide,
		change,
		remove,
		add,
		closeModal,
		openModal
	};
};

export default useIndex;
