import React from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { InvStatementDescProps } from "./type";
import { InvStatementHire, InvStatementBunkerPrice, InvStatementOffHireGroup } from "./components";
import {
	InvCommonOtherExpenses,
	InvRemitAmount,
	InvoicesViewsCommonHeader
} from "../../../../common";

const InvStatementDesc: React.FC<InvStatementDescProps> = ({
	item,
	summary,
	otherItem,
	onOtherItemChange,
	hireItem,
	onHireCommit,
	bunkerPriceItems,
	onBunkerPriceCommit,
	onBunkerPriceDelete,
	disableBunkers,
	otherExpenses,
	onOtherExpensesChange,
	onOtherExpensesAdd,
	onOtherExpensesDelete,
	onBunkerPriceAdd,
	offHireGroupItems,
	onOffHireCommit,
	onOffHireAdd,
	onOffHireDelete,
	onOffHireGroupCommit,
	onOffHireGroupAdd,
	onOffHireGroupDelete,
	onOffHireOpen
}) => {
	return (
		<div className={classnames("invoicesDetail-item", style["statement-desciption"])}>
			<InvoicesViewsCommonHeader
				type="desc"
				title="Description"
				item={{
					voyageNo: item?.voyageNo
				}}
			/>
			<InvStatementHire
				item={hireItem}
				onCommit={onHireCommit}
				otherItem={{
					addHire: otherItem?.addHire
				}}
				onOtherItemChange={onOtherItemChange}
			/>
			<InvStatementBunkerPrice
				items={bunkerPriceItems}
				disableBunkers={disableBunkers}
				onDelete={onBunkerPriceDelete}
				onCommit={onBunkerPriceCommit}
				onAdd={onBunkerPriceAdd}
				otherItem={{
					addBunker: otherItem?.addBunker
				}}
				onOtherItemChange={onOtherItemChange}
			/>
			<InvStatementOffHireGroup
				items={offHireGroupItems}
				otherItem={{
					addOffHire: otherItem?.addOffHire
				}}
				onAdd={onOffHireAdd}
				onCommit={onOffHireCommit}
				onDelete={onOffHireDelete}
				onGroupAdd={onOffHireGroupAdd}
				onGroupCommit={onOffHireGroupCommit}
				onOffHireOpen={onOffHireOpen}
				onGroupDelete={onOffHireGroupDelete}
				onOtherItemChange={onOtherItemChange}
			/>
			<InvCommonOtherExpenses
				items={otherExpenses}
				onChange={onOtherExpensesChange}
				// onFieldOpen={onOtherExpensesFieldOpen}
				onAdd={onOtherExpensesAdd}
				onDelete={onOtherExpensesDelete}
				otherItem={{
					addOther: otherItem?.addOther
				}}
				onOtherItemChange={onOtherItemChange}
			/>
			{/* <InvRemitAmount remitAmount={summary?.plus - summary?.less} /> */}
		</div>
	)
};

export default InvStatementDesc;
