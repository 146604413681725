import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { InvStatementHireProps, InvStatementHireItemType } from "./type";
import { FleetDatePicker, FleetFormItem, FleetVerifyInput } from "@/components";
import dayjs from "dayjs";
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/components";
import { FleetTabbar } from "@/components/Tabs";
import { formatThousandthNumber } from "@/tools/amount";
import { getCveAmount } from "../../tools";

const InvStatementHire: FC<InvStatementHireProps> = ({
	item,
	otherItem,
	onOtherItemChange,
	onCommit
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invStatement-offHire"])}>
			<div className="invoicesForm-header">
				<InvoiceAddTo
					type="addHire"
					value={otherItem?.addHire}
					onChange={(key, val) => {
						onOtherItemChange?.("addHire", val)
					}}
				>
					<span>Total Hire</span>
				</InvoiceAddTo>
				<span></span>
			</div>
			<div className="invoicesForm-container ttl-onhireForm">
				<FleetVerifyInput
					value={item?.ttlOnHireDay}
					label="TTL On-Hire days"
					type="number"
					disabled={!item?.onHireStartDay}
					onChange={(val: number) => {
						// if (!item?.onHireStartDay) return
						const totalHire = val * +item?.dailyHire
						const addComm = (totalHire * +item?.addCommRate) / 100
						// const brokerage = (totalHire * +item?.brokerageRate) / 100;
						const params: Partial<InvStatementHireItemType> = {
							ttlOnHireDay: val,
							onHireEndDay: dayjs(item?.onHireStartDay)?.add(val, "day")?.valueOf(),
							totalHire: totalHire,
							addComm,
							// brokerage,
							hireCve: getCveAmount({
								cveRatio: item?.hireCveRatio,
								unit: item?.hireCveUnit,
								ttlDay: val
							})
						}
						onCommit(params)
					}}
				/>
				<FleetFormItem label="On-Hire days(start)" needVerity>
					<FleetDatePicker
						value={item?.onHireStartDay ? dayjs(item?.onHireStartDay) : null}
						showHour
						showMinute
						showTime
						format="YYYY-MM-DD HH:mm"
						allowClear
						disabledDate={(current) => {
							return (
								(current && item?.onHireEndDay && current.isAfter(item?.onHireEndDay)) ||
								current.isSame(item?.onHireEndDay)
							)
						}}
						onChange={(val, timeStramp) => {
							const params: Partial<InvStatementHireItemType> = {
								onHireStartDay: timeStramp?.startOf("minute")?.valueOf()
							}
							if (item?.onHireEndDay) {
								const diffVal = +(
									dayjs(item?.onHireEndDay)?.diff(timeStramp, "minute") /
									(60 * 24)
								)?.toFixed(7)
								const totalHire = diffVal * +item?.dailyHire
								params.ttlOnHireDay = diffVal
								params.hireCve = getCveAmount({
									cveRatio: item?.hireCveRatio,
									unit: item?.hireCveUnit,
									ttlDay: diffVal
								})
								params.addComm = (totalHire * +item?.addCommRate) / 100
								// params.brokerage = (totalHire * +item?.brokerageRate) / 100;
							} else if (item?.ttlOnHireDay) {
								params.onHireEndDay = dayjs(timeStramp)?.add(item?.ttlOnHireDay, "day")?.valueOf()
							}
							onCommit?.(params)
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="On-Hire days(end)" needVerity>
					<FleetDatePicker
						value={item?.onHireEndDay ? dayjs(item?.onHireEndDay) : null}
						showHour
						showMinute
						showTime
						format="YYYY-MM-DD HH:mm"
						allowClear
						disabled={!item?.onHireStartDay}
						onChange={(val, timeStramp) => {
							if (!item?.onHireStartDay) return
							const params: Partial<InvStatementHireItemType> = {
								onHireEndDay: timeStramp?.startOf("minute")?.valueOf()
							}
							const diffVal = +(
								timeStramp?.diff(item?.onHireStartDay, "minute") /
								(60 * 24)
							)?.toFixed(7)
							const totalHire = diffVal * +item?.dailyHire
							params.totalHire = totalHire
							params.ttlOnHireDay = diffVal
							params.hireCve = getCveAmount({
								cveRatio: item?.hireCveRatio,
								unit: item?.hireCveUnit,
								ttlDay: diffVal
							})
							params.addComm = (totalHire * +item?.addCommRate) / 100
							// params.brokerage = (totalHire * +item?.brokerageRate) / 100;
							onCommit(params)
						}}
					/>
				</FleetFormItem>
			</div>
			<div className="invoicesForm-container">
				<FleetVerifyInput
					value={item?.dailyHire}
					needVerity
					type="number"
					label="Daily Hire"
					onChange={(val: number) => {
						const totalHire = val * +item?.ttlOnHireDay
						const addComm = (totalHire * +item?.addCommRate) / 100
						const brokerage = (totalHire * +item?.brokerageRate) / 100
						onCommit?.({
							dailyHire: val,
							totalHire: totalHire,
							addComm: addComm
							// brokerage: brokerage
						})
					}}
				/>
				<FleetVerifyInput value={item?.totalHire} label="TTL Hire" type="number" disabled />
				<div className="grid grid-cols-[1fr_61px] gap-[4px]">
					<FleetVerifyInput
						label="C/V/E Ratio"
						value={item?.hireCveRatio}
						type="number"
						onChange={(value: number) => {
							const hireCve = getCveAmount({
								cveRatio: value,
								unit: item?.hireCveUnit,
								ttlDay: item?.ttlOnHireDay
							})
							onCommit?.({
								hireCve,
								hireCveRatio: value
							})
						}}
					/>
					<FleetTabbar
						list={["PM", "PY"]}
						value={item?.hireCveUnit}
						onChange={(value: "PM" | "PY") => {
							const hireCve = getCveAmount({
								cveRatio: item?.hireCveRatio,
								unit: value,
								ttlDay: item?.ttlOnHireDay
							})
							onCommit?.({
								hireCve,
								hireCveUnit: value
							})
						}}
						className=" self-end"
					/>
				</div>
				<FleetVerifyInput
					value={item?.hireCve}
					label="On-Hire C/V/E Amount ($)"
					type="number"
					disabled
				/>
			</div>
			<div className="invoicesForm-container">
				<FleetVerifyInput
					value={item?.addCommRate}
					containerClassname="freight-unit"
					label="Add. Comm. Rate %"
					min={0}
					max={100}
					type="number"
					onChange={(val: number) => {
						const addComm = (val * +item?.totalHire) / 100
						onCommit?.({
							addCommRate: val,
							addComm
						})
					}}
				/>
				<FleetVerifyInput value={item?.addComm} type="number" label="Add. Comm. ($)" disabled />
				{/* <FleetVerifyInput
					value={item?.brokerageRate}
					label="Brokerage Rate %"
					min={0}
					max={100}
					type="number"
					containerClassname="freight-unit"
					onChange={(val: number) => {
						const brokerage = (val * +item?.totalHire) / 100;
						onCommit?.({
							brokerageRate: val,
							brokerage
						});
					}}
				/> */}
				{/* <FleetVerifyInput
					value={item?.brokerage}
					type="number"
					label="Brokerage ($)"
					disabled
					// onChange={(val: number) => {
					//   onChange?.("brokerage", val)
					// }}
				/> */}
			</div>
			<ul className="w-full flex flex-col gap-[4px] border-t border-[#E7E9EC] border-solid pt-[16px]">
				<li className="text-[14px] leading-[22px] font-normal">
					<span className="text-[#A3A3A3]">TTL Hire Amount: </span>
					<span className="text-[#7D7D7D]">
						{formatThousandthNumber(+item?.dailyHire)} $ * {+item?.ttlOnHireDay} Days ={" "}
						{formatThousandthNumber(item?.totalHire)} $
					</span>
				</li>
				<li className="text-[14px] leading-[22px] font-normal">
					<span className="text-[#A3A3A3]">C/V/E: </span>
					<span className="text-[#7D7D7D]">
						{item?.hireCveUnit === "PM"
							? `${formatThousandthNumber(+item?.hireCveRatio)} $ * ${item?.ttlOnHireDay} Days / 365 * 12 = ${formatThousandthNumber(item?.hireCve)} $`
							: `${formatThousandthNumber(+item?.hireCveRatio)} $ * ${item?.ttlOnHireDay} Days / 365 = ${formatThousandthNumber(item?.hireCve)} $`}
					</span>
				</li>
				{/* <li className="text-[12px] leading-[22px] font-normal">
					<span className="text-[#A3A3A3]">Brokerage: </span>
					<span className="text-[#7D7D7D]">
						{formatThousandthNumber(item?.totalHire)} $ * {+item?.brokerageRate} % ={" "}
						{formatThousandthNumber(item?.brokerage, 3, 3)} $
					</span>
				</li> */}
				<li className="text-[14px] leading-[22px] font-normal">
					<span className="text-[#A3A3A3]">Add.Commisisons: </span>
					<span className="text-[#7D7D7D]">
						{formatThousandthNumber(item?.totalHire)} $ * {+item?.addCommRate} % ={" "}
						{formatThousandthNumber(item?.addComm, 3, 3)} $
					</span>
				</li>
				<li className="text-[14px] leading-[22px] font-normal">
					<span className="text-[#A3A3A3]">Net Hire Amount: </span>
					<span className="text-[#7D7D7D]">
						{formatThousandthNumber(item?.totalHire)} $ + {+item?.hireCve} $ -
						{formatThousandthNumber(item?.addComm, 3, 3)} $ ={" "}
						{formatThousandthNumber(item?.totalHire + item?.hireCve - item?.addComm, 3, 3)} $
					</span>
				</li>
			</ul>
		</div>
	)
};

export default InvStatementHire;
