import React, { useMemo } from "react";
import classnames from "classnames";
import { FleetIcon } from "@/components";
import { setFirstSelected } from "@/store/globalSlice";
import { useNavigate } from "react-router-dom";
import { LayoutChildren, type LayoutChildrenProps } from "@/layout/router";
import style from "./index.module.less";
import { useAppDispatch, useAppSelector } from "@/hook";
import useReminder from "@/hook/useReminder";

const Item: React.FC<
	LayoutChildrenProps & {
		keyPath: string;
	}
> = ({ hidden, label, icon, selectedIcon, keyPath, disabled, meta }) => {
	const { firstSelected } = useAppSelector((state) => state.GlobalStore);
	const dispatch = useAppDispatch();
	const navigator = useNavigate();
	const { reminder } = useReminder();

	const isSelected = firstSelected?.startsWith(keyPath);

	const handleJump = () => {
		if (disabled) {
			reminder("warning", "Not yet open, please stay tuned");
			return;
		}

		if (isSelected) return;
		dispatch(setFirstSelected(keyPath));
		navigator(keyPath);
	};

	return (
		<div
			onClick={handleJump}
			className={classnames("menu-item", {
				active: isSelected,
				disabled: disabled
			})}
		>
			{/* <FleetIcon
        type={isSelected ? selectedIcon : icon}
        outerLayerClassname="menu-icon"
      /> */}
			<span className={classnames("menu-icon font_family", meta?.icon)}></span>
			<span>{meta?.title}</span>
		</div>
	);
};

const HeaderMenu: React.FC<{
	items: LayoutChildrenProps[];
}> = ({ items }) => {
	return (
		<div className={style["layout-header-menu"]}>
			{items?.map((item: LayoutChildrenProps, idx: number) => {
				return item?.hidden ? null : <Item key={idx} {...item} keyPath={item?.key as string} />;
			})}
		</div>
	);
};

export default HeaderMenu;
