import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cacheStorage } from "@/storage";
import { StorageKeyMap } from "@/storage/types";
import { initialUserState } from "./state";
import { logoutSysApi } from "@/api/login";
import { MenuHandler } from "space-gl-lib"

export const loginOutAction = createAsyncThunk("login/out", async (callback?: () => void) => {
	const res = await logoutSysApi()
	callback?.()
	return res
})

export const UserSlice = createSlice({
	name: "userStore",
	initialState: initialUserState,
	reducers: {
		loginIn: (state, action) => {
			const access_token =
				action.payload.access_token || cacheStorage.get(StorageKeyMap["access_token"])
			const userInfo = { ...action.payload.userInfo, menus: action?.payload?.menus }
			const permissions = action.payload.permissions
			const roles = action.payload.roles
			cacheStorage.set(StorageKeyMap["user_info"], userInfo)
			cacheStorage.set(StorageKeyMap["access_token"], access_token)
			cacheStorage.set(StorageKeyMap["permissions"], permissions)
			cacheStorage.set(StorageKeyMap["roles"], roles)
			return {
				...state,
				permissions,
				roles,
				access_token: access_token,
				userInfo: userInfo
			}
		},
		loginOut: (state, action) => {
			cacheStorage.logout()
			return {
				...state,
				menus: [],
				access_token: "",
				userInfo: {
					username: state?.userInfo?.username,
					password: state?.userInfo?.password,
					userId: null,
					menus: []
				},
				roles: [],
				permissions: []
			}
		},
		rememberIn: (state, action) => {
			const permissions = action.payload.permissions,
				roles = action.payload.roles
			const userInfo = {
				...state,
				username: action.payload.username,
				password: action.payload.password || "",
				menus: MenuHandler.get_menus(action?.payload?.menus),
				userId: action.payload.userId
			}
			cacheStorage.set(StorageKeyMap["remember"], action.payload.remember)
			cacheStorage.set(StorageKeyMap["user_info"], userInfo)
			cacheStorage.set(StorageKeyMap["permissions"], permissions)
			cacheStorage.set(StorageKeyMap["roles"], roles)
			return {
				...state,
				permissions,
				roles,
				userInfo: {
					...userInfo
				},
				remember: action.payload.remember
			}
		}
	},
	extraReducers(builder) {
		builder
			.addCase(loginOutAction.pending, (state) => {
				console.log("🚀 ~ 进行中！")
			})
			.addCase(loginOutAction.fulfilled, (state, { payload }) => {
				console.log("🚀 ~ fulfilled", payload)
				// state.list = payload.data.list
				// state.totals = payload.data.list.length
				cacheStorage.clear()
				return {
					...state,
					menus: [],
					access_token: "",
					userInfo: {
						username: state?.userInfo?.username,
						password: state?.userInfo?.password,
						userId: null,
						menus: []
					},
					permissions: [],
					roles: []
				}
			})
			.addCase(loginOutAction.rejected, (state, err) => {
				console.log("🚀 ~ rejected", err)
			})
	}
})

export const { loginIn, loginOut, rememberIn } = UserSlice.actions;

export default UserSlice.reducer;
