import { addContactAction, queryContactGroupAction } from "@/action/dataBase/contact";
import useCommonIndex from "../../../hooks/useIndex";
import { CommonContactItemType, CommonContactSelectProps } from "../type";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
const useIndex = (
	activeItems: Partial<CommonContactItemType> | Partial<CommonContactItemType>[],
	showKey: keyof CommonContactItemType,
	onFinish: (newItem: CommonContactItemType, operateType: "add" | "del") => void,
	primaryKey: keyof CommonContactItemType = "id",
	disabled: boolean,
	params?: { businessType: CommonContactItemType["businessType"] },
	contactActions?: CommonContactSelectProps["contactActions"]
) => {
	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	} = useCommonIndex<CommonContactItemType, keyof CommonContactItemType>(
		activeItems,
		showKey,
		onFinish,
		queryContactGroupAction,
		primaryKey,
		disabled,
		params
	);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { reminder } = useReminder();

	const [hide, setHide] = useState(true);
	const [newlyLoading, setNewlyLoading] = useState(false);

	const handleContactAddFront = () => {
		setNewlyLoading(true);
	};

	const handleContactAddFailed = (error) => {
		setNewlyLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};
	const handleContactAdd = (item: CommonContactItemType) => {
		addContactAction(
			item,
			handleContactAddFront,
			(response) => {
				contactActions?.onConfirm?.(response?.data);
				setNewlyLoading(false);
				setHide(true);
				reminder("success", response?.msg);
			},
			handleContactAddFailed,
			dispatch,
			navigate
		);
	};

	return {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender,
		hide,
		newlyLoading,
		setHide,
		handleContactAdd
	};
};

export default useIndex;
