import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	InvoicesViewsListProps,
	InvoicesViewsDetailsProps,
	initialInvoiceItem
} from "../components";
import {
	getInvFreightGroupAction,
	getInvStatementGroupAction
} from "@/action/voyageManage/invoices";
import { InvoicesViewsProps } from "../type";
import { initialUppercase } from "@/tools/string";

const useIndex = (
	activeVoyageItem: InvoicesViewsProps["activeVoyageItem"],
	activeInvType: InvoicesViewsProps["activeInvType"],
	newly: InvoicesViewsProps["newly"],
	onInvTypeSelect: InvoicesViewsProps["onInvTypeSelect"],
	onNewlyCommit: InvoicesViewsProps["onNewlyCommit"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [hide, setHide] = useState(true);
	const [loading, setLoading] = useState(false);
	const [dataSource, setDataSource] = useState<InvoicesViewsListProps["items"]>([]);
	const [activeIndex, setActiveIndex] = useState<InvoicesViewsListProps["activeIndex"]>(null);

	const currentIndex = useRef<number>(-1);
	const addIng = useRef<boolean>(false);

	const activeInvoice = useMemo(() => {
		return dataSource?.[activeIndex];
	}, [dataSource, activeIndex]);

	const getInvoicesGroupSuccess = (response) => {
		setDataSource(response?.data);
		setActiveIndex(response?.data?.length >= 1 ? 0 : null);
		onNewlyCommit?.({
			type: response?.data?.length >= 1 ? "detail" : "first",
			status: newly?.status + 1
		});
		addIng.current = false;
	};

	const getInvoicesGroupError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getInvoicesGroup = () => {
		debugger;
		switch (true) {
			case activeInvType === "freightInv" && activeVoyageItem?.voyageType === "TCVC":
				getInvFreightGroupAction(
					{ voyageId: activeVoyageItem?.id },
					null,
					getInvoicesGroupSuccess,
					getInvoicesGroupError,
					dispatch,
					navigate
				);
				break;
			case activeInvType === "relet" && activeVoyageItem?.voyageType === "TCTC":
			case activeInvType === "rent" && activeVoyageItem?.voyageType === "TCTC":
			case activeInvType === "statement" && activeVoyageItem?.voyageType === "TCVC":
				getInvStatementGroupAction(
					{
						voyageId: activeVoyageItem?.id,
						type:
							activeInvType === "statement"
								? null
								: (initialUppercase(activeInvType) as "Rent" | "Relet")
					},
					null,
					getInvoicesGroupSuccess,
					getInvoicesGroupError,
					dispatch,
					navigate
				);
				break;
			default:
				break;
		}
	};

	const handleInvTypeSelect: InvoicesViewsListProps["onTypeSelect"] = (type) => {
		onInvTypeSelect?.(type);
		onNewlyCommit?.({ status: -1 });
		// setNewly((prev) => ({ ...prev, status: -1 }));
	};

	const handleInvItemSelect: InvoicesViewsListProps["onItemSelect"] = (index, item) => {
		if (activeIndex === index) return;
		if (!dataSource[activeIndex]?.id) {
			currentIndex.current = index;
			setHide(false);
			return;
		}

		setActiveIndex(index);
		onNewlyCommit?.({
			status: newly?.status + 1,
			type: !dataSource[index]?.id ? "lastDetail" : "detail"
		});
		// setNewly((prev) => {
		// 	return {
		// 		status: prev?.status + 1,
		// 		type: !dataSource[index]?.id ? "lastDetail" : "detail"
		// 	};
		// });
	};

	const handleItemSelectConfirm = () => {
		setActiveIndex(currentIndex.current);
		setHide(true);
		onNewlyCommit?.({
			status: newly?.status + 1,
			type: dataSource?.[currentIndex.current]?.id ? "detail" : "lastDetail"
		});
		// setNewly((prev) => {
		// 	return {
		// 		status: prev?.status + 1,
		// 		type: dataSource?.[currentIndex.current]?.id ? "detail" : "lastDetail"
		// 	};
		// });
	};

	const handleItemSelectCancel = () => {
		setHide(true);
	};

	const handleInvoicesAdd: InvoicesViewsListProps["onAdd"] = (type) => {
		if (addIng?.current) {
			reminder("warning", "You have unsaved invoices and cannot add them temporarily!");
			return;
		}
		onNewlyCommit?.({
			status: newly?.status + 1,
			type: dataSource?.length <= 1 && !dataSource?.at(-1)?.id ? "first" : "last"
		});
		// setNewly((prev) => {
		// 	return {
		// 		status: prev?.status + 1,
		// 		type: dataSource?.length <= 1 && !dataSource?.at(-1)?.id ? "first" : "last"
		// 	};
		// });
	};

	const handleInvoicesInit: InvoicesViewsDetailsProps["onInvoiceAdd"] = () => {
		addIng.current = true;
		setDataSource((prev) => {
			return [...prev, initialInvoiceItem({ period: dataSource?.length + 1 })];
		});
		setActiveIndex(dataSource?.length);
	};

	const handleInvoicesUpate: InvoicesViewsDetailsProps["onInvoiceUpdate"] = (item) => {
		console.log("handleInvoicesUpate", item);
		setDataSource((prev) => {
			const currentItem = prev?.[activeIndex];
			prev.splice(activeIndex, 1, {
				...currentItem,
				...item
			});
			return [...prev];
		});
		addIng.current = false;
	};

	useEffect(() => {
		if (!activeVoyageItem?.id) return;
		console.log("activeVoyageItem", activeVoyageItem);
		getInvoicesGroup();
	}, [activeVoyageItem, activeInvType]);

	return {
		loading,
		activeIndex,
		activeInvoice,
		hide,
		addIng,
		dataSource,
		handleInvItemSelect,
		handleInvTypeSelect,
		handleInvoicesAdd,
		handleItemSelectCancel,
		handleItemSelectConfirm,
		handleInvoicesInit,
		handleInvoicesUpate
	};
};

export default useIndex;
