import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetTable } from "@/components";
import { columns } from "./source";
import useIndex from "./hooks/useIndex";
import { VesselArchivesFilter } from "./components";
import { Button } from "@/_components";
import { CommonVesselArrival } from "@/common";

const DatabaseVesselArchives: React.FC<{}> = (props) => {
	const {
		loading,
		hide,
		record,
		opereteColumns,
		dataSource,
		editType,
		pager,
		selectedRowKeys,
		onPageChange,
		setHide,
		onSelectChange,
		onSearch,
		handleReset,
		deleteVesselArchive,
		handleEdit
	} = useIndex();
	return (
		<div className={classnames("database-common", style["database-vesselArchives"])}>
			<div className="database-toolbar vesselArchives-toolbar">
				<VesselArchivesFilter onSearch={onSearch} />
				<div className="toolbar-actions">
					<Button
						size="small"
						prefixIcon="fleet-delete"
						disabled={selectedRowKeys?.length === 0}
						onClick={() => deleteVesselArchive("all")}
					>
						Delete
					</Button>
					<Button
						prefixIcon="fleet-add"
						size="small"
						disabled
						onClick={() => handleEdit("add", null)}
					>
						Add Vessel Archive
					</Button>
				</div>
			</div>
			<FleetTable
				columns={[...columns, ...opereteColumns]}
				className="database-table"
				dataSource={dataSource}
				loading={loading}
				id="imo"
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
				rowSelection={{
					columnWidth: 64,
					selectedRowKeys,
					onChange: onSelectChange
				}}
				onRow={(rowItem) => {
					return {
						onDoubleClick: () => {
							record.current = rowItem;
							setHide(false);
						}
					};
				}}
			/>
			<CommonVesselArrival
				hide={hide}
				type="details"
				onClose={() => {
					setHide(true);
				}}
				imo={record?.current?.imo}
			/>
		</div>
	);
};

export default DatabaseVesselArchives;
