import React, { FC } from "react"
import style from "./index.module.less"
import { MovementVesselBasicsProps } from "./type"
import { FleetDatePicker, FleetFormItem, FleetInput } from "@/components"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { formatThousandthNumber } from "@/tools/amount"
import { CommonContactSelect } from "@/common/Select"
import copy from "copy-to-clipboard"
import useReminder from "@/hook/useReminder"

const MovementVesselBasics: FC<MovementVesselBasicsProps> = ({
	item,
	otherItem,
	onChange,
	onCommit,
	onCargoCommit
}) => {
	const { t } = useTranslation()
	const { reminder } = useReminder()

	const handleCopy = () => {
		if (!otherItem?.voyageNo) return
		copy(otherItem?.voyageNo || "")
		reminder("success", "Copy successful")
	}

	return (
		<div className={style["movement-vesselBasics"]}>
			<div className="movement-details--header vesselBasics-header">
				<span className="fleet-title">Vessel Basics</span>
				<div className="vesselBasics-voyageNo">
					<span>Voyage No. {otherItem?.voyageNo}</span>
					<span className="font_family fleet-copy" onClick={handleCopy}></span>
				</div>
			</div>
			<div className="vesselBasics-item">
				<FleetFormItem type="row" label={t("voyageManagement.movement.ownerName")} needVerity>
					<FleetInput value={item?.ownerName} disabled />
				</FleetFormItem>
				<FleetFormItem type="row" needVerity label={t("voyageManagement.movement.vesselName")}>
					<FleetInput value={item?.vesselName} disabled />
				</FleetFormItem>
			</div>
			<div className="vesselBasics-item broker-container broker">
				<div className="vesselBasics-item--container rate-container">
					<FleetFormItem type="row" label={t("voyageManagement.movement.brokerName")}>
						<CommonContactSelect
							activeItems={{
								id: item?.brokerId,
								companyName: item?.brokerName
							}}
							showKey="companyName"
							onFinish={(newItem, type) => {
								onCommit?.({
									brokerId: newItem?.id ?? null,
									brokerName: newItem?.companyName ?? ""
								})
							}}
							contactParmas={{ businessType: "Broker" }}
							contactActions={{
								onConfirm: (newItem) => {
									onCommit?.({
										brokerId: newItem?.id ?? null,
										brokerName: newItem?.companyName ?? ""
									})
								}
							}}
						/>
					</FleetFormItem>
					<FleetFormItem type="row" label={t("common.dailyHire")}>
						<FleetInput
							value={item?.dailyHire}
							type="number"
							onChange={(val: number) => {
								onChange?.("dailyHire", val)
							}}
						/>
					</FleetFormItem>
				</div>
				<div className="vesselBasics-item--container rate-container">
					<FleetFormItem type="row" label={t("common.commission")}>
						<FleetInput
							value={item?.commission}
							type="number"
							onChange={(val: number) => {
								onChange?.("commission", val)
							}}
						/>
					</FleetFormItem>
					<FleetFormItem type="row" label={t("common.brokerage")}>
						<FleetInput
							value={item?.brokerage}
							type="number"
							onChange={(val: number) => {
								onChange?.("brokerage", val)
							}}
						/>
					</FleetFormItem>
				</div>
			</div>
			<div className="vesselBasics-item">
				<FleetFormItem type="row" label={t("voyageManagement.common.delievryTime")}>
					<FleetDatePicker
						value={item?.deliveryTime ? dayjs(item?.deliveryTime) : null}
						showTime={{
							format: "HH:mm"
						}}
						format={(value) => value?.format("YYYY-MM-DD HH:mm")}
						showHour
						showMinute
						disabledDate={(current) => {
							return (
								item?.estRedeliveryDate && current > dayjs(item?.estRedeliveryDate).endOf("minutes")
							)
						}}
						onChange={(val, timeStramp) => {
							onChange?.("deliveryTime", timeStramp?.startOf?.("minute")?.valueOf?.())
							onCargoCommit?.({
								paymentStartDay: timeStramp
									?.add(+otherItem?.paymentTerm, "day")
									?.format("YYYY-MM-DD")
							})
						}}
					/>
				</FleetFormItem>
				<div className="vesselBasics-item--container redel-container">
					<FleetFormItem type="row" label={t("voyageManagement.movement.estRedeliveryDate")}>
						<FleetDatePicker
							showTime={{
								format: "HH:mm"
							}}
							format={(value) => value?.format("YYYY-MM-DD HH:mm")}
							showHour
							showMinute
							disabledDate={(current) => {
								return item?.deliveryTime && current < dayjs(item?.deliveryTime).endOf("minutes")
							}}
							value={item?.estRedeliveryDate ? dayjs(item?.estRedeliveryDate) : null}
							onChange={(val, timestamp) => {
								onChange?.("estRedeliveryDate", timestamp?.startOf("minutes")?.valueOf())
							}}
						/>
					</FleetFormItem>
					<span>
						{item?.estRedeliveryDate
							? formatThousandthNumber(
									(+item?.estRedeliveryDate - Date.now()) / (1000 * 60 * 60 * 24),
									1,
									1
								)
							: undefined}{" "}
						d.
					</span>
				</div>
			</div>
		</div>
	)
}

export default MovementVesselBasics
