import { useRef, useState } from "react";
import { InvStatementOffHireTimeCalculateProps, OffHireTimeCalculateGroupType } from "../type";
import { initialOffHireGroup, initialOffHireItem } from "../source";
import { InvCommonOffHireProps } from "@/pages/voyagemanage/business/invoices/components/views/components/details/common/offHire/type";

const useIndex = (otherItem: Pick<OffHireTimeCalculateGroupType, "invId" | "voyageId">) => {
	const [dataSource, setDataSource] =
		useState<InvStatementOffHireTimeCalculateProps["offHireGroup"]>(initialOffHireGroup());
	const deleteIds = useRef<number[]>([]);

	const handleOffHireItemAdd = () => {
		setDataSource((prev) => {
			const currentItems = prev?.["moveOffHireList"];
			currentItems?.push(
				initialOffHireItem({
					voyageId: otherItem?.voyageId,
					invId: otherItem?.invId,
					groupId: prev?.id
				})
			);
			return {
				...prev,
				moveOffHireList: [...currentItems]
			};
		});
	};

	const handleOffHireItemConfirm: InvCommonOffHireProps["onConfirm"] = (items, delIds) => {
		setDataSource((prev) => ({ ...prev, moveOffHireList: [...items] }));
		deleteIds.current = delIds;
	};

	const commit: InvStatementOffHireTimeCalculateProps["onOffHireGroupCommit"] = (item) => {
		setDataSource((prev) => ({ ...prev, ...item }));
	};

	const init = (item: OffHireTimeCalculateGroupType) => {
		setDataSource(initialOffHireGroup(item));
		deleteIds.current = [];
	};

	return {
		dataSource,
		deleteIds,
		handleOffHireItemAdd,
		handleOffHireItemConfirm,
		commit,
		init
	};
};

export default useIndex;
