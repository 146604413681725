import { FleetSelect } from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import { CommonAccountSelectProps } from "./type";
import { CommonAccountPopup } from "./components";
import classnames from "classnames";
import style from "./index.module.less";
import { Icon } from "@/_components";
import { CommonNewlyAccount } from "@/common/Newly";

const CommonAccountSelect: FC<CommonAccountSelectProps> = ({
	activeItems,
	onFinish,
	disabled,
	className,
	showCount,
	placeholder,
	primaryKey = "id",
	showKey,
	accountActions
}) => {
	const {
		loading,
		list,
		open,
		loadMore,
		closePopup,
		focusPopup,
		searchValue,
		tagRender,
		handleSearch,
		handleItemSelect,
		selectValue,
		handleClear,
		hide,
		setHide,
		handleAccountAdd
	} = useIndex(activeItems, showKey, onFinish, primaryKey, disabled, accountActions);

	return (
		<div
			className={classnames(style["common-select--account"], {
				[style["common-select--account--newly"]]: accountActions
			})}
		>
			<FleetSelect
				value={selectValue}
				showSearch
				allowClear
				placeholder={placeholder}
				showCount={showCount}
				popupMatchSelectWidth={false}
				onClear={handleClear}
				className={classnames("account-select--container", className)}
				onDropdownVisibleChange={closePopup}
				disabled={disabled}
				mode={Array?.isArray(activeItems) ? "multiple" : undefined}
				open={open}
				onSearch={handleSearch}
				onFocus={() => focusPopup()}
				tagRender={tagRender}
				dropdownRender={() => (
					<CommonAccountPopup
						loadMore={loadMore}
						list={list}
						loading={loading}
						onSelect={handleItemSelect}
					/>
				)}
			/>
			{accountActions && (
				<>
					<Icon
						type="fleet-plus"
						className="cursor-pointer opacity-40"
						size={32}
						onClick={() => {
							setHide(false);
						}}
					/>
					<CommonNewlyAccount
						type="add"
						hide={hide}
						onClose={() => {
							setHide(true);
						}}
						onConfirm={handleAccountAdd}
					/>
				</>
			)}
		</div>
	);
};

export default CommonAccountSelect;
