import React, { FC } from "react"
import style from "./index.module.less"
import classnames from "classnames"
import {
	FleetDatePicker,
	FleetFormItem,
	FleetImage,
	FleetTable,
	FleetTooltip,
	FleetUpload,
	FleetVerifyInput
} from "@/components"
import { FleetCustomRadio } from "@/components/Radio"
import useIndex from "./hooks/useIndex"
import { columns } from "./source"
import MovementTable from "../../../charterBase/common/table"
import { FleetToast } from "@/common"
import { Button } from "@/_components"
import { MovementUpload } from "../../../upload"
import { MovementInsureFormProps } from "./type"
import dayjs from "dayjs"
import { dateToUtcString } from "@/tools/date"

const MovementInsureForm: FC<MovementInsureFormProps> = ({ dataSource, onChange }) => {
	const {
		loading,
		hide,
		handleUpload,
		handleDelete,
		handlePreview,
		handleUploadOpen,
		handleClose
	} = useIndex(dataSource, onChange)
	return (
		<div className={classnames("insureForm-commom", style["insureForm-contain"])}>
			<div className="insureForm-part">
				<span className="header--title">Basics</span>
				<div className="flex-container">
					<FleetVerifyInput
						label="Member"
						value={dataSource?.member}
						className="w-56"
						onChange={(val: string) => {
							onChange("member", val)
						}}
					/>
					<FleetVerifyInput label="Voyage No." value={dataSource?.voyageNo} disabled />
				</div>
			</div>
			<div className="insureForm-part">
				<span className="header--title">Vessel</span>
				<div className="flex-container">
					<FleetVerifyInput
						label="Vessel Name"
						value={dataSource?.vesselName}
						disabled
						className="w-56"
					/>
					<FleetVerifyInput
						label="GT"
						value={dataSource?.grossTonnage}
						onChange={(val: number) => {
							onChange("grossTonnage", val)
						}}
					/>
					<FleetVerifyInput
						label="Year of Built"
						value={dataSource?.buildYear}
						onChange={(val: number) => {
							onChange("buildYear", val)
						}}
					/>
				</div>
			</div>
			<div className="insureForm-part">
				<span className="header--title">Voyage</span>
				<div className="flex-container">
					<FleetVerifyInput
						label="Types of Charteringe"
						value={dataSource?.typeOfCharter}
						disabled
					/>
					<FleetFormItem label="Delivery Date">
						<FleetDatePicker
							className="w-48"
							value={dataSource?.deliveryDate ? dayjs(dataSource?.deliveryDate) : null}
							disabled
						/>
					</FleetFormItem>
					<FleetFormItem label="Redelivery Date">
						<FleetDatePicker
							className="w-48"
							value={dataSource?.redeliveryDate ? dayjs(dataSource?.redeliveryDate) : null}
							disabled
						/>
					</FleetFormItem>
					<FleetVerifyInput label="Duration (Days)" value={dataSource?.duration} disabled />
				</div>
				<div className="flex-container">
					<FleetVerifyInput
						label="Cargo Type"
						value={dataSource?.cargoType}
						onChange={(val: string) => {
							onChange("cargoType", val)
						}}
					/>
					<FleetVerifyInput label="Cargo Quantity" value={dataSource?.cargoQuantity} disabled />
					<span>
						<span className="radio-label">Back-to-Back</span>
						<span className="flex">
							<FleetCustomRadio
								label="Yes"
								value={dataSource?.isBack === 1}
								onChange={(val) => {
									onChange?.("isBack", val === true ? 1 : 0)
								}}
							/>
						</span>
					</span>
				</div>
				<FleetVerifyInput
					label="Remarks"
					value={dataSource?.remarks}
					onChange={(val: string) => {
						onChange("remarks", val)
					}}
				/>
			</div>
			<div className="insureForm-part">
				<span className="header--title">Premium ($)</span>
				<MovementTable
					bordered
					columns={columns}
					actions={{
						onChange
					}}
					dataSource={[
						{
							cl: dataSource?.cl,
							fdd: dataSource?.fdd,
							fddDay: dataSource?.fddDay,
							total: dataSource?.total
						}
					]}
					className="insure-table"
				/>
				<div className="insureForm-premium">
					<span className="title">Premium：</span>
					<span>
						<span className="title">CL：</span>
						{dataSource?.cl} * {dataSource?.cargoQuantity}= ${" "}
						{dataSource?.cl * dataSource?.cargoQuantity}
					</span>
					<span>
						<span className="title">FDD：</span>
						{dataSource?.fdd} / 365 *{" "}
						{dataSource?.duration && Number(dataSource?.duration) > dataSource?.fddDay
							? dataSource?.duration
							: dataSource?.fddDay}{" "}
						= ${" "}
						{(
							((dataSource?.fdd || 0) / 365) *
							(dataSource?.duration && Number(dataSource?.duration) > dataSource?.fddDay
								? Number(dataSource?.duration || 0)
								: dataSource?.fddDay || 0)
						).toFixed(3)}
					</span>
				</div>
			</div>
			<div className="insureForm-part">
				<span className="header--title">CHARTERERS’ LIABILITY DECLARATION FORM</span>
				{dataSource?.files?.length > 0 &&
					dataSource?.files?.map((fitem, findex) => {
						return (
							<div className="uploadfile-item" key={findex}>
								<div className="uploadfile-info">
									<FleetImage className="img-icon" />
									<span>{fitem?.fileName}</span>
								</div>
								<div className="uploadfile-tool">
									<span
										className="font_family fleet-download  uploadfile-icon"
										onClick={() => {
											handlePreview?.("download", fitem)
										}}
									></span>
									<span
										className="font_family fleet-show uploadfile-icon"
										onClick={() => {
											handlePreview?.("preview", fitem)
										}}
									></span>
									<FleetTooltip
										outerLayer={<span className="font_family fleet-delete  uploadfile-icon"></span>}
										render={(setIsOpen) => {
											return (
												<FleetToast
													label="That file will be deleted. Are you sure?"
													onCancel={() => {
														setIsOpen(false)
													}}
													onConfirm={() => {
														setIsOpen(false)
														handleDelete?.(fitem?.id)
													}}
												/>
											)
										}}
									/>
								</div>
							</div>
						)
					})}
				<Button
					type="secondary"
					prefixIcon="fleet-update1"
					size="small"
					disabled={loading}
					onClick={handleUploadOpen}
				>
					Click to Upload
				</Button>
				<MovementUpload
					loading={loading}
					items={dataSource?.files}
					hide={hide}
					onConfirm={(files: File[]) =>
						handleUpload(files, 1, dataSource?.id, dataSource?.voyageId)
					}
					onDelete={(id: string) => {
						handleDelete?.(id)
					}}
					onClose={handleClose}
				/>
			</div>
		</div>
	)
}

export default MovementInsureForm
