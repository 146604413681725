import { ExtendsKeys } from "@/types";
import { InvFreightSettlementType } from "./type";

export const calcFreightValues = <
	K extends ExtendsKeys<
		keyof InvFreightSettlementType,
		"freightRate" | "freightType" | "freightPercent"
	>
>(
	record: InvFreightSettlementType,
	key: K,
	value: InvFreightSettlementType[K]
) => {
	let freightType = record?.freightType,
		freightRate = record?.freightRate,
		freightPercent = record?.freightPercent;
	const params: Partial<InvFreightSettlementType> = {
		[key]: value
	};

	switch (true) {
		case key === "freightType":
			freightType = value as InvFreightSettlementType["freightType"];
			break;
		case key === "freightRate":
			freightRate = value as InvFreightSettlementType["freightRate"];
			break;
		case key === "freightPercent":
			freightPercent = value as InvFreightSettlementType["freightPercent"];
			break;
		default:
			break;
	}

	const freightIncome =
		freightType === "FR" ? +freightRate * +record?.ttlCargoQuantity : +freightRate;
	// params.brokerage = freightIncome * +record?.brokerageRate / 100;
	params.addComm = (freightIncome * +record?.addCommRate * +record?.addCommPaymentRatio) / 10000;
	params.freightIncome = (freightIncome * +freightPercent) / 100;

	return params;
};;
