export enum CurrentDirective {
	North,
	Northeast = 45,
	East = 90,
	Southeast = 135,
	South = 180,
	Southwest = 225,
	West = 270,
	Northwest = 315
}

export enum Directive {
	North = 180,
	Northeast = 225,
	East = 270,
	Southeast = 315,
	South = 0,
	Southwest = 45,
	West = 90,
	Northwest = 135
}
