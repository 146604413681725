import React, { forwardRef, useImperativeHandle } from "react";
import style from "./index.module.less";
import { InvCommonOffHireItemType, InvCommonOffHireProps, InvCommonOffHireRefType } from "./type";
import { FleetPrompt } from "@/common";
import { FleetDatePicker, FleetIcon, FleetInput } from "@/components";
import dayjs from "dayjs";
import { Button } from "@/_components";
import useIndex from "./hooks/useIndex";
import { calcInput } from "./tools";
import useReminder from "@/hook/useReminder";
import { formatThousandthNumber, getRetainDecimalsNumber } from "@/tools/amount"

const InvCommonOffHire = forwardRef<InvCommonOffHireRefType, InvCommonOffHireProps>(
	({ voyageId, activeInvId, activePeriod, onConfirm }, ref) => {
		const {
			hide,
			dataSource,
			summary,
			commit,
			change,
			add,
			remove,
			handleOpen,
			handleClose,
			handleConfirm
		} = useIndex(voyageId, activeInvId, activePeriod, onConfirm);

		const { reminder } = useReminder();

		useImperativeHandle(ref, () => {
			return {
				openModal: handleOpen
			};
		});

		return (
			<FleetPrompt hide={hide} mode="fixed">
				<div className={style["invCommon-offHire"]}>
					<div className="offHire-header">
						<span className="fleet-title">Off Hire Calculator</span>
						<FleetIcon
							type="FleetCloseIcon"
							outerLayerClassname="header-icon"
							onClick={handleClose}
						/>
					</div>
					<div className="offHire-container">
						<div className="offHireItem-common offHireItem-header">
							<span className="offHireItem">Period</span>
							<span className="offHireItem">From (GMT)</span>
							<span className="offHireItem">To (GMT)</span>
							<span className="offHireItem">Duration (Days)</span>
							<span className="offHireItem">Ratio (%)</span>
							<span className="offHireItem">Final Duration</span>
							<span className="offHireItem">Remarks</span>
							<span className="offHireItem"></span>
						</div>
						<div className="offHireItem-scrollArea fleet-nonScrolBar">
							{dataSource?.map((item, idx) => {
								return (
									<div key={idx} className="offHireItem-common offHireItem-container">
										<div className="offHireItem">{item?.period}</div>
										<div className="offHireItem">
											<FleetDatePicker
												showHour
												showTime
												allowClear
												format="YYYY-MM-DD HH:mm"
												showMinute
												showSecond={false}
												value={item?.startDatetime ? dayjs(item?.startDatetime) : null}
												onChange={(val, timeStramp) => {
													const params: Partial<InvCommonOffHireItemType> = {
														startDatetime: timeStramp?.valueOf()
													}
													if (item?.endDatetime) {
														params.duration = +(
															dayjs(item?.endDatetime)?.diff(timeStramp) /
															(1000 * 60 * 60 * 24)
														)?.toFixed(2)
														params.finalDuration = getRetainDecimalsNumber(
															(params.duration * +item?.ratio) / 100,
															3
														)
														params.ratio = 100
													}
													//  else if (item?.duration) {
													// 	params.endDatetime = dayjs(timeStramp)
													// 		?.add(item?.duration, "day")
													// 		?.valueOf();
													// }
													commit(idx, params)
												}}
												// disabledDate={(current) => {
												// 	return (
												// 		(current && item?.endDatetime && current.isAfter(item?.endDatetime)) ||
												// 		current.isSame(item?.endDatetime)
												// 	);
												// }}
											/>
										</div>
										<div className="offHireItem">
											<FleetDatePicker
												showHour
												showTime
												allowClear
												format="YYYY-MM-DD HH:mm"
												// disabled={!item?.startDatetime}
												showMinute
												showSecond={false}
												value={item?.endDatetime ? dayjs(item?.endDatetime) : null}
												onChange={(val, timeStramp) => {
													const params: Partial<InvCommonOffHireItemType> = {
														endDatetime: timeStramp?.valueOf()
													}
													// if (!item?.startDatetime) return;
													if (item?.startDatetime) {
														params.duration = +(
															timeStramp?.diff(item?.startDatetime) /
															(1000 * 60 * 60 * 24)
														)?.toFixed(2)
														params.finalDuration = getRetainDecimalsNumber(
															(params.duration * +item?.ratio) / 100,
															3
														)
														params.ratio = 100
													}
													commit(idx, params)
												}}
											/>
										</div>
										<div className="offHireItem">
											<FleetInput
												value={item?.duration}
												// disabled={!item?.startDatetime}
												onChange={(val: number) => {
													// if (!item?.startDatetime) return;
													// console.log("res", calcInput(val));
													const params: Partial<InvCommonOffHireItemType> = {
														duration: val
														// finalDuration: getRetainDecimalsNumber((val * +item?.ratio) / 100, 2)
														// endDatetime: dayjs(item?.startDatetime)?.add(val, "day")?.valueOf()
													}
													commit(idx, params)
												}}
												onBlur={(event) => {
													console.log("event", event?.target?.value)
													const result = calcInput(event?.target?.value, () => {
														reminder("error", "Please enter a valid calculation rule")
													})
													const params: Partial<InvCommonOffHireItemType> = {
														duration: result,
														startDatetime: null,
														endDatetime: null,
														finalDuration: getRetainDecimalsNumber((result * +item?.ratio) / 100, 3)
														// endDatetime: dayjs(item?.startDatetime)?.add(val, "day")?.valueOf()
													}
													commit(idx, params)
												}}
											/>
										</div>
										<div className="offHireItem">
											<FleetInput
												type="number"
												value={item?.ratio}
												min={0}
												max={100}
												onChange={(val: number) => {
													commit(idx, {
														ratio: val,
														finalDuration: getRetainDecimalsNumber((val * +item?.duration) / 100, 3)
													})
												}}
											/>
										</div>
										<div className="offHireItem">
											<FleetInput type="number" disabled value={item?.finalDuration} />
										</div>
										<div className="offHireItem">
											<FleetInput
												value={item?.remark}
												onChange={(val: string) => {
													change(idx, "remark", val)
												}}
												maxLength={30}
											/>
										</div>
										<div className="offHireItem">
											<FleetIcon
												type="FleetPortAddIcon"
												outerLayerClassname="offHireItem-icon"
												onClick={() => add(idx)}
											/>
											<FleetIcon
												type="FleetPortDelIcon"
												outerLayerClassname="offHireItem-icon"
												onClick={() => remove(idx)}
											/>
										</div>
									</div>
								)
							})}
						</div>
					</div>
					<div className="offHire-count">
						<span>Total</span>
						<div>
							<span>{formatThousandthNumber(summary?.duration, 3, 3) ?? 0}</span>
							<span>{formatThousandthNumber(summary?.finalDuration, 3, 3) ?? 0}</span>
						</div>
					</div>
					<div className="offHire-footer">
						<Button type="secondary" size="small" onClick={handleClose}>
							Cancel
						</Button>
						<Button type="primary" size="small" onClick={handleConfirm}>
							Confirm
						</Button>
					</div>
				</div>
			</FleetPrompt>
		)
	}
);

export default InvCommonOffHire;
