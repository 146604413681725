import React, { FC } from "react";
import style from "./index.module.less";
import { FleetPrompt } from "@/common";
import { FleetImage, FleetLoading } from "@/components";
import { FileDownloadProps } from "./type";
import useIndex from "./hooks/useIndex";

const FileDownload: FC<FileDownloadProps> = ({ hide, data, type, onClose }) => {
	const { loading, handlePreview } = useIndex(hide);
	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={style["statement-fileDownload"]}>
				<FleetLoading loading={loading} />
				<div className="prompt-header--container">
					<span className="header-title">File List</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<div className="fileDownload-container">
					{type === "bl" &&
						data?.dischargePortDetaliList.map((item, index) => {
							return (
								<div className="fileDownload-files" key={index}>
									{<span className="header--title">{item?.dischargePortName}</span>}
									{item?.files?.length > 0 &&
										item?.files?.map((fitem, findex) => {
											return (
												<div className="uploadfile-item" key={findex}>
													<div className="uploadfile-info">
														<FleetImage className="img-icon" />
														<span>{fitem?.fileName}</span>
													</div>
													<div className="uploadfile-tool">
														<span
															className="font_family fleet-download  uploadfile-icon"
															onClick={() => {
																handlePreview?.("download", fitem);
															}}
														></span>
														<span
															className="font_family fleet-show uploadfile-icon"
															onClick={() => {
																handlePreview?.("preview", fitem);
															}}
														></span>
													</div>
												</div>
											);
										})}

									{(item.files?.length === 0 || !item.files) && (
										<div className="fileDownload-nodata">No Files</div>
									)}
								</div>
							);
						})}
					{type === "insure" &&
						data?.files?.length > 0 &&
						data?.files?.map((fitem, findex) => {
							return (
								<div className="fileDownload-files" key={findex}>
									<div className="uploadfile-item">
										<div className="uploadfile-info">
											<FleetImage className="img-icon" />
											<span>{fitem?.fileName}</span>
										</div>
										<div className="uploadfile-tool">
											<span
												className="font_family fleet-download  uploadfile-icon"
												onClick={() => {
													handlePreview?.("download", fitem);
												}}
											></span>
											<span
												className="font_family fleet-show uploadfile-icon"
												onClick={() => {
													handlePreview?.("preview", fitem);
												}}
											></span>
										</div>
									</div>
								</div>
							);
						})}

					{type === "insure" && (data?.files?.length === 0 || !data?.files) && (
						<div className="fileDownload-nodata">No Files</div>
					)}
				</div>
			</div>
		</FleetPrompt>
	);
};

export default FileDownload;
