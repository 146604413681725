import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import PortFilter from "@/featrue/database/port/filter";
import { FleetButton, FleetTable } from "@/components";
import { columns } from "./source";
import useIndex from "./hooks/useIndex";
import { PortDaFilter, PortDaNewly } from "./components";
import { Button } from "@/_components";
import { CommonRoleWrap } from "@/common";

const DatabasePortDa: React.FC<{}> = (props) => {
  const {
    loading,
    hide,
    record,
    opereteColumns,
    dataSource,
    pager,
    filter,
    selectedRowKeys,
    onSearch,
    editType,
    onPageChange,
    setHide,
    onSelectChange,
    handleReset,
    handleFilterChange,
    deletePortDa,
    handleEdit,
  } = useIndex();
  return (
		<div className={classnames(style["database-portDa"], "database-common")}>
			<div className="database-toolbar portDa-toolbar">
				<PortDaFilter item={filter} onFilterChange={handleFilterChange} onSearch={onSearch} />
				<div className="toolbar-actions">
					<CommonRoleWrap roleStr="pda:list:batchDelete">
						<Button
							size="small"
							disabled={selectedRowKeys?.length === 0}
							onClick={() => deletePortDa("all")}
							prefixIcon="fleet-delete"
						>
							Delete
						</Button>
					</CommonRoleWrap>
					<CommonRoleWrap roleStr="pda:list:add">
						<Button size="small" prefixIcon="fleet-add" onClick={() => handleEdit("add", null)}>
							Add PDA
						</Button>
					</CommonRoleWrap>
				</div>
			</div>
			<FleetTable
				columns={[...columns, ...opereteColumns]}
				className="database-table"
				dataSource={dataSource}
				loading={loading}
				id="id"
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
				rowSelection={{
					columnWidth: 64,
					selectedRowKeys,
					onChange: onSelectChange
				}}
			/>
			<PortDaNewly
				id={record?.current?.id}
				hide={hide}
				type={editType}
				onClose={() => setHide(true)}
				onConfirm={handleReset}
			/>
		</div>
	);
};

export default DatabasePortDa;
