import React, { FC } from "react";
import { InvStatementOffHireGroupProps } from "./type";
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/components";
import { Button } from "@/_components";
import { InvStatementOffHire } from "./components";
import style from "./index.module.less";
import { cn } from "@/tools";

const InvStatementOffHireGroup: FC<InvStatementOffHireGroupProps> = ({
	otherItem,
	onOtherItemChange,
	onAdd,
	onOffHireOpen,
	items,
	onCommit,
	onDelete,
	onGroupAdd,
	onGroupCommit,
	onGroupDelete
}) => {
	return (
		<div className={cn("invoicesDetail-form", style["invStatement-offHire-group"])}>
			<div className="w-full flex gap-[8px] flex-row justify-between items-center !pb-0 !border-b-0">
				<InvoiceAddTo
					type="addBunker"
					value={otherItem?.addOffHire}
					onChange={(key, val) => {
						onOtherItemChange?.("addOffHire", val)
					}}
				>
					<span>Off-Hire Calculation</span>
				</InvoiceAddTo>
				<Button prefixIcon="fleet-plus" type="primary" size="small" onClick={onAdd}>
					New Off-Hire Group
				</Button>
			</div>
			{items?.map((item, currentIndex) => {
				return (
					<InvStatementOffHire
						key={currentIndex}
						currentIndex={currentIndex}
						onOffHireOpen={() => {
							onOffHireOpen?.(currentIndex)
						}}
						item={item}
						onCommit={(params) => {
							onCommit?.(currentIndex, params)
						}}
						onDelete={() => {
							onDelete?.(currentIndex)
						}}
						onGroupAdd={(type) => {
							onGroupAdd?.(type, currentIndex)
						}}
						onGroupCommit={(type, index, params) => {
							onGroupCommit?.(type, currentIndex, index, params)
						}}
						onGroupDelete={(type, index) => {
							onGroupDelete?.(type, currentIndex, index)
						}}
					/>
				)
			})}
		</div>
	)
};

export default InvStatementOffHireGroup;
