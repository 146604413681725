import { MutableRefObject, useRef } from "react";
import { RouteCommonMapRefType } from "../type";
import { GeoJSONSource } from "mapbox-gl";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { formLocalToUtc } from "../tools";

dayjs.extend(utc);

const routePlanSource = {
	"route-plan-line-source": "route-plan-line-layer",
	"route-plan-vessel-source": "route-plan-vessel-layer"
};

const useRoutePlan = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const activeRouteMap = useRef<
		Map<string, GeoJSON.Feature<GeoJSON.MultiLineString, { type?: "primary_route" | "other" }>>
	>(new Map());
	const activePointsMap = useRef<
		Map<string, GeoJSON.Feature<GeoJSON.Point, { lat: number; lon: number; time: number }>[]>
	>(new Map());

	const curretPosition = useRef<
		Map<string, GeoJSON.Feature<GeoJSON.Point, { lat: number; lon: number; time: number }>>
	>(new Map());

	const clear = () => {
		activePointsMap?.current?.clear();
		activePointsMap?.current?.clear();
	};

	const routeCommit = (
		items: Record<
			string,
			{ features: GeoJSON.Feature<GeoJSON.MultiLineString, { type?: "primary_route" | "other" }>[] }
		>
	) => {
		activeRouteMap?.current?.clear();
		for (let [uuid, feature] of Object.entries(items)) {
			activeRouteMap?.current?.set(uuid, feature?.features?.[0]);
		}
	};

	const pointCommit = (
		items: Record<
			string,
			GeoJSON.Feature<GeoJSON.Point, { lat: number; lon: number; time: number }>[]
		>
	) => {
		activePointsMap?.current?.clear();
		curretPosition?.current?.clear();
		for (let [uuid, features] of Object.entries(items)) {
			activePointsMap?.current?.set(uuid, features);
			curretPosition?.current?.set(uuid, features?.[0]);
		}
	};

	const loadSource = () => {
		mapboxGl?.current?.addSource("route-plan-line-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		});
		mapboxGl?.current?.addSource("route-plan-vessel-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		});
	};

	const loadLayers = () => {
		mapboxGl?.current?.addLayer({
			id: routePlanSource["route-plan-line-source"],
			source: "route-plan-line-source",
			type: "line",
			layout: {
				"line-join": "round",
				"line-cap": "round"
			},
			paint: {
				// "line-color": "#6081cb99",
				// "line-color": ["case", ["==", ["get", "type"], "primary_route"], "#365691", "#6081cb"],
				"line-color": "#6081cb",
				"line-width": 4
			}
		});
		mapboxGl?.current?.addLayer({
			id: routePlanSource["route-plan-vessel-source"],
			source: "route-plan-vessel-source",
			type: "symbol",
			paint: {},
			layout: {
				"icon-size": 0.6,
				"icon-allow-overlap": true,
				"icon-image": "routePlanVesselIcon",
				"icon-rotate": ["get", "heading"]
			}
		});
	};

	const loadRoutePlan: RouteCommonMapRefType["onRoutePlanLoad"] = (items) => {
		console.log("RouteCommonMapRefType", items);
		routeCommit(items);
		const features: GeoJSON.Feature<
			GeoJSON.MultiLineString,
			{ type?: "primary_route" | "other" }
		>[] = [];
		for (let line of activeRouteMap?.current?.values()) {
			features?.push(line);
			console.log("loadRoutePlan", line);
		}
		const currentLineSource = mapboxGl?.current?.getSource(
			"route-plan-line-source"
		) as GeoJSONSource;
		currentLineSource?.setData({
			type: "FeatureCollection",
			features: features
		});
	};

	const loadRoutePlanVessel: RouteCommonMapRefType["onRoutePlanVesselLoad"] = (items) => {
		pointCommit(items);
		const features: GeoJSON.Feature<GeoJSON.Point, { lat: number; lon: number; time: number }>[] = [
			...curretPosition?.current?.values()
		];
		const currentSource = mapboxGl?.current?.getSource("route-plan-vessel-source") as GeoJSONSource;
		currentSource?.setData({
			type: "FeatureCollection",
			features: features
		});
	};

	const updateRoutePlanVessel = (time: number) => {
		const currentSource = mapboxGl?.current?.getSource("route-plan-vessel-source") as GeoJSONSource;
		const currentTime = dayjs.utc(formLocalToUtc(dayjs(time)?.startOf("minute")));
		console.log("points", formLocalToUtc(dayjs(time)));
		for (let [uuid, points] of activePointsMap?.current?.entries()) {
			const activePoint = points?.find((point) => {
				return dayjs.utc(point?.properties?.time)?.startOf("minute")?.isSame(currentTime);
			});
			if (activePoint) {
				curretPosition?.current?.set(uuid, activePoint);
			}
		}
		currentSource?.setData({
			type: "FeatureCollection",
			features: [...curretPosition?.current?.values()]
		});
	};

	const removeRoutePlan = (uuid: string) => {
		const currentSource = mapboxGl?.current?.getSource("route-plan-vessel-source") as GeoJSONSource;
		const currentLineSource = mapboxGl?.current?.getSource(
			"route-plan-line-source"
		) as GeoJSONSource;
		const features: GeoJSON.Feature<
			GeoJSON.MultiLineString,
			{ type?: "primary_route" | "other" }
		>[] = [];
		activePointsMap?.current?.delete(uuid);
		activeRouteMap?.current?.delete(uuid);
		curretPosition?.current?.delete(uuid);
		for (let line of activeRouteMap?.current?.values()) {
			features?.push(line);
		}
		currentSource?.setData({
			type: "FeatureCollection",
			features: [...curretPosition?.current?.values()]
		});
		currentLineSource?.setData({
			type: "FeatureCollection",
			features: features
		});
	};

	return {
		loadSource,
		loadLayers,
		loadRoutePlan,
		loadRoutePlanVessel,
		updateRoutePlanVessel,
		removeRoutePlan
	};
};

export default useRoutePlan;
