import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { InvCommonOtherExpensesProps } from "./type";
import { FleetIcon, FleetVerifyInput } from "@/components";
import { Button, Icon } from "@/_components"
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module"
import { cn } from "@/tools"

const InvCommonOtherExpenses: FC<InvCommonOtherExpensesProps> = ({
	items,
	// onFieldOpen,
	onChange,
	onDelete,
	otherItem,
	onOtherItemChange,
	onAdd
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invoices-otherExpenses"])}>
			<div className="invoicesForm-header">
				<InvoiceAddTo
					type="addOther"
					value={otherItem?.addOther}
					onChange={(key, val) => {
						onOtherItemChange?.("addOther", val)
					}}
				>
					<span>Other Items</span>
				</InvoiceAddTo>
				<span>Settlement other expenses</span>
			</div>
			{items?.map((item, index) => {
				return (
					<div className="invoicesForm-container" key={index}>
						<FleetVerifyInput
							value={item?.fieldName}
							label="Title"
							onChange={(val: string) => {
								onChange?.(index, "fieldName", val)
							}}
						/>
						<FleetVerifyInput
							value={item?.fieldVal}
							label="Total Amount ($)"
							type="number"
							onChange={(val: number) => {
								onChange?.(index, "fieldVal", val)
							}}
						/>
						<div className={cn("grid grid-cols-[1fr_32px] gap-[4px]")}>
							<FleetVerifyInput
								value={item?.description}
								label="Remarks"
								onChange={(val: string) => {
									onChange?.(index, "description", val)
								}}
							/>
							<Icon
								type="fleet-minus"
								className="self-end opacity-40"
								size={32}
								onClick={() => {
									onDelete?.(index)
								}}
							/>
						</div>
					</div>
				)
			})}
			{/* <div className="invoicesForm-container">
				{items?.map((item, index) => {
					return (
						<div className="otherExpenses-fields" key={index}>
							<FleetVerifyInput
								value={item?.fieldVal}
								label={item?.fieldName}
								type="number"
								onChange={(val: number) => {
									onChange?.(index, "fieldVal", val);
								}}
							/>
							<FleetIcon
								type="FleetPortDelIcon"
								onClick={() => {
									onDelete?.(index);
								}}
							/>
						</div>
					);
				})}
			</div> */}
			<Button
				prefixIcon="fleet-plus"
				type="primary"
				size="small"
				className="invoices-add cursor-pointer"
				// onClick={() => onFieldOpen?.("otherItems")}
				onClick={() => {
					onAdd?.({ fieldName: "", fieldVal: "", description: "", type: "" })
				}}
			>
				Add field
			</Button>
		</div>
	)
}

export default InvCommonOtherExpenses