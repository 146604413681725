import React, { useImperativeHandle, forwardRef, Suspense } from "react";
import style from "./index.module.less";
import { RouteCommonDetailsProps, RouteCommonDetailsRefType } from "./type";
import useIndex from "./hooks/useIndex";
import {
	RouteCommonAreaVessel,
	RouteCommonDailyReport,
	RouteCommonDetailsTabs,
	RouteCommonPortCall,
	RouteCommonPortVessel,
	RouteCommonRoutePlan,
	RouteCommonRoutePlanDetails
} from "./components";
import { FleetLoading } from "@/components";

const RouteCommonDetails = forwardRef<RouteCommonDetailsRefType, RouteCommonDetailsProps>(
	(
		{
			activeVoyageTab,
			activeOption,
			onDetailsTabSelect,
			onVesselLoad,
			onAreaVesselSelect,
			onPortVesselSelect,
			onFlyTo,
			onRoutePlanLoad,
			onRoutePlanVesselLoad,
			onRoutePlanRemove,
			onDailyReportLoad
		},
		ref
	) => {
		const { areaVesselRef, portVesselRef, headerRef, wrapperRef, handlePortCallOpen } = useIndex();
		useImperativeHandle(ref, () => {
			return {
				areaVesselRef: areaVesselRef,
				portVesselRef: portVesselRef
			};
		});
		return (
			<div className={style["routeCommon-details"]} ref={wrapperRef} id="routeCommon-details">
				<div className="routeCommon-details-header" ref={headerRef}>
					<RouteCommonDetailsTabs
						activeTab={activeOption?.activeTab}
						onSelect={onDetailsTabSelect}
					/>
				</div>
				<div className="routeCommon-details-container">
					<RouteCommonDailyReport onDailyReportLoad={onDailyReportLoad} />
					<RouteCommonRoutePlan
						onRoutePlanLoad={onRoutePlanLoad}
						onRoutePlanVesselLoad={onRoutePlanVesselLoad}
						onRoutePlanRemove={onRoutePlanRemove}
					/>
					<RouteCommonRoutePlanDetails onFlyTo={onFlyTo} />
					<Suspense fallback={<FleetLoading loading />}>
						<RouteCommonAreaVessel
							ref={areaVesselRef}
							onPortCallOpen={handlePortCallOpen}
							onVesselLoad={onVesselLoad}
							onVesselSelect={onAreaVesselSelect}
						/>
					</Suspense>
					<RouteCommonPortVessel
						ref={portVesselRef}
						onPortCallOpen={handlePortCallOpen}
						onVesselLoad={onVesselLoad}
						onVesselSelect={onPortVesselSelect}
					/>
					<RouteCommonPortCall />
				</div>
			</div>
		);
	}
);

export default RouteCommonDetails;
