import { MovementCommonTableColumnsType } from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table/type";
import { MidExpensesItemType } from "./type";
import React from "react";
import { FleetInput } from "@/components";
import { Icon } from "@/_components";

export const columns: MovementCommonTableColumnsType<
	MidExpensesItemType,
	keyof MidExpensesItemType,
	{
		onChange: (currentIndex: number, value: number) => void;
		onRemove: (currentIndex: number) => void;
	}
>[] = [
	{
		label: "Item Name",
		dataIndex: "defaultField",
		width: 175,
		// fixed: "left",
		render: (value, record, index, actions) => {
			return (
				<span>
					Mid {index + 1} Expense{}
				</span>
			);
		}
	},
	{
		label: "Amount",
		dataIndex: "expenses",
		width: 175,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					type="number"
					className="td-input"
					value={value}
					onChange={(value: number) => {
						actions?.onChange?.(index, value);
					}}
				/>
			);
		}
	},
	{
		label: "Action",
		dataIndex: "action",
		// fixed: "right",
		width: 50,
		render: (value, record, index, actions) => {
			return (
				<div className="flex items-center justify-center">
					<Icon
						type="fleet-delete"
						color="red"
						onClick={() => {
							actions?.onRemove?.(index);
						}}
					/>
				</div>
			);
		}
	}
];
