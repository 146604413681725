import { convertToDms } from "@/tools/graph"
import { dateToUtcString } from "@/tools/date"
import { formatThousandthNumber } from "@/tools/amount"
import React from "react"
import { CommonVesselArrivalKeys } from "@/common/VesselArchives/type";


// 船舶类型枚举
export enum ArrivalVesselTypeMap {
	TANKER,
	LNG,
	BULK,
	PCC,
	CNTR,
	MR,
	Other = 99
}

// 船舶二级类型枚举
export enum ArrivalVesselSubTypeMap {
	VLCC = 1,
	Aframax,
	Suezmax,
	"145K" = 11,
	"160K",
	"174K",
	"200+K",
	"Other",
	VLOC = 21,
	Capesize,
	"Post-Panamax",
	Panamax,
	Supramax,
	Handymax,
	Handysize,
	"Small Bulk",
	PCC = 31,
	Feeder = 41,
	"Intermediate (3000-5999TEU)",
	"Intermediate (6000-7999TEU)",
	"Neo-Panamax (8000-11999TEU)",
	"Neo-Panamax (12000-16999TEU)",
	"Post-Panamax (17000+TEU)",
	MR = 51
}

// 脱硫塔安装状态
export enum ArrivalSoxScrubberStatusMap {
  "否",
  "是",
}

// 状态
export enum ArrivalStatusMap {
  Orderbook = 1,
  "In Service",
  Repair,
  Storage,
  "Laid Up",
  Abnormal,
  FPSO,
  Decommisioned
}

// 节能型标识
export enum ArrivalEcoEngineMap {
  "非节能型",
  "节能型"
}

// 节能型版本
export enum ArrivalEnergySavingTypeMap {
  "第一代节能型" = 1,
  "第二代节能型"
}

// 贸易类型
export enum ArrivalTradeTypeMap {
  "内贸" = 1,
  "外贸",
  "内外贸"
}

// 是否有传动装置
export enum ArrivalGearMap {
  "否",
  "是",
  "无(未知)" = null
}

export enum ArrivalShipStatusMap {
  // 0 未知
  Unknown,
  // 1（在海）重载运行
  Laden,
  // 2（在海）空载运行
  Ballast,
  // 3（在港）装货
  Loading,
  // 4（在港）卸货
  Discharging,
  // 5（在港）等待装货
  "Waiting Loading",
  // 6（在港）等待卸货
  "Waiting Discharging",
  // 7（在港）修船
  Dockyard
}


export const vesselArrivalOptions: {
  dataIndex: CommonVesselArrivalKeys,
  renderValue?: (key, record, aisRecord) => React.ReactNode
}[][] = [
    [
      { dataIndex: "lastTime", renderValue: (key, record, aisRecord) => <span>{aisRecord?.[key] && dateToUtcString(aisRecord?.[key]).slice(0, -3)}</span> },
      { dataIndex: "imo", renderValue: (key, record, aisRecord) => <span>{aisRecord?.[key]}</span> },
      { dataIndex: "coordinates", renderValue: (key, record, aisRecord) => <span>{convertToDms(aisRecord?.lat, aisRecord?.lon)}</span> },
      { dataIndex: "dest", renderValue: (key, record, aisRecord) => <span>{aisRecord?.[key]}</span> },
      { dataIndex: "speed", renderValue: (key, record, aisRecord) => <span>{formatThousandthNumber(aisRecord?.[key])}</span> },
      { dataIndex: "eta", renderValue: (key, record, aisRecord) => <span>{record?.[key] && dateToUtcString(aisRecord?.[key]).slice(5, -3)}</span> }
    ],
    [
      { dataIndex: "vesselType", renderValue: (key, record, aisRecord) => <span>{ArrivalVesselTypeMap[record?.[key]]}</span> },
      { dataIndex: "vesselTypeSub", renderValue: (key, record, aisRecord) => <span>{ArrivalVesselSubTypeMap[record?.[key]]}</span> },
      { dataIndex: "mmsi" },
      { dataIndex: "callSign" },
      { dataIndex: "dwt", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
      { dataIndex: "tpc", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> }
    ],
    [
      { dataIndex: "loa", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
      { dataIndex: "bm", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
      { dataIndex: "draft", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
      { dataIndex: "grossTonnage" },
      { dataIndex: "netTonnage" },
      { dataIndex: "depth", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> }
    ],
    [
      { dataIndex: "portOfRegistry" },
      { dataIndex: "displacement" },
      { dataIndex: "soxScrubberStatus", renderValue: (key, record, aisRecord) => <span>{ArrivalSoxScrubberStatusMap[record?.[key]]}</span> },
      { dataIndex: "flagCountryName" },
      { dataIndex: "speedMax" },
      { dataIndex: "seviceSpeed" }
    ],
    [
      // { dataIndex: "builderCountry" },
      { dataIndex: "builder" },
      { dataIndex: "buildYear" },
      { dataIndex: "buildMonth" },
      { dataIndex: "classification" },
      { dataIndex: "status", renderValue: (key, record, aisRecord) => <span>{ArrivalStatusMap[record?.[key]]}</span> }
    ],
    [
      // { dataIndex: "ownerCode" },
      // { dataIndex: "operatorCode" },
      // { dataIndex: "managerCode" },
      { dataIndex: "ecoEngine", renderValue: (key, record, aisRecord) => <span>{ArrivalEcoEngineMap[record?.[key]]}</span> },
      { dataIndex: "energySavingType", renderValue: (key, record, aisRecord) => <span>{ArrivalEnergySavingTypeMap[record?.[key]]}</span> },
      { dataIndex: "updateTime", renderValue: (key, record) => <span>{record?.[key] && dateToUtcString(record?.[key]).slice(0, -3)}</span> }
    ],
    [
      { dataIndex: "vesselNameCn" },
      { dataIndex: "ventureType" },
      { dataIndex: "exName" },
      { dataIndex: "pniClub" },
      { dataIndex: "baleCapacity" },
      { dataIndex: "grainCapacity" }
    ],
    [
      { dataIndex: "holdsTotalNo" },
      { dataIndex: "hatchesTotalNo" },
      // { dataIndex: "countryName" },
      { dataIndex: "operatorCountryName" },
      { dataIndex: "managerCountryNameCn" },
      { dataIndex: "auxiliaryEngineNumber" }
    ],
    [
      { dataIndex: "mainEngineNumber" },
      { dataIndex: "mainEngineModel" },
      { dataIndex: "powerType" },
      { dataIndex: "mainEngineFuelType" },
      { dataIndex: "mainEngineRpm" },
      { dataIndex: "mainEngineMcrRpm" }
    ],
    [
      { dataIndex: "gear", renderValue: (key, record, aisRecord) => <span>{ArrivalGearMap[record?.[key]]}</span> },
      { dataIndex: "gearSummary" },
      { dataIndex: "grabsNo" },
      { dataIndex: "countryName" },
      { dataIndex: "countryNameCn" },
      { dataIndex: "companyName" }
    ],
    [
      { dataIndex: "operatorCountryName" },
      { dataIndex: "operatorCompanyName" },
      { dataIndex: "builderCountryName" },
      { dataIndex: "builderCompanyName" },
      { dataIndex: "managerCompanyName" },
      { dataIndex: "managerCountryName" }
    ],
  ];