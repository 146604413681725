import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Layout from "@/layout";
import { useAppSelector } from "@/hook";
import { cacheStorage } from "@/storage";
import { findRouter } from "@/tools/menus";

const PrivateRoute: React.FC<{
	[propName: string]: any
}> = (props) => {
	const location = useLocation()
	const access_token = cacheStorage.get("access_token")
	const _access_token = useAppSelector((state) => state.UserStore.access_token)
	const { userInfo } = useAppSelector((state) => state.UserStore)

	console.log("userInfo", userInfo, location?.pathname)

	if (!(access_token || _access_token)) {
		return <Navigate to="/login" />
	}

	if (location?.pathname === "/layout/routecommon") {
		return <Layout />
	}

	if (!findRouter(location?.pathname, userInfo?.menus)) {
		return <Navigate to="/404" />
	}

	// if (location?.pathname === "/layout/voyagemanage" || location?.pathname === "/layout/database") {
	//   return
	// }

	return <Layout />
}

export default PrivateRoute;
