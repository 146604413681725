import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetTable } from "@/components";
import useGroup from "@/hook/database/contact/useGroup";
import ContactFilter from "@/featrue/database/contact/filter";
import { CommonNewlyContact, CommonRoleWrap } from "@/common";
import DatabaseContactEdit from "@/featrue/database/contact/edit";
import { Button } from "@/_components";

const DatabaseContact: React.FC<{}> = (props) => {
	const {
		loading,
		hideMap,
		setHide,
		columns,
		dataSource,
		pager,
		onHideChange,
		addLoading,
		onAdd,
		setAddLoading,
		filter,
		onPageChange,
		selectedRowKeys,
		onSelectChange,
		onFilterChange,
		onSearch,
		onRow,
		record,
		onEdit,
		handleConfirm
	} = useGroup();
	return (
		<div className={classnames(style["database-contact"], "database-common")}>
			<div className="database-toolbar contact-toolbar">
				<ContactFilter onSearch={onSearch} onChange={onFilterChange} item={filter} />
				<CommonRoleWrap roleStr="contact:list:add">
					<Button size="small" prefixIcon="fleet-add" onClick={() => onHideChange("add", false)}>
						Add Contact
					</Button>
				</CommonRoleWrap>
			</div>
			<FleetTable
				columns={columns}
				className="database-table"
				dataSource={dataSource}
				loading={loading}
				id="id"
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
				rowSelection={{
					columnWidth: 64,
					selectedRowKeys,
					onChange: onSelectChange
				}}
				onRow={onRow}
			/>
			<CommonNewlyContact
				hide={hideMap?.add}
				onClose={() => onHideChange("add", true)}
				onConfirm={onAdd}
				loading={addLoading}
				setLoading={setAddLoading}
			/>
			<DatabaseContactEdit
				hide={hideMap?.edit}
				onClose={() => onHideChange("edit", true)}
				id={record?.current?.id}
				onConfirm={handleConfirm}
			/>
		</div>
	);
};

export default DatabaseContact;
