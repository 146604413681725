import React, { forwardRef, useImperativeHandle } from "react";
import { MidExpensesProps, MidExpensesRefType } from "./type";
import { FleetPrompt } from "@/common";
import useIndex from "./hooks/useIndex";
import { Button, Icon } from "@/_components";
import MovementTable from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table";
import { columns } from "./source";
import { filter } from "lodash";

const MidExpenses = forwardRef<MidExpensesRefType, MidExpensesProps>(({ onConfirm }, ref) => {
	const {
		activeIndex,
		hide,
		dataSource,
		summary,
		setHide,
		add,
		change,
		remove,
		closeModal,
		openModal
	} = useIndex();
	useImperativeHandle(ref, () => {
		return {
			openModal
		};
	});
	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className="m-[500px] flex flex-col gap-[12px] bg-white p-[20px] rounded-[6px]">
				<div className="prompt-header--container">
					<span className="header-title">Intermediate Expense</span>
					<Icon type="fleet-close" onClick={closeModal} />
				</div>
				<div className="w-full flex flex-col gap-[12px]">
					<MovementTable
						bordered
						className="w-full !h-[300px]"
						columns={columns}
						dataSource={dataSource}
						actions={{
							onChange: change,
							onRemove: remove
						}}
						summary={summary}
					/>
					<Button type="secondary" size="small" onClick={add}>
						Add amount
					</Button>
				</div>
				<div className="prompt-footer--container">
					<Button size="small" type="secondary" onClick={closeModal}>
						Cancel
					</Button>
					<Button
						size="small"
						onClick={() => {
							onConfirm?.(
								activeIndex?.current,
								dataSource?.filter((item) => !!item?.expenses).map((item) => +item?.expenses)
							);
							setHide(true);
						}}
					>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
});

export default MidExpenses;
