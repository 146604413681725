import React, { useRef } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { I18nextProvider } from "react-i18next";
import { ConfigProvider as DialogProvider } from "@/components/Dialog/store";

import "@/asserts/styles/global.less"
import "@/asserts/iconfont/iconfont.js"

import store from "@/store"
import AuthRouter from "@/router"
import { AliveScope } from "./router/keepAlice"
import { Toaster } from "react-hot-toast"
import i18n from "./locale"

const App: React.FC<{}> = (props) => {
	const notistackRef = useRef(null);

	return (
		<Provider store={store}>
			<SnackbarProvider maxSnack={3} ref={notistackRef}>
				<DialogProvider>
					<I18nextProvider i18n={i18n}>
						<AliveScope>
							<BrowserRouter>
								<AuthRouter></AuthRouter>
							</BrowserRouter>
						</AliveScope>
					</I18nextProvider>
				</DialogProvider>
			</SnackbarProvider>
			<Toaster />
		</Provider>
	);
};

export default App;
