import { useMemo } from "react"
import { MovementTctcVesselBasicsItemType } from "../components"
import { MovementTctcOtherItemType } from "../type"
import useEstimation from "./useEstimation"
import { MovementFinancialDataItemType, initialFinacialItem } from "../../../common"
import { calcPercentage } from "@/tools/amount"
import { downloadMovementTctcPnlParams } from "@/api/voyageManage/movement/pnl/type"

const usePnl = (
	rentVesselItem: MovementTctcVesselBasicsItemType,
	reletVesselItem: MovementTctcVesselBasicsItemType,
	otherItem: MovementTctcOtherItemType
) => {
	const {
		totalProfit,
		totalRevenue,
		tce,
		totalCost: totalRentCost,
		rentTotalHireCost,
		reletTotalHireCost,
		otherExpenses,
		rentCommission,
		rentBrokerage,
		reletCommission,
		reletBrokerage,
		rentHireCost,
		reletHireCost,
		rentHireExpense,
		rentTotalBunkerCost,
		reletTotalBunkerCost,
		reletHireExpense,
		disputeCharges,
		otherCommission
	} = useEstimation(rentVesselItem, reletVesselItem, otherItem)

	const totalSummary = useMemo(() => {
		const revenues =
			(otherItem?.ar?.otherIncome ?? 0) +
			(otherItem?.ar?.invHire ?? 0) +
			(otherItem?.reletBunkerPlan?.bunkerPlanSettled?.settledAmount ?? 0)
		const totalCost =
			(otherItem?.ap?.invHire ?? 0) +
			(otherItem?.rentBunkerPlan?.bunkerPlanSettled?.settledAmount ?? 0) +
			(otherItem?.ap?.moveCommission ?? 0) +
			(otherItem?.ap?.otherExpenseItem ?? 0)

		return {
			revenues,
			totalCost
		}
	}, [
		otherItem?.otherIncome,
		otherItem?.ar,
		otherItem?.reletBunkerPlan,
		otherItem?.ap,
		otherItem?.rentBunkerPlan
	])

	const totalProfitAndLoss = useMemo<MovementFinancialDataItemType>(() => {
		return initialFinacialItem({
			desc: "Profit & Loss",
			actual: totalProfit,
			posted: totalSummary.revenues - totalSummary.totalCost
		})
	}, [totalProfit, totalSummary])

	const reletBunkerCost = useMemo(() => {
		return initialFinacialItem({
			desc: "Bunker Cost",
			actual: reletTotalBunkerCost,
			posted: otherItem?.reletBunkerPlan?.bunkerPlanSettled?.settledAmount,
			diffPosted: calcPercentage(
				reletTotalBunkerCost,
				otherItem?.reletBunkerPlan?.bunkerPlanSettled?.settledAmount ?? 0
			),
			children: [
				initialFinacialItem({
					desc: "HSFO Cost",
					actual:
						otherItem?.reletBunkerPlan?.hsfoTotalPrice +
						+otherItem?.reletBunkerPlan?.scrubberTotalPrice
				}),
				initialFinacialItem({
					desc: "LSFO Cost",
					actual: otherItem?.reletBunkerPlan?.lsfoTotalPrice
				}),
				initialFinacialItem({
					desc: "MDO Cost",
					actual: otherItem?.reletBunkerPlan?.mdoTotalPrice
				}),
				initialFinacialItem({
					desc: "MGO Cost",
					actual: otherItem?.reletBunkerPlan?.mgoTotalPrice
				})
			]
		})
	}, [reletTotalBunkerCost, otherItem?.reletBunkerPlan])

	const totalRevenues = useMemo<MovementFinancialDataItemType>(() => {
		return initialFinacialItem({
			desc: "Total Revenues",
			actual: totalRevenue,
			posted: totalSummary?.revenues,
			diffPosted: totalRevenue ? (totalSummary?.revenues - totalRevenue) / totalRevenue : 0,
			children: [
				initialFinacialItem({
					desc: "Hire Income",
					actual: reletHireCost,
					posted: otherItem?.ar?.invHire,
					diffPosted: calcPercentage(reletHireCost, otherItem?.ar?.invHire ?? 0),
					children: reletHireExpense?.calcuate
				}),
				reletBunkerCost,
				initialFinacialItem({
					desc: "Others Income",
					actual: +otherItem?.otherIncome,
					posted: otherItem?.ar?.otherIncome,
					diffPosted: calcPercentage(otherItem?.otherIncome, otherItem?.ar?.otherIncome ?? 0)
				})
			]
		})
	}, [
		reletHireCost,
		totalRevenue,
		otherItem?.otherIncome,
		reletHireExpense,
		reletBunkerCost,
		otherItem?.ar,
		totalSummary
	])

	const rentBunkerCost = useMemo(() => {
		return initialFinacialItem({
			desc: "Bunker Cost",
			actual: rentTotalBunkerCost,
			posted: otherItem?.rentBunkerPlan?.bunkerPlanSettled?.settledAmount,
			diffPosted: calcPercentage(
				rentTotalBunkerCost,
				otherItem?.rentBunkerPlan?.bunkerPlanSettled?.settledAmount ?? 0
			),
			children: [
				initialFinacialItem({
					desc: "HSFO Cost",
					actual:
						otherItem?.rentBunkerPlan?.hsfoTotalPrice +
						+otherItem?.rentBunkerPlan?.scrubberTotalPrice
				}),
				initialFinacialItem({
					desc: "LSFO Cost",
					actual: otherItem?.rentBunkerPlan?.lsfoTotalPrice
				}),
				initialFinacialItem({
					desc: "MDO Cost",
					actual: otherItem?.rentBunkerPlan?.mdoTotalPrice
				}),
				initialFinacialItem({
					desc: "MGO Cost",
					actual: otherItem?.rentBunkerPlan?.mgoTotalPrice
				})
			]
		})
	}, [rentTotalBunkerCost, otherItem?.rentBunkerPlan, otherItem?.ap])

	const totalCommission = useMemo(() => {
		return initialFinacialItem({
			desc: "Commission",
			actual: otherCommission?.total,
			children: [
				...otherCommission?.calcuate
				// initialFinacialItem({
				// 	desc: "Chrt's Brokerage",
				// 	actual: reletBrokerage
				// }),
				// initialFinacialItem({
				// 	desc: "Chit's Addcom.",
				// 	actual: reletCommission
				// }),
				// initialFinacialItem({
				// 	desc: "Owr's Brokerage",
				// 	actual: rentBrokerage
				// }),
				// initialFinacialItem({
				// 	desc: "Owr's Addcom.",
				// 	actual: rentCommission
				// })
			]
		})
	}, [otherCommission])
	const totalOtherExpenses = useMemo(() => {
		return initialFinacialItem({
			desc: "Other Expenses",
			actual: otherExpenses?.total,
			posted: otherItem?.ap?.otherExpenseItem,
			diffPosted: calcPercentage(otherExpenses?.total, otherItem?.ap?.otherExpenseItem ?? 0),
			children: otherExpenses?.calcuate
		})
	}, [otherExpenses, otherItem?.ap])

	const totalCost = useMemo(() => {
		return initialFinacialItem({
			desc: "Total Cost",
			actual: totalRentCost,
			posted: totalSummary.totalCost,
			children: [
				rentBunkerCost,
				initialFinacialItem({
					desc: "Hire Cost",
					actual: rentHireCost,
					posted: otherItem?.ap?.invHire,
					diffPosted: calcPercentage(rentHireCost, otherItem?.ap?.invHire ?? 0),
					children: rentHireExpense?.calcuate
				}),
				totalCommission,
				totalOtherExpenses
			]
		})
	}, [
		totalRentCost,
		rentBunkerCost,
		rentHireExpense,
		rentHireCost,
		totalCommission,
		totalOtherExpenses,
		otherItem?.ap
	])

	const totalDisputeCharge = useMemo(() => {
		return initialFinacialItem({
			desc: "Dispute Charge",
			actual: disputeCharges?.total,
			children: disputeCharges?.calcuate
		})
	}, [disputeCharges])

	const handleCombineParams = (
		item: Omit<downloadMovementTctcPnlParams, "income" | "outgoing" | "disputeCharges" | "totalPnl">
	): downloadMovementTctcPnlParams => {
		// const revenues =
		// 	+otherItem?.otherIncome +
		// 	(otherItem?.ar?.invHire ?? 0) +
		// 	(otherItem?.reletBunkerPlan?.bunkerPlanSettled?.settledAmount ?? 0)
		// const totalCost =
		// 	(otherItem?.ap?.invHire ?? 0) +
		// 	(otherItem?.rentBunkerPlan?.bunkerPlanSettled?.settledAmount ?? 0) +
		// 	(otherItem?.ap?.moveCommission ?? 0) +
		// 	(otherItem?.ap?.otherExpenseItem ?? 0)
		return {
			...item,
			income: {
				hireList: reletHireExpense?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					time: item?.time,
					payerName: item?.payerName,
					posted: item?.posted
				})),
				bunkerage: {
					bunkerCost: otherItem?.reletBunkerPlan?.bunkerPlanSettled?.latestFinancialDate
						? otherItem?.reletBunkerPlan?.bunkerPlanSettled?.settledAmount
						: reletTotalBunkerCost,
					bunkerCostTime: otherItem?.reletBunkerPlan?.bunkerPlanSettled?.latestFinancialDate
				},
				otherIncome: otherItem?.otherIncome,
				total: 0
			},
			outgoing: {
				hireList: rentHireExpense?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					time: item?.time,
					payerName: item?.payerName,
					posted: item?.posted
				})),
				bunkerage: {
					bunkerCost: otherItem?.rentBunkerPlan?.bunkerPlanSettled?.latestFinancialDate
						? otherItem?.rentBunkerPlan?.bunkerPlanSettled?.settledAmount
						: rentTotalBunkerCost,
					bunkerCostTime: otherItem?.rentBunkerPlan?.bunkerPlanSettled?.latestFinancialDate
				},
				commission: {
					chrtsCommission: reletCommission,
					chrtsBrokerage: reletBrokerage,
					ownrsBrokerage: rentBrokerage,
					ownrsCommission: rentCommission
				},
				commissionList: otherCommission?.calcuate?.map((commissionItem) => ({
					actual: commissionItem?.actual,
					posted: commissionItem?.posted,
					time: commissionItem?.time,
					desc: commissionItem?.desc,
					payerName: commissionItem?.payerName
				})),
				otherExpenses: otherExpenses?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					posted: item?.posted,
					time: item?.time
				})),
				total: 0
			},
			disputeCharges: disputeCharges?.calcuate?.map((item) => ({
				desc: item?.desc,
				actual: item?.actual
			})),
			totalPnl: 0
		}
	}

	console.log("totalSummary", totalSummary)

	return {
		totalRevenues,
		totalCost,
		totalDisputeCharge,
		totalProfit,
		totalRevenue,
		tce,
		totalRentCost,
		otherExpenses,
		rentCommission,
		rentBrokerage,
		reletCommission,
		reletBrokerage,
		rentHireCost,
		reletHireCost,
		rentTotalBunkerCost,
		reletTotalBunkerCost,
		disputeCharges,
		totalProfitAndLoss,
		handleCombineParams
	}
}

export default usePnl
