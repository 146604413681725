import { CommonInitItemEvent } from "@/types/event";
import { InvStatementBunkerItemType } from "../../common";
import { InvStatementOffHireInitItemType } from "./components"

export const initialBunkerItem: CommonInitItemEvent<InvStatementBunkerItemType> = (item) => {
	return {
		id: item?.id ?? null,
		invId: item?.invId ?? null,
		voyageId: item?.voyageId ?? null,
		bunkerType: item?.bunkerType ?? null,
		type: item?.type ?? null,
		consumption: item?.consumption ?? "",
		price: item?.price ?? "",
		total: item?.total ?? 0,
		groupId: item?.groupId ?? null
	};
};

export const initialDelGroup = (): {
	bunker: string[];
	others: string[];
	offHire: string[];
} => {
	return {
		bunker: [],
		others: [],
		offHire: []
	};
};


export const checkBunkerKeys: (keyof InvStatementBunkerItemType)[] = [
	"consumption",
	"price",
	"bunkerType"
]

export const checkOtherKeys: (keyof InvStatementOffHireInitItemType["otherCalDetailList"][number])[] =
	["totalAmount", "title"]
