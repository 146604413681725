import React, { FC } from "react"
import classnames from "classnames"
import commonStyle from "../../../../index.module.less"
import style from "./index.module.less"
import { CommonAccountPopupProps } from "./type"
import { FleetLoading } from "@/components"
import { Popover, Tooltip } from "@/_components"

const CommonAccountPopup: FC<CommonAccountPopupProps> = ({ list, loading, onSelect, loadMore }) => {
	const handleSelect = (
		e: React.MouseEvent<HTMLUListElement, MouseEvent>,
		item: CommonAccountPopupProps["list"][number]
	) => {
		e?.stopPropagation()
		onSelect?.(item)
	}
	return (
		<div
			className={classnames(
				commonStyle["common-select--Popup"],
				style["common-accountSelect--Popup"]
			)}
		>
			<FleetLoading loading={loading} />
			<ul className="select--popup--header">
				<li>Beneficiary Name</li>
				<li>Bank Account</li>
				<li>BankAccount Address</li>
			</ul>
			<div className="select--popup--container fleet-nonScrolBar">
				<div className="select--popup--scrollArea">
					{list?.map((item, index) => {
						return (
							<ul
								key={index}
								className="select--popup--item"
								onClick={(e) => {
									handleSelect?.(e, item)
								}}
							>
								<Popover
									placement="top-start"
									trigger="hover"
									overlayClassName={style["common-accountSelect--Popup--tooltip"]}
									content={item?.beneficiaryName}
									children={<li>{item?.beneficiaryName}</li>}
								/>
								<li>{item?.bankAccountNumber || "-"}</li>
								<li>{item?.bankAccountAddress || "-"}</li>
							</ul>
						)
					})}
				</div>
			</div>
			<div className="select--popup--loadMore" onClick={loadMore}>
				<span>Load More</span>
				<span className="font_family fleet-back"></span>
			</div>
		</div>
	)
}

export default CommonAccountPopup
