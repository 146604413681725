import React from "react";
import { MovementTableColumnsType } from "@/featrue/voyagemanage/movement/common/table";
import { MovementCargoChangeEvent } from "@/featrue/voyagemanage/movement/details/portRotation/cargo/type";
import { FleetInput } from "@/components";
import { MovementInsureFormProps } from "./type";

export type InsureDataSourceType = any;

export const columns: MovementTableColumnsType<
	InsureDataSourceType,
	{
		onChange: MovementInsureFormProps["onChange"];
	}
>[] = [
	{
		width: 125,
		label: "CL Basic Setting",
		dataIndex: "cl",
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="insure-input"
					onChange={(val: number) => {
						actions?.onChange?.("cl", val);
					}}
				/>
			);
		}
	},
	{
		width: 125,
		label: "FDD Basic Setting",
		dataIndex: "fdd",
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="insure-input"
					onChange={(val: number) => {
						actions?.onChange?.("fdd", val);
					}}
				/>
			);
		}
	},
	{
		width: 125,
		label: "FDD Day Setting",
		dataIndex: "fddDay",
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="insure-input"
					onChange={(val: number) => {
						actions?.onChange?.("fddDay", val);
					}}
				/>
			);
		}
	},
	{
		width: 126,
		label: "Total",
		dataIndex: "total",
		render: (value, record, index, actions) => {
			return <FleetInput disabled value={value} type="number" className="insure-input" />;
		}
	}
];
