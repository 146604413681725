import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { InvoicesViewsCommonHeaderProps } from "./type";
import dayjs from "dayjs";
import { Switch } from "antd";

const InvoicesViewsCommonHeader: FC<InvoicesViewsCommonHeaderProps> = ({
	title,
	type,
	item,
	onSwitchFinal
}) => {
	return (
		<div
			className={classnames(style["invoices-views-commonHeader"], {
				[style["invoices-views-commonHeader--desc"]]: type === "desc"
			})}
		>
			<div className="commonHeader-header">
				<ul className="header-container">
					<li className="header-title">{title}</li>
					{item?.status === "Settled" && <li className="header-tags">{item?.status}</li>}
				</ul>
				{type === "base" && item?.updateTime && (
					<span className="header-updateDate">
						Update time: {dayjs(item?.updateTime)?.format("MM/DD/YY HH:mm")}
					</span>
				)}
			</div>
			<div className="flex gap-[10px] items-center flex-row">
				<span className="commonHeader-container">
					{type === "base" ? `INV. No. ${item?.invNo}` : `Voyage No. ${item?.voyageNo}`}
				</span>
				{onSwitchFinal && item?.isFinal && (
					<div className="flex gap-[10px] items-center flex-row">
						<Switch
							value={item?.invFinal}
							onChange={(checked) => {
								onSwitchFinal?.(checked);
							}}
						/>
						<span className="text-[12px] font-normal leading-[20px]">{`${item?.invFinal ? "cancel" : "set"} inv final?`}</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default InvoicesViewsCommonHeader