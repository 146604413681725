import React, { FC } from "react";
import commonNewlyStyle from "../../index.module.less";
import style from "./index.module.less";
import { CommonNewlyAccountProps } from "./type";
import { FleetPrompt } from "@/common";
import classnames from "classnames";
import { FleetFormItem, FleetInput, FleetLoading, FleetVerifyInput } from "@/components";
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";
import { initialUppercase } from "@/tools/string";
import { columns } from "./source";
import { CommonCompanySelect } from "@/common/Select";

const CommonNewlyAccount: FC<CommonNewlyAccountProps> = ({
	activeItem,
	hide,
	type,
	onClose,
	onConfirm
}) => {
	const {
		dataSource,
		company,
		loading,
		handleAdd,
		handleChange,
		handleDelete,
		updateAccount,
		handleCompanySelect
	} = useIndex(hide, onConfirm, type, activeItem);
	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={classnames(commonNewlyStyle["common-newly"], style["common-newly--account"])}>
				<FleetLoading loading={loading} />
				<div className="newly-header">
					<span className="header-title">{initialUppercase(type)} Account</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<div className="newly-container">
					<FleetFormItem label="Company Name" containerClassname="accountNewly-company" needVerity>
						<CommonCompanySelect
							showKey="companyName"
							activeItems={{
								id: company?.companyId,
								companyName: company?.companyName
							}}
							onFinish={(newItem, type) => {
								handleCompanySelect({
									companyId: newItem?.id ?? null,
									companyName: newItem?.companyName ?? ""
								});
							}}
							companyActions={{
								onConfirm: (newItem) => {
									handleCompanySelect({
										companyId: newItem?.id ?? null,
										companyName: newItem?.companyName ?? ""
									});
								}
							}}
						/>
					</FleetFormItem>
					<div className="accountNewly-toolbar">
						<span className="toolbar-label">Bank Account</span>
						<Button size="small" type="secondary" onClick={handleAdd} prefixIcon="fleet-add">
							Add Account
						</Button>
					</div>
					<div className="accountNewly-table">
						<table className="table-layout">
							<colgroup>
								{columns?.map((column, index) => {
									return <col key={index} style={{ width: column?.width }} />;
								})}
							</colgroup>
							<thead className="table-thead">
								<tr>
									{columns?.map((column, index) => {
										return (
											<th
												key={index}
												style={{
													[column?.fixed]: column?.fixedWidth
												}}
												className={classnames({
													required: column?.required
												})}
											>
												{column?.title}
											</th>
										);
									})}
								</tr>
							</thead>
							<tbody className="table-tbody">
								{dataSource?.map((row, rowIndex) => {
									return (
										<tr key={rowIndex}>
											{columns?.map((column, colIndex) => {
												return (
													<td
														key={colIndex}
														className={classnames({ sticky: column?.fixed })}
														style={{
															[column?.fixed]: column?.fixedWidth
														}}
													>
														{column?.render ? (
															column?.render(row[column?.dataIndex], row, rowIndex, {
																onRemove: (record) => handleDelete(rowIndex)
															})
														) : (
															<FleetInput
																value={row[column?.dataIndex]}
																onChange={(val: string) =>
																	handleChange?.(rowIndex, column?.dataIndex, val)
																}
															/>
														)}
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				<div className="newly-footer">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={updateAccount} disabled={loading}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default CommonNewlyAccount;
