import { InvStatementOtherItemType } from "../../../../../../../type";
import { useOtherCalaulateIndex } from "../../otherCalculate";
import { useTimeCalculateIndex } from "../../timeCalculate";
import { InvStatementOffHireInitItemType } from "../type";
import useBunkerIndex from "./useBunkerIndex";

const useIndex = (otherItem: Pick<InvStatementOtherItemType, "id" | "voyageId">) => {
	const {
		dataSource: offHireGroupItem,
		deleteIds: offHireGroupDelIds,
		handleOffHireItemAdd,
		handleOffHireItemConfirm,
		commit: handleOffHireGroupCommit,
		init: handleOffHireGroupInit
	} = useTimeCalculateIndex({ voyageId: otherItem?.voyageId, invId: otherItem?.id });

	const {
		dataSource: otherCalculateItems,
		deleteIds: otherCalculateDelIds,
		change: handleOtherCalculateChange,
		remove: handleOtherCalculateRemove,
		add: handleOtherCalculateAdd,
		init: handleOtherCalculateInit
	} = useOtherCalaulateIndex({
		voyageId: otherItem?.voyageId,
		invId: otherItem?.id,
		groupId: offHireGroupItem?.id
	});

	const {
		dataSource: bohBunkerItems,
		commit: handleBohBunkerCommit,
		summary: bohSummaty,
		deleteIds: buhDelIds,
		remove: handleBohBunkerDelete,
		add: handleBohBunkerAdd,
		init: handleBohBunkerInit
	} = useBunkerIndex({
		voyageId: otherItem?.voyageId,
		id: otherItem?.id,
		groupId: offHireGroupItem?.id
	});

	const init = (item: InvStatementOffHireInitItemType) => {
		const { bunkerPriceList, otherCalDetailList, ...rest } = item;
		handleBohBunkerInit(bunkerPriceList);
		handleOtherCalculateInit(otherCalDetailList);
		handleOffHireGroupInit(rest);
	};

	return {
		offHireGroupItem,
		offHireGroupDelIds,
		handleOffHireItemAdd,
		handleOffHireItemConfirm,
		handleOffHireGroupCommit,
		otherCalculateItems,
		otherCalculateDelIds,
		handleOtherCalculateChange,
		handleOtherCalculateRemove,
		handleOtherCalculateAdd,
		bohBunkerItems,
		handleBohBunkerCommit,
		bohSummaty,
		buhDelIds,
		handleBohBunkerDelete,
		handleBohBunkerAdd,
		init
	};
};

export default useIndex;
