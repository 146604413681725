import React, { Fragment } from "react"
import classnames from "classnames"
import { MovementFinancialDataItemType } from "./type"
import { formatThousandthNumber } from "@/tools/amount"
import { MovementCurrentCommonProps } from "../../type"
import { Tooltip } from "@/_components"

export enum FinancialDataIndexMap {
	"the-second" = 2,
	"the-third",
	"the-fourth"
}

export enum FinancialDataIndexColorMap {
	"Profit & Loss" = "rgba(53, 86, 145, 0.3)",
	"Total Revenues" = "#EFF8F3",
	"Total Cost" = "#FAF4ED",
	"Dispute Charge" = "#E1E6EF"
}

export const renderFinancialData = (
	voyageType: MovementCurrentCommonProps["voyageType"],
	item: MovementFinancialDataItemType,
	index?: number
) => {
	index = index || 1
	return (
		<>
			<ul
				key={item?.desc}
				style={{
					background: index === 1 ? (FinancialDataIndexColorMap[item?.desc] ?? "unset") : "unset"
				}}
				className={classnames(
					"financialData-item--common",
					"financialData-container--item",
					FinancialDataIndexMap[index]
				)}
			>
				{item?.children?.length > 0 ? (
					<li className="financialData-item--desc">
						<span className="font_family fleet-spread-out"></span>
						<Tooltip title={item?.desc} children={item?.desc} />
					</li>
				) : (
					<Tooltip
						title={item?.desc}
						children={<li className="text-ellipsis overflow-hidden">{item?.desc}</li>}
					/>
				)}
				{voyageType === "TCVC" && <li>{formatThousandthNumber(item?.plan)}</li>}
				<li>{formatThousandthNumber(item?.actual)}</li>
				<li>{(item?.diffActual * 100)?.toFixed(2)}</li>
				<li>{formatThousandthNumber(item?.posted)}</li>
				<li>{formatThousandthNumber(item?.diffPosted * 100, 2, 2)}</li>
			</ul>
			{item?.children?.map((childItem, childIndex) => {
				return (
					<Fragment key={childIndex}>
						{renderFinancialData(voyageType, childItem, index + 1)}
					</Fragment>
				)
			})}
		</>
	)
}

// 153 204 255
// 255 153 204
// 204 255 255
