import { useAppDispatch } from "@/hook"
import { InvoicesViewsContainerProps } from "../type"
import { useNavigate } from "react-router-dom"
import useReminder from "@/hook/useReminder"
import { useState } from "react"
import { downloadFreightInvAction, downloadStatementInvAction } from "@/action/voyageManage/invoices"

const useIndex = (
	activeVoyageItem: InvoicesViewsContainerProps["activeVoyageItem"],
	currentInvoice: InvoicesViewsContainerProps["activeInvoice"],
	currentInvType: InvoicesViewsContainerProps["activeInvType"],
	onSave: InvoicesViewsContainerProps["onSave"]
) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()

	const [hide, setHide] = useState(true)

	const downloadInvError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	const handleDownloadInv = () => {
		if (!currentInvoice?.id) {
			reminder(
				"warning",
				"The newly created invoice needs to be saved before it can be downloaded."
			)
			return
		}
		onSave?.(() => {
			if (currentInvType === "freightInv") {
				downloadFreightInvAction(
					{ id: currentInvoice?.id },
					null,
					null,
					downloadInvError,
					dispatch,
					navigate
				)
				return
			}
			downloadStatementInvAction(
				{ id: currentInvoice?.id },
				null,
				null,
				downloadInvError,
				dispatch,
				navigate
			)
		})
	}

	const handleMovementBack = () => {
		navigate(
			`/layout/voyagemanage/movement/details?id=${activeVoyageItem?.id}&voyageType=${activeVoyageItem?.voyageType}`
		)
	}

	return {
		handleMovementBack,
		handleDownloadInv,
		hide,
		setHide
	}
}

export default useIndex