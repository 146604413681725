import { MutableRefObject } from "react";
import { addSourceToMap } from "../utils";
import { dailyReportSource, initialDailyReportGeoJson } from "../source";
import { RouteCommonMapRefType } from "../type";
import { GeoJSONSource } from "mapbox-gl";

const useDailyReport = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const loadSource = () => {
		addSourceToMap(mapboxGl?.current, {
			sourceName: "daily-report-source",
			source: {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: []
				}
			}
		});
	};
	const loadLayers = () => {
		mapboxGl.current.addLayer({
			id: dailyReportSource["daily-report-info-source"],
			type: "symbol",
			source: "daily-report-source",
			layout: {
				// 'symbol-placement': 'line',
				"symbol-spacing": 100, // 图标间隔，默认为250
				"icon-image": "dailyReportBgIcon", //箭头图标
				"icon-size": 0.2,
				"text-field": ["concat", ["get", "type"], " ", ["get", "reportDateTime"]],
				"icon-offset": [-380, 0],
				"text-offset": [-7.3, 0],
				"text-max-width": 1000, // 设置一个足够大的宽度，确保文本不会换行
				// 文字大小
				"text-size": 10,
				"text-allow-overlap": true,
				"icon-allow-overlap": true
			}
		});
		mapboxGl?.current?.addLayer({
			id: dailyReportSource["daily-report-source"],
			source: "daily-report-source",
			type: "symbol",
			layout: {
				"icon-image": "dailyReportIcon",
				"icon-size": 0.4,
				"icon-allow-overlap": true
			}
		});
	};

	const loadDailyReport: RouteCommonMapRefType["onDailyReportLoad"] = (items) => {
		console.log("items", items);
		const features = items?.map((item) => initialDailyReportGeoJson(item));
		const currentSource = mapboxGl?.current?.getSource("daily-report-source") as GeoJSONSource;
		currentSource?.setData({
			type: "FeatureCollection",
			features
		});
	};

	return {
		loadSource,
		loadLayers,
		loadDailyReport
	};
};

export default useDailyReport;