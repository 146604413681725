import React, { Fragment } from "react"
import classnames from "classnames"
import style from "./index.module.less"
import { FleetButton, FleetIcon, FleetSelect } from "@/components"
import { useNavigate, useSearchParams } from "react-router-dom"
import StowagePlan from "@/featrue/voyagemanage/movement/details/stowagePlan"
import { setFirstSelected } from "@/store/globalSlice"
import { useAppDispatch } from "@/hook"
import {
	MovementCargoInfo,
	MovementVesselBasics,
	MovementCharterBase,
	MovementPortExpenses,
	MovementLaytime,
	MovementSeaReport,
	MovementPortReport,
	MovementBunkerPlan,
	MovementOtherReports,
	BillOfLading,
	InsureForm
} from "./components"
import { movementTcvcDialogOptions } from "./source"
import useIndex from "./hooks/useIndex"
import { CommonOtherExpenses, CommonOtherIncome } from "@/common"
import { Button } from "@/_components"
import {
	MovementHandover,
	MovementHandoverFormSummary,
	MovementSettings,
	MovementSummary,
	MovementVoyageRemarks,
	MovementDisputeCharge,
	MovementOffHire,
	MovementTask,
	MovementFinancialData,
	MovementCommission
} from "../../common"
import { MovementVoaygeStatusOptions } from "../../source"

const MovemoentTcvcDetails: React.FC<{
	voyageId: string
	loading: boolean
	onLoadingChange: (params: { loading: boolean; vesselName?: string }) => void
}> = ({ voyageId, loading, onLoadingChange }) => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const {
		actualCalauate,
		vesselBasicForm,
		otherForm,
		dialogGroup,
		handleDialogChange,
		portGroup,
		cargoGroup,
		handleCargoChange,
		handleCargoCommit,
		handleVesselBasicChange,
		handleVesselBasicCommit,
		cargoInfoForm,
		handleCargoInfoCommit,
		handleCargoInfoChange,
		settingsForm,
		handleSettingsCommit,
		handleSettingsChange,
		handleActualPortGroupChange,
		handleActualPortGroupCommit,
		handleActualPortGroupAdd,
		handleActualPortGroupDelete,
		handleActualPortItemMove,
		saveMovementDetail,
		handleOtherFormChange,
		handleOtherLaytimeChange,
		handleStatusChange,
		totalRevenues,
		totalCost,
		totalDisputeCharge,
		estLoading,
		estVesselItem,
		estPortItems,
		estCargoItems,
		totalProfitAndLoss,
		estCalculate,
		handleEstCargoChange,
		handleEstCargoCommit,
		handleEstVesselParChange,
		setMovementMainPlan,
		handleSeaReportsChange,
		handleEstPortItemCommit,
		handleEstPortItemChange,
		handleEstPortItemAdd,
		handleEstPortItemDelete,
		handleEstPortCommit,
		handleEstBatchCommit,
		otherItem,
		handleOtherItemChange,
		handlePnlDownload,
		activePlanIndex,
		activePlanItem,
		activePlanType,
		planItems,
		estQueryLoading,
		handleEstPlanItemSelect,
		handlePlanAdd,
		handlePlanSave,
		refresh,
		handleBunkerCostConfirm,
		handleBatchFoConsumCommit,
		handlePortExpensesConfirm,
		handlePnlConfirm,
		dialogRef,
		handleCommissionComfirm
	} = useIndex(voyageId, onLoadingChange)

	return (
		<Fragment>
			<div className="movementDetails-header">
				<div className="header-actions">
					<Button
						type="icon"
						size="small"
						onClick={() => {
							navigate("/layout/voyagemanage")
						}}
					>
						<FleetIcon type="FleetLpointerIcon" outerLayerClassname="rback-icon" />
					</Button>
					{movementTcvcDialogOptions?.map((option, index) => {
						return (
							<Button
								size="small"
								key={index}
								type="secondary"
								onClick={() => {
									if (option?.disabled) return
									if (option?.value === "commission") {
										dialogRef?.current?.openModal(otherForm?.commissionList)
										return
									}
									handleDialogChange(option?.value, false)
								}}
								disabled={option?.disabled}
							>
								{option?.label}
							</Button>
						)
					})}
				</div>
				<div className="header-toolbar">
					<Button size="small" prefixIcon="fleet-save" onClick={saveMovementDetail}>
						Save
					</Button>
					<span className="toolbar-line"></span>
					<FleetButton
						className="toolbar-secondary"
						size="small"
						icon="FleetInvoivesIcon"
						type="secondary"
						onClick={() => {
							dispatch(setFirstSelected("/layout/voyagemanage/invoices"))
							navigate(`/layout/voyagemanage/invoices?voyageId=${voyageId}&voyageType=TCVC`)
						}}
					>
						Invoives
					</FleetButton>
					<Button
						size="small"
						type="secondary"
						prefixIcon="fleet-bl1"
						onClick={() => {
							handleDialogChange("billOfLading", false)
						}}
					>
						B/L
					</Button>
					<Button
						size="small"
						type="secondary"
						prefixIcon="fleet-a-Labprofile"
						onClick={() => {
							handleDialogChange("insureForm", false)
						}}
					>
						PNI
					</Button>
					{/* <FleetButton
						size="small"
						icon="FleetRouteingIcon"
						onClick={() => {
							dispatch(setFirstSelected("/layout/monitoring"));
							navigate(
								`/layout/monitoring?id=${voyageId}&voyageNo=${otherForm?.voyageNo}&voyageType=TCVC`
							);
						}}
					>
						Routeing
					</FleetButton> */}
					<FleetSelect
						popupMatchSelectWidth={false}
						className="status-select"
						options={MovementVoaygeStatusOptions}
						value={[otherForm?.status]}
						optionRender={(options) => {
							return (
								<div className={style["statusSelect-warp"]}>
									<FleetIcon type={options?.data?.icon} outerLayerClassname="statusSelect-icon" />
									<span className={classnames("statusSelect-text", options?.data?.value)}>
										{options?.data?.label}
									</span>
								</div>
							)
						}}
						onChange={handleStatusChange}
					/>
				</div>
			</div>
			<div className="movementDetails-container fleet-nonScrolBar">
				<div className="movementDetails-scrollArea">
					<div className="movementDetails-voyageDetails">
						<div className="voyageDetails-container">
							<div className="voyageDetails-basicInfo">
								<MovementVesselBasics
									item={vesselBasicForm}
									otherItem={{
										voyageNo: otherForm?.voyageNo,
										paymentTerm: cargoInfoForm?.paymentTerm
									}}
									onChange={handleVesselBasicChange}
									onCommit={handleVesselBasicCommit}
									onCargoCommit={handleCargoInfoCommit}
								/>
								<MovementCargoInfo
									otherItem={{
										deliveryTime: vesselBasicForm?.deliveryTime
									}}
									item={cargoInfoForm}
									onChange={handleCargoInfoChange}
									onCommit={handleCargoInfoCommit}
								/>
								<MovementSettings
									item={settingsForm}
									onChange={handleSettingsChange}
									onCommit={handleSettingsCommit}
									voyageType="TCVC"
								/>
							</div>
							<MovementSummary
								voyageType="TCVC"
								item={{
									totalCost: actualCalauate?.totalCost,
									totalProfit: actualCalauate?.totalProfit,
									totalRevenue: actualCalauate?.totalRevenue,
									hireCost: actualCalauate?.hireCost - +otherForm?.ownerAccount,
									bunkerage: actualCalauate?.bunkerCost,
									portDisbursement: actualCalauate?.portExpense,
									disputeCharge: actualCalauate?.totalDisputeCharge
								}}
							/>
							<MovementCharterBase
								estQueryLoading={estQueryLoading}
								planItems={planItems}
								activePlanIndex={activePlanIndex}
								activePlanType={activePlanType}
								planActions={{
									onAdd: handlePlanAdd,
									onSelect: handleEstPlanItemSelect,
									onSave: handlePlanSave,
									onSet: setMovementMainPlan
								}}
								portItems={portGroup}
								cargoItems={cargoGroup}
								onCargoItemChange={handleCargoChange}
								onCargoItemCommit={handleCargoCommit}
								onPortItemChange={handleActualPortGroupChange}
								onPortItemCommit={handleActualPortGroupCommit}
								onPortItemDelete={handleActualPortGroupDelete}
								onPortItemAdd={handleActualPortGroupAdd}
								onPortItemMove={handleActualPortItemMove}
								estLoading={estLoading}
								estVesselItem={estVesselItem}
								estCargoItem={estCargoItems}
								estPortItems={estPortItems}
								estOtherItem={otherItem}
								onEstOtherChange={handleOtherItemChange}
								onEstCargoChange={handleEstCargoChange}
								onEstCargoCommit={handleEstCargoCommit}
								onEstPortItemAdd={handleEstPortItemAdd}
								onEstPortItemDelete={handleEstPortItemDelete}
								onEstPortCommit={handleEstPortCommit}
								onEstPortItemChange={handleEstPortItemChange}
								onEstPortItemCommit={handleEstPortItemCommit}
								onEstVesselParChange={handleEstVesselParChange}
								onEstPortBatchCommit={handleEstBatchCommit}
								onEstPortConsumCommit={handleBatchFoConsumCommit}
							/>
						</div>
						<MovementFinancialData
							voyageType="TCVC"
							totalProfitItem={totalProfitAndLoss}
							totalRevenuesItem={totalRevenues}
							totalCostItem={totalCost}
							totalDisputeChargeItem={totalDisputeCharge}
							activePlan={activePlanItem}
							onPnlDowload={handlePnlDownload}
						/>
					</div>
					<MovementVoyageRemarks
						voyageType="TCVC"
						item={{
							highlightedPoint: otherForm?.highlightedPoint,
							voyageRemark: otherForm?.voyageRemark
						}}
						onChange={handleOtherFormChange}
					/>
					<MovementHandoverFormSummary voyageId={voyageId} voyageType="TCVC" />
				</div>
			</div>
			<MovementBunkerPlan
				hide={dialogGroup?.bunkerPlan}
				voyageId={voyageId}
				onClose={() => handleDialogChange("bunkerPlan", true)}
				onConfirm={handleBunkerCostConfirm}
				// onConfirm={(bunkerPlan) => {
				//   handleHideChange("bunkerPlan", true)
				// }}
			/>
			<MovementOffHire
				hide={dialogGroup?.offHire}
				voyageType="TCVC"
				onClose={() => handleDialogChange("offHire", true)}
				voyageId={voyageId}
			/>
			{/* <StowagePlan
        voyageId={voyageId}
        hide={dialogGroup?.stowagePlan}
        onClose={() => handleHideChange("stowagePlan", true)}
        portSource={{
          ballastPortList,
          ladenPortList,
          delievryPortCode: vesselBasicForm.deliveryPortCode,
          delievryPortName: vesselBasicForm.deliveryPortName
        }}
        vesselSource={{
          seaFuelBallast: vesselBasicForm?.seaBallastHsFo || vesselBasicForm?.seaBallastLsFo,
          seaFuelLaden: vesselBasicForm?.seaLadenHsFo || vesselBasicForm?.seaLadenLsFo,
          seaFuelBallastMdo: vesselBasicForm?.seaBallastMdo || vesselBasicForm?.seaBallastMgo,
          seaFuelLadenMdo: vesselBasicForm?.seaLadenMdo || vesselBasicForm?.seaLadenMgo,
          portFuelIdle: vesselBasicForm?.portIdleHsFo || vesselBasicForm?.portIdleLsFo,
          portFuelWork: vesselBasicForm?.portWorkHsFo || vesselBasicForm?.portWorkLsFo,
          portFuelMdo: vesselBasicForm?.portWorkMdo || vesselBasicForm?.portWorkMgo
        }}
      /> */}
			<MovementLaytime
				voyageId={voyageId}
				hide={dialogGroup?.layTime}
				onClose={() => handleDialogChange("layTime", true)}
				onConfirm={handleOtherLaytimeChange}
			/>
			<CommonOtherIncome
				type="movement"
				voyageId={voyageId}
				voyageType="TCVC"
				hide={dialogGroup?.otherIncome}
				onConfirm={(voyageType, item) => {
					handleOtherFormChange?.("otherIncome", item)
				}}
				onClose={() => handleDialogChange("otherIncome", true)}
			/>
			<MovementDisputeCharge
				voyageId={voyageId}
				voyageType="TCVC"
				hide={dialogGroup?.disputeCharge}
				onConfirm={handleOtherFormChange}
				onClose={() => handleDialogChange("disputeCharge", true)}
			/>
			<MovementPortExpenses
				voyageId={voyageId}
				hide={dialogGroup?.portExpenses}
				onConfirm={handlePortExpensesConfirm}
				onClose={() => handleDialogChange("portExpenses", true)}
			/>
			<CommonOtherExpenses
				type="movement"
				voyageType="TCVC"
				primaryId={voyageId}
				hide={dialogGroup?.otherExpenses}
				onConfirm={(items) => handleOtherFormChange?.("otherExpense", items)}
				onClose={() => handleDialogChange("otherExpenses", true)}
			/>
			<MovementSeaReport
				hide={dialogGroup?.seaReports}
				onClose={() => handleDialogChange("seaReports", true)}
				onConfirm={handleSeaReportsChange}
				voyageId={voyageId}
				voyageNo={otherForm.voyageNo}
			/>
			<MovementPortReport
				hide={dialogGroup?.portLog}
				onClose={() => handleDialogChange("portLog", true)}
				onConfirm={handleSeaReportsChange}
				voyageId={voyageId}
			/>
			<MovementHandover
				voyageId={voyageId}
				hide={dialogGroup?.handoverForm}
				onClose={() => handleDialogChange("handoverForm", true)}
				voyageType="TCVC"
			/>
			<MovementTask
				activeVoyageType="TCVC"
				voyageId={voyageId}
				refresh={refresh}
				onOpenDialog={handleDialogChange}
			/>
			<MovementOtherReports
				hide={dialogGroup?.otherReports}
				onClose={() => handleDialogChange("otherReports", true)}
				onConfirm={handleSeaReportsChange}
				voyageId={voyageId}
			/>
			<BillOfLading
				hide={dialogGroup?.billOfLading}
				voyageId={voyageId}
				onClose={() => handleDialogChange("billOfLading", true)}
			/>
			<InsureForm
				hide={dialogGroup?.insureForm}
				voyageId={voyageId}
				onClose={() => handleDialogChange("insureForm", true)}
				onGetPniTotal={handlePnlConfirm}
			/>
			<MovementCommission
				ref={dialogRef}
				option={{
					voyageId: voyageId,
					voyageType: "TCVC"
				}}
				onConfirm={handleCommissionComfirm}
			/>
		</Fragment>
	)
}

export default MovemoentTcvcDetails
