import React from "react"
import { InvStatementTemplateProps } from "./type"
import { InvTemplateStyle } from "../../../../common"
import useIndex from "./hooks/useIndex"

const InvStatementTemplate: React.FC<InvStatementTemplateProps> = ({
	canvasRef,
	dataSource,
	activeTab
}) => {
	useIndex(canvasRef, dataSource, activeTab)
	return (
		<div className={InvTemplateStyle["invoices-template-common"]}>
			<canvas width="930" height="2000" id="invoices-template" ref={canvasRef}></canvas>
		</div>
	)
}

export default InvStatementTemplate
