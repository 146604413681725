import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useRouteCommonContext } from "@/pages/routeCommon/store";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteCommonRoutePlanProps, RoutePlanItemType } from "../type";
import { onRequest } from "@/action";
import { RoutePlanModalRefType } from "../components";
import { getFirstPlan } from "../tools";
import { RowSelectMethod } from "antd/es/table/interface";

const useIndex = (
	onRoutePlanLoad: RouteCommonRoutePlanProps["onRoutePlanLoad"],
	onRoutePlanVesselLoad: RouteCommonRoutePlanProps["onRoutePlanVesselLoad"],
	onRoutePlanRemove: RouteCommonRoutePlanProps["onRoutePlanRemove"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { activeDetailsOption, activeLegVessel, onDetailsOptionCommit } = useRouteCommonContext();

	const downloadRef = useRef<RoutePlanModalRefType<RoutePlanItemType>>(null);
	const uploadRef = useRef<RoutePlanModalRefType<null>>(null);

	const [loading, setLoading] = useState(false);
	const [dataSource, setDataSource] = useState<RoutePlanItemType[]>([]);
	const [selectRows, setSelecRows] = useState<RoutePlanItemType[]>([]);

	const handleRowSelect = (
		selectedRowKeys: React.Key[],
		currentSelectedRows: RoutePlanItemType[],
		info: {
			type: RowSelectMethod;
		}
	) => {
		console.log("handleRowSelect", {
			selectedRowKeys,
			currentSelectedRows,
			info
		});
		// return;
		switch (true) {
			case info?.type === "all" && currentSelectedRows?.length === 0:
			case info?.type === "single" && currentSelectedRows?.length === 0:
				setSelecRows([]);
				onRoutePlanLoad?.({});
				onRoutePlanVesselLoad?.({});
				break;
			default:
				getRoutePlanLines(currentSelectedRows);
				getRoutePlanPoints(currentSelectedRows);
				setSelecRows(currentSelectedRows);
				break;
		}
	};

	const getRoutePlanFront = () => {
		setLoading(true);
	};

	const getRoutePlanSuccess = (response) => {
		setDataSource(response?.data);
		const selectPlan = getFirstPlan(response?.data);
		console.log("selectPlan", selectPlan);
		if (selectPlan) {
			getRoutePlanLines([selectPlan]);
			getRoutePlanPoints([selectPlan]);
			setSelecRows([selectPlan]);
			return;
		} else {
			setSelecRows([]);
			onRoutePlanLoad?.({});
			onRoutePlanVesselLoad?.({});
		}
		setLoading(false);
	};

	const getRoutePlanFaild = (error) => {
		console.log("error", error);
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getRoutePlanPoints = (items: RoutePlanItemType[]) => {
		const selectIds = items?.map((item) => item?.id);
		onRequest(
			"getRouteCommonRoutePlanPointsApi",
			{
				idList: selectIds?.join(",")
				// idList: "x1857272364210909186"
			},
			getRoutePlanFront,
			(response) => {
				onRoutePlanVesselLoad?.(response?.data);
				setLoading(false);
			},
			getRoutePlanFaild,
			dispatch,
			navigate
		);
	};

	const getRoutePlanLines = (items: RoutePlanItemType[]) => {
		const selectIds = items?.map((item) => item?.id);
		onRequest(
			"getRouteCommonRoutePlanLinesApi",
			{ uuid: selectIds?.join(",") },
			// { uuid: "x1857272364210909186" },
			getRoutePlanFront,
			(response) => {
				setLoading(false);
				// setSelecRows(items);
				onRoutePlanLoad?.(response?.data);
			},
			getRoutePlanFaild,
			dispatch,
			navigate
		);
	};

	const getRoutePlan = () => {
		onRequest(
			"getRouteCommonRoutePlanApi",
			{
				voyageNo: activeLegVessel?.voyageNo,
				legType: activeLegVessel?.vesselStatus
			},
			getRoutePlanFront,
			getRoutePlanSuccess,
			getRoutePlanFaild,
			dispatch,
			navigate
		);
	};

	const deleteRoutePlan = (selectItem: RoutePlanItemType) => {
		onRequest(
			"deleteRouteCommonRoutePlanApi",
			{
				id: selectItem?.id
			},
			getRoutePlanFront,
			(response) => {
				reminder("success", "Delete this plan success.");
				setLoading(false);
				setDataSource((prev) => {
					const currentIndex = prev?.findIndex((item) => selectItem?.id === item?.id);
					prev?.splice(currentIndex, 1);
					return [...prev];
				});
				const selectIndex = selectRows?.findIndex((row) => row?.id === selectItem?.id);
				if (selectIndex !== -1) {
					setSelecRows((prev) => {
						prev?.splice(selectIndex, 1);
						return [...prev];
					});
					onRoutePlanRemove?.(selectItem?.id);
				}
			},
			getRoutePlanFaild,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		console.log("activeDetailsOption", {
			activeDetailsOption: activeDetailsOption?.activeTab !== "routePlans",
			activeLegVessel
		});
		if (
			activeDetailsOption?.activeTab !== "routePlans" ||
			!activeLegVessel
			// !!activeDetailsOption?.activeRoutePlan
		)
			return;
		getRoutePlan();
	}, [activeDetailsOption, activeLegVessel]);

	return {
		activeDetailsOption,
		dataSource,
		loading,
		selectRows,
		activeLegVessel,
		downloadRef,
		uploadRef,
		onDetailsOptionCommit,
		handleRowSelect,
		deleteRoutePlan,
		getRoutePlan
	};
};

export default useIndex;
