export {
	getInsureFormDataApi,
	saveInsureFormDataApi,
	getInsureTableDataApi,
	downloadInsureTableDataApi
} from "./api";

export {
	getInsureFormDataParams,
	saveInsureFormDataParams,
	getInsureTableDataParams
} from "./type";
