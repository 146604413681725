import { FleetNumType } from "@/types/common";

/**
 *
 * @param input number
 * @returns string
 * @author azuuuuuuuu
 * @description transfer number to thousandth string
 */
export const formatThousandthNumber = (
	input: number = 0,
	minimumFractionDigits: number = 3,
	maximumFractionDigits: number = 3
): string => {
	return input?.toLocaleString("en-US", {
		minimumFractionDigits: minimumFractionDigits,
		maximumFractionDigits: maximumFractionDigits
	})
}

export const getRetainDecimalsNumber = (input: number, digit: number) => {
	return +input?.toFixed(digit);
};

export const calcPercentage = (subtrahend: FleetNumType, minuend: FleetNumType) => {
	return +minuend ? (+subtrahend - +minuend) / +subtrahend : 0;
};
