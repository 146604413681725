import React, { FC } from "react";
import { Summary } from "rc-table";
import { AparSummaryProps } from "./type";
import { formatThousandthNumber } from "@/tools/amount";
import { cn } from "@/tools";

const AparSummary: FC<AparSummaryProps> = ({
	type,
	arapTotal,
	differenceAmountTotal,
	financialAmountTotal
}) => {
	return (
		<Summary.Row>
			<Summary.Cell
				colSpan={10}
				index={0}
				className={cn("!font-semibold", {
					// "!bg-[#11930f1a]": type === "AR",
					// "!bg-[#4f8dfd1a]": type === "AP"
				})}
			>
				{type} Summary
			</Summary.Cell>
			<Summary.Cell
				index={1}
				align="center"
				className={cn("!font-semibold", {
					// "!bg-[#11930f1a]": type === "AR",
					// "!bg-[#4f8dfd1a]": type === "AP"
				})}
			>
				{formatThousandthNumber(arapTotal)}
			</Summary.Cell>
			<Summary.Cell
				index={1}
				align="center"
				className={cn("!font-semibold", {
					// "!bg-[#11930f1a]": type === "AR",
					// "!bg-[#4f8dfd1a]": type === "AP"
				})}
			>
				{formatThousandthNumber(financialAmountTotal)}
			</Summary.Cell>
			<Summary.Cell
				index={1}
				align="center"
				className={cn("!font-semibold", {
					// "!bg-[#11930f1a]": type === "AR",
					// "!bg-[#4f8dfd1a]": type === "AP"
				})}
			>
				{formatThousandthNumber(differenceAmountTotal)}
			</Summary.Cell>
			<Summary.Cell
				index={1}
				colSpan={3}
				align="center"
				className={cn("!font-semibold", {
					// "!bg-[#11930f1a]": type === "AR",
					// "!bg-[#4f8dfd1a]": type === "AP"
				})}
			></Summary.Cell>
		</Summary.Row>
	);
};

export default AparSummary;
