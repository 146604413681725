import React, { FC } from "react";
import style from "./index.module.less";
import { BillOfLadingProps } from "./type";
import { FleetPrompt } from "@/common";
import { FleetLoading } from "@/components";
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";
import { MovementBillOfLading } from "./components";
const BillOfLading: FC<BillOfLadingProps> = ({ hide, voyageId, onClose }) => {
	const {
		loading,
		dataSource,
		uploadHide,
		handleChange,
		handleChangePort,
		handleConfirm,
		handleChangeHide
	} = useIndex(hide, voyageId, onClose);
	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={style["movement-billOfLading"]}>
				<FleetLoading loading={loading} />
				<div className="prompt-header--container">
					<span className="header-title">Bill Of Lading</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<div className="billOfLading">
					<MovementBillOfLading
						uploadHide={uploadHide}
						dataSource={dataSource}
						onChangeHide={handleChangeHide}
						onChange={handleChange}
						onPortDetailChange={handleChangePort}
					/>
				</div>
				<div className="prompt-footer--container">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={handleConfirm}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default BillOfLading;
