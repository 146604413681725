import { OffHireTimeCalculateItemType, OffHireTimeCalculateGroupType } from "./type";
import { InvCommonFieldInitEvent } from "@/pages/voyagemanage/business/invoices/module";

export const initialOffHireItem: InvCommonFieldInitEvent<OffHireTimeCalculateItemType> = (
	type,
	item
) => {
	return {
		voyageId: item?.voyageId ?? null,
		startDatetime: item?.startDatetime ?? undefined,
		endDatetime: item?.endDatetime ?? undefined,
		duration: item?.duration ?? "",
		offHireFo: item?.offHireFo ?? "",
		offHireDo: item?.offHireDo ?? "",
		ratio: item?.ratio ?? "",
		finalDuration: item?.finalDuration ?? 0,
		remark: item?.remark ?? "",
		id: type === "details" && item?.id ? item?.id : null,
		invId: type === "details" && item?.invId ? item?.invId : null,
		groupId: type === "details" && item?.groupId ? item?.groupId : null,
		period: item?.period ?? 1,
		type: item?.type ?? null
	};
};

export const initialOffHireGroup: InvCommonFieldInitEvent<OffHireTimeCalculateGroupType> = (
	type,
	item
) => {
	return {
		id: type === "details" && item?.id ? item?.id : null,
		invId: type === "details" && item?.invId ? item?.invId : null,
		voyageId: item?.voyageId ?? null,
		offHireTtlDays: item?.offHireTtlDays ?? "",
		offHireDaily: item?.offHireDaily ?? "",
		offHireAmount: item?.offHireAmount ?? 0,
		addCommRation: item?.addCommRation ?? "",
		addComm: item?.addComm ?? 0,
		brokerageRation: item?.brokerageRation ?? "",
		brokerage: item?.brokerage ?? 0,
		cveRatio: item?.cveRatio ?? "",
		unit: item?.unit ?? "PM",
		cve: item?.cve ?? 0,
		moveOffHireList: (item?.moveOffHireList ?? [])?.map((offHireItem) =>
			initialOffHireItem(type, offHireItem)
		)
	};
};
