import { MutableRefObject, useRef } from "react"
import TongliLogo from "static/image/tongli-logo.png"
import { dateToInvoiceTime } from "@/tools/date"
import { convertCamelToCustomFormat } from "../tools"
import { useTranslation } from "react-i18next"
import { CommonFieldAddItemType } from "@/common"
import { initialUppercase } from "@/tools/string"

const useIndex = () => {
	const { t } = useTranslation()
	const markRef = useRef<HTMLCanvasElement>(null)
	const ctxPosition = useRef({
		currentX: 65,
		currentY: 41
	})

	const getFontInfo = (ctx: CanvasRenderingContext2D, text: string) => {
		let metrics = ctx.measureText(text)
		let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent // 当前字体下的文字高度
		let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent // 当前文字的实际高度
		return {
			width: metrics.width,
			height: fontHeight,
			actualHeight,
			offset: (fontHeight - actualHeight) / 2 // 实际高度在当前字体下高度下的上下偏移量
		}
	}

	const drawText = (
		ctx: CanvasRenderingContext2D,
		text: string,
		startX: number,
		startY: number,
		maxWidth?: number,
		isDrawRect: boolean = true
	) => {
		let { width, height, actualHeight, offset } = getFontInfo(ctx, text)

		ctx.fillText(text, startX, startY + height, maxWidth)
		// if (isDrawRect) {
		//   ctx.save();
		//   ctx.lineWidth = 1;
		//   ctx.beginPath()
		//   ctx.strokeRect(startX, startY, width, height)
		//   // debugger
		//   ctx.closePath()
		//   ctx.restore();
		// }
		return {
			occupyWidth: width,
			occupyHeight: height
		}
	}

	const drawSpecialText = (
		ctx: CanvasRenderingContext2D,
		canvasRef: MutableRefObject<HTMLCanvasElement>,
		text: string,
		type: "suffix" | "prefix",
		currentHeight: number,
		needLine?: boolean
	) => {
		let currentY = currentHeight
		let occupyZone
		ctx.textAlign = "right"
		switch (type) {
			case "suffix":
				occupyZone = drawText(
					ctx,
					text,
					canvasRef.current.width - ctxPosition.current.currentX,
					currentY
				)
				needLine &&
					drawLine(
						ctx,
						canvasRef.current.width - ctxPosition.current.currentX - occupyZone.occupyWidth,
						currentY + occupyZone.occupyHeight + 2,
						canvasRef.current.width - ctxPosition.current.currentX,
						currentY + occupyZone.occupyHeight + 2
					)
				break
			case "prefix":
				occupyZone = drawText(ctx, text, canvasRef.current.width - 196, currentY)
				needLine &&
					drawLine(
						ctx,
						canvasRef.current.width - 196 - occupyZone.occupyWidth,
						currentY + occupyZone.occupyHeight + 2,
						canvasRef.current.width - 196,
						currentY + occupyZone.occupyHeight + 2
					)
		}
		ctx.textAlign = "left"

		return occupyZone.occupyHeight + (needLine ? 3 : 0)
	}

	const drawLine = (
		ctx: CanvasRenderingContext2D,
		startX: number,
		startY: number,
		endX: number,
		endY: number,
		lineWidth: number = 1,
		color: string = "#000"
	) => {
		ctx.save()
		ctx.lineWidth = lineWidth
		ctx.strokeStyle = color
		ctx.beginPath()
		ctx.moveTo(startX, startY)
		ctx.lineTo(endX, endY)
		ctx.stroke()
		ctx.closePath()
		ctx.restore()
	}

	const splitWords = (
		context: CanvasRenderingContext2D,
		text: string | number,
		maxWidth: number,
		label?: string
	) => {
		let words = text?.toString().split(""),
			line = "",
			lines = []
		for (let n = 0; n < words.length; n++) {
			let testLine = line + words[n] + ""
			let metrics = context.measureText(testLine)
			let testWidth = metrics.width
			if (
				(lines?.length <= 0 && label && context.measureText(label)?.width + testWidth > maxWidth) ||
				(testWidth > maxWidth && n > 0)
			) {
				lines.push(line)
				line = words[n] + ""
			} else {
				line = testLine
			}
		}
		if (line?.length > 0) {
			lines.push(line)
		}

		return {
			lines
		}
	}

	const wrapText = (
		context: CanvasRenderingContext2D,
		text: string,
		x: number,
		y: number,
		maxWidth: number,
		lineHeight: number
	) => {
		let words = text.split(""),
			line = "",
			lines = [],
			occupyHeight = 0

		for (let n = 0; n < words.length; n++) {
			let testLine = line + words[n] + ""
			let metrics = context.measureText(testLine)
			let testWidth = metrics.width
			if (testWidth > maxWidth && n > 0) {
				lines.push(line)
				occupyHeight += getFontInfo(context, line)?.height
				line = words[n] + ""
			} else {
				line = testLine
			}
		}

		if (line?.length > 0) {
			lines.push(line)
			occupyHeight += getFontInfo(context, line)?.height
		}

		for (let i = 0; i < lines.length; i++) {
			context.fillText(lines[i], x, y + i * lineHeight)
		}

		return {
			length: lines.length - 1,
			occupyHeight
		}
	}

	const drawHeader = (
		ctx: CanvasRenderingContext2D,
		companyInfo: { label: string; value: string }[],
		companyName: string,
		date: string,
		invNo: string
	) => {
		// const ctx = canvasRef?.current?.getContext("2d");
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		/**
		 * 1. 绘制 logo
		 * position: x:66 ,y: 52, width: 150, height: 144
		 * end:画笔的 x 位置向前移动 150(logo的宽度) + 10 (logo与公司名的间距)
		 */
		ctx.save()

		const logoImage = new Image()
		console.log("before", currentX, currentY)
		const _currentX = currentX,
			_currentY = currentY
		logoImage.src = TongliLogo
		logoImage.onload = () => {
			ctx.drawImage(logoImage, _currentX, _currentY, 138, 41)
		}
		// ctx.strokeStyle = "rgba(0, 0, 0, 0.88)";
		// ctx.lineWidth = 0.5;
		// ctx.beginPath();
		// ctx.drawImage(logoImage,currentX,currentY,150,144)
		// ctx.strokeRect(currentX, currentY, 150, 144);
		// ctx.closePath();
		ctx.restore()
		currentX += 138 + 22

		/**
		 * 2. 绘制标题
		 * position: x:66 + 150 + 10 = 226 ,y: 52, width: 478, height: 74
		 * end:画笔的 y 位置向下移动 74(标题的宽度) + 20 (公司名与公司信息的间距)
		 */
		currentY += 41
		ctx.save()
		let rectangleWidth = 930
		ctx.font = "600 32px Arial"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.textAlign = "center"
		wrapText(ctx, companyName, rectangleWidth / 2, currentY + 30, rectangleWidth * 0.8, 37)
		ctx.restore()
		currentY = currentY + 74 + 20

		/**
		 * 3. 绘制发票信息
		 * position: x:66 + 150 + 10 = 226 ,y: 52 + 74 + 20 = 146, width: 638,
		 * baseProp: lineHeight: 14, marginBottom: 4, marginRight: 20, linePaddingLeft: 9
		 * end:画笔的 y 位置向下移动 lineHeight * lineNum + 4 (发票信息与主体信息之间的间距),x 还原到初始位置
		 */

		ctx.save()
		ctx.font = "400 12px Arial"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.textAlign = "left"

		rectangleWidth = 638 // 公司信息范围宽度
		let currentYIndex = 0 // 标记当前是第几行
		let currentXIndex = 0 // 标记当前是某行的第几列
		let resetangleWidth = rectangleWidth // 当前行剩余可绘制宽度
		let occupyZone
		for (let item of companyInfo) {
			let line = item?.label + " : " + item?.value
			let metrics = getFontInfo(ctx, line)
			switch (true) {
				case metrics?.width <= resetangleWidth:
					occupyZone = drawText(
						ctx,
						line,
						currentX + 9 + (rectangleWidth - resetangleWidth),
						currentY
					)
					resetangleWidth = resetangleWidth - occupyZone.occupyWidth - 20
					currentXIndex++
					break
				case metrics?.width > resetangleWidth:
					currentY += occupyZone.occupyHeight + 4
					occupyZone = drawText(ctx, line, currentX + 9, currentY)
					// occupyZone = drawText(ctx,line,currentX + 9,currentY);
					currentXIndex = 1
					resetangleWidth = rectangleWidth - occupyZone.occupyWidth - 20
					break
				default:
					break
			}
		}
		// ctx.restore();
		let fontHeight = getFontInfo(ctx, companyInfo?.[0]?.label).height
		let resetHeight = 144 + 52 - currentY - ((currentYIndex + 1) * fontHeight + currentYIndex * 4)
		currentY =
			resetHeight > 0
				? 52 + 144 + 7.5
				: currentY + ((currentYIndex + 1) * fontHeight + currentYIndex * 4) + 7.5
		currentX = 66
		/**
		 * 4. 绘制附加信息
		 * position: x:66 ,y: 200 || 200+,
		 * baseProp: lineHeight: 14, marginBottom: 4, marginRight: 20, linePaddingLeft: 9
		 * end:画笔的 y 位置向下移动 18 + 14，x 还原到初始位置
		 */
		let currentWidth = currentX
		// occupyZone = drawText(ctx, "To", currentWidth, currentY); // 绘制文字 To
		currentWidth += 649

		occupyZone = drawText(ctx, invNo, currentWidth, currentY)
		currentY += occupyZone.occupyHeight + 3

		occupyZone = drawText(ctx, "Date", currentWidth, currentY) // 绘制文字 Statement date

		// occupyZone = drawText(ctx, "Ref No.:", currentWidth, currentY);
		// `${day}${suffix} ${month}, ${year}`
		if (date) {
			const { day, suffix, month, year } = dateToInvoiceTime(date)
			occupyZone = drawText(
				ctx,
				`${day}${suffix} ${month}, ${year}`,
				currentWidth + occupyZone.occupyWidth + 19.5,
				currentY
			)
		}
		currentY += occupyZone.occupyHeight + 2.5

		ctx.restore()
		ctxPosition.current.currentX = currentX
		ctxPosition.current.currentY = currentY
	}

	const drawFooter = (
		ctx: CanvasRenderingContext2D,
		type: "statement" | "freightInv",
		footers: Pick<CommonFieldAddItemType, "fieldName" | "fieldVal">[]
	) => {
		let currentX = ctxPosition.current.currentX
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "700 12px Arial"

		// ctxPosition.current.currentY = 1139;

		let occupyZone = drawText(ctx, "Owners’ Bank Detail:", currentX, ctxPosition.current.currentY)

		ctxPosition.current.currentY += occupyZone.occupyHeight + 12

		for (let i = 0; i < footers?.length; i++) {
			const currentItem = footers?.[i]
			if (currentItem?.fieldVal) {
				// drawFooterItem(
				// 	ctx,
				// 	`${t(`voyageManagement.invoices.${type}.${currentItem?.fieldName}`, {
				// 		defaultValue: initialUppercase(currentItem?.fieldName)
				// 	})}: ${currentItem.fieldVal}`
				// );
				drawFooterItem(ctx, type, currentItem)
				if (i !== footers?.length - 1) {
					ctxPosition.current.currentY += 3
				}
			}
		}

		ctx.restore()
	}

	const drawFooterItem = (
		ctx: CanvasRenderingContext2D,
		type: "statement" | "freightInv",
		item: Pick<CommonFieldAddItemType, "fieldName" | "fieldVal">
	) => {
		ctx.save()
		ctx.textAlign = "left"
		ctx.font = "400 16px Arial"
		const label = `${t(`voyageManagement.invoices.${type}.${item?.fieldName}`, {
				defaultValue: initialUppercase(item?.fieldName)
			})}: `,
			value = item?.fieldVal
		let occupyZone = drawText(
			ctx,
			label,
			ctxPosition.current.currentX,
			ctxPosition.current.currentY
		)
		const { lines } = splitWords(ctx, value, 650, label)

		lines?.forEach((line, index) => {
			let _occupyZone = drawText(
				ctx,
				line,
				index === 0
					? ctxPosition.current.currentX + occupyZone.occupyWidth
					: ctxPosition.current.currentX,
				ctxPosition.current.currentY
			)
			ctxPosition.current.currentY += _occupyZone.occupyHeight
		})

		ctx.restore()
		if (lines?.length <= 0) {
			ctxPosition.current.currentY += occupyZone.occupyHeight
		}
	}

	// const drawFooterItem = (ctx: CanvasRenderingContext2D, text: string) => {
	// 	ctx.save();
	// 	ctx.textAlign = "left";
	// 	ctx.font = "400 16px Arial";
	// 	let occupyZone = drawText(
	// 		ctx,
	// 		convertCamelToCustomFormat(text),
	// 		ctxPosition.current.currentX,
	// 		ctxPosition.current.currentY
	// 	);
	// 	ctx.restore();
	// 	ctxPosition.current.currentY += occupyZone.occupyHeight;
	// };

	const markMouseMove = (event) => {
		const rect = markRef?.current?.getBoundingClientRect()
		const ctx = markRef?.current?.getContext("2d")
		const mouseX = event.clientX - rect.left
		const mouseY = event.clientY - rect.top
		// 清空画布
		ctx.clearRect(0, 0, markRef?.current?.width, markRef?.current?.height)
		ctx.save()
		ctx.font = "12px Arial"
		const distanceLeft = mouseX
		const distanceRight = markRef?.current?.width - mouseX
		const distanceTop = mouseY
		ctx.fillText(`Mouse Position: (${mouseX}, ${mouseY})`, 10, 20)
		ctx.fillText(`Distance to left: ${distanceLeft}px`, 10, 40)
		ctx.fillText(`Distance to right: ${distanceRight}px`, 10, 60)
		ctx.fillText(`Distance to top: ${distanceTop}px`, 10, 80)
		ctx.fillText(`CurrentY: ${ctxPosition.current.currentY}px`, 10, 100)
		ctx.lineWidth = 1

		ctx.beginPath()
		ctx.moveTo(0, mouseY)
		ctx.lineTo(markRef?.current?.width, mouseY)
		ctx.moveTo(mouseX, 0)
		ctx.lineTo(mouseX, markRef?.current?.height)
		ctx.strokeStyle = "#355691"
		ctx.stroke()
		ctx.restore()
	}

	const markMouseLeave = (evnet) => {
		const ctx = markRef?.current?.getContext("2d")
		ctx.clearRect(0, 0, markRef?.current?.width, markRef?.current?.height)
	}

	const markRefInit = () => {
		markRef?.current?.addEventListener("mousemove", markMouseMove)
		markRef?.current?.addEventListener("mouseleave", markMouseLeave)

		return () => {
			markRef?.current?.removeEventListener("mousemove", markMouseMove)
			markRef?.current?.removeEventListener("mouseleave", markMouseLeave)
		}
	}

	return {
		markRef,
		ctxPosition,
		markRefInit,
		getFontInfo,
		drawHeader,
		drawFooter,
		drawLine,
		drawText,
		drawSpecialText,
		splitWords
	}
}

export default useIndex
