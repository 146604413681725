import { ColumnsType } from "antd/es/table";
import { DailyReportCategoryType, DailyReportItemType, RouteCommonDailyReportProps } from "./type";
import React from "react";
import { dateToUtcString } from "@/tools/date";
import { CommonColumnCategoryProps } from "@/common";
import { transformLatLng } from "./tools";

export const initialCheckGroup = (): Record<
	DailyReportCategoryType,
	(keyof DailyReportItemType)[]
> => {
	return {
		General: ["no", "type", "reportDateTime", "positionStr"],
		// NAVIGGATION: ["sog", "perfSpeed", "course", "steamTimeSlr", "distSlr", "gwDay"],
		NAVIGGATION: ["sog", "course", "steamTimeSlr", "distSlr", "gwDay"],
		"BUNKER MONITORING (MT)": [
			"brobHfo",
			"brobLsfo",
			"brobMdo",
			"brobMgo",
			"meHfoSlr",
			"meLsfoSlr",
			"meMdoSlr",
			"meMgoSlr",
			"auxHfoSlr",
			"auxLsfoSlr",
			"auxMdoSlr",
			"auxMgoSlr"
		],
		"Prime Mover": ["avgRpm", "mePower", "meLoadSlr", "slip"],
		"Loading Condition": ["draftFwd", "draftAft", "meanDraft"],
		"Weather Condition": ["windBf", "windDir", "sea", "swellHeight", "swellDir"]
	};
};

export const categoryOptions: CommonColumnCategoryProps<
	DailyReportCategoryType,
	keyof DailyReportItemType
>["options"] = [
	{
		dataIndex: "General",
		children: [
			{
				label: "No.",
				value: "no",
				disabled: true
			},
			{
				label: "Type",
				value: "type",
				disabled: true
			},
			{
				label: "Date/Time(utc)",
				value: "reportDateTime",
				disabled: true
			},
			{
				label: "Position",
				value: "positionStr"
			}
		]
	},
	{
		dataIndex: "NAVIGGATION",
		children: [
			{
				label: "SOG(Kts)",
				value: "sog"
			},
			// {
			// 	label: "PERF.SPEED(kts)",
			// 	value: "perfSpeed"
			// },
			{
				label: "Course(°)",
				value: "course"
			},
			{
				label: "Steam Time SLR(HRS)",
				value: "steamTimeSlr"
			},
			{
				label: "Distance SLR(NM)",
				value: "distSlr"
			},
			{
				label: "GW Day Selection",
				value: "gwDay"
			}
		]
	},
	{
		dataIndex: "BUNKER MONITORING (MT)",
		children: [
			{
				label: "BROB HSFO(MT)",
				value: "brobHfo"
			},
			{
				label: "BROB LSFO(MT)",
				value: "brobLsfo"
			},
			{
				label: "BROB MDO(MT)",
				value: "brobMdo"
			},
			{
				label: "BROB MGO(MT)",
				value: "brobMgo"
			},
			{
				label: "ME HSFO CONS(MT)",
				value: "meHfoSlr"
			},
			{
				label: "ME LSFO CONS(MT)",
				value: "meLsfoSlr"
			},
			{
				label: "ME MDO CONS(MT)",
				value: "meMdoSlr"
			},
			{
				label: "ME MGO CONS(MT)",
				value: "meMgoSlr"
			},
			{
				label: "AUX HSFO CONS(MT)",
				value: "auxHfoSlr"
			},
			{
				label: "AUX LSFO CONS(MT)",
				value: "auxLsfoSlr"
			},
			{
				label: "AUS MDO CONS(MT)",
				value: "auxMdoSlr"
			},
			{
				label: "AUX MGO CONS(MT)",
				value: "auxMgoSlr"
			}
		]
	},
	{
		dataIndex: "Prime Mover",
		children: [
			{
				label: "AVG RPM(rpm)",
				value: "avgRpm"
			},
			{
				label: "ME POWER(kW)",
				value: "mePower"
			},
			{
				label: "ME Load SLR",
				value: "meLoadSlr"
			},
			{
				label: "Slip(%)",
				value: "slip"
			}
		]
	},
	{
		dataIndex: "Loading Condition",
		children: [
			{
				label: "FORE DRAFT",
				value: "draftFwd"
			},
			{
				label: "AFT DRAFT",
				value: "draftAft"
			},
			{
				label: "MEAN DRAFT",
				value: "meanDraft"
			}
		]
	},
	{
		dataIndex: "Weather Condition",
		children: [
			{
				label: "Wind BF",
				value: "windBf"
			},
			{
				label: "Wind Direction",
				value: "windDir"
			},
			{
				label: "Sea Height",
				value: "sea"
			},
			{
				label: "Swell Height",
				value: "swellHeight"
			},
			{
				label: "Swell Direction",
				value: "swellDir"
			}
		]
	}
];

export const columns: ColumnsType<DailyReportItemType> = [
	{
		title: "No.",
		dataIndex: "no",
		width: 50,
		ellipsis: true,
		fixed: "left",
		render: (value, record, index) => {
			return <span>{index + 1}</span>;
		}
	},
	{
		title: "Type",
		dataIndex: "type",
		width: 80,
		ellipsis: true,
		fixed: "left"
	},
	{
		width: 200,
		title: "Date/Time(utc)",
		dataIndex: "reportDateTime",
		ellipsis: true,
		fixed: "left",
		render: (value, record) => {
			return (
				<>{record.reportDateTime ? dateToUtcString(record.reportDateTime)?.slice(0, -3) : null}</>
			);
		}
	},
	{
		title: "Position",
		dataIndex: "positionStr",
		width: 200,
		ellipsis: true
	},
	{
		width: 200,
		title: "SOG(Kts)",
		dataIndex: "sog",
		ellipsis: true
	},
	// {
	//   title: 'WATER SPEED(kts)',
	//   dataIndex: 'avgWaterSpeed',
	//   ,
	//   group: 'NAVIGGATION',
	// },
	// {
	// 	width: 200,
	// 	title: "PERF.SPEED(kts)",
	// 	dataIndex: "perfSpeed",
	// 	ellipsis: true
	// },
	{
		width: 200,
		title: "Course(°)",
		dataIndex: "course",
		ellipsis: true
	},
	// {
	//   title: 'WF(kts)',
	//   dataIndex: 'wf',
	//   ,
	//   group: 'NAVIGGATION',
	// }, {
	//   title: 'CF(kts)',
	//   dataIndex: 'cf',
	//   ,
	//   group: 'NAVIGGATION',
	// },
	{
		width: 200,
		title: "Steam Time SLR(HRS)",
		dataIndex: "steamTimeSlr",
		ellipsis: true
	},
	{
		width: 200,
		title: "Distance SLR(NM)",
		dataIndex: "distSlr",
		ellipsis: true
	},
	{
		width: 200,
		title: "GW Day Selection",
		dataIndex: "gwDay",
		ellipsis: true
	},
	{
		width: 200,
		title: "BROB HSFO(MT)",
		dataIndex: "brobHfo",
		ellipsis: true
	},
	{
		width: 200,
		title: "BROB LSFO(MT)",
		dataIndex: "brobLsfo",
		ellipsis: true
	},
	{
		width: 200,
		title: "BROB MDO(MT)",
		dataIndex: "brobMdo",
		ellipsis: true
	},
	{
		width: 200,
		title: "BROB MGO(MT)",
		dataIndex: "brobMgo",
		ellipsis: true
	},
	{
		width: 200,
		title: "ME HSFO CONS(MT)",
		dataIndex: "meHfoSlr",
		ellipsis: true
	},
	{
		width: 200,
		title: "ME LSFO CONS(MT)",
		dataIndex: "meLsfoSlr",
		ellipsis: true
	},
	{
		width: 200,
		title: "ME MDO CONS(MT)",
		dataIndex: "meMdoSlr",
		ellipsis: true
	},
	{
		width: 200,
		title: "ME MGO CONS(MT)",
		dataIndex: "meMgoSlr",
		ellipsis: true
	},
	{
		title: "AUX HSFO CONS(MT)",
		dataIndex: "auxHfoSlr",
		width: 200,
		ellipsis: true
	},
	{
		title: "AUX LSFO CONS(MT)",
		dataIndex: "auxLsfoSlr",
		width: 200
	},
	{
		width: 200,
		title: "AUS MDO CONS(MT)",
		dataIndex: "auxMdoSlr"
	},
	{
		width: 200,
		title: "AUX MGO CONS(MT)",
		dataIndex: "auxMgoSlr"
	},
	{
		width: 200,
		title: "AVG RPM(rpm)",
		dataIndex: "avgRpm"
	},
	{
		width: 200,
		title: "ME POWER(kW)",
		dataIndex: "mePower"
	},
	{
		width: 200,
		title: "ME Load SLR",
		dataIndex: "meLoadSlr"
	},
	{
		width: 200,
		title: "Slip(%)",
		dataIndex: "slip"
	},
	{
		width: 200,
		title: "FORE DRAFT",
		dataIndex: "draftFwd"
	},
	{
		width: 200,
		title: "AFT DRAFT",
		dataIndex: "draftAft"
	},
	{
		width: 200,
		title: "MEAN DRAFT",
		dataIndex: "meanDraft"
	},
	{
		width: 200,
		title: "Wind BF",
		dataIndex: "windBf"
	},
	{
		width: 200,
		title: "Wind Direction",
		dataIndex: "windDir"
	},
	{
		width: 200,
		title: "Sea Height",
		dataIndex: "sea"
	},
	{
		width: 200,
		title: "Swell Height",
		dataIndex: "swellHeight"
	},
	{
		width: 200,
		title: "Swell Direction",
		dataIndex: "swellDir"
	}
];

export const initialDailyReportItem = (
	item: DailyReportItemType
): Parameters<RouteCommonDailyReportProps["onDailyReportLoad"]>[0][number] => {
	const { lat, lng } = transformLatLng(item?.positionStr);
	return {
		lat,
		lng,
		type: item?.windDir,
		reportDateTime: item?.reportDateTime
	};
};