import {
	deleteBlPortFileAction,
	downloadBlPortFileAction,
	uploadBlPortFileAction
} from "@/action/voyageManage/movement"
import { useAppDispatch } from "@/hook"
import useReminder from "@/hook/useReminder"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { MovementInsureFormProps } from "../type"

const useIndex = (
	dataSource: MovementInsureFormProps["dataSource"],
	onChange: MovementInsureFormProps["onChange"]
) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()
	const [loading, setLoading] = useState(false)
	const [hide, setHide] = useState<boolean>(true)

	const handleDelete = (id: string) => {
		deleteBlPortFileAction(
			{
				id
			},
			uploadBillOfLadingDetailFront,
			(res) => deleteBlPortFileActionSuccess(res, id),
			uploadBillOfLadingDetailFailed,
			dispatch,
			navigate
		)
	}

	const deleteBlPortFileActionSuccess = (res, id) => {
		const currentIndex = dataSource?.files?.findIndex((selectItem) => selectItem.id === id)
		dataSource?.files?.splice(currentIndex, 1)
		onChange("files", [...dataSource?.files])
		reminder("success", "File deleted successfully")
		setLoading(false)
	}

	const handleUpload = (files: File[], type: 0 | 1, dataId: string, voyageId: string) => {
		if (!voyageId || loading || files?.length <= 0) {
			setHide(true)
			return
		}
		const formData = new FormData()
		for (let file of files) {
			formData.append("files", file)
		}
		uploadBlPortFileAction(
			{
				voyageId,
				type,
				files: formData,
				dataId
			},
			uploadBillOfLadingDetailFront,
			(res) => uploadBlPortFileActionSuccess(res),
			uploadBillOfLadingDetailFailed,
			dispatch,
			navigate
		)
	}

	const uploadBillOfLadingDetailFront = () => {
		setLoading(true)
	}

	const uploadBillOfLadingDetailFailed = (error) => {
		setLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	const uploadBlPortFileActionSuccess = (response) => {
		setLoading(false)
		reminder("success", "File uploaded successfully")
		setHide(true)
		onChange("files", [...(dataSource.files || []), ...response.data])
	}

	const handlePreview = (type: "preview" | "download", item) => {
		downloadBlPortFileAction(
			{
				path: item?.filePath
			},
			uploadBillOfLadingDetailFront,
			(response) => {
				setLoading(false)
				if (type === "download") {
					fetch(response.data)
						.then((data) => {
							return data.arrayBuffer()
						})
						.then((buffer) => {
							const blob = new Blob([buffer], { type: "application/octet-stream" })
							const link = document.createElement("a")
							link.href = URL.createObjectURL(blob)
							link.download = item.fileName
							link.click()
							URL.revokeObjectURL(link.href)
						})
				} else {
					window.open(response?.data)
				}
			},
			uploadBillOfLadingDetailFailed,
			dispatch,
			navigate
		)
	}

	const handleUploadOpen = () => {
		setHide(false)
	}

	const handleClose = () => {
		setHide(true)
	}

	return {
		loading,
		hide,
		handleUpload,
		handleDelete,
		handlePreview,
		handleUploadOpen,
		handleClose
	}
}

export default useIndex
