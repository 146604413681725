import React, { useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetTable } from "@/components";
import useGroup from "@/hook/estimation/list/useGroup";
import useReminder from "@/hook/useReminder";
import { useNavigate } from "react-router-dom";
import { EstimationNewly, EstListCompare, EstListFilter } from "./components";

const EstimationList: React.FC<{

}> = (props) => {
  const [hide, setHide] = useState(true);
  const { reminder } = useReminder();
  const navigate = useNavigate();
  const [compareHide, setCompareHide] = useState(true);
  const {
    checkGroup,
    layCan,
    selectedRowKeys,
    onSearch,
    columns,
    dataSource,
    loading,
    pager,
    onLayCanChange,
    setSelectedRowKeys,
    handleReset,
    handleChange,
    onPageChange,
    onSelectChange,
    onDeleteItems,
    onRow,
    onReset
  } = useGroup();

  const ids = useMemo(() => {
    return selectedRowKeys?.slice(0, 6)?.join(",")
  }, [selectedRowKeys])


  const handleClose = useCallback(() => {
    setHide(true);
  }, []);

  const handleCompareClose = useCallback(() => {
    setCompareHide(true);
  }, [])

  const handleSaveAdd = (ids: string) => {
    setSelectedRowKeys(ids?.split(","))
    setHide(true);
    if (ids?.length > 1) {
      navigate(`/layout/estimation/details?id=${ids}`)
    } else {
      setCompareHide(false);
    }
  }

  return (
		<div className={classnames("estimation-common", style["estimation-list"])}>
			<EstListFilter
				checkGroup={checkGroup}
				timeRange={layCan}
				onTimeRangeChange={onLayCanChange}
				onSearch={onSearch}
				onReset={handleReset}
				onChange={handleChange}
				selectedRowKeys={selectedRowKeys}
				onOpenNewAdd={() => setHide(false)}
				onOpenCompare={() => {
					if (selectedRowKeys?.length > 6) {
						reminder("info", "more than six, the following will be ignored");
					}
					setCompareHide(false);
				}}
				onDeleteItems={onDeleteItems}
			/>
			<FleetTable
				columns={columns}
				dataSource={dataSource}
				loading={loading}
				className="estimation-table"
				onRow={onRow}
				id="id"
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
				rowSelection={{
					columnWidth: 64,
					selectedRowKeys,
					onChange: onSelectChange
				}}
			/>
			<EstimationNewly hide={hide} onClose={handleClose} onSave={handleSaveAdd} />
			<EstListCompare
				hide={compareHide}
				onClose={handleCompareClose}
				ids={ids}
				onConfirm={onReset}
			/>
		</div>
	);
}

export default EstimationList;