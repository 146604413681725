import useCommonIndex from "../../../hooks/useIndex";
import { CommonCompanyItemType, CommonCompanySelectProps } from "../type";
import { getCompanyGroupAction } from "@/action/dataBase/company";
import { useState } from "react";
const useIndex = (
	activeItems: Partial<CommonCompanyItemType> | Partial<CommonCompanyItemType>[],
	showKey: keyof CommonCompanyItemType,
	onFinish: (newItem: CommonCompanyItemType, operateType: "add" | "del") => void,
	primaryKey: keyof CommonCompanyItemType = "id",
	disabled: boolean,
	params?: { businessType: string },
	companyActions?: CommonCompanySelectProps["companyActions"]
) => {
	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	} = useCommonIndex<CommonCompanyItemType, keyof CommonCompanyItemType>(
		activeItems,
		showKey,
		onFinish,
		getCompanyGroupAction,
		primaryKey,
		disabled,
		params
	);

	const handleClear = () => {
		if (!activeItems[primaryKey]) return;
		onFinish?.(null, "del");
	};

	const [hide, setHide] = useState(true);
	const handleCompanyAdd = (item: CommonCompanyItemType) => {
		companyActions?.onConfirm?.(item);
		setHide(true);
	};

	return {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender,
		handleClear,
		hide,
		setHide,
		handleCompanyAdd
	};
};

export default useIndex;
