import { FleetInput, FleetTooltip } from "@/components";
import {
	CommonOtherIncomeChangeEvent,
	CommonOtherIncomeCommitEvent,
	CommonOtherIncomeItemType,
	CommontOtherIncomeDeleteEvent
} from "./type";
import React from "react";
import { CommonContactSelect, FleetToast } from "@/common";
import { MovementCommonTableColumnsType } from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table/type";

export const initialOtherIncomeItem = (voyageId: string): CommonOtherIncomeItemType => {
	return {
		voyageId,
		itemName: "",
		itemValue: "",
		remark: "",
		agentId: null,
		agentName: ""
	};
};

export const columns: MovementCommonTableColumnsType<
	CommonOtherIncomeItemType,
	keyof CommonOtherIncomeItemType,
	{
		onChange: CommonOtherIncomeChangeEvent;
		onDelete: CommontOtherIncomeDeleteEvent;
		onCommit: CommonOtherIncomeCommitEvent;
	}
>[] = [
	{
		label: "Item Name",
		dataIndex: "itemName",
		width: 150,
		fixed: "left",
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					className="td-input"
					value={value}
					onChange={(val: string) => {
						actions?.onChange?.(index, "itemName", val);
					}}
				/>
			);
		}
	},
	{
		label: "Price",
		dataIndex: "itemValue",
		width: 125,
		align: "right",
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					type="number"
					className="td-input"
					value={value}
					onChange={(val: number) => {
						actions?.onChange?.(index, "itemValue", val);
					}}
				/>
			);
		}
	},
	{
		label: "Receive From",
		dataIndex: "agentId",
		width: 250,
		render: (value, record, index, actions) => {
			return (
				<CommonContactSelect
					showKey="companyName"
					primaryKey="id"
					activeItems={{
						id: record?.agentId,
						companyName: record?.agentName
					}}
					onFinish={(newNoItem, type) => {
						actions?.onCommit?.(index, {
							agentId: newNoItem?.id ?? null,
							agentName: newNoItem?.companyName ?? ""
						});
					}}
					// contactParmas={{ businessType: "Owner" }}
					contactActions={{
						onConfirm: (newNoItem) => {
							actions?.onCommit?.(index, {
								agentId: newNoItem?.id ?? null,
								agentName: newNoItem?.companyName ?? ""
							});
						}
					}}
				/>
			);
		}
	},
	{
		label: "Remarks",
		dataIndex: "remark",
		width: 470,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					className="td-input"
					value={value}
					onChange={(val: string) => {
						actions?.onChange?.(index, "remark", val);
					}}
				/>
			);
		}
	},
	{
		label: "Action",
		dataIndex: "action",
		fixed: "right",
		width: 60,
		render: (value, record, index, actions) => {
			return (
				<div className="td-actions">
					<FleetTooltip
						outerLayer={<span className="font_family fleet-delete"></span>}
						render={(setIsOpen) => {
							return (
								<FleetToast
									label="Are you sure delete this item?"
									onCancel={() => {
										setIsOpen(false);
									}}
									onConfirm={() => {
										actions?.onDelete?.(index);
										setIsOpen(false);
									}}
								/>
							);
						}}
					/>
				</div>
			);
		}
	}
];
