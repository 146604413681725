import { LayoutChildren } from "@/layout/router"
import { PageRouterProps } from "@/router"

export const findRouter = (pathname: string, menus: PageRouterProps[] = []) => {
	let flag = false
	console.log("me", menus)
	for (let menu of menus) {
		switch (true) {
			case pathname === "/" && menu.key === "/layout/estimation":
			case menu.key === pathname:
				return true
			case menu.children?.length > 0:
				flag = findRouter(pathname, menu.children)
				if (flag) return true
				break
			default:
				break
		}
	}

	console.log("flag", flag)

	return flag
}

export const flatMenus = (menus: PageRouterProps[]) => {
	const newMenus: PageRouterProps[] = []
	menus.map((item) => {
		if (!item.children || item?.children?.length === 0) {
			newMenus.push(item)
		} else {
			newMenus.push(item)
			newMenus.push(...flatMenus(item.children))
		}
	})

	return newMenus
}

export const findRouterElement = (key: string | number) => {
	const newMenus = flatMenus(LayoutChildren)
	console.log("newroutew", newMenus, key)
	return newMenus?.find((item) => item?.key === key)?.element
}