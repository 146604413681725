import { InvStatementOtherItemType } from "@/pages/voyagemanage/business/invoices/components";
import { useMemo, useRef, useState } from "react";
import { InvStatementBunkerProps, initialBunkerItem } from "../../../../../common";
import { allBunkers } from "../../../../_bunkerPrice/source";

const useBunkerIndex = (
	otherItem: Pick<InvStatementOtherItemType, "id" | "voyageId"> & { groupId: string }
) => {
	const [dataSource, setDataSource] = useState<InvStatementBunkerProps["items"]>([]);
	const deleteIds = useRef<string[]>([]);
	const disableBunkers = useMemo(() => {
		return [...new Set(dataSource?.map((item) => item?.bunkerType))];
	}, [dataSource]);

	const add = () => {
		if (disableBunkers?.length >= 4) return;
		const otherBunkers = allBunkers?.filter((bunker) => !disableBunkers?.includes(bunker));
		setDataSource((prev) => {
			prev?.push(
				initialBunkerItem({
					type: "BOH",
					bunkerType: otherBunkers?.[0],
					voyageId: otherItem?.voyageId,
					invId: otherItem?.id,
					groupId: otherItem?.groupId
				})
			);
			return [...prev];
		});
	};

	const commit: InvStatementBunkerProps["onCommit"] = (current, item) => {
		setDataSource((prev) => {
			let currentIndex = prev?.findIndex(
				(selectItem) => selectItem?.bunkerType === current?.bunkerType && selectItem?.type === "BOH"
			);
			prev?.splice(currentIndex, 1, {
				...prev?.[currentIndex],
				...item
			});
			return [...prev];
		});
	};

	const remove: InvStatementBunkerProps["onDelete"] = (item) => {
		setDataSource((prev) => {
			let currentIndex = prev?.findIndex(
				(selectItem) => selectItem?.bunkerType === item?.bunkerType && selectItem?.type === "BOH"
			);
			prev?.[currentIndex]?.id && deleteIds.current.push(prev?.[currentIndex]?.id);
			prev?.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const init = (items: InvStatementBunkerProps["items"]) => {
		setDataSource(items ?? []);
		deleteIds.current = [];
	};

	const summary = useMemo(() => {
		return dataSource?.reduce((prev, curr) => {
			return prev + curr?.total;
		}, 0);
	}, [dataSource]);

	return {
		summary,
		dataSource,
		commit,
		remove,
		add,
		init,
		disableBunkers,
		deleteIds
	};
};

export default useBunkerIndex;
