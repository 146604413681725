import React, { forwardRef, useImperativeHandle } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import mapboxgl from "mapbox-gl";
import { connectMapboxglToLeaflet } from "./tools";
import { RouteCommonMapProps, RouteCommonMapRefType } from "./type";
import useIndex from "./hooks/useIndex";
import "./lib/libBoot.js";

connectMapboxglToLeaflet(window.L, mapboxgl);

const RouteCommonMap = forwardRef<RouteCommonMapRefType, RouteCommonMapProps>(
	({ className, children, onIsAisCollectGet }, ref) => {
		const {
			handleControllChange,
			handleMeteoChange,
			getMeteoCalendar,
			handleMeteoTimeChange,
			handleRangingSelect,
			handleVesselLoad,
			handleVesselTrackLoad,
			handlePointControlSelect,
			handleVesselVisible,
			handleVesselGroupVisible,
			handleFlyTo,
			handleControllHidden,
			handleMeteoHidden,
			loadRoutePlan,
			loadRoutePlanVessel,
			removeRoutePlan,
			loadDailyReport
		} = useIndex(onIsAisCollectGet);

		useImperativeHandle(ref, () => {
			return {
				controllChange: handleControllChange,
				meteoChange: handleMeteoChange,
				getMeteoCalendar,
				meteoTimeChange: handleMeteoTimeChange,
				onVesselLoad: handleVesselLoad,
				onVesselTrackLoad: handleVesselTrackLoad,
				onRangeControlSelect: handleRangingSelect,
				onPointControlSelect: handlePointControlSelect,
				onVesselListVisible: handleVesselVisible,
				onVesselGroupVisible: handleVesselGroupVisible,
				onFlyTo: handleFlyTo,
				onControllHidden: handleControllHidden,
				onMeteoHidden: handleMeteoHidden,
				onRoutePlanLoad: loadRoutePlan,
				onRoutePlanVesselLoad: loadRoutePlanVessel,
				onRoutePlanRemove: removeRoutePlan,
				onDailyReportLoad: loadDailyReport
			};
		});

		return (
			<div className={classnames(style["routeCommon-map"], className)}>
				<div className={style["wMap"]} id={"windy"}></div>
				{children}
			</div>
		);
	}
);

export default RouteCommonMap;
