import React, { FC } from "react";
import style from "./index.module.less";
import { MovementUploadProps } from "./type";
import { FleetPrompt, FleetToast } from "@/common";
import { Button, Icon, Popover } from "@/_components";
import { FleetImage, FleetLoading, FleetTooltip, FleetUpload } from "@/components";
import useIndex from "./hooks/useIndex";

const MovementUpload: FC<MovementUploadProps> = ({
	loading,
	items,
	hide,
	onConfirm,
	onClose,
	onDelete
}) => {
	const { fileList, uploadStatus, handleUpload, handleDelete, handleConfirm } = useIndex(
		hide,
		onConfirm
	);

	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={style["voyageManage-attach-upload"]}>
				<FleetLoading loading={loading} />
				<div className="attach-upload-header">
					<span className="header-title">Upload</span>
					<Icon type="fleet-close" className="header-close" onClick={onClose} />
				</div>
				<div className="attach-upload-container">
					<FleetUpload
						type="dragger"
						multiple
						onUpload={handleUpload}
						showFileList={false}
						uploadStatus={uploadStatus}
						fileList={fileList}
					/>
					{items?.length > 0 &&
						items?.map((item, index) => {
							return (
								<div className="uploadfile-item" key={index}>
									<div className="uploadfile-info">
										<FleetImage className="attach-icon" />
										<span>{item?.fileName}</span>
									</div>
									<Popover
										trigger="click"
										placement="left-start"
										children={<Icon className="uploadfile-remove" type="fleet-delete" />}
										render={(setIsOpen) => {
											return (
												<FleetToast
													label="That file will be deleted. Are you sure?"
													onCancel={() => {
														setIsOpen(false);
													}}
													onConfirm={() => {
														setIsOpen(false);
														onDelete?.(item?.id);
													}}
												/>
											);
										}}
									/>
								</div>
							);
						})}
					{fileList?.length > 0 &&
						fileList?.map((item, index) => {
							return (
								<div className="uploadfile-item" key={index}>
									<div className="uploadfile-info">
										<FleetImage className="attach-icon" />
										<span>{item?.name}</span>
									</div>
									<Popover
										trigger="click"
										placement="left-start"
										children={<Icon className="uploadfile-remove" type="fleet-delete" />}
										render={(setIsOpen) => {
											return (
												<FleetToast
													label="That file will be deleted. Are you sure?"
													onCancel={() => {
														setIsOpen(false);
													}}
													onConfirm={() => {
														setIsOpen(false);
														handleDelete(item);
													}}
												/>
											);
										}}
									/>
								</div>
							);
						})}
				</div>
				<div className="attach-upload-footer">
					<Button size="small" type="secondary" onClick={onClose}>
						cancel
					</Button>
					<Button size="small" onClick={handleConfirm}>
						confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default MovementUpload;
