import React, { FC } from "react"
import style from "./index.module.less"
import { InsureFormProps } from "./type"
import { FleetPrompt } from "@/common"
import { FleetLoading } from "@/components"
import useIndex from "./hooks/useIndex"
import { Button } from "@/_components"
import { MovementInsureForm } from "./components"

const InsureForm: FC<InsureFormProps> = ({ hide, voyageId, onClose, onGetPniTotal }) => {
	const { loading, dataSource, handleChange, handleConfirm } = useIndex(
		hide,
		voyageId,
		onClose,
		onGetPniTotal
	)

	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={style["movement-insureForm"]}>
				<FleetLoading loading={loading} />
				<div className="prompt-header--container">
					<span className="header-title">Charterers’ Liability Declaration Form</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<div className="insureForm">
					<MovementInsureForm dataSource={dataSource} onChange={handleChange} />
				</div>
				<div className="prompt-footer--container">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={handleConfirm}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	)
}

export default InsureForm
