import React, { FC } from "react";
import style from "./index.module.less";
import { InvStatementProps } from "./type";
import { InvCommonOffHire, InvoicesViewsContainer } from "../../common";
import useIndex from "./hooks/useIndex";
import { InvStatementBase, InvStatementDesc, InvStatementTemplate } from "./components";
import { CommonFieldAdd } from "@/common";

const InvStatement: FC<InvStatementProps> = ({
	activeInvoice,
	newly,
	activeInvType,
	activeVoyageItem,
	onInvoiceAdd,
	onInvoiceUpdate,
	totalInv
}) => {
	const {
		canvasRef,
		loading,
		activeTab,
		handleTabSelect,
		activeField,
		handleFieldOpen,
		handleFieldAdd,
		otherItem,
		handleOtherItemChange,
		dialogItem,
		handleDialogChange,
		baseItem,
		handleBaseItemChange,
		handleBaseItemCommit,
		basicsFieldItems,
		counterPartyFieldItems,
		descItem,
		summary,
		hireItems,
		handleHireCommit,
		bunkerPriceItems,
		handleBunkerPriceCommit,
		handleBunkerPriceDelete,
		handleBunkerPriceAdd,
		disableBunkers,
		otherExpenses,
		handleOtherExpensesChange,
		handleOtherExpensesDelete,
		handleSave,
		handleDownPdf,
		offhireRef,
		handleOtherExpensesAdd,
		offGroup,
		handleOffGroupCommit,
		handleOffGroupAdd,
		handleOffGroupDelete,
		handleOffHireOpen,
		handleOffHireConfirm,
		handleGroupAdd,
		handleGroupCommit,
		handleGroupDelete
	} = useIndex(
		activeVoyageItem,
		activeInvoice,
		activeInvType,
		newly,
		onInvoiceUpdate,
		onInvoiceAdd
	);

	return (
		<InvoicesViewsContainer
			loading={loading}
			activeVoyageItem={activeVoyageItem}
			activeInvType="statement"
			activeInvoice={activeInvoice}
			activeTab={activeTab}
			onTabSelect={handleTabSelect}
			period={baseItem?.period}
			total={otherItem?.total}
			onSave={handleSave}
			onDownloadPdf={handleDownPdf}
			formChildren={
				<div className={style["invocies-statement"]}>
					<InvStatementBase
						activeInvType={activeInvType}
						item={baseItem}
						onChange={handleBaseItemChange}
						onCommit={handleBaseItemCommit}
						basicsFieldList={basicsFieldItems}
						counterFieldList={counterPartyFieldItems}
						otherItem={otherItem}
						onFieldOpen={handleFieldOpen}
						totalInv={totalInv}
						onSwitchFinal={(value) => {
							handleOtherItemChange("invFinal", value);
							onInvoiceUpdate?.({ invFinal: value });
						}}
					/>
					<InvStatementDesc
						item={descItem}
						summary={summary}
						otherItem={otherItem}
						onOtherItemChange={handleOtherItemChange}
						otherExpenses={otherExpenses}
						onOtherExpensesChange={handleOtherExpensesChange}
						onOtherExpensesDelete={handleOtherExpensesDelete}
						bunkerPriceItems={bunkerPriceItems}
						onBunkerPriceDelete={handleBunkerPriceDelete}
						onBunkerPriceCommit={handleBunkerPriceCommit}
						onBunkerPriceAdd={handleBunkerPriceAdd}
						disableBunkers={disableBunkers}
						hireItem={hireItems}
						onHireCommit={handleHireCommit}
						onOtherExpensesAdd={handleOtherExpensesAdd}
						offHireGroupItems={offGroup}
						onOffHireAdd={handleOffGroupAdd}
						onOffHireCommit={handleOffGroupCommit}
						onOffHireDelete={handleOffGroupDelete}
						onOffHireGroupAdd={handleGroupAdd}
						onOffHireGroupCommit={handleGroupCommit}
						onOffHireGroupDelete={handleGroupDelete}
						onOffHireOpen={(currentIndex) => {
							handleOffHireOpen?.(currentIndex, (type, items, delIds) => {
								offhireRef?.current?.openModal(type, items, delIds);
							});
						}}
					/>
					<CommonFieldAdd
						hide={dialogItem?.field}
						mode="fixed"
						valueProp={{
							label: "Value",
							type: activeField.current === "otherItems" ? "number" : "text"
						}}
						onClose={() => handleDialogChange("field", true)}
						onAdd={handleFieldAdd}
					/>
					<InvCommonOffHire
						activeInvId={activeInvoice?.id}
						activePeriod={activeInvoice?.period}
						voyageId={activeVoyageItem?.id}
						onConfirm={(items, delIds) => {
							handleOffHireConfirm(items, delIds);
						}}
						ref={offhireRef}
					/>
				</div>
			}
			previewChildren={
				<InvStatementTemplate
					canvasRef={canvasRef}
					activeTab={activeTab}
					dataSource={{
						baseItem,
						basicsFieldItems,
						counterPartyFieldItems,
						summary,
						hireItem: hireItems,
						otherExpenses,
						otherItem,
						bunkerPriceItems,
						offGroup
						// bunkerPriceItems
					}}
				/>
			}
		/>
	);
};

export default InvStatement;