import { transform2lnglat } from "@/tools";

export const transformLatLng = (positionStr: string) => {
	const positions = positionStr?.split(",");
	const latFlag = positions[1]?.at(-1) === "N" ? 1 : -1;
	const lat = transform2lnglat(positions[1]?.substring(0, positions[1].length - 1)) * latFlag;
	const lngFlag = positions[0]?.at(-1) === "E" ? 1 : -1;
	const lng = transform2lnglat(positions[0]?.substring(0, positions[0].length - 1)) * lngFlag;

	return {
		lat,
		lng
	};
};
