import useCommonIndex from "../../../hooks/useIndex";
import { CommonDivisionItemType } from "../type";
import { getMovementDivisionAction } from "@/action/voyageManage/movement";
const useIndex = (
	activeItems: Partial<CommonDivisionItemType> | Partial<CommonDivisionItemType>[],
	showKey: keyof CommonDivisionItemType,
	onFinish: (newItem: CommonDivisionItemType, operateType: "add" | "del") => void,
	primaryKey: keyof CommonDivisionItemType = "id",
	disabled: boolean
) => {
	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender,
		setOpen
	} = useCommonIndex<CommonDivisionItemType, keyof CommonDivisionItemType>(
		activeItems,
		showKey,
		onFinish,
		getMovementDivisionAction,
		primaryKey,
		disabled
	);

	console.log("selectValue", selectValue);

	return {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender,
		setOpen
	};
};

export default useIndex;
