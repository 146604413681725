import { FleetOptionType } from "@/types/advancedType"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import React from "react"
import classnames from "classnames"
import { dateToUtcString } from "@/tools/date"

export const charterOptions: FleetOptionType<"VC" | "TCT" | "LTC", "VC" | "TCT" | "LTC">[] = [
	{
		value: "VC",
		label: "VC"
	},
	{
		value: "TCT",
		label: "TCT"
	},
	{
		value: "LTC",
		label: "LTC"
	}
]

export const b2bOptions: FleetOptionType<"No" | "Yes", 0 | 1>[] = [
	{
		value: 1,
		label: "Yes"
	},
	{
		value: 0,
		label: "No"
	}
]

export const sortOptions: FleetOptionType<
	| "Voyage No(A〜Z)"
	| "Voyage No(Z〜A)"
	| "Vessel Name(A〜Z)"
	| "Vessel Name(Z〜A)"
	| "Delivery Date(A〜Z)"
	| "Delivery Date(Z〜A)"
	| "Redelivery Date(A〜Z)"
	| "Redelivery Date(Z〜A)"
	| "Cargo Type(A〜Z)"
	| "Cargo Type(Z〜A)",
	1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
>[] = [
	{
		value: 1,
		label: "Voyage No(A〜Z)"
	},
	{
		value: 2,
		label: "Voyage No(Z〜A)"
	},
	{
		value: 3,
		label: "Vessel Name(A〜Z)"
	},
	{
		value: 4,
		label: "Vessel Name(Z〜A)"
	},
	{
		value: 5,
		label: "Delivery Date(A〜Z)"
	},
	{
		value: 6,
		label: "Delivery Date(Z〜A)"
	},
	{
		value: 7,
		label: "Redelivery Date(A〜Z)"
	},
	{
		value: 8,
		label: "Redelivery Date(Z〜A)"
	},
	{
		value: 9,
		label: "Cargo Type(A〜Z)"
	},
	{
		value: 10,
		label: "Cargo Type(Z〜A)"
	}
]
export const columns: ColumnsType<any> = [
	{
		dataIndex: "member",
		width: 200,
		title: "Member",
		key: "member",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "voyageNo",
		width: 150,
		title: "Voyage No.",
		key: "voyageNo",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "vesselName",
		width: 150,
		title: "Vessel Name",
		key: "vesselName",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "grossTonnage",
		width: 150,
		title: "GT",
		key: "grossTonnage",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "buildYear",
		width: 150,
		title: "Year Built",
		key: "buildYear",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "typeOfCharter",
		width: 160,
		title: "Types Of Charter",
		key: "typeOfCharter",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "deliveryDate",
		width: 150,
		title: "Delivery Date",
		key: "deliveryDate",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{value ? dayjs(value)?.format("YYYY-MM-DD") : null}</span>
		}
	},
	{
		dataIndex: "redeliveryDate",
		width: 150,
		title: "Redelivery Date",
		key: "redeliveryDate",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{value ? dayjs(value)?.format("YYYY-MM-DD") : null}</span>
		}
	},
	{
		dataIndex: "duration",
		width: 150,
		title: "Duration (Days)",
		key: "duration",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "cargoType",
		width: 150,
		title: "Cargo Type",
		key: "cargoType",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "cargoQuantity",
		width: 150,
		title: "Cargo Quantity",
		key: "cargoQuantity",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "isBack",
		width: 150,
		title: "Back-to-Back",
		key: "isBack",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return (
				<span className={classnames("movement-isBack", value ? "yes" : "no")}>
					{value ? "Yes" : "No"}
				</span>
			)
		}
	},
	{
		dataIndex: "cl",
		width: 150,
		title: "CL",
		key: "cl",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "fdd",
		width: 150,
		title: "FDD",
		key: "fdd",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "operatedByName",
		width: 150,
		title: "Operated By",
		key: "operatedByName",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "remarks",
		width: 150,
		title: "Remarks",
		key: "remarks",
		align: "center",
		ellipsis: true
	}
]
