export const calcInput = (text: string, toast: () => void) => {
	const value = text?.trim();
	switch (true) {
		case value === "":
			return "";
		case !Number.isNaN(+value):
			return +value;
		case !value?.startsWith("="):
			toast?.();
			return "";
		default:
			break;
	}
	try {
		const res = eval(value?.slice(1));
		return res;
	} catch (error) {
		toast?.();
		return "";
	}
};
