import { useEffect, useMemo, useRef } from "react";
import { calcTimeLineOffset, generateDates } from "../source";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import debounce from "lodash/debounce";
import { TimeAxisTimeLineProps } from "../type";
import { getScheduleTime } from "../../../tools";
dayjs.extend(utc);

const useIndex = (
	currentTime: TimeAxisTimeLineProps["currentTime"],
	onChange: TimeAxisTimeLineProps["onChange"]
) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const currentDates = useRef<Dayjs[]>(generateDates());
	const timeLineRef = useRef<HTMLDivElement>(null);
	const scheduleRef = useRef<HTMLDivElement>(null);
	const currentRef = useRef<HTMLSpanElement>(null);
	const timeLineInfoRef = useRef<HTMLDivElement>(null);
	const currentTimeInfoRef = useRef<HTMLDivElement>(null);
	const disabledRef = useRef<HTMLDivElement>(null);

	const option = useRef<{ dateOffset: number; timeOffset: number; currentTime: Dayjs }>(
		calcTimeLineOffset(currentTime)
	);

	const handleCurrentDateChange = (options: {
		currentDate: Dayjs;
		currentOffset: number;
		needToUtc?: boolean;
	}) => {
		timeLineInfoRef.current.style.left = options?.currentOffset - 24 + "px";
		(timeLineInfoRef.current.firstElementChild as HTMLDivElement).innerText = options?.currentDate // options?.needToUtc ? dayjs.utc(options?.currentDate) : options?.currentDate
			?.format("MM-DD HH:mm");
	};

	const resetScheduleWidth = (type: "reset" | "init", currentTime?: Dayjs) => {
		if (type === "reset") {
			option.current = calcTimeLineOffset(currentTime);
		}
		// const endTime = getMeteoCalendarEndTime();
		const dateShardingWidth = timeLineRef?.current?.clientWidth / 10,
			timeShardingWidth = dateShardingWidth / 24;
		const shardingWidth =
			option?.current?.dateOffset * dateShardingWidth +
			timeShardingWidth * option?.current?.timeOffset;
		scheduleRef.current.style.width = shardingWidth + "px";
		console.log("scheduleRef", {
			option: option?.current,
			currentTime
		});
		const today = dayjs(),
			todayOption = calcTimeLineOffset(today);
		// const endTimeOption = {
		// 	dateOffset: dayjs()?.startOf("hour")?.add(10, "day")?.diff(endTime, "day"),
		// 	timeOffset: dayjs()?.startOf("hour")?.add(10, "day")?.diff(endTime, "hour")
		// };
		console.log("endTimeOption", todayOption);
		const curretLeft =
			todayOption.dateOffset * dateShardingWidth +
			todayOption?.timeOffset * timeShardingWidth -
			currentRef?.current?.clientWidth;
		currentRef.current.style.left = curretLeft + "px";
		// timeLineInfoRef.current.style.left = shardingWidth - 24 + "px";
		handleCurrentDateChange({
			currentDate: currentTime ?? today,
			currentOffset: shardingWidth
			// needToUtc: type === "init"
		});
	};

	const handleReset = useMemo(() => {
		const loadOptions = () => {
			console.log("resetScheduleWidth", currentTime);
			resetScheduleWidth("reset", currentTime);
		};
		return debounce(loadOptions, 500);
	}, [currentTime]);

	const handleScheduleClick = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		currentDate: Dayjs,
		currentIndex: number
	) => {
		debugger;
		const lastDate = currentDates?.current?.[currentDates?.current?.length - 1];
		if (currentTime?.startOf("hour")?.isAfter(lastDate?.startOf("day")?.add(1, "day"))) return;
		// 1. 获取进度轴容器总宽、进度轴子项宽度、进度轴相当于外层容器的偏移量
		const parentClientWidth = timeLineRef?.current?.clientWidth,
			clientWidth = parentClientWidth / 10,
			parentOffsetLeft = timeLineRef?.current?.parentElement?.offsetLeft;

		// 2. 获取鼠标标的当前偏移量、鼠标点击位置的天的偏移量
		const mouseX = event?.clientX,
			mouseOffsetX = clientWidth * currentIndex + 22 + parentOffsetLeft;

		// 3. 获取3小时在日进度轴的宽度、当前鼠标位置相当于日起点的偏移量
		const eachWidth = clientWidth / 24;
		const offsetVal = mouseX - mouseOffsetX;

		// 4. 确定当前点击位置是第几份
		const currentPart = offsetVal / eachWidth,
			roundPart = Math.round(currentPart),
			minPart = Math.floor(currentPart),
			maxPart = Math.ceil(currentPart),
			totalPart = roundPart >= minPart + 0.5 ? maxPart : minPart,
			finalPart = getScheduleTime(totalPart);

		let totalOffset = clientWidth * currentIndex + finalPart * eachWidth,
			finalDate = currentDate?.startOf("day")?.add(finalPart, "hour");

		console.log("finalRes", {
			finalDate,
			totalOffset,
			currentDate,
			currentDates,
			lastDate,
			totalPart,
			finalPart,
			clientWidth,
			currentIndex,
			eachWidth,
			parentClientWidth
		});

		if (finalDate?.isAfter(lastDate?.startOf("day")?.add(1, "day"))) {
			finalDate = lastDate?.startOf("day")?.add(1, "day");
		}

		if (totalOffset >= parentClientWidth) {
			totalOffset = parentClientWidth;
		}
		scheduleRef.current.style.width = totalOffset + "px";
		// timeLineInfoRef.current.style.left = totalOffset - 24 + "px";
		handleCurrentDateChange({
			currentDate: finalDate,
			currentOffset: totalOffset
		});

		document?.removeEventListener("mousemove", handleMouseMove);
		document.onmousemove = null;
		currentTimeInfoRef.current.style.opacity = "0";
		onChange?.(finalDate);
	};

	const handleDateSelect = (value: Dayjs, currentIndex: number) => {
		const parentClientWidth = timeLineRef?.current?.clientWidth,
			clientWidth = parentClientWidth / 10,
			eachWidth = clientWidth / 24;
		const totalOffset = clientWidth * currentIndex + 12 * eachWidth,
			finalDate = value?.startOf("day")?.add(12, "hour");
		scheduleRef.current.style.width = totalOffset + "px";
		// timeLineInfoRef.current.style.left = totalOffset - 24 + "px";
		handleCurrentDateChange({
			currentDate: finalDate,
			currentOffset: totalOffset
		});
		currentTimeInfoRef.current.style.opacity = "0";
		document?.removeEventListener("mousemove", handleMouseMove);
		document.onmousemove = null;
		onChange?.(finalDate);
	};

	const handleMouseMove = (event: MouseEvent) => {
		console.log("event", event);
		const parentClientWidth = timeLineRef?.current?.clientWidth,
			clientWidth = parentClientWidth / 10,
			parentOffsetLeft = timeLineRef?.current?.parentElement?.offsetLeft,
			mouseX = event?.clientX,
			eachWidth = clientWidth / 24,
			mouseOffsetWidth = mouseX - 22 - parentOffsetLeft;

		const dateOffset = Math.floor(mouseOffsetWidth / clientWidth),
			currentPart = (mouseOffsetWidth - clientWidth * dateOffset) / eachWidth,
			roundPart = Math.round(currentPart),
			minPart = Math.floor(currentPart),
			maxPart = Math.ceil(currentPart),
			totalPart = roundPart >= minPart + 0.5 ? maxPart : minPart;
		const currentTime = dayjs()
				?.startOf("day")
				?.add(dateOffset, "day")
				?.add(totalPart, "hour")
				?.format("HH:mm"),
			currentOffset = clientWidth * dateOffset + totalPart * eachWidth - 24;
		currentTimeInfoRef.current.style.cssText += `left: ${currentOffset}px;opacity:1`;
		(currentTimeInfoRef.current.firstElementChild as HTMLDivElement).innerText = currentTime;
	};
	// ?.format("MM-DD HH:mm"),

	const handleMouseEnter = (event: HTMLElementEventMap["mouseenter"]) => {
		// event?.stopPropagation();
		console.log("div event", event);
		document?.addEventListener("mousemove", handleMouseMove);
	};

	const handleMouseLeave = (event: HTMLElementEventMap["mouseleave"]) => {
		// event?.stopPropagation();
		document.removeEventListener("mousemove", handleMouseMove);
		document.onmousemove = null;
		currentTimeInfoRef.current.style.opacity = "0";
	};

	useEffect(() => {
		resetScheduleWidth("init");
		containerRef?.current?.addEventListener("mouseenter", handleMouseEnter);
		containerRef?.current?.addEventListener("mouseleave", handleMouseLeave);
		return () => {
			containerRef?.current?.removeEventListener("mouseenter", handleMouseEnter);
			containerRef?.current?.removeEventListener("mouseleave", handleMouseLeave);
		};
	}, []);

	useEffect(() => {
		window.addEventListener("resize", handleReset);
		return () => {
			window.removeEventListener("resize", handleReset);
		};
	}, [currentTime]);

	return {
		currentDates,
		timeLineRef,
		scheduleRef,
		currentRef,
		timeLineInfoRef,
		currentTimeInfoRef,
		resetScheduleWidth,
		handleReset,
		handleScheduleClick,
		handleDateSelect,
		containerRef
	};
};

export default useIndex;
