import React, { FC } from "react";
import style from "./index.module.less";
import { InvFreightDescProps } from "./type";
import classnames from "classnames";
import { InvCommonOtherExpenses, InvRemitAmount, InvoicesViewsCommonHeader } from "../../../../common";
import { FleetDatePicker, FleetFormItem, FleetVerifyInput } from "@/components";
import dayjs from "dayjs";
import { InvFreightCargoDetails, InvFreightPortDetails, InvFreightSettlement } from "./components";

const InvFreightDesc: FC<InvFreightDescProps> = ({
	summary,
	otherItem,
	onOtherItemChange,
	settleItem,
	onSettlementCommit,
	loadPortItems,
	dischargePortItems,
	onPortItemChange,
	onPortDetailsAdd,
	onPortDetailsDelete,
	onPortItemCommit,
	cargoItems,
	onCargoDetailsAdd,
	onCargoDetailsDelete,
	onCargoItemChange,
	otherExpenses,
	// onFieldOpen,
	onOtherExpensesChange,
	onOtherExpensesDelete,
	onOtherExpensesAdd
}) => {
	return (
		<div className={classnames("invoicesDetail-item", style["freightInv-desciption"])}>
			<InvoicesViewsCommonHeader
				type="desc"
				title="Description"
				item={{
					voyageNo: settleItem?.voyageNo
				}}
			/>
			<div className="invoices-base--header">
				<FleetVerifyInput value={settleItem?.vesselName} label="MV" disabled />
				<FleetFormItem label="Fixture Date">
					<FleetDatePicker
						value={settleItem?.fixtureDate ? dayjs(settleItem?.fixtureDate) : null}
						disabled
					/>
				</FleetFormItem>
				<FleetVerifyInput value={settleItem?.imo} label="IMO NO." disabled />
			</div>
			<InvFreightPortDetails
				loadPortItems={loadPortItems}
				dischargePortItems={dischargePortItems}
				onAdd={onPortDetailsAdd}
				onDelete={onPortDetailsDelete}
				otherItem={{
					addPort: otherItem?.addPort
				}}
				onOtherItemChange={onOtherItemChange}
			/>
			<InvFreightCargoDetails
				items={cargoItems}
				onAdd={onCargoDetailsAdd}
				onDelete={onCargoDetailsDelete}
				onChange={onCargoItemChange}
				onSettleCommit={onSettlementCommit}
				otherItem={{
					addCargo: otherItem?.addCargo
				}}
				onOtherItemChange={onOtherItemChange}
			/>
			<InvFreightSettlement
				item={settleItem}
				onCommit={onSettlementCommit}
				onPortItemCommit={onPortItemCommit}
				otherItem={{
					addDesDem: otherItem?.addDesDem,
					addFreightIncome: otherItem?.addFreightIncome,
					addCargo: otherItem?.addCargo
				}}
				onOtherItemChange={onOtherItemChange}
				loadPortItems={loadPortItems}
				dischargePortItems={dischargePortItems}
				onPortItemChange={onPortItemChange}
			/>
			<InvCommonOtherExpenses
				items={otherExpenses}
				onChange={onOtherExpensesChange}
				onDelete={onOtherExpensesDelete}
				// onFieldOpen={onFieldOpen}
				otherItem={otherItem}
				onOtherItemChange={onOtherItemChange}
				onAdd={onOtherExpensesAdd}
			/>
			{/* <InvRemitAmount remitAmount={summary} /> */}
		</div>
	)
};

export default InvFreightDesc;
