import React, { FC } from "react";
import style from "./index.module.less";
import { CommonDivisionPopupProps } from "./type";
import { FleetLoading } from "@/components";
import commonStyle from "../../../../index.module.less";
import classnames from "classnames";

const CommonDivisionPopup: FC<CommonDivisionPopupProps> = ({
	list,
	loading,
	onSelect,
	loadMore
}) => {
	const handleSelect = (
		e: React.MouseEvent<HTMLUListElement, MouseEvent>,
		item: CommonDivisionPopupProps["list"][number]
	) => {
		e?.stopPropagation();
		onSelect?.(item);
	};
	return (
		<div
			className={classnames(
				commonStyle["common-select--Popup"],
				style["common-divisionSelect--Popup"]
			)}
		>
			<FleetLoading loading={loading} />
			<ul className="select--popup--header">
				<span>Division Name</span>
			</ul>
			<div className="select--popup--container fleet-nonScrolBar">
				<div className="select--popup--scrollArea">
					{list?.map((item, index) => {
						return (
							<ul
								key={index}
								className="select--popup--item"
								onClick={(e) => {
									handleSelect?.(e, item);
								}}
							>
								<li>{item?.label}</li>
							</ul>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default CommonDivisionPopup;