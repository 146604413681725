import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApArColumnItemType } from "../type";
import { AparListFilterItemType, AparListFilterProps } from "../components/filter/type";
import debounce from "lodash/debounce";
import React from "react";
import { onRequest } from "@/action";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [financialHide, setFinancialHide] = useState(true);

	const [loading, setLoading] = useState(false);
	const [historyHide, setHistoryHide] = useState(true);
	const record = useRef<ApArColumnItemType>(null);
	const [selectedRows, setSelectedRows] = useState<ApArColumnItemType[]>([]);

	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
		arapTotal: number;
		differenceAmountTotal: number;
		financialAmountTotal: number;
	}>({
		total: 0,
		pageNum: 0,
		pageSize: 20,
		arapTotal: 0,
		differenceAmountTotal: 0,
		financialAmountTotal: 0
	});

	const [search, setSearch] = useState("");

	const cacheSource = useRef<{ [index: number]: ApArColumnItemType[] }>({});

	const dataSource = cacheSource.current[pager.pageNum];

	console.log("pager", pager?.pageNum);

	const [filter, setFilter] = useState<AparListFilterItemType>({
		companyName: "",
		counterpartyId: null,
		end: undefined,
		start: undefined,
		type: null,
		status: null,
		voyageType: null
	});

	const summary = useMemo(() => {
		return selectedRows?.reduce(
			(prev, curr) => {
				console.log("curr", curr);
				return {
					arTotal: prev?.arTotal + (curr?.arAp === "AR" ? curr?.invAmount : 0),
					apTotal: prev?.apTotal + (curr?.arAp === "AP" ? curr?.invAmount : 0),
					arDifferenceAmountTotal:
						prev?.arDifferenceAmountTotal + (curr?.arAp === "AR" ? curr?.differenceAmount : 0),
					apDifferenceAmountTotal:
						prev?.apDifferenceAmountTotal + (curr?.arAp === "AP" ? curr?.differenceAmount : 0),
					arFinancialAmountTotal:
						prev?.arFinancialAmountTotal + (curr?.arAp === "AR" ? curr?.financialAmount : 0),
					apFinancialAmountTotal:
						prev?.apFinancialAmountTotal + (curr?.arAp === "AP" ? curr?.financialAmount : 0)
				};
			},
			{
				arTotal: 0,
				apTotal: 0,
				arDifferenceAmountTotal: 0,
				apDifferenceAmountTotal: 0,
				arFinancialAmountTotal: 0,
				apFinancialAmountTotal: 0
			}
		);
	}, [selectedRows]);

	const handleRecordClick = (item: ApArColumnItemType) => {
		record.current = item;
		setHistoryHide(false);
	};

	const handleClose = () => {
		record.current = null;
		setHistoryHide(true);
	};

	const handleFinancialClick = (item: ApArColumnItemType) => {
		record.current = item;
		setFinancialHide(false);
	};

	const handleFinancialClose = () => {
		record.current = null;
		setFinancialHide(true);
	};

	const handleFinancialConfirm = () => {
		handleFinancialClose();
		getApArList();
	};

	const updateApArStatus = (
		type: "all" | "single",
		record: ApArColumnItemType,
		params: (Pick<ApArColumnItemType, "dataId" | "dataType" | "status" | "voyageId"> & {
			arap: ApArColumnItemType["arAp"];
		})[]
	) => {
		onRequest(
			"updateApArStatusApi",
			params,
			getApArListFront,
			type === "all"
				? updateApArStatusSuccess
				: (response) => {
						handleRecordChange(record, "status", params?.[0]?.status);
						setLoading(false);
					},
			getApArListFailed,
			dispatch,
			navigate
		);
	};

	const handleFilterCommit: AparListFilterProps["onCommit"] = (item) => {
		setFilter((prev) => ({ ...prev, ...item }));
	};

	const onSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			cacheSource.current = {};
			setSearch(val);
			getApArList(val);
		};
		return debounce(loadOptions, 500);
	}, []);

	const getApArListFront = () => {
		setLoading(true);
	};

	const getApArListSuccess = (response) => {
		let total = 0;
		switch (true) {
			case !Array.isArray(response?.data):
				// setDataSource(response?.data?.items);
				cacheSource.current[1] = response?.data?.list;
				total = response?.data?.total;
				break;
			default:
				// setDataSource([]);
				cacheSource.current = {};
				break;
		}
		setPager((prev) => ({
			...prev,
			total,
			pageNum: 1,
			arapTotal: response?.data?.arapTotal ?? 0,
			differenceAmountTotal: response?.data?.differenceAmountTotal ?? 0,
			financialAmountTotal: response?.data?.financialAmountTotal ?? 0
		}));
		setLoading(false);
	};

	const getApArListFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getApArList = (searchVal?: string) => {
		cacheSource.current = {};
		onRequest(
			"getApArListApi",
			{
				...filter,
				pageNum: 1,
				pageSize: pager.pageSize,
				filter: searchVal ?? search
			},
			getApArListFront,
			getApArListSuccess,
			getApArListFailed,
			dispatch,
			navigate
		);
	};

	const onSelectChange = (
		newSelectedRowKeys: React.Key[],
		newSelectedRows: ApArColumnItemType[]
	) => {
		setSelectedRows(newSelectedRows);
	};

	const onPageChange = (page: number, size: number) => {
		if (dataSource?.length >= pager.total) return;
		// if (page * size <= dataSource?.length) return;
		if (cacheSource.current[page]) {
			setPager((prev) => ({ ...prev, pageNum: page }));
			return;
		}
		onRequest(
			"getApArListApi",
			{
				...filter,
				pageNum: page,
				pageSize: pager.pageSize,
				filter: search
			},
			getApArListFront,
			(response) => {
				if (!Array.isArray(response?.data)) {
					cacheSource.current[page] = response?.data?.list;
				} else {
					cacheSource.current[page] = [];
				}
				setPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			getApArListFailed,
			dispatch,
			navigate
		);
	};

	const handleDownload = () => {
		if (selectedRows?.length === 0) return;
		const selectList = dataSource
			?.filter((item) => selectedRows?.map((selectItem) => selectItem?.key)?.includes(item?.key))
			?.map((item) => {
				return {
					dataId: item?.dataId,
					dataType: item?.dataType,
					voyageId: item?.voyageId
				};
			});
		onRequest("downloadApArListApi", selectList, null, null, getApArListFailed, dispatch, navigate);
	};

	const handleRecordChange = <K extends keyof ApArColumnItemType>(
		record: ApArColumnItemType,
		key: K,
		value: ApArColumnItemType[K]
	) => {
		debugger;
		const currentSource = cacheSource?.current?.[pager.pageNum];
		const currentIndex = cacheSource?.current?.[pager.pageNum]?.findIndex(
			(item) => item?.key === record?.key
		);
		currentSource?.splice(currentIndex, 1, {
			...record,
			[key]: value
		});
		cacheSource.current = {
			...cacheSource.current,
			[pager.pageNum]: [...currentSource]
		};
	};

	const updateApArStatusSuccess = (response) => {
		setSelectedRows([]);
		getApArList();
	};

	const handleSettle: AparListFilterProps["onSettle"] = (type) => {
		if (selectedRows?.length <= 0) return;
		const params = Object?.values(cacheSource?.current)
			?.flat()
			?.filter(
				(item) =>
					selectedRows?.map((selectItem) => selectItem?.key)?.includes(item?.key) &&
					item?.status !== type
			)
			?.map((item) => {
				return {
					arap: item?.arAp,
					voyageId: item?.voyageId,
					dataType: item?.dataType,
					dataId: item?.dataId,
					status:
						item?.status === "Settled" ? "Unsettled" : ("Settled" as ApArColumnItemType["status"])
				};
			});
		updateApArStatus("all", null, params);
	};

	useEffect(() => {
		getApArList();
	}, [filter]);

	return {
		financialHide,
		record,
		historyHide,
		filter,
		pager,
		dataSource,
		loading,
		onSearch,
		onPageChange,
		selectedRows,
		onSelectChange,
		handleDownload,
		handleFilterCommit,
		handleSettle,
		handleClose,
		handleFinancialClose,
		handleFinancialConfirm,
		handleFinancialClick,
		handleRecordClick,
		summary
	};
};

export default useIndex;
