import { useAppDispatch } from "@/hook";
import { MovementOffHireItemType, MovementOffHireProps } from "../type";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useState } from "react";
import { OffHireRentMap } from "../source";
import { onRequest } from "@/action";
import { downloadStatementInvAction } from "@/action/voyageManage/invoices";

const useIndex = (
	voyageId: MovementOffHireProps["voyageId"],
	voyageType: MovementOffHireProps["voyageType"],
	hide: boolean
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const [selectRows, setSelectRows] = useState<string[]>([])

	const [activeTctcType, setActiveTctcType] = useState<"reletOffHire" | "rentOffHire">(
		"rentOffHire"
	)
	const [dataSource, setDataSource] = useState<MovementOffHireItemType[]>([])

	const handleTabSelect = (value: "reletOffHire" | "rentOffHire") => {
		if (activeTctcType === value) return
		setActiveTctcType(value)
	}

	const getMovementOffHireFront = () => {
		setLoading(true)
	}

	const getMovementOffHireSuccess = (response) => {
		// if (response?.data?.length <= 0) {
		// 	setDataSource([initialOffHireItem({ voyageId })]);
		// } else {
		// 	setDataSource(response?.data ?? []);
		// }
		setSelectRows(response?.data?.map((item) => item?.invId))
		setDataSource(response?.data ?? [])
		setLoading(false)
	}

	const getMovementOffHireError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
		setLoading(false)
	}

	const getMovementOffHire = () => {
		onRequest(
			"getMovementOffHireApi",
			{
				voyageId: voyageId,
				voyageType: voyageType === "TCVC" ? undefined : OffHireRentMap[activeTctcType]
			},
			getMovementOffHireFront,
			getMovementOffHireSuccess,
			getMovementOffHireError,
			dispatch,
			navigate
		)
	}

	const handleDownload = (invId: string) => {
		downloadStatementInvAction(
			{ id: invId },
			getMovementOffHireFront,
			(response) => {
				setLoading(false)
			},
			getMovementOffHireError,
			dispatch,
			navigate
		)
	}

	const handleRowSelect = (id: string) => {
		setSelectRows((prev) => {
			const index = prev?.findIndex((item) => item === id)
			if (index !== -1) {
				prev?.splice(index, 1)
			} else {
				prev?.push(id)
			}
			return [...prev]
		})
	}

	useEffect(() => {
		if (hide) return
		if (!voyageId) return
		getMovementOffHire()
	}, [hide, voyageId, activeTctcType])

	return {
		selectRows,
		loading,
		dataSource,
		activeTctcType,
		handleTabSelect,
		handleDownload,
		handleRowSelect
	}
};

export default useIndex;
