import { useEffect, useMemo, useRef, useState } from "react"
import { FilterType, InsureTableDataSourceType, ParamsType } from "../type"
import {
	downloadInsureTableDataAction,
	getInsureTableDataAction
} from "@/action/voyageManage/movement"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import useReminder from "@/hook/useReminder"
import { debounce } from "lodash"
import dayjs from "dayjs"
import { Button } from "@/_components"
import React from "react"

const useIndex = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()
	const [pageNum, setPageNum] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(20)
	const [loading, setLoading] = useState<boolean>(false)
	const [btnLoading, setBtnLoading] = useState<boolean>(false)
	const [search, setSearch] = useState<string>("")
	const [dataSource, setDataSource] = useState<InsureTableDataSourceType[]>([])
	const [filter, setFilter] = useState<FilterType>({
		charter: null,
		sortRule: null,
		isBack: null,
		redeliveryDate: null,
		deliveryDate: null
	})
	const [total, setToal] = useState<number>(0)
	const record = useRef<InsureTableDataSourceType>()
	const [hide, setHide] = useState<boolean>(true)
	const params = useRef<ParamsType>()

	const handlePageChange = (page: number, size: number) => {
		if (dataSource?.length >= total) return
		setPageNum(page)
		getInsureTableData()
	}

	const handleSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			setSearch(val)
		}
		return debounce(loadOptions, 100)
	}, [])

	const getInsureTableData = () => {
		params.current = {
			pageSize: pageSize,
			pageNum: pageNum,
			keyword: search,
			startReDeliveryDate: filter.redeliveryDate
				? new Date(dayjs(filter.redeliveryDate[0])?.format("YYYY-MM-DD")).getTime()
				: null,
			endReDeliveryDate: filter.redeliveryDate
				? new Date(dayjs(filter.redeliveryDate[1])?.format("YYYY-MM-DD")).getTime()
				: null,
			startDeliveryDate: filter.deliveryDate
				? new Date(dayjs(filter.deliveryDate[0])?.format("YYYY-MM-DD")).getTime()
				: null,
			endDeliveryDate: filter.deliveryDate
				? new Date(dayjs(filter.deliveryDate[1])?.format("YYYY-MM-DD")).getTime()
				: null,
			sortRule: filter.sortRule,
			isBack: filter.isBack,
			typeOfCharter: filter.charter
		}
		getInsureTableDataAction(
			params.current,
			getInsureTableDataFront,
			getInsureTableDataSuccess,
			getInsureTableDataFailed,
			dispatch,
			navigate
		)
	}

	const getInsureTableDataFront = () => {
		setLoading(true)
	}

	const getInsureTableDataSuccess = (response) => {
		setLoading(false)
		setToal(response.data.total)
		setDataSource(response.data.list)
	}

	const getInsureTableDataFailed = (error) => {
		setLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	const handleFilterChange = (key: keyof FilterType, value) => {
		setFilter((prev) => ({ ...prev, [key]: value }))
	}

	const handleClose = () => {
		setHide(true)
	}

	const opereteColumns = useMemo(() => {
		return [
			{
				dataIndex: "action",
				width: 170,
				title: "Action",
				key: "action",
				align: "center",
				fixed: "right",
				ellipsis: true,
				render: (value, record, index) => {
					return (
						<div className="flex justify-center items-center">
							<Button
								type="secondary"
								size="minuscule"
								prefixIcon="fleet-download"
								onClick={() => {
									handleOpen(record)
								}}
							>
								File Download
							</Button>
						</div>
					)
				}
			}
		]
	}, [record])

	const handleOpen = (item: InsureTableDataSourceType) => {
		setHide(false)
		record.current = item
	}

	const handleDownload = () => {
		downloadInsureTableDataAction(
			params.current,
			downloadInsureTableDataFront,
			downloadInsureTableDataSuccess,
			downloadInsureTableDataFailed,
			dispatch,
			navigate
		)
	}

	const downloadInsureTableDataFront = () => {
		setBtnLoading(true)
	}

	const downloadInsureTableDataSuccess = () => {
		setBtnLoading(false)
	}

	const downloadInsureTableDataFailed = (error) => {
		setBtnLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	useEffect(() => {
		getInsureTableData()
	}, [filter, search])

	return {
		loading,
		pageSize,
		pageNum,
		total,
		search,
		filter,
		handleSearch,
		dataSource,
		hide,
		opereteColumns,
		record,
		btnLoading,
		handleClose,
		handlePageChange,
		handleFilterChange,
		handleDownload
	}
}

export default useIndex
