import { getBillDetailDataAction, saveBillDetailDataAction } from "@/action/voyageManage/movement";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BillOfLadingDataType, PortDetailType } from "../type";
import { CommonChangeEvent, CommonColumnChangeEvent } from "@/types/event";

const useIndex = (hide: boolean, voyageId: string, onClose: () => void) => {
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [dataSource, setDataSource] = useState<BillOfLadingDataType>();
	const [uploadHide, setUploadHide] = useState<boolean[]>([]);

	const getBillDetailData = () => {
		getBillDetailDataAction(
			{ voyageId: voyageId },
			getBillDetailDataFront,
			getBillDetailDataSuccess,
			getBillDetailDataError,
			dispatch,
			navigate
		);
	};

	const getBillDetailDataFront = () => {
		setLoading(true);
	};

	const getBillDetailDataSuccess = (response) => {
		const hideList = [];
		response.data?.dischargePortDetaliList?.forEach(() => {
			hideList.push(true);
		});
		setUploadHide((prev) => {
			return [...prev, ...hideList];
		});
		setDataSource(response.data);
		setLoading(false);
	};

	const getBillDetailDataError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const handleChange: CommonChangeEvent<BillOfLadingDataType> = (key, value) => {
		setDataSource((prev) => {
			return { ...prev, [key]: value };
		});
	};

	const handleChangePort: CommonColumnChangeEvent<PortDetailType> = (currentIndex, key, val) => {
		setDataSource((prev) => {
			const currentItem = prev?.dischargePortDetaliList[currentIndex];
			if (key === "isBillLading" && val === 0) {
				currentItem.blBaseOn = 2;
			}
			prev?.dischargePortDetaliList?.splice(currentIndex, 1, {
				...currentItem,
				[key]: val
			});
			return { ...prev };
		});
	};

	const handleConfirm = () => {
		saveBillDetailDataAction(
			dataSource,
			getBillDetailDataFront,
			saveBillDetailDataSuccess,
			getBillDetailDataError,
			dispatch,
			navigate
		);
	};

	const saveBillDetailDataSuccess = () => {
		reminder("success", "Save successfully");
		setLoading(false);
		onClose();
	};

	const handleChangeHide = (index: number, type: boolean) => {
		setUploadHide((prev) => {
			prev[index] = type;
			return [...prev];
		});
	};

	useEffect(() => {
		if (hide || !voyageId) return;
		getBillDetailData();
	}, [hide]);

	return {
		loading,
		dataSource,
		uploadHide,
		handleChange,
		handleChangePort,
		handleConfirm,
		handleChangeHide
	};
};
export default useIndex;
