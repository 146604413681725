import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { AparFinancial, AparListFilter, AparSummary } from "./components";
import useIndex from "./hooks/useIndex";
import { HistoryDuplicate } from "@/pages/voyagemanage/components";
import { Button, Icon, Table } from "@/_components";
import { Summary } from "rc-table";
import { columns } from "./source";
import { CommonRoleWrap } from "@/common";

const VoyageManageApar: React.FC<{}> = (props) => {
	const {
		summary,
		financialHide,
		record,
		historyHide,
		filter,
		pager,
		dataSource,
		loading,
		onSearch,
		handleFinancialClick,
		handleRecordClick,
		onPageChange,
		selectedRows,
		onSelectChange,
		handleDownload,
		handleFilterCommit,
		handleSettle,
		handleClose,
		handleFinancialConfirm,
		handleFinancialClose
	} = useIndex();

	console.log("pager", pager);

	return (
		<div className={classnames(style["voyagemanage-apAr"], "voyagemanage-common")}>
			<AparListFilter
				item={filter}
				onSearch={onSearch}
				onDownload={handleDownload}
				onCommit={handleFilterCommit}
				onSettle={handleSettle}
				disabled={selectedRows?.length <= 0}
			/>
			<Table
				className={classnames("apAr-table", {
					summary: selectedRows?.length > 0
				})}
				columns={[
					...columns,
					{
						dataIndex: "option",
						width: 160,
						title: "Action",
						key: "option",
						align: "center",
						fixed: "right",
						render: (value, record, index) => {
							return (
								<div className="flex gap-[8px] items-center">
									<CommonRoleWrap roleStr="apar:list:settle">
										<Button
											type="secondary"
											size="minuscule"
											prefixIcon="fleet-check"
											onClick={() => {
												handleFinancialClick(record);
											}}
										>
											Settled
										</Button>
									</CommonRoleWrap>
									{record?.total === null ? null : (
										<div
											className="flex gap-[3px] items-center cursor-pointer"
											onClick={() => {
												handleRecordClick(record);
											}}
										>
											<Icon type="fleet-history" size={14} className="leading-[22px]" />
											<span className="text-[#151515] text-[14px] font-normal leading-[22px]">
												{record?.total}
											</span>
										</div>
									)}
								</div>
							);
						}
					}
				]}
				loading={loading}
				summary={(data) => {
					return (
						<Summary fixed="bottom">
							{selectedRows?.length <= 0 ? (
								<AparSummary
									arapTotal={pager?.arapTotal}
									financialAmountTotal={pager?.financialAmountTotal}
									differenceAmountTotal={pager?.differenceAmountTotal}
								/>
							) : (
								<>
									<AparSummary
										type="AR"
										arapTotal={summary?.arTotal}
										financialAmountTotal={summary?.arFinancialAmountTotal}
										differenceAmountTotal={summary?.arDifferenceAmountTotal}
									/>
									<AparSummary
										type="AP"
										arapTotal={summary?.apTotal}
										financialAmountTotal={summary?.apFinancialAmountTotal}
										differenceAmountTotal={summary?.apDifferenceAmountTotal}
									/>
								</>
							)}
						</Summary>
					);
				}}
				primaryKey="key"
				dataSource={dataSource}
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
				rowSelection={{
					columnWidth: 64,
					selectedRowKeys: selectedRows?.map((selectItem) => selectItem?.key),
					onChange: onSelectChange
				}}
			/>
			<HistoryDuplicate
				hide={historyHide}
				invoicesType={record?.current?.dataType === "inv_freight" ? "freightInv" : "statement"}
				onClose={handleClose}
				invId={record?.current?.dataId}
				mode="fixed"
				period={record?.current?.period}
			/>
			<AparFinancial
				hide={financialHide}
				invoice={record?.current}
				onClose={handleFinancialClose}
				onConfirm={handleFinancialConfirm}
			/>
		</div>
	);
};

export default VoyageManageApar;
