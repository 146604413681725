import {
	downloadLaytimeAttachAction,
	getMovementLaytimeAction,
	getMovementLaytimeGroupAction,
	saveMovementLaytimeGroupAction
} from "@/action/voyageManage/movement"
import { MovementLaytimeCalculatorType } from "../components/calculator/type"
import { MovementLaytimeSubTotalFormChangeEvent } from "../components/subTotal/type"
import {
	MovementLaytimePortChangeEvent,
	MovementLaytimePortFormType
} from "../components//tabs/type"
import { useAppDispatch } from "@/hook"
import useReminder from "@/hook/useReminder"
import { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import useLaytimeGroup from "./useLaytimeGroup"
import { saveMovementLaytimeGroupParams } from "@/api/voyageManage/movement"
import { MovementLaytimeProps } from "../type"
import dayjs, { Dayjs } from "dayjs"

function adjustNumber(num) {
	var decimalPart = num % 1 // 获取小数部分
	if (decimalPart >= 0.95) {
		return Math.ceil(num) // 将整数部分进一
	} else {
		return num
	}
}

const useIndex = (
	voyageId: MovementLaytimeProps["voyageId"],
	hide: boolean,
	onClose: () => void,
	onConfirm: MovementLaytimeProps["onConfirm"]
) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()
	const [loading, setLoading] = useState(false)
	// 当前港口
	const {
		activeIndex,
		laytimeDataSource,
		exceptDate,
		rangeSource,
		setActiveIndex,
		handleRangeChange,
		handleExpectDateChange,
		handleExpectDateCommit,
		handleExpectDateCrud,
		handleLaytimeChange,
		initLaytime
	} = useLaytimeGroup()

	const [norRangeSource, setNorRangeSource] = useState<[Dayjs | undefined, Dayjs | undefined]>([
		undefined,
		undefined
	])

	const handleNorRangeSourceChange = (index: number, val: Dayjs | undefined) => {
		setNorRangeSource((prev) => {
			prev?.splice(index, 1, val)
			return [...prev]
		})
	}

	const [portDataSource, setPortDataSource] = useState<MovementLaytimePortFormType[]>([])
	const otherSource = useRef<{
		vesselName: string
		cargoName: string
	}>({
		vesselName: "",
		cargoName: ""
	})

	const activePort = useMemo(() => {
		return portDataSource.find((item) => item?.id === activeIndex)
	}, [portDataSource, activeIndex])

	const laytimeRate = useMemo(() => {
		let allowed = +activePort?.loadingDischargeRate
			? +activePort?.cargoQuantity / +activePort?.loadingDischargeRate
			: 0
		let used = 0
		let amount = 0
		const usedLaytimeGroup = laytimeDataSource?.filter((item) => item?.status === 1) ?? []
		for (let laytimeItem of usedLaytimeGroup) {
			const diffInMilliseconds = +laytimeItem?.workTo - +laytimeItem?.workFrom
			// allowed += diffInMilliseconds / 3600000;
			used += +laytimeItem?.timeUsedD * 24 + +laytimeItem?.timeUsedH + +laytimeItem?.timeUsedM / 60
		}
		const diffHours = +Math.abs(used / 24 - allowed)?.toFixed(7)
		amount =
			used / 24 < allowed ? diffHours * +activePort?.desRate : diffHours * +activePort?.demRate

		console.log("laytimeRate", {
			coor: used / 24 > allowed,
			value: used / 24 > allowed ? +activePort?.desRate : +activePort?.demRate,
			diffHours,
			allowed,
			used,
			amount,
			activePort
		})
		return {
			allowed: adjustNumber(allowed),
			used: adjustNumber(used / 24),
			amount: amount
		}
	}, [activeIndex, laytimeDataSource, portDataSource])

	const warningDate = useMemo(() => {
		let currentTime = 0,
			expireTime = 0
		for (let item of laytimeDataSource) {
			const diffTime = +item?.workTo - +item?.workFrom
			expireTime += diffTime
			if (item?.status === 0) continue
			if (currentTime + diffTime > +laytimeRate?.allowed * 86400000) {
				return {
					expireItem: item,
					expireTime: +(+laytimeRate?.allowed * 86400000 - currentTime)?.toFixed(0)
				}
			}
			currentTime += diffTime
		}
		return null
	}, [laytimeDataSource, laytimeRate])

	const handleIndexChange: MovementLaytimePortChangeEvent = (id) => {
		if (activeIndex === id) return
		setActiveIndex(id)
	}

	const handleSubtotalChange: MovementLaytimeSubTotalFormChangeEvent = (id, key, value) => {
		const index = portDataSource?.findIndex((item) => item?.id === id)
		setPortDataSource((prev) => {
			const item = prev[index]
			item[key] = value
			prev.splice(index, 1, item)
			return [...prev]
		})
	}

	const getMovementLaytimeGroupFront = () => {
		setLoading(true)
	}

	const getMovementLaytimeGroupSuccess = (response) => {
		const { portVos, vesselName, cargoName } = response?.data
		const portSource = []
		const laytimeGroup: {
			portIndex: string | number
			layTimeList: MovementLaytimeCalculatorType[]
		}[] = []
		for (let item of portVos) {
			const { layTimeList, ...rest } = item
			// laytimeGroup.push({ portIndex: item?.id, layTimeList })
			portSource.push(rest)
		}
		// initLaytime(laytimeGroup)
		setPortDataSource(portSource)
		setActiveIndex(portVos?.[0]?.id)
		otherSource.current = {
			...otherSource.current,
			vesselName: vesselName,
			cargoName: cargoName
		}

		setLoading(false)
	}

	const getMovementLaytimeGroupError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
		setLoading(false)
	}

	const getMovementLaytimeGroup = () => {
		getMovementLaytimeGroupAction(
			{ id: voyageId },
			getMovementLaytimeGroupFront,
			getMovementLaytimeGroupSuccess,
			getMovementLaytimeGroupError,
			dispatch,
			navigate
		)
	}

	const getMovementLaytimeFront = () => {
		setLoading(true)
	}

	const getMovementLaytimeSuccess = (response) => {
		initLaytime(activeIndex, response?.data?.layTimeList)
		setNorRangeSource(
			[response?.data?.norStartDate, response?.data?.norEndDate]?.map((item) => {
				if (!item) return undefined
				return dayjs(item)
			}) as [Dayjs | undefined, Dayjs | undefined]
		)
		setLoading(false)
	}

	console.log("nor", norRangeSource)

	const getMovementLaytimeError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
		setLoading(false)
	}

	const getMovementLaytime = () => {
		getMovementLaytimeAction(
			{
				id: activeIndex
			},
			getMovementLaytimeFront,
			getMovementLaytimeSuccess,
			getMovementLaytimeError,
			dispatch,
			navigate
		)
	}

	const combinationLaytime = (isDownload: boolean = false) => {
		let amount = +laytimeRate.amount?.toFixed(3)
		let params: saveMovementLaytimeGroupParams = {
			...activePort,
			demAmount: 0,
			desAmount: 0,
			voyageId: voyageId,
			commenceEndDate: rangeSource?.[1]?.valueOf?.(),
			commenceStartDate: rangeSource?.[0]?.valueOf?.(),
			norStartDate: norRangeSource?.[0]?.valueOf?.(),
			norEndDate: norRangeSource?.[1]?.valueOf?.(),
			layTimeList: laytimeDataSource?.map((item) => {
				const newItem = item
				const exceptItem = exceptDate?.find(
					(exceptItem) =>
						exceptItem?.date?.startOf("day")?.isSame(dayjs(item?.workDate)) &&
						exceptItem?.startTime === item?.workFrom &&
						exceptItem.endTime === item?.workTo
				)
				exceptItem && (newItem.isSelect = exceptItem.isSelect)
				return {
					...newItem
				}
			})
		}

		laytimeRate.used > laytimeRate.allowed
			? (params.demAmount = amount)
			: (params.desAmount = amount)

		if (isDownload) {
			if (warningDate?.expireTime === 0) {
				return params
			}
			const layTimeList = []
			for (let item of params?.layTimeList) {
				if (
					warningDate?.expireItem?.workDate === item?.workDate &&
					warningDate?.expireItem?.workFrom === item?.workFrom &&
					warningDate?.expireItem?.workTo === item?.workTo
				) {
					const prevHours = Math.floor(warningDate?.expireTime / 3600000),
						prevMinutes = Math.floor((warningDate?.expireTime % 3600000) / 60000),
						nextTime = +item?.workTo - (+item?.workFrom + +warningDate?.expireTime),
						nextHours = Math.floor(nextTime / 3600000),
						nextMinutes = Math.ceil((nextTime % 3600000) / 60000)
					layTimeList?.push({
						...item,
						workTo: +item?.workFrom + +warningDate?.expireTime,
						timeUsedD: 0,
						timeUsedH: prevHours,
						timeUsedM: prevMinutes
					})
					layTimeList?.push({
						...item,
						timeUsedD: 0,
						timeUsedH: nextHours,
						timeUsedM: nextMinutes,
						workFrom: +item?.workFrom + +warningDate?.expireTime
					})
				} else {
					layTimeList?.push(item)
				}
			}
			return {
				...params,
				layTimeList
			}
		}
		return params
	}

	const saveMovementLaytimeGroup = () => {
		const params = combinationLaytime()
		// const baseDate = item?.date ? dayjs(item?.date) : null;
		// const workFrom = (item?.date && (item?.startTime || item?.startTime === 0) && item?.endTime) ? baseDate.add(+item?.startTime, "milliseconds") : null;
		// const workTo = (item?.date && item?.endTime) ? baseDate.add(+item?.endTime, "milliseconds") : null;
		saveMovementLaytimeGroupAction(
			{
				...params
			},
			getMovementLaytimeFront,
			(response) => {
				setLoading(false)
				reminder("success", response?.msg)
				onConfirm?.(params)
				onClose?.()
			},
			getMovementLaytimeError,
			dispatch,
			navigate
		)
	}

	const downloadLaytimeAttachSuccess = (response) => {
		setLoading(false)
	}

	const downloadLaytimeAttach = () => {
		const params = combinationLaytime(true)
		downloadLaytimeAttachAction(
			{
				...params,
				layTimeAllowed: +laytimeRate.allowed?.toFixed(7),
				layTimeUsed: +laytimeRate.used?.toFixed(7),
				demOrDesAmount: +laytimeRate.amount?.toFixed(7)
			},
			getMovementLaytimeFront,
			downloadLaytimeAttachSuccess,
			getMovementLaytimeError,
			dispatch,
			navigate
		)
	}

	useEffect(() => {
		if (hide) return
		getMovementLaytimeGroup()
	}, [hide])

	useEffect(() => {
		if (!activeIndex || hide) return
		getMovementLaytime()
	}, [activeIndex, hide])

	return {
		loading,
		activeIndex,
		activePort,
		portDataSource,
		laytimeDataSource,
		rangeSource,
		exceptDate,
		laytimeRate,
		norRangeSource,
		handleNorRangeSourceChange,
		handleExpectDateChange,
		handleExpectDateCommit,
		handleLaytimeChange,
		handleExpectDateCrud,
		handleIndexChange,
		handleSubtotalChange,
		handleRangeChange,
		saveMovementLaytimeGroup,
		downloadLaytimeAttach
	}
}

export default useIndex
