import React, { FC, useState } from "react";
import style from "./index.module.less";
import { HandoverFormHeadFixtureProps, HandoverFormHeadFixtureType } from "./type";
import classnames from "classnames";
import {
	FleetDatePicker,
	FleetFormItem,
	FleetRangePicker,
	FleetTextarea,
	FleetVerifyInput
} from "@/components";
import dayjs, { Dayjs } from "dayjs";
import { FleetCustomRadio } from "@/components/Radio";
import HandoverFormLoadMore from "../loadMore";
import { CommonContactSelect, CommonOtherIncome, CommonPortSelect } from "@/common";
import { CommonOtherExpenses } from "@/common";
import { useTranslation } from "react-i18next";
import { HeadFixtureLabelMap } from "./source";

const HandoverFormHeadFixture: FC<HandoverFormHeadFixtureProps> = ({
	voyageType,
	currentId,
	voyageId,
	item,
	onChange,
	onCommit
}) => {
	const [hide, setHide] = useState(true);
	const { t } = useTranslation();
	return (
		<div
			id={currentId}
			className={classnames("handoverForm--common", style["handoverForm-headFixture"])}
		>
			<span className="handoverForm--common--header">{HeadFixtureLabelMap[currentId]}</span>
			<div className="headFixture-container">
				<div className="headFixture-basicInfo">
					<FleetFormItem
						label={
							voyageType === "TCTC" && currentId === "handoverForm-tctc-relet"
								? "Charterer Name"
								: "Shipowner Name"
						}
						needVerity
					>
						<CommonContactSelect
							showKey="companyName"
							primaryKey="id"
							activeItems={{
								id: item?.ownerId,
								companyName: item?.ownerName
							}}
							onFinish={(newNoItem, type) => {
								onCommit?.({
									ownerId: newNoItem?.id ?? null,
									ownerName: newNoItem?.companyName ?? ""
								});
							}}
							contactParmas={{ businessType: "Owner" }}
							contactActions={{
								onConfirm: (newNoItem) => {
									onCommit?.({
										ownerId: newNoItem?.id ?? null,
										ownerName: newNoItem?.companyName ?? ""
									});
								}
							}}
						/>
					</FleetFormItem>
					<FleetFormItem label="Broker Name">
						<CommonContactSelect
							showKey="companyName"
							primaryKey="id"
							activeItems={{
								id: item?.brokerId,
								companyName: item?.brokerName
							}}
							onFinish={(newNoItem, type) => {
								onCommit?.({
									brokerId: newNoItem?.id ?? null,
									brokerName: newNoItem?.companyName ?? ""
								});
							}}
							contactParmas={{ businessType: "Broker" }}
							contactActions={{
								onConfirm: (newNoItem) => {
									onCommit?.({
										brokerId: newNoItem?.id ?? null,
										brokerName: newNoItem?.companyName ?? ""
									});
								}
							}}
						/>
					</FleetFormItem>
					<FleetFormItem label="CP Date" needVerity>
						<FleetDatePicker
							value={item?.cpDate ? dayjs(item?.cpDate) : null}
							onChange={(val: string) => {
								onChange?.("cpDate", val);
							}}
							allowClear
						/>
					</FleetFormItem>
					<FleetFormItem label="Delivery Port" needVerity>
						<CommonPortSelect
							activeItems={{
								portCode: item?.deliveryPortCode,
								portName: item?.deliveryPortName
							}}
							primaryKey="portCode"
							showKey="portName"
							onFinish={(newItem, type) => {
								onCommit?.({
									deliveryPortCode: newItem?.portCode ?? "",
									deliveryPortName: newItem?.portName ?? ""
								});
							}}
						/>
					</FleetFormItem>
					<FleetVerifyInput
						label="Daily Hire"
						needVerity
						type="number"
						value={item?.dailyHire}
						onChange={(val: number) => {
							onChange?.("dailyHire", val);
						}}
					/>
					<FleetVerifyInput
						label="Add. Comm. (%)"
						needVerity
						type="number"
						value={item?.commission}
						onChange={(val: number) => {
							onChange?.("commission", val);
						}}
					/>
					<FleetVerifyInput
						needVerity
						label="Brokerage (%)"
						type="number"
						value={item?.brokerage}
						onChange={(val: number) => {
							onChange?.("brokerage", val);
						}}
					/>
					<FleetVerifyInput
						label={
							voyageType === "TCTC" && currentId === "handoverForm-tctc-relet"
								? "Other Income"
								: "Other Expenses"
						}
						type="number"
						value={item?.otherExpenses}
						onChange={(val: number) => {
							onChange?.("otherExpenses", val);
						}}
						renderSuffix={() => {
							return (
								<span
									className={classnames("font_family fleet-deploy", {
										"fleet-disabled": !voyageId
									})}
									onClick={(e) => {
										e?.stopPropagation();
										if (!voyageId) return;
										setHide(false);
									}}
								></span>
							);
						}}
					/>
				</div>
				<div className="headFixture-basicInfo est-redel">
					<FleetFormItem label="Laycan From/To" needVerity>
						<FleetRangePicker
							allowClear
							showHour
							showMinute
							value={[
								item?.layCanFrom ? dayjs(item?.layCanFrom) : null,
								item?.layCanTo ? dayjs(item?.layCanTo) : null
							]}
							format="YYYY-MM-DD HH:mm"
							showTime={{
								defaultValue: [undefined, dayjs().hour(23).minute(59)]
							}}
							onChange={(vals: [Dayjs, Dayjs]) => {
								const ranges: Pick<HandoverFormHeadFixtureType, "layCanFrom" | "layCanTo"> = {
									layCanFrom: null,
									layCanTo: null
								};
								if (vals) {
									ranges.layCanFrom = vals?.[0]?.startOf("minutes")?.valueOf();
									ranges.layCanTo = vals?.[1]?.startOf("minutes")?.valueOf();
								}
								onCommit?.({ ...ranges });
							}}
						/>
					</FleetFormItem>
					<FleetFormItem label="Est. Redel Port">
						<CommonPortSelect
							activeItems={{
								// id: item.deliveryPortId,
								portCode: item?.redeliveryPortCode,
								portName: item?.redeliveryPortName
							}}
							showKey="portName"
							primaryKey="portCode"
							onFinish={(newItem, type) => {
								onCommit?.({
									// "deliveryPortId", item.id,
									redeliveryPortCode: newItem?.portCode,
									redeliveryPortName: newItem?.portName
								});
							}}
						/>
					</FleetFormItem>
					<FleetFormItem label="Est. Redel Time">
						<FleetDatePicker
							disabledDate={(current) => {
								return item?.deliveryTime && current < dayjs(item?.deliveryTime).endOf("minutes");
							}}
							value={item?.estRedeliveryDate ? dayjs(item?.estRedeliveryDate) : null}
							onChange={(val, timeStamp) => {
								onChange?.("estRedeliveryDate", timeStamp?.startOf?.("minutes")?.valueOf());
							}}
							allowClear
							showTime={{
								format: "HH:mm"
							}}
							showHour
							showMinute
							format={(value) => value?.format("YYYY-MM-DD HH:mm")}
						/>
					</FleetFormItem>
				</div>
				<div className="handoverForm--common--remarks">
					<div className="handoverForm--remarks--header">
						<span>Shipowner Rating & C/P Special Clause</span>
						<FleetCustomRadio
							value={item?.tough}
							label="Tough"
							onChange={(val) => {
								onChange?.("tough", val);
							}}
						/>
						<FleetCustomRadio
							value={item?.vip}
							label="VIP"
							onChange={(val) => {
								onChange?.("vip", val);
							}}
						/>
					</div>
					<FleetTextarea
						placeholder=""
						className="handoverForm--remarks--textarea"
						value={item?.shipownerRatingSpecialClaus}
						onChange={(val: string) => {
							onChange?.("shipownerRatingSpecialClaus", val);
						}}
					/>
				</div>
				<HandoverFormLoadMore>
					<div className="headFixture-basicInfo">
						<FleetVerifyInput
							label="Owner Country"
							value={item?.ownerCountry}
							onChange={(val: string) => {
								onChange?.("ownerCountry", val);
							}}
						/>
						<FleetFormItem label="Delivery Time">
							<FleetDatePicker
								value={item?.deliveryTime ? dayjs(item?.deliveryTime) : null}
								onChange={(val, timeStamp) => {
									onCommit?.({
										deliveryTime: timeStamp?.startOf?.("minutes")?.valueOf(),
										paymentStartDay: timeStamp?.add(3, "day")?.format("YYYY-MM-DD")
									});
								}}
								disabledDate={(current) => {
									return (
										item?.estRedeliveryDate &&
										current > dayjs(item?.estRedeliveryDate).endOf("minutes")
									);
								}}
								allowClear
								showTime={{
									format: "HH:mm"
								}}
								showHour
								showMinute
								format={(value) => value?.format("YYYY-MM-DD HH:mm")}
							/>
						</FleetFormItem>
						<FleetVerifyInput
							label="Min. Duration Day"
							value={item?.minDuration}
							type="number"
							onChange={(val: number) => {
								onChange?.("minDuration", val);
							}}
						/>
						<FleetVerifyInput
							label="Max. Duration Day"
							value={item?.maxDuration}
							type="number"
							onChange={(val: number) => {
								onChange?.("maxDuration", val);
							}}
						/>
						<FleetFormItem label="First Payment Start">
							<FleetDatePicker
								allowClear
								value={item?.paymentStartDay ? dayjs(item?.paymentStartDay) : null}
								onChange={(val: string) => {
									onChange?.("paymentStartDay", val);
								}}
							/>
						</FleetFormItem>
						<FleetVerifyInput
							label="Payment Cycle"
							value={item?.paymentCycle}
							type="number"
							onChange={(val: number) => {
								onChange?.("paymentCycle", val);
							}}
						/>
						<FleetVerifyInput
							label="Trading Area"
							value={item?.tradingArea}
							onChange={(val: string) => {
								onChange?.("tradingArea", val);
							}}
						/>
					</div>
				</HandoverFormLoadMore>
			</div>
			{voyageType === "TCTC" && currentId === "handoverForm-tctc-relet" ? (
				<CommonOtherIncome
					type="preop"
					voyageId={voyageId}
					voyageType="TCTC"
					hide={hide}
					onConfirm={(voyageType, item) => {
						onChange?.("otherExpenses", item);
					}}
					onClose={() => setHide(true)}
				/>
			) : (
				<CommonOtherExpenses
					hide={hide}
					onClose={() => setHide(true)}
					voyageType={voyageType}
					primaryId={item?.id}
					type="preOp"
					onConfirm={(items) => {
						const otherExpenses = items?.reduce((prev, curr) => {
							return prev + +curr?.netExpense;
						}, 0);
						onChange?.("otherExpenses", otherExpenses);
					}}
				/>
			)}
		</div>
	);
};

export default HandoverFormHeadFixture;
