import React, { ReactNode } from "react";
import { Navigate, Route } from "react-router-dom";
import LayoutRouter from "@/layout/router";
import { IconKeys } from "@/components/Icon/icons";
import { Routes } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import Login from "@/pages/login";
import Page404 from "@/pages/404";
import { useAppSelector } from "@/hook";
import { findRouterElement } from "@/tools/menus";
import { cacheStorage } from "@/storage";
import PersonalCenter from "@/pages/personalCenter"

export interface PageRouterProps {
	path?: string;
	element?: JSX.Element;
	key?: string | number;
	className?: string;
	component?: string;
	icon?: IconKeys;
	index?: boolean;
	children?: PageRouterProps[];
	label?: string;
	selectedIcon?: IconKeys;
	disabled?: boolean;
	notTab?: boolean;
	id?: number;
	hidden?: boolean;
	meta?: {
		icon: string;
		title: string;
	};
}

export const routers: PageRouterProps[] = [].concat(LayoutRouter);

const RouteAuthFun = (routeList: any) => {
	return routeList.map(
		(item: {
			path: string;
			auth: boolean;
			element: ReactNode;
			key: string;
			children?: any;
			index?: boolean;
			component?: string;
		}) => {
			const element = findRouterElement(item?.component ?? item?.key);
			return (
				<Route path={item.path} key={item.key} element={element} index={item?.index}>
					{item?.children && RouteAuthFun(item.children)}
				</Route>
			);
		}
	);
};

export default function Router() {
	const { firstSelected } = useAppSelector((state) => state.GlobalStore);
	const selectRouter = routers?.findIndex((route) => route.key === firstSelected);
	const access_token = cacheStorage.get("access_token");
	const _access_token = useAppSelector((state) => state.UserStore.access_token);
	const { userInfo } = useAppSelector((state) => state.UserStore);
	const currentTo =
		access_token || _access_token
			? selectRouter !== -1
				? firstSelected
				: userInfo?.menus?.[0]?.key
			: "/login";
	console.log("uise", RouteAuthFun(userInfo?.menus ?? []));
	return (
		<Routes>
			<Route path="/" element={<Navigate to={currentTo} />} />
			<Route path="/layout" element={<PrivateRoute />}>
				{RouteAuthFun(userInfo?.menus ?? [])}
			</Route>
			<Route path="/personal" element={<PersonalCenter />}></Route>
			<Route path="/login" element={<PublicRoute />}>
				<Route index={true} element={<Login />} />
			</Route>
			<Route path="*" element={<Page404 />} />
		</Routes>
	)
}
