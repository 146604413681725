import { CommonInitItemEvent } from "@/types/event";
import { InvStatementBunkerItemType } from "./type";
import { CommonOptionType } from "@/types/advancedType";

export const initialBunkerItem: CommonInitItemEvent<InvStatementBunkerItemType> = (item) => {
	return {
		id: item?.id ?? null,
		invId: item?.invId ?? null,
		voyageId: item?.voyageId ?? null,
		bunkerType: item?.bunkerType ?? null,
		type: item?.type ?? null,
		consumption: item?.consumption ?? "",
		price: item?.price ?? "",
		total: item?.total ?? 0
	};
};

export const invStatementBunkerOptions: CommonOptionType<
	InvStatementBunkerItemType["bunkerType"],
	InvStatementBunkerItemType["bunkerType"]
>[] = [
	{
		label: "HSFO",
		value: "HSFO"
	},
	{
		label: "LSFO",
		value: "LSFO"
	},
	{
		label: "MDO",
		value: "MDO"
	},
	{
		label: "LSMGO",
		value: "LSMGO"
	}
];
