import { useEffect, useState } from "react";
import {
	RouteCommonRangingControllTabType,
	RouteCommonToolbarControllItemType,
	RouteCommonToolbarControllKeys,
	RouteCommonToolbarMeteoSwitchMap,
	RouteCommonToolbarProps
} from "../type";
import { MeteorologicalKeys, controllOptions } from "../source";
import { RouteCommonMeteoType } from "@/pages/routeCommon/type";

const useIndex = (
	meteoStatus: RouteCommonToolbarProps["meteoStatus"],
	onMeteoStatusChange: RouteCommonToolbarProps["onMeteoStatusChange"],
	onControllChange: RouteCommonToolbarProps["onControllChange"],
	onRangeSelect: RouteCommonToolbarProps["onRangeSelect"],
	onPointSelect: RouteCommonToolbarProps["onPointSelect"],
	onControllHidden: RouteCommonToolbarProps["onControllHidden"],
	onMeteoHidden: RouteCommonToolbarProps["onMeteoHidden"]
) => {
	const [controllStatus, setControllStatus] = useState<RouteCommonToolbarControllKeys[]>([]);

	const [activeRangeIndex, setActiveRangeIndex] = useState<RouteCommonRangingControllTabType>(null);

	const [pointStatus, setPointStatus] = useState<boolean>(false);

	const handleHifleetHidden = () => {
		if (meteoStatus.ENC === RouteCommonToolbarMeteoSwitchMap.On) {
			onMeteoStatusChange?.("ENC");
		}
	};

	const handleControllChange = (item: RouteCommonToolbarControllItemType) => {
		setControllStatus((prev) => {
			const index = prev?.findIndex((selectItem) => selectItem === item?.value);
			if (index === -1) {
				prev?.push(item?.value);
			} else {
				prev?.splice(index, 1);
			}
			onControllChange?.(item?.layerIds, index === -1);
			// handleHifleetHidden();
			return [...prev];
		});
	};

	const handleRangeSelect: RouteCommonToolbarProps["onRangeSelect"] = (type) => {
		switch (true) {
			case !type:
			case activeRangeIndex === type:
				setActiveRangeIndex(null);
				break;
			default:
				setActiveRangeIndex(type);
				// handleHifleetHidden();
				break;
		}
		onRangeSelect?.(activeRangeIndex === type ? null : type);
	};

	const handlePointSelect: RouteCommonToolbarProps["onPointSelect"] = (value) => {
		setPointStatus(value);
		onPointSelect?.(value);
		// value && handleHifleetHidden();
	};

	const handleEncSelect = (selectMeteo: MeteorologicalKeys) => {
		switch (true) {
			case selectMeteo !== "ENC" && meteoStatus.ENC === RouteCommonToolbarMeteoSwitchMap.On:
				handleHifleetHidden();
				return;
			case selectMeteo !== "ENC" && meteoStatus.ENC === RouteCommonToolbarMeteoSwitchMap.Off:
				return;
			case selectMeteo === "ENC" && meteoStatus.ENC === RouteCommonToolbarMeteoSwitchMap.On:
				return;
			default:
				break;
		}
		const meteoList = Object?.entries(meteoStatus)?.filter(
			(meteoItem) =>
				meteoItem?.[0] !== "ENC" && meteoItem?.[1] === RouteCommonToolbarMeteoSwitchMap.On
		);
		// const controllList = controllOptions?.filter((controllItem) =>
		// 	controllStatus?.includes(controllItem?.value)
		// );
		if (meteoList?.length > 0) {
			onMeteoHidden?.(meteoList?.map((meteoItem) => meteoItem?.[0]) as RouteCommonMeteoType[]);
		}
		// if (controllList?.length > 0) {
		// 	onControllHidden?.(
		// 		controllList?.reduce((prev, curr) => {
		// 			prev?.push(...curr?.layerIds);
		// 			return prev;
		// 		}, [])
		// 	);
		// 	setControllStatus([]);
		// }
		// if (pointStatus) {
		// 	handlePointSelect(false);
		// }
		// if (!!activeRangeIndex) {
		// 	setActiveRangeIndex(null);
		// 	onRangeSelect?.(null);
		// }
	};

	const handleKeyUp = (event: KeyboardEvent) => {
		console.log("event", event);
		if (event.key === "Escape") {
			debugger;
			handleRangeSelect?.(null);
			event?.preventDefault();
		}
	};

	useEffect(() => {
		document.addEventListener("keyup", handleKeyUp, false);
		return () => {
			document.removeEventListener("keyup", handleKeyUp, false);
		};
	}, []);

	return {
		activeRangeIndex,
		controllStatus,
		handleControllChange,
		handleRangeSelect,
		pointStatus,
		handlePointSelect,
		handleEncSelect
	};
};

export default useIndex;
