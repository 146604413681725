import React, { forwardRef, useImperativeHandle } from "react";
import style from "../../index.module.less";
import {
	HandoverFormBasicInfo,
	HandoverFormBunkerAndPerformance,
	HandoverFormFixtureNotes,
	HandoverFormHeadFixture,
	HandoverFormPortDetails
} from "@/pages/voyagemanage/business/preOp&movement/components";
import classnames from "classnames";
import { tcvcHandoverIdMap } from "./source";
import useIndex from "./hooks/useIndex";
import { CommonNewlyContact } from "@/common";
import { VoyageManageHandoverFormProps, VoyageManageHandoverFormCommonRefType } from "../../type";
import { FleetLoading } from "@/components";

const VoyageManageTcvcHandoverForm = forwardRef<
	VoyageManageHandoverFormCommonRefType,
	VoyageManageHandoverFormProps
>(({ className, voyageId }, ref) => {
	const {
		loading,
		basicInfo,
		handleBasicInfoChange,
		handleBasicInfoCommit,
		headFixture,
		handleHeadFixtureChange,
		handleHeadFixtureCommit,
		bunkerClause,
		handleBunkerClauseChange,
		handleBunkerClauseCommit,
		performance,
		handlePerformanceChange,
		handlePerformanceCommit,
		fixtureNotes,
		handleFixtureNotesChange,
		handleFixtureNotesCommit,
		portDetails,
		handlePortDetailChange,
		handlePortDetailCommit,
		handlePortDetailAdd,
		handlePortDetailMove,
		handlePortDetailDelete,
		handlePortDndCommit,
		otherItem,
		handleOtherChange,
		handleOtherCommit,
		activeIndex,
		containerRef,
		currentIndex,
		handleScrollIntoView,
		// handleScroll,
		handleCombine,
		handleReset,
		handleCheck
	} = useIndex(voyageId);

	console.log("tcvc baseinfo", basicInfo);

	useImperativeHandle(ref, () => ({
		combineParams: handleCombine,
		reset: handleReset,
		check: handleCheck
	}));

	return (
		<div className={classnames("fleet-nonScrolBar", style["voyageManage-handoverForm"], className)}>
			<FleetLoading loading={loading} />
			<div className="handoverForm-container" ref={containerRef}>
				<HandoverFormBasicInfo
					currentId="handoverForm-basicInfo"
					item={basicInfo}
					otherItem={otherItem}
					onChange={handleBasicInfoChange}
					onCommit={handleBasicInfoCommit}
					onOtherChange={handleOtherChange}
				/>
				<HandoverFormHeadFixture
					voyageType="TCVC"
					currentId="handoverForm-headFixture"
					item={headFixture}
					onChange={handleHeadFixtureChange}
					onCommit={handleHeadFixtureCommit}
					voyageId={voyageId}
				/>
				<HandoverFormBunkerAndPerformance
					voyageType="TCVC"
					currentId="handoverForm-bunkerAndperformance"
					otherItem={otherItem}
					onBunkerPlanChange={handleOtherChange}
					onBunkerPlanCommit={handleOtherCommit}
					bunkerClauseItem={bunkerClause}
					onBunkerClauseChange={handleBunkerClauseChange}
					onBunkerClauseCommit={handleBunkerClauseCommit}
					performanceItem={performance}
					onPerformanceChange={handlePerformanceChange}
					onPerformanceCommit={handlePerformanceCommit}
				/>
				<HandoverFormFixtureNotes
					currentId="handoverForm-fixtureNotes"
					item={fixtureNotes}
					onChange={handleFixtureNotesChange}
					onCommit={handleFixtureNotesCommit}
				/>
				<HandoverFormPortDetails
					currentId="handoverForm-portDetails"
					items={portDetails}
					onChange={handlePortDetailChange}
					onCommit={handlePortDetailCommit}
					onAdd={handlePortDetailAdd}
					onDelete={handlePortDetailDelete}
					onDndCommit={handlePortDndCommit}
					otherItem={{
						dhd: otherItem?.dhd
					}}
					onOtherChange={handleOtherChange}
					onMove={handlePortDetailMove}
				/>
			</div>
			<div className="handoverForm-anchor">
				<div className="anchor-scrollBar--scrollArea">
					<span
						className="anchor-scrollBar"
						style={{
							transform: `translateY(${currentIndex * 28}px)`
						}}
					></span>
				</div>
				<ul className="anchor-list">
					{tcvcHandoverIdMap?.map((item, index) => {
						return (
							<li
								key={item?.value}
								onClick={(e) => {
									handleScrollIntoView?.(e, item?.value, index);
								}}
								className={classnames({
									active: activeIndex === item?.value
								})}
							>
								{item?.label}
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
});

export default VoyageManageTcvcHandoverForm;
