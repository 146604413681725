import { CommonInitItemEvent } from "@/types/event";
import { InvoicesItemType } from "./type";

export const initialInvoiceItem: CommonInitItemEvent<InvoicesItemType> = (item) => {
  return {
		id: item?.id ?? null,
		invAmount: item?.invAmount ?? 0,
		invNo: item?.invNo ?? "",
		vesselName: item?.vesselName ?? "",
		voyageNo: item?.voyageNo ?? "",
		total: item?.total ?? 0,
		status: item?.status ?? "Unsettled",
		remitAmount: item?.remitAmount ?? 0,
		period: item?.period ?? 0,
		bill: item?.bill ?? "",
		counterparty: item?.counterparty ?? "",
		createUser: item?.createUser ?? "",
		createTime: item?.createTime ?? null,
		invFinal: item?.invFinal ?? false
	};
}