import React, { FC } from "react"
import style from "./index.module.less"
import { InvFreightBeneficiaryInfoProps } from "./type"
import classnames from "classnames"
import { FleetFormItem, FleetVerifyInput } from "@/components"
import { Button } from "@/_components"
import { CommonAccountSelect } from "@/common/Select"

const InvFreightBeneficiaryInfo: FC<InvFreightBeneficiaryInfoProps> = ({
	item,
	onCommit,
	onChange,
	fieldItems,
	onFieldChange,
	onFieldDelete,
	onFieldOpen
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invStatement-counterParty"])}>
			<div className="invoicesForm-header">
				<span>Beneficiary information</span>
				<span>Recipient information</span>
			</div>
			<div className="invoicesForm-firstRow">
				<FleetFormItem label="Company/Beneficiary Name" needVerity>
					<CommonAccountSelect
						className="beneficiary-select"
						showKey="beneficiaryName"
						primaryKey="beneficiaryName"
						activeItems={{
							beneficiaryName: item?.beneficiaryName ?? "",
							beneficiaryCompanyAddress: item?.beneficiaryAddress ?? "",
							bankAccountNumber: item?.beneficiaryAccount ?? "",
							bankName: item?.beneficiaryBank ?? "",
							swiftNumber: item?.swiftCode ?? "",
							bankAccountAddress: item?.bankAddress ?? ""
						}}
						onFinish={(accountItem) => {
							onCommit?.({
								beneficiaryName: accountItem?.beneficiaryName ?? "",
								beneficiaryAddress: accountItem?.beneficiaryCompanyAddress ?? "",
								beneficiaryAccount: accountItem?.bankAccountNumber ?? "",
								beneficiaryBank: accountItem?.bankName ?? "",
								swiftCode: accountItem?.swiftNumber ?? "",
								bankAddress: accountItem?.bankAccountAddress ?? ""
							})
						}}
						accountActions={{
							onConfirm: (accountItems) => {
								const accountItem = accountItems?.[0]
								onCommit?.({
									beneficiaryName: accountItem?.beneficiaryName ?? "",
									beneficiaryAddress: accountItem?.beneficiaryCompanyAddress ?? "",
									beneficiaryAccount: accountItem?.bankAccountNumber ?? "",
									beneficiaryBank: accountItem?.bankName ?? "",
									swiftCode: accountItem?.swiftNumber ?? "",
									bankAddress: accountItem?.bankAccountAddress ?? ""
								})
							}
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					label="Company Address"
					value={item?.beneficiaryAddress}
					onChange={(val: string) => {
						onChange?.("beneficiaryAddress", val)
					}}
				/>
				<FleetVerifyInput
					value={item?.beneficiaryAccount}
					needVerity
					onChange={(val: string) => {
						onChange?.("beneficiaryAccount", val)
					}}
					label="A/C OF BENEFICIARY"
				/>
			</div>
			<div className="invoicesForm-secondRow">
				<FleetVerifyInput
					value={item?.beneficiaryBank}
					needVerity
					onChange={(val: string) => {
						onChange?.("beneficiaryBank", val)
					}}
					label="BENEFICIARY'S BANK"
				/>
				<FleetVerifyInput
					value={item?.swiftCode}
					needVerity
					onChange={(val: string) => {
						onChange?.("swiftCode", val)
					}}
					label="SWIFT CODE"
				/>
				<FleetVerifyInput
					value={item?.bankAddress}
					needVerity
					onChange={(val: string) => {
						onChange?.("bankAddress", val)
					}}
					label="BANK ADD"
				/>
				{fieldItems?.map((item, index) => {
					return (
						<div className="freight-fields" key={index}>
							<FleetVerifyInput
								value={item?.fieldVal}
								label={item?.fieldName}
								onChange={(val: string) => {
									onFieldChange?.(index, "fieldVal", val)
								}}
							/>
							<span className="font_family fleet-minus"></span>
						</div>
					)
				})}
				<Button
					prefixIcon="fleet-plus"
					size="small"
					type="secondary"
					className="invoices-add"
					disabled
					// onClick={() => onOpenFieldsAdd("beneficiary")}
				>
					Add field
				</Button>
			</div>
		</div>
	)
}

export default InvFreightBeneficiaryInfo
