import { VoyageType } from "@/types/common"
import { MovementCommissionItemType } from "./type"
import { MovementCommonTableColumnsType } from "../../components/charterBase/common/table/type"
import { CommonColumnChangeEvent, CommonColumnCommitEvent } from "@/types/event"
import { FleetInput, FleetTooltip } from "@/components"
import React from "react"
import { CommonContactSelect, FleetToast } from "@/common"

export const initialCommissionItem = (option: {
	voyageId: string
	voyageType: VoyageType
}): MovementCommissionItemType => {
	return {
		id: null,
		itemName: "",
		itemValue: "",
		payeeId: null,
		payeeName: "",
		voyageId: option?.voyageId,
		status: "Unsettled",
		settledTime: null,
		voyageType: option?.voyageType,
		settledAmount: 0,
		latestFinancialDate: null
	}
}

export const columns: MovementCommonTableColumnsType<
	MovementCommissionItemType,
	keyof MovementCommissionItemType,
	{
		onDelete: (currentIndex: number) => void
		onCommit: CommonColumnCommitEvent<MovementCommissionItemType>
	}
>[] = [
	{
		label: "Item Name",
		dataIndex: "itemName",
		width: 150,
		fixed: "left",
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					className="td-input"
					value={value}
					onChange={(val: string) => {
						actions?.onCommit?.(index, {
							itemName: val
						})
					}}
				/>
			)
		}
	},
	{
		label: "Payee",
		dataIndex: "payeeName",
		width: 250,
		render: (value, record, index, actions) => {
			return (
				<CommonContactSelect
					showKey="companyName"
					primaryKey="id"
					activeItems={{
						id: record?.payeeId,
						companyName: record?.payeeName
					}}
					onFinish={(newNoItem, type) => {
						actions?.onCommit?.(index, {
							payeeId: newNoItem?.id ?? null,
							payeeName: newNoItem?.companyName ?? ""
						})
					}}
					contactActions={{
						onConfirm: (newNoItem) => {
							actions?.onCommit?.(index, {
								payeeId: newNoItem?.id ?? null,
								payeeName: newNoItem?.companyName ?? ""
							})
						}
					}}
				/>
			)
		}
	},
	{
		label: "Amount（$）",
		dataIndex: "itemValue",
		width: 150,
		fixed: "left",
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					className="td-input"
					value={value}
					type="number"
					onChange={(val: number) => {
						actions?.onCommit?.(index, {
							itemValue: val
						})
					}}
				/>
			)
		}
	},
	{
		label: "Action",
		dataIndex: "action",
		fixed: "right",
		width: 60,
		render: (value, record, index, actions) => {
			return (
				<div className="td-actions">
					<FleetTooltip
						outerLayer={<span className="font_family fleet-delete"></span>}
						render={(setIsOpen) => {
							return (
								<FleetToast
									label="Are you sure delete this item?"
									onCancel={() => {
										setIsOpen(false)
									}}
									onConfirm={() => {
										actions?.onDelete?.(index)
										setIsOpen(false)
									}}
								/>
							)
						}}
					/>
				</div>
			)
		}
	}
]
