import React from "react";
import AntdTable from "antd/es/table/index";
import { TableProps } from "./interface";
import classnames from "classnames";
import style from "./index.module.less";

export default function Table<T>({
	primaryKey = "id",
	sticky = true,
	scroll = { x: 1420, y: 650 },
	pagination = {
		current: 1,
		pageSize: 20,
		defaultCurrent: 1
	},
	className,
	dataSource,
	summary,
	...restProps
}: TableProps<T>) {
	return (
		<AntdTable
			{...restProps}
			className={classnames(
				style["fleet-table"],
				{
					[style["fleet-table-empty"]]: dataSource?.length === 0,
					[style["fleet-table-sumary"]]: !!summary
				},
				className
			)}
			summary={summary}
			sticky={sticky}
			rowKey={(record) => record?.[primaryKey]}
			dataSource={dataSource}
			scroll={scroll}
			pagination={pagination}
		/>
	);
}
