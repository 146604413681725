import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { InvFreightBaseProps } from "./type";
import { InvoicesViewsCommonHeader } from "../../../../common";
import { FleetDatePicker, FleetFormItem, FleetVerifyInput } from "@/components";
import dayjs from "dayjs";
import { InvFreightBeneficiaryInfo, InvFreightCounterParty } from "./components";

const InvFreightBase: FC<InvFreightBaseProps> = ({
	otherItem,
	item,
	onChange,
	onCommit,
	counterFieldList,
	onCounterFieldChange,
	beneficiaryFieldList,
	onBeneficiaryFieldChange,
	onFieldOpen,
	onSwitchFinal,
	totalInv
}) => {
	return (
		<div className={classnames("invoicesDetail-item", style["freight-base"])}>
			<InvoicesViewsCommonHeader
				type="base"
				item={{
					status: otherItem?.status,
					updateTime: otherItem?.updateTime,
					invNo: item?.invNo,
					invFinal: otherItem?.invFinal,
					isFinal: item?.period === totalInv || !item?.period
				}}
				title="Freight Invoices"
				onSwitchFinal={onSwitchFinal}
			/>
			<div className="invoices-base--header">
				<FleetVerifyInput
					value={item?.invNo}
					onChange={(val: string) => {
						onChange?.("invNo", val);
					}}
					label="INV. No."
					needVerity
				/>
				<FleetFormItem label="Invoice Date" needVerity>
					<FleetDatePicker
						value={item?.invDate ? dayjs(item?.invDate) : null}
						onChange={(val, timeStamp) => {
							onChange?.("invDate", val);
						}}
						allowClear
					/>
				</FleetFormItem>
			</div>
			<InvFreightCounterParty
				item={{
					ownerName: item?.ownerName,
					ownerId: item?.ownerId,
					registerAddress: item?.registerAddress
				}}
				onCommit={onCommit}
				fieldItems={counterFieldList}
				onFieldChange={onCounterFieldChange}
			/>
			<InvFreightBeneficiaryInfo
				item={{
					beneficiaryName: item?.beneficiaryName,
					beneficiaryAddress: item?.beneficiaryAddress,
					beneficiaryAccount: item?.beneficiaryAccount,
					beneficiaryBank: item?.beneficiaryBank,
					bankAddress: item?.bankAddress,
					swiftCode: item?.swiftCode
				}}
				onChange={onChange}
				onCommit={onCommit}
				fieldItems={beneficiaryFieldList}
				onFieldChange={onBeneficiaryFieldChange}
			/>
		</div>
	);
};

export default InvFreightBase;
