import React, { FC } from "react"
import classnames from "classnames"
import "./index.less"
import { TooltipProps } from "./interface"
import Popover from "../Popover"

const Tooltip: FC<TooltipProps> = ({
	title,
	trigger = "hover",
	overlayClassName,
	...restProps
}) => {
	console.log("test222", overlayClassName)

	return (
		<Popover
			{...restProps}
			trigger={trigger}
			className={classnames(overlayClassName, "fleet-tooltip")}
			content={title}
		/>
	)
}

export default Tooltip
