import React from "react";
import style from "./index.module.less";
import { PortDaFilterProps } from "./type";
import { FleetFormItem, FleetRangePicker, FleetVerifyInput } from "@/components";
import { CommonContactSelect } from "@/common";

const PortDaFilter: React.FC<PortDaFilterProps> = ({ item, onSearch, onFilterChange }) => {
	return (
		<div className={style["portDa-filter"]}>
			<FleetVerifyInput
				value={undefined}
				onChange={onSearch}
				placeholder="Port Name/Agent"
				label="Filter"
				prefix="LayoutSearchIcon"
				containerClassname="filter-search"
			/>
			<FleetFormItem label="Agent" containerClassname="filter-contactSelect">
				<CommonContactSelect
					onFinish={(newItem, type) => {
						switch (type) {
							case "add":
								onFilterChange?.("agent", { id: newItem.id as number, name: newItem.name });
								break;
							case "del":
								onFilterChange?.("agent", { id: null, name: "" });
								break;
							default:
								break;
						}
					}}
					activeItems={item?.agent}
					contactParmas={{ businessType: "Port Agent" }}
					primaryKey="id"
					showKey="name"
				/>
			</FleetFormItem>
			<FleetFormItem label="Port Arrival" containerClassname="filter-timeSelect">
				<FleetRangePicker
					allowClear
					value={item?.timeRange}
					onChange={(vals) => {
						onFilterChange?.("timeRange", vals ?? [undefined, undefined]);
					}}
				/>
			</FleetFormItem>
		</div>
	);
};

export default PortDaFilter;
