import { InvFreightOtherItemType } from "@/pages/voyagemanage/business/invoices/module";
import { useMemo, useRef, useState } from "react";
import {
	InvFreightPortItemAddEvent,
	InvFreightPortItemDeleteEvent,
	InvFreightPortItemType,
	InvFreightPortProps
} from "../type";
import { calcRate } from "../tools";
import { checkKeys, initialInvFreightPortItem } from "../source";
import { commonItemsCheck } from "@/tools/check";
import useReminder from "@/hook/useReminder";

const useIndex = (otherItem: Pick<InvFreightOtherItemType, "addDesDem">) => {
	const { reminder } = useReminder();
	const [loadDataSource, setLoadDataSource] = useState<InvFreightPortItemType[]>([]);
	const [dischargeDataSource, setDischargeDataSource] = useState<InvFreightPortItemType[]>([]);

	const delIds = useRef<number[]>([]);

	const loadSummary = useMemo(() => {
		return otherItem?.addDesDem
			? loadDataSource?.reduce((prev, curr) => {
					return prev + calcRate(curr)
				}, 0)
			: 0
	}, [otherItem?.addDesDem, loadDataSource])

	const dischargeSummary = useMemo(() => {
		return otherItem?.addDesDem
			? dischargeDataSource?.reduce((prev, curr) => {
					return prev + calcRate(curr)
				}, 0)
			: 0
	}, [otherItem?.addDesDem, dischargeDataSource])

	const summary = useMemo(() => {
		return loadSummary + dischargeSummary;
	}, [loadSummary, dischargeSummary]);

	const change: InvFreightPortProps["onChange"] = (type, currentIndex, key, value) => {
		switch (true) {
			case type === "load":
				setLoadDataSource((prev) => {
					const currentItem = prev?.[currentIndex];
					prev?.splice(currentIndex, 1, {
						...currentItem,
						[key]: value
					});
					return [...prev];
				});
				break;
			case type === "discharge":
				setDischargeDataSource((prev) => {
					const currentItem = prev?.[currentIndex];
					prev?.splice(currentIndex, 1, {
						...currentItem,
						[key]: value
					});
					return [...prev];
				});
				break;
			default:
				break;
		}
	};

	const commit: InvFreightPortProps["onCommit"] = (type, currentIndex, item) => {
		switch (true) {
			case type === "load":
				setLoadDataSource((prev) => {
					const currentItem = prev?.[currentIndex];
					prev?.splice(currentIndex, 1, {
						...currentItem,
						...item
					});
					return [...prev];
				});
				break;
			case type === "discharge":
				setDischargeDataSource((prev) => {
					const currentItem = prev?.[currentIndex];
					prev?.splice(currentIndex, 1, {
						...currentItem,
						...item
					});
					return [...prev];
				});
				break;
			default:
				break;
		}
	};

	const add: InvFreightPortItemAddEvent = (type, item) => {
		if (type === "load") {
			setLoadDataSource((prev) => [...prev, initialInvFreightPortItem("init", item)]);
			return;
		}
		setDischargeDataSource((prev) => [...prev, initialInvFreightPortItem("init", item)]);
	};

	const remove: InvFreightPortItemDeleteEvent = (type, currentIndex) => {
		debugger;
		const currentPortList = type === "load" ? loadDataSource : dischargeDataSource;
		if (currentPortList?.length <= 1) {
			reminder("info", "Please reserve at least one port");
			return;
		}
		switch (true) {
			case type === "load":
				setLoadDataSource((prev) => {
					const currentItem = prev?.[currentIndex];
					currentItem?.id && delIds.current.push(currentItem?.id);
					prev?.splice(currentIndex, 1);
					return [...prev];
				});
				break;
			case type === "discharge":
				setDischargeDataSource((prev) => {
					const currentItem = prev?.[currentIndex];
					currentItem?.id && delIds.current.push(currentItem?.id);
					prev?.splice(currentIndex, 1);
					return [...prev];
				});
				break;
			default:
				break;
		}
	};

	const init = (
		type: "init" | "details",
		loadItems: InvFreightPortItemType[] = [],
		dischargeItems: InvFreightPortItemType[] = []
	) => {
		setLoadDataSource(loadItems?.map((loadItem) => initialInvFreightPortItem(type, loadItem)));
		setDischargeDataSource(
			dischargeItems?.map((dischargeItem) => initialInvFreightPortItem(type, dischargeItem))
		);
	};

	const check = () => {
		return otherItem?.addDesDem
			? commonItemsCheck([...loadDataSource, ...dischargeDataSource], checkKeys)
			: {
					checked: true,
					checkKey: ""
				}
	};

	return {
		loadDataSource,
		dischargeDataSource,
		summary,
		delIds,
		change,
		add,
		remove,
		init,
		check,
		commit
	};
};

export default useIndex;
