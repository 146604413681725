import React, { FC } from "react";
import { InvStatementOtherCalculateProps } from "./type";
import { FleetVerifyInput } from "@/components";
import { cn } from "@/tools";
import { Button, Icon } from "@/_components";

const InvStatementOtherCalculate: FC<InvStatementOtherCalculateProps> = ({
	items,
	onAdd,
	onDelete,
	onCommit
}) => {
	return (
		<div className="w-full gap-2 flex flex-col border-b border-solid border-[#e7e9ec] pb-2">
			<span className="text-[#151515] text-[12px] leading-[20px] font-medium">
				Other Calculation Details
			</span>
			<div className="w-full flex flex-col gap-2">
				{items?.map((item, index) => {
					return (
						<div className="grid grid-cols-[200px_200px_1fr] grid-rows-1 gap-2" key={index}>
							<FleetVerifyInput
								needVerity
								value={item?.title}
								label="Title"
								onChange={(val: string) => {
									onCommit?.(index, {
										title: val
									})
								}}
							/>
							<FleetVerifyInput
								needVerity
								value={item?.totalAmount}
								label="Total Amount ($)"
								type="number"
								onChange={(val: number) => {
									onCommit?.(index, {
										totalAmount: val
									})
								}}
							/>
							<div className={cn("grid grid-cols-[1fr_32px] gap-[4px]")}>
								<FleetVerifyInput
									value={item?.remark}
									label="Remarks"
									onChange={(val: string) => {
										onCommit?.(index, {
											remark: val
										})
									}}
								/>
								<Icon
									type="fleet-minus"
									className="self-end opacity-40  cursor-pointer"
									size={32}
									onClick={() => {
										onDelete?.(index)
									}}
								/>
							</div>
						</div>
					)
				})}
			</div>
			<Button prefixIcon="fleet-plus" type="primary" size="small" onClick={onAdd}>
				Add row
			</Button>
		</div>
	)
};

export default InvStatementOtherCalculate;
