import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { useEffect, useState } from "react";
import { CommonNewlyAccountItemType, CommonNewlyAccountProps } from "../type";
import { checkAccountKeys, initialAccountItem } from "../source";
import { getAccountGroupAction, updateAccountAction } from "@/action/dataBase/account";
import { useTranslation } from "react-i18next";
import { CommonColumnChangeEvent } from "@/types/event";
import { commonItemsCheck } from "@/tools/check";
const useIndex = (
	hide: boolean,
	onConfirm: CommonNewlyAccountProps["onConfirm"],
	type: "add" | "edit",
	activeItem?: CommonNewlyAccountProps["activeItem"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const [delList, setDelList] = useState<number[]>([]);

	const [company, setCompany] = useState<CommonNewlyAccountProps["activeItem"]>({
		companyId: null,
		companyName: ""
	});

	const [dataSource, setDataSource] = useState<CommonNewlyAccountItemType[]>([]);

	const handleChange: CommonColumnChangeEvent<CommonNewlyAccountItemType> = (
		currentIndex,
		key,
		value
	) => {
		setDataSource((prev) => {
			const current = prev[currentIndex];
			prev.splice(currentIndex, 1, {
				...current,
				[key]: value
			});
			return [...prev];
		});
	};

	const handleAdd = () => {
		if (!company?.companyId) {
			reminder("warning", "Please select a company");
			return;
		}
		const newlyAccountItem = initialAccountItem(
			type === "add" ? { ...company } : { ...activeItem }
		);
		setDataSource((prev) => [...prev, newlyAccountItem]);
	};

	const handleDelete = (currentIndex: number) => {
		setDataSource((prev) => {
			const current = prev[currentIndex];
			current?.id && setDelList((prev) => [...prev, current.id]);
			prev.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const handleCompanySelect = (company: CommonNewlyAccountProps["activeItem"]) => {
		setCompany(company);
		setDataSource((prev) => {
			return prev?.map((item) => {
				return { ...item, companyId: company?.companyId, companyName: company?.companyName };
			});
		});
	};

	const getAccountDetailsFront = () => {
		setLoading(true);
	};

	const getAccountDetailsSuccess = (response) => {
		setDataSource(response?.data?.items ?? []);
		setCompany({
			...activeItem
		});
		setLoading(false);
	};

	const updateAccountSuccess = (response) => {
		reminder("success", response?.msg);
		setLoading(false);
		onConfirm?.(response?.data ?? []);
	};

	const getAccountDetailsFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const updateAccount = () => {
		if (!company?.companyId) {
			reminder("error", `Company Name is empty`);
			return;
		}
		if (dataSource?.length === 0 && delList?.length === 0) return;
		const { checkKey, checked } = commonItemsCheck(dataSource, checkAccountKeys);
		if (!checked) {
			reminder("error", `Required field ${t(`dataBase.account.${checkKey}`)} is empty`);
			return;
		}
		updateAccountAction(
			{ params: { accountList: dataSource, deleteIds: delList }, method: "post" },
			getAccountDetailsFront,
			updateAccountSuccess,
			getAccountDetailsFailed,
			dispatch,
			navigate
		);
	};

	const getAccountDetails = () => {
		getAccountGroupAction(
			{ pageNum: 1, pageSize: 100, companyId: activeItem?.companyId, keyword: "" },
			getAccountDetailsFront,
			getAccountDetailsSuccess,
			getAccountDetailsFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (hide) {
			setDataSource([]);
			setDelList([]);
			setCompany({ companyName: "", companyId: null });
			return;
		}
		if (type === "add" || !activeItem?.companyId) return;
		getAccountDetails();
	}, [hide, activeItem]);

	return {
		dataSource,
		loading,
		company,
		setCompany,
		handleAdd,
		handleDelete,
		handleChange,
		updateAccount,
		handleCompanySelect
	};
};

export default useIndex;
