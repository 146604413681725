import { PageRouterProps } from "@/router";
import { BillOfLading, CharterersLiability } from "./business";
import React from "react";

const StatementRouter: PageRouterProps[] = [
	{
		path: "bill-of-lading",
		key: "/layout/statement/bill-of-lading",
		element: <BillOfLading />,
		label: "Bill of Lading"
	},
	{
		path: "charterers-liability",
		key: "/layout/statement/charterers-liability",
		element: <CharterersLiability />,
		label: "Charterers’ Liability Declaration Form"
	}
];

export default StatementRouter;
