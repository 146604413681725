import React from "react";
import classnames from "classnames";
import dayjs, { type Dayjs } from "dayjs";
import DatePicker, { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import style from "./index.module.less";
const { RangePicker } = DatePicker;
import { TimePicker as AntdTimePicker } from "antd";
import { RangePickerProps as RcRangePickerProps, SharedTimeProps } from "rc-picker/lib/index";

export const FleetRangePicker: React.FC<{
	className?: string;
	value?: RangePickerProps["value"];
	onChange?: (value: [Dayjs, Dayjs]) => void;
	disabledDate?: RangePickerProps["disabledDate"];
	showTime?: RcRangePickerProps<Dayjs>["showTime"];
	format?: string;
	showHour?: boolean;
	showMinute?: boolean;
	showSecond?: boolean;
	disabled?: boolean;
	allowClear?: boolean;
}> = ({
	className,
	value,
	onChange,
	disabledDate,
	format,
	showTime = false,
	showMinute = false,
	showSecond = false,
	showHour = false,
	allowClear = false,
	disabled
}) => {
	const handleChange = (value: RangePickerProps["value"], dateString: [string, string]) => {
		onChange?.(value);
	};
	return (
		<RangePicker
			value={value}
			format={format}
			showTime={showTime}
			disabled={disabled}
			showHour={showHour}
			showMinute={showMinute}
			showSecond={showSecond}
			onChange={handleChange}
			allowClear={allowClear}
			disabledDate={disabledDate}
			className={classnames(style["fleet-datePicker"], className)}
			defaultValue={[dayjs(new Date()), dayjs(new Date())]}
		/>
	);
};

const FleetDatePicker: React.FC<{
	className?: string;
	onChange?: (val: string, timeStramp?: Dayjs) => void;
	disabledDate?: RangePickerProps["disabledDate"];
	value: Dayjs;
	disabled?: boolean;
	showTime?: boolean | SharedTimeProps<Dayjs>;
	showHour?: boolean;
	showMinute?: boolean;
	showSecond?: boolean;
	bordered?: boolean;
	allowClear?: boolean;
	format?: DatePickerProps["format"];
	size?: number;
}> = ({
	className,
	onChange,
	disabled,
	disabledDate,
	showTime = false,
	showMinute = false,
	showSecond = false,
	showHour = false,
	bordered,
	format,
	value,
	allowClear = false,
	size
}) => {
	const handleChange: (value: Dayjs | null, dateString: string) => void = (value, dataString) => {
		if (disabled) return;
		onChange?.(dataString, value);
	};
	return (
		<DatePicker
			disabled={disabled}
			bordered={bordered}
			showTime={showTime}
			showHour={showHour}
			showMinute={showMinute}
			showSecond={showSecond}
			format={format}
			disabledDate={disabledDate}
			className={classnames(style["fleet-Picker"], className)}
			onChange={handleChange}
			allowClear={allowClear}
			value={value}
			defaultValue={dayjs(new Date())}
			// allowClear={{
			//   clearIcon:<FleetIcon type="FleetCloseIcon" />
			// }}
		/>
	);
};

export const FleetTimePicker: React.FC<{
	className?: string;
	onChange?: (val: string) => void;
	disabledDate?: RangePickerProps["disabledDate"];
	allowClear?: boolean;
	value: Dayjs;
}> = ({ className, allowClear, onChange, disabledDate, value }) => {
	const handleChange: (value: Dayjs | null, dateString: string) => void = (value, dataString) => {
		onChange?.(dataString);
	};
	return (
		<DatePicker
			disabledDate={disabledDate}
			className={classnames(style["fleet-Picker"], className)}
			onChange={handleChange}
			allowClear={allowClear}
			showTime
			showHour
			showMinute
			showSecond={false}
			value={value}
			defaultValue={dayjs(new Date())}
			// allowClear={{
			//   clearIcon:<FleetIcon type="FleetCloseIcon" />
			// }}
		/>
	);
};

export const FleetTimeRangePicker: React.FC<{
	className?: string;
	onChange?: (value: [Dayjs, Dayjs]) => void;
	disabledDate?: RangePickerProps["disabledDate"];
	value?: RangePickerProps["value"];
	format?: RangePickerProps["format"];
	suffixIcon?: React.ReactNode;
	disabled?: boolean;
	showSecond?: boolean;
	renderExtraFooter?: RangePickerProps["renderExtraFooter"];
}> = ({
	className,
	suffixIcon,
	onChange,
	disabledDate,
	disabled,
	format,
	value,
	showSecond = false,
	renderExtraFooter
}) => {
	const handleChange = (value: RangePickerProps["value"], dateString: [string, string]) => {
		onChange?.(value);
	};
	return (
		<AntdTimePicker.RangePicker
			disabledDate={disabledDate}
			className={classnames(style["fleet-Picker"], className)}
			onChange={handleChange}
			allowClear={false}
			format={format}
			suffixIcon={suffixIcon}
			disabled={disabled}
			use12Hours={false}
			showHour
			showMinute
			showSecond={showSecond}
			value={value}
			renderExtraFooter={renderExtraFooter}
		/>
	);
};

export default FleetDatePicker;
