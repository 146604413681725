import React from "react"
import { MovementCommonTableColumnsType } from "../charterBase/common/table/type"
import {
	MovementPortExpensesItemType,
	MovementPortExpensesPortItemChangeEvent,
	MovementPortExpensesPortItemCommitEvent,
	MovementPortExpensesPortItemType
} from "./type"
import { FleetInput, FleetSelect } from "@/components"
import { CargoUnitType, FleetNumType, PortPurposeType } from "@/types/common"
import { CargoUnitOptions } from "@/types/option"
import { CommonContactSelect } from "@/common"
import { portPurposeOptions } from "@/pages/voyagemanage/business/movement/source"
import { formatThousandthNumber } from "@/tools/amount"
import { Icon } from "@/_components"

export const initialPortExpenses: MovementPortExpensesItemType = {
	vesselName: "",
	vesselType: "",
	cargoName: "",
	portExpenseVoList: []
}

export const columns: MovementCommonTableColumnsType<
	MovementPortExpensesPortItemType,
	keyof MovementPortExpensesPortItemType,
	{
		onChange: MovementPortExpensesPortItemChangeEvent
		onCommit: MovementPortExpensesPortItemCommitEvent
		onOpen: (currentIndex: number, value: FleetNumType[]) => void
	}
>[] = [
	{
		label: "Status",
		dataIndex: "purpose",
		width: 150,
		// fixed: "left",
		render: (value, record, index, actions) => {
			return (
				<FleetSelect
					//@ts-ignore
					value={[value]}
					disabled
					popupMatchSelectWidth={false}
					className="td-select"
					options={portPurposeOptions}
					onChange={(val: PortPurposeType) => {
						actions?.onChange(index, "purpose", val)
					}}
				/>
			)
		}
	},
	{
		label: "Port Name",
		dataIndex: "portName",
		fixed: "left",
		width: 222,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					disabled
					className="td-input"
					//@ts-ignore
					value={value}
				/>
			)
		}
	},
	{
		label: "Cargo Quantity",
		dataIndex: "cargoQuantity",
		width: 196,
		render: (value, record, index, actions) => {
			return (
				<div className="portExpenses-cargo--container">
					<FleetInput
						type="number"
						className="td-input"
						//@ts-ignore
						value={value}
						onChange={(val: number) => {
							actions?.onChange?.(index, "cargoQuantity", val)
						}}
					/>
					<FleetSelect
						value={[record?.cargoUnit]}
						popupMatchSelectWidth={false}
						className="td-select"
						options={CargoUnitOptions}
						onChange={(val: CargoUnitType) => {
							actions?.onChange(index, "cargoUnit", val)
						}}
					/>
				</div>
			)
		}
	},
	{
		label: "Pre-Port Disb",
		dataIndex: "prePortDisb",
		width: 150,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					type="number"
					className="td-input"
					//@ts-ignore
					value={value}
					onChange={(val: number) => {
						const midExpenses = +(record?.midPriceList as FleetNumType[])?.reduce(
							(prev, curr) => +prev + +curr,
							0
						)
						actions?.onCommit?.(index, {
							prePortDisb: val,
							portExpense: +record?.settlement + midExpenses + val
						})
					}}
				/>
			)
		}
	},
	{
		label: "Intermediate Expense",
		dataIndex: "midPriceList",
		width: 150,
		render: (value, record, index, actions) => {
			return (
				<div
					className="cursor-pointer flex justify-between items-center py-[3px] px-[12px] border-[1px] border-[#DADADA] border-solid bg-[#EFEFEF] rounded-[6px]"
					onClick={() => {
						actions?.onOpen?.(index, value as FleetNumType[])
					}}
				>
					<span className="font-normal text-[14px]">
						{formatThousandthNumber(
							+(value as FleetNumType[])?.reduce((prev, curr) => +prev + +curr, 0)
						)}
					</span>
					<Icon type="fleet-edit2" />
				</div>
			)
		}
	},
	{
		label: "Settlement",
		dataIndex: "settlement",
		width: 150,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					type="number"
					className="td-input"
					//@ts-ignore
					value={record?.settlement}
					onChange={(val: number) => {
						const midExpenses = +(record?.midPriceList as FleetNumType[])?.reduce(
							(prev, curr) => +prev + +curr,
							0
						)
						actions?.onCommit?.(index, {
							settlement: val,
							portExpense: +record?.prePortDisb + midExpenses + val
						})
					}}
				/>
			)
		}
	},
	{
		label: "Total Expense",
		dataIndex: "portExpense",
		width: 150,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					type="number"
					className="td-input"
					disabled //@ts-ignore
					value={value}
				/>
			)
		}
	},
	{
		label: "Agent",
		dataIndex: "portAgentId",
		// fixed: "right",
		width: 400,
		render: (value, record, index, actions) => {
			return (
				<CommonContactSelect
					showKey="companyName"
					primaryKey="id"
					activeItems={{
						id: record?.portAgentId,
						companyName: record?.portAgentName
					}}
					contactParmas={{
						businessType: "Port Agent"
					}}
					className="td-select"
					onFinish={(newItem, type) => {
						actions?.onCommit?.(index, {
							portAgentId: newItem?.id ?? null,
							portAgentName: newItem?.companyName ?? ""
						})
					}}
					contactActions={{
						onConfirm: (newItem) => {
							actions?.onCommit?.(index, {
								portAgentId: newItem?.id ?? null,
								portAgentName: newItem?.companyName ?? ""
							})
						}
					}}
				/>
			)
		}
	}
]
