import { useMemo, useRef, useState } from "react"
import {
	MovementCommissionItemType,
	MovementCommissionProps,
	MovementCommissionRefType
} from "../type"
import { onRequest } from "@/action"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "@/hook"
import useReminder from "@/hook/useReminder"
import { CommonColumnCommitEvent } from "@/types/event"
import { initialCommissionItem } from "../source"

const useIndex = (
	option: MovementCommissionProps["option"],
	onConfirm: MovementCommissionProps["onConfirm"]
) => {
	const dispatch = useAppDispatch()
	const naviagte = useNavigate()
	const { reminder } = useReminder()

	const [loading, setLoading] = useState(false)
	const [hide, setHide] = useState(true)
	const delIds = useRef<string[]>([])
	const [dataSource, setDataSource] = useState<MovementCommissionItemType[]>([])

	const summary = useMemo(() => {
		return dataSource?.reduce(
			(prev, curr) => {
				return {
					itemName: "Total",
					itemValue: prev?.itemValue + +curr?.itemValue
				}
			},
			{
				itemName: "",
				itemValue: 0
			}
		)
	}, [dataSource])

	const commit: CommonColumnCommitEvent<MovementCommissionItemType> = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex]
			prev?.splice(currentIndex, 1, { ...currentItem, ...item })
			return [...prev]
		})
	}

	const add = () => {
		setDataSource((prev) => [...prev, initialCommissionItem(option)])
	}

	const remove = (currentIndex: number) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex]
			currentItem?.id && delIds?.current?.push(currentItem?.id)
			prev?.splice(currentIndex, 1)
			return [...prev]
		})
	}

	const openModal: MovementCommissionRefType["openModal"] = (items) => {
		setDataSource(items ?? [])
		delIds.current = []
		setHide(false)
	}

	const closeModal = () => {
		setHide(true)
	}

	const saveMovementCommissionFront = () => {
		setLoading(true)
	}

	const saveMovementCommissionSuccess = (response) => {
		setLoading(false)
		onConfirm?.(response?.data ?? [])
		setHide(true)
	}

	const saveMovementCommissionFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
		setLoading(false)
	}

	const handleConfirm = () => {
		onRequest(
			"saveMovementCommissionApi",
			{
				list: dataSource,
				delIds: delIds?.current
			},
			saveMovementCommissionFront,
			saveMovementCommissionSuccess,
			saveMovementCommissionFailed,
			dispatch,
			naviagte
		)
	}

	return {
		loading,
		hide,
		dataSource,
		summary,
		remove,
		commit,
		add,
		openModal,
		closeModal,
		handleConfirm
	}
}

export default useIndex
