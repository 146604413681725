import { FC, forwardRef, useImperativeHandle } from "react"
import { MovementCommissionProps, MovementCommissionRefType } from "./type"
import { FleetPrompt } from "@/common"
import React from "react"
import useIndex from "./hooks/useIndex"
import { FleetLoading } from "@/components"
import { Button, Icon } from "@/_components"
import MovementTable from "../../components/charterBase/common/table"
import { cn } from "@/tools"
import { columns } from "./source"

const MovementCommission = forwardRef<MovementCommissionRefType, MovementCommissionProps>(
	({ onConfirm, option }, ref) => {
		const {
			loading,
			hide,
			dataSource,
			summary,
			remove,
			commit,
			add,
			openModal,
			closeModal,
			handleConfirm
		} = useIndex(option, onConfirm)

		useImperativeHandle(ref, () => {
			return {
				openModal
			}
		})
		return (
			<FleetPrompt hide={hide} mode="fixed">
				<div className="w-[800px] px-[20px] py-[24px] flex flex-col rounded-[6px] bg-[#fff] gap-[16px] h-full">
					<FleetLoading loading={loading} />
					<div className="w-full flex items-center justify-between">
						<span className="fleet-title">Commission</span>
						<Icon type="fleet-close" size={16} className=" cursor-pointer" onClick={closeModal} />
					</div>
					<div
						className={cn(
							"w-full overflow-y-auto overflow-x-hidden h-[calc(100%-44px)] fleet-nonScrolBar"
						)}
					>
						<div className={cn("w-full flex flex-col gap-[12px]")}>
							<MovementTable
								bordered
								className="!h-[300px]"
								columns={columns}
								summary={summary}
								dataSource={dataSource}
								actions={{
									onCommit: commit,
									onDelete: remove
								}}
							/>
							<Button type="secondary" onClick={add} size="small">
								Add Charge
							</Button>
							<div className="prompt-footer--container">
								<Button size="small" type="secondary" onClick={closeModal}>
									Cancel
								</Button>
								<Button size="small" onClick={handleConfirm}>
									Confirm
								</Button>
							</div>
						</div>
					</div>
				</div>
			</FleetPrompt>
		)
	}
)

export default MovementCommission
