import { InvCommonOffHireItemType, InvCommonOffHireProps, InvCommonOffHireRefType } from "../type";
import { useMemo, useRef, useState } from "react";
import { checkKeys, initialOffHireItem } from "../source";
import { CommonColumnChangeEvent, CommonColumnCommitEvent } from "@/types/event";
import { commonItemsCheck } from "@/tools/check";
import useReminder from "@/hook/useReminder";

const useIndex = (
	voyageId: InvCommonOffHireProps["voyageId"],
	activeInvId: InvCommonOffHireProps["activeInvId"],
	activePeriod: InvCommonOffHireProps["activePeriod"],
	onConfirm: InvCommonOffHireProps["onConfirm"]
) => {
	const { reminder } = useReminder();
	const [hide, setHide] = useState(true);
	const [dataSource, setDataSource] = useState<InvCommonOffHireItemType[]>([]);
	const delIds = useRef<string[]>([]);
	const activeTctcType = useRef<"Rent" | "Relet">(null);
	const summary = useMemo(() => {
		return dataSource?.reduce(
			(prev, curr) => {
				return {
					duration: prev?.duration + +curr?.duration,
					finalDuration: prev?.finalDuration + +curr?.finalDuration
				}
			},
			{ duration: 0, finalDuration: 0 }
		)
	}, [dataSource]);

	const add = (currentIndex: number) => {
		setDataSource((prev) => {
			prev.splice(
				currentIndex + 1,
				0,
				initialOffHireItem({
					voyageId,
					invId: activeInvId,
					period: activePeriod,
					type: activeTctcType?.current
				})
			);
			return [...prev];
		});
	};

	const remove = (currentIndex: number) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev.splice(currentIndex, 1);
			currentItem?.id && delIds.current?.push(currentItem?.id);
			return [...prev];
		});
	};

	const change: CommonColumnChangeEvent<InvCommonOffHireItemType> = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const commit: CommonColumnCommitEvent<InvCommonOffHireItemType> = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...item
			});
			return [...prev];
		});
	};

	const handleOpen: InvCommonOffHireRefType["openModal"] = (type, items, ids) => {
		console.log("InvCommonOffHireRefType", type, items, ids);
		activeTctcType.current = type;
		delIds.current = ids;
		setDataSource(
			items?.length > 0
				? items
				: [
						initialOffHireItem({
							voyageId,
							invId: activeInvId,
							period: activePeriod,
							type: activeTctcType?.current
						})
					]
		);
		setHide(false);
	};

	const handleClose = () => {
		setHide(true);
	};

	const handleConfirm = () => {
		const { checkKey, checked } = commonItemsCheck(dataSource, checkKeys);
		if (!checked) {
			reminder("warning", `Fields ${checkKey} is Required`);
			return;
		}
		onConfirm?.(dataSource, delIds?.current);
		setHide(true);
	};

	return {
		hide,
		dataSource,
		summary,
		change,
		commit,
		remove,
		add,
		handleOpen,
		handleClose,
		handleConfirm
	};
};

export default useIndex;
