import { useMemo, useRef, useState } from "react"
import { InvFreightCargoDetailsProps } from "../type"
import { checkKeys, initialInvFreightCargoItem } from "../source"
import { InvFreightOtherItemType } from "@/pages/voyagemanage/business/invoices/module"
import { commonItemsCheck } from "@/tools/check"

const useIndex = (otherItem: Pick<InvFreightOtherItemType, "addCargo">) => {
  const [dataSource, setDataSource] = useState<InvFreightCargoDetailsProps["items"]>([])

  const summary = useMemo(() => {
    if (!otherItem?.addCargo) return 0
    return dataSource?.reduce((prev, curr) => {
      return prev + +curr?.cargoQuantity
    }, 0)
  }, [dataSource, otherItem?.addCargo])

  const delIds = useRef<number[]>([])

  const change: InvFreightCargoDetailsProps["onChange"] = (currentIndex, key, value) => {
    setDataSource(prev => {
      const currentItem = prev?.[currentIndex];
      prev?.splice(currentIndex, 1, {
        ...currentItem,
        [key]: value
      })
      return [...prev]
    })
  }

  const add: InvFreightCargoDetailsProps["onAdd"] = () => {
    setDataSource(prev => {
      return [...prev, initialInvFreightCargoItem("init")]
    })
  }

  const remove: InvFreightCargoDetailsProps["onDelete"] = (currentIndex) => {
    setDataSource(prev => {
      const currentItem = prev?.[currentIndex];
      currentItem?.id && (delIds?.current?.push(currentItem?.id))
      prev?.splice(currentIndex, 1)
      return [...prev]
    })
  }

  const check = () => {
    return otherItem?.addCargo
			? commonItemsCheck(dataSource, checkKeys)
			: {
					checked: true,
					checkKey: ""
				}
  }

  const init = (type: "init" | "details", items: InvFreightCargoDetailsProps["items"] = []) => {
    setDataSource(items?.map(item => initialInvFreightCargoItem(type, item)))
  }

  return {
    dataSource,
    delIds,
    summary,
    check,
    change,
    add,
    remove,
    init
  }
}

export default useIndex