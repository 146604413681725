import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";
import { getMovementOffHireParams, MovementOffHireParamsType } from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getMovementOffHireParameter
}

export type ApiUrl = keyof typeof ApiMap;

const offHireApi: ApiParameter<ApiUrl> = {
	getMovementOffHireParameter: {
		method: "get",
		url: appVersion + "/inv/group/voyage"
	}
};

export const getMovementOffHireApi = (params: getMovementOffHireParams) => {
	return request.request({
		...offHireApi.getMovementOffHireParameter,
		url: `${offHireApi.getMovementOffHireParameter.url}/${params?.voyageId}`,
		params: { voyageType: params?.voyageType }
	});
};

export const offHireApiMap: RequestApiMapType<ApiUrl, MovementOffHireParamsType> = {
	getMovementOffHireApi
};
