import React, { ReactElement } from "react";
import classnames from "classnames";
import AntdUpload, { type UploadProps } from "antd/es/upload/index";
import type { UploadFile, RcFile } from "antd/es/upload/interface";
import { FleetButton, FleetIcon, FleetImage, FleetLoading, FleetTooltip } from "@/components";
import style from "./index.module.less";
import { VmRemarkUploadFileType } from "@/featrue/voyagemanage/preOperation/voyage/voyageRemarks/type";
import { FleetToast } from "@/common";
import { Button } from "@/_components";

const { Dragger } = AntdUpload;

const ItemRender: React.FC<{
	remove?: () => void;
	preview?: () => void;
	file: VmRemarkUploadFileType;
}> = ({ file, remove, preview }) => {
	console.log("file", file);
	return (
		<div
			className={classnames(style["fleet-upload-item"], {
				[style["fleet-upload-item__uploading"]]: file?.status === "uploading",
				[style["fleet-upload-item__error"]]: file?.status === "error"
			})}
		>
			<div className="upload-file">
				{file?.type?.startsWith("image/") ? (
					<FleetImage src={file?.thumbUrl} className="file-icon" />
				) : /.{1,}\.(docx|doc)$/.test(file?.name || file?.originFileObj?.name) ? (
					<FleetIcon type="FleetUploadWordIcon" outerLayerClassname="file-icon" />
				) : (
					<FleetIcon type="FleetUploadPdfIcon" outerLayerClassname="file-icon" />
				)}
				<span className="file-text">{file?.name || file?.originFileObj?.name}</span>
			</div>
			<div className="upload-actions">
				<FleetIcon
					onClick={preview}
					type="FleetUploadPreviewIcon"
					outerLayerClassname="upload-action__preview"
				/>
				<FleetTooltip
					iconProps={{
						type: "FleetUploadDeleteIcon",
						outerLayerClassname: "upload-action__delete"
					}}
					render={(setIsOpen) => {
						return (
							<FleetToast
								label="That file will be deleted. Are you sure?"
								onCancel={() => {
									setIsOpen(false);
								}}
								onConfirm={() => {
									setIsOpen(false);
									remove();
								}}
							/>
						);
					}}
				/>
			</div>
			{file?.status === "uploading" && <span className="uploading">Uploading....</span>}
		</div>
	);
};

const UploadContainer: React.FC<{
	type?: "dragger";
	showFileList?: boolean;
	disabled?: boolean;
	className?: string;
	onUpload?: (file: RcFile | RcFile[]) => void;
	fileList?: VmRemarkUploadFileType[];
	onDelete?: (id: string | number) => void;
	loading?: boolean;
	uploadStatus?: React.MutableRefObject<"uploading" | "static">;
	multiple?: boolean;
	buttonText?: string;
}> = ({
	type,
	onUpload,
	onDelete,
	className,
	uploadStatus,
	disabled,
	showFileList = true,
	fileList,
	multiple,
	loading,
	buttonText = "Click to Upload"
}) => {
	if (type === "dragger") {
		return (
			<Upload
				className={className}
				Container={Dragger}
				disabled={disabled}
				showFileList={showFileList}
				multiple={multiple}
				fileList={fileList}
				onDelete={onDelete}
				onUpload={onUpload}
				uploadStatus={uploadStatus}
				render={() => {
					return (
						<div className="upload-dragger">
							<FleetLoading loading={loading} />
							<FleetIcon type="FleetUploadDraggerIcon" outerLayerClassname="dragger-icon" />
							<span className="dragger-title">Click or drag file to this area to upload</span>
							<span className="dragger-text">
								Support for a single or bulk upload. Strictly prohibit from uploading company data
								or other band files
							</span>
						</div>
					);
				}}
			/>
		);
	}

	return (
		<Upload
			className={className}
			Container={AntdUpload}
			onDelete={onDelete}
			disabled={disabled}
			showFileList={showFileList}
			multiple={multiple}
			fileList={fileList}
			uploadStatus={uploadStatus}
			onUpload={onUpload}
			render={() => {
				return (
					<Button
						size="small"
						type="secondary"
						prefixIcon="fleet-update1"
						disabled={disabled || loading}
						className={style["fleet-upload-btn"]}
						loading={loading}
					>
						{buttonText}
					</Button>
				);
			}}
		/>
	);
};

const Upload: React.FC<{
	addFile?: (file: UploadFile) => void;
	disabled?: boolean;
	className?: string;
	render?: () => ReactElement;
	Container?: typeof AntdUpload | typeof Dragger;
	onUpload?: (file: RcFile | RcFile[]) => void;
	onPreview?: () => void;
	showFileList?: boolean;
	fileList?: VmRemarkUploadFileType[];
	onDelete?: (id: string | number) => void;
	multiple?: boolean;
	uploadStatus?: React.MutableRefObject<"uploading" | "static">;
}> = ({
	render,
	fileList,
	Container,
	onUpload,
	className,
	uploadStatus,
	showFileList,
	onDelete,
	multiple = false
}) => {
	const uploadProps: UploadProps = {
		className: classnames(style["fleet-upload"], className),
		fileList: fileList,
		showUploadList: showFileList,
		multiple: multiple,
		beforeUpload(file, FileList) {
			if (uploadStatus?.current === "uploading") return false;
			onUpload?.(multiple ? FileList : file);
			return false;
		},
		itemRender: (
			originNode: ReactElement,
			file: VmRemarkUploadFileType,
			fileList: object[],
			actions: { download: Function; preview: () => void; remove: () => void }
		) => <ItemRender file={file} remove={() => onDelete?.(file?.uid)} preview={actions.preview} />
	};

	return <Container {...uploadProps}>{render()}</Container>;
};
// const Upload: React.FC<{
//   addFile?: (file: UploadFile) => void
// }> = ({
//   addFile
// }) => {
//     const [fileList,setFilelist] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const inputRef = useRef(null);
//     const handleSelectFile = () => {
//       inputRef?.current?.click()
//     }
//     const handleUpload:React.ChangeEventHandler<HTMLInputElement> = (event) => {
//       console.log(event.target.files)
//       setFilelist([...fileList,{
//         ...event.target.files[0],
//         status:"error"
//       }])
//     }
//     return <div className={style["fleet-upload"]}>
//       <div className='upload-select'>
//         <input ref={inputRef} type="file" onChange={handleUpload} />
//         <FleetButton
//           onClick={handleSelectFile}
//           type="cancel"
//           icon={<FleetIcon type="FleetUploadIcon" outerLayerClassname="fleet-upload-icon" />}
//           loading={loading}
//         >Click to Upload</FleetButton>
//       </div>
//       <div className='upload-list'>
//         <span className='list-divider'></span>
//         {
//           fileList.map((item,idx) => {
//             console.log('item',item)
//             return <ItemRender {...item} key={idx} />
//           })
//         }
//       </div>
//     </div>
//   }
// function Upload() {
//   const [file, setFile] = useState(null);
//   const [uploadedChunks, setUploadedChunks] = useState([]);
//   const [uploading, setUploading] = useState(false);
//   const uploadRequestRef = useRef();

//   const handleFileChange = (event) => {
//     const selectedFile = event.target.files[0];
//     setFile(selectedFile);
//   };

//   const uploadChunk = (chunk) => {
//     // 创建FormData对象
//     const formData = new FormData();
//     formData.append('file', chunk);

//     // 发送切片到服务器
//     return fetch('your-upload-url', {
//       method: 'POST',
//       body: formData
//     })
//     .then(response => response.json())
//     .then(data => {
//       console.log(data);
//       // 处理响应结果
//       return data;
//     });
//   };

//   const upload = async () => {
//     if (!file) {
//       alert("请选择要上传的文件！");
//       return;
//     }

//     const chunkSize = 1024 * 1024; // 1MB
//     const totalChunks = Math.ceil(file.size / chunkSize);

//     let start = 0;
//     let end = Math.min(chunkSize, file.size);

//     setUploading(true);

//     for (let i = 0; i < totalChunks; i++) {
//       const chunk = file.slice(start, end);
//       const uploadedChunkIndex = uploadedChunks.indexOf(i);

//       if (uploadedChunkIndex === -1) {
//         try {
//           const response = await uploadChunk(chunk);
//           setUploadedChunks((prevChunks) => [...prevChunks, i]);

//           // 保存已上传的切片信息到本地存储
//           localStorage.setItem('uploadedChunks', JSON.stringify(uploadedChunks));
//         } catch (error) {
//           console.error(error);
//           // 处理错误
//         }
//       }

//       start = end;
//       end = Math.min(start + chunkSize, file.size);
//     }

//     setUploading(false);

//     // 上传完毕，清除本地存储的切片信息
//     localStorage.removeItem('uploadedChunks');
//   };

//   useEffect(() => {
//     const storedUploadedChunks = localStorage.getItem('uploadedChunks');

//     if (storedUploadedChunks) {
//       setUploadedChunks(JSON.parse(storedUploadedChunks));
//     }
//   }, []);

//   return (
//     <div>
//       <input type="file" onChange={handleFileChange} />
//       <button onClick={upload} disabled={uploading}>
//         {uploading ? '上传中...' : '上传'}
//       </button>
//     </div>
//   );
// }

export default UploadContainer;
