import React from "react";
import style from "./index.module.less";
import { InvoicesHistoryDuplicateProps } from "./type";
import { FleetPrompt } from "@/common";
import { FleetLoading } from "@/components";
import { dateFormat } from "@/tools/date";
import useIndex from "./hooks/useIndex";
import { formatThousandthNumber } from "@/tools/amount";
import { getInvPeriod } from "@/tools/inv";

const HistoryDuplicate: React.FC<InvoicesHistoryDuplicateProps> = ({
  hide,
  onClose,
  invId,
  invoicesType,
  period,
  mode
}) => {
  const {
    loading,
    dataSource
  } = useIndex(
    hide,
    invId,
    invoicesType,
    period
  )
  return (
		<FleetPrompt hide={hide} mode={mode}>
			<div className={style["invoices-histotyDuplicate"]}>
				<FleetLoading loading={loading} />
				<div className="histotyDuplicate-header">
					<div className="header-title">
						<span className="fleet-title">Historical Invocie List</span>
						<span className="invoices-no">
							{invoicesType === "freightInv" ? "Freight INV." : "Statement Inv."}{" "}
							{period ? `# ${period} ${getInvPeriod(period)}` : null}
						</span>
					</div>
					<span className="font_family fleet-close header-close" onClick={onClose}></span>
				</div>
				<div className="histotyDuplicate-container fleet-nonScrolBar">
					<div className="histotyDuplicateItem-common histotyDuplicateItem-header">
						<span>Periods</span>
						<span>Date of creation</span>
						<span>Update time</span>
						<span>Amount</span>
						<span>Remit Amount</span>
						<span>Status</span>
					</div>
					{dataSource?.map((item, idx) => {
						return (
							<div className="histotyDuplicateItem-common" key={idx}>
								<span>{item?.basic?.period}</span>
								<span>{dateFormat("YY-mm-dd HH:MM", item?.createTime)}</span>
								<span>{dateFormat("YY-mm-dd HH:MM", item?.updateTime)}</span>
								<span>{formatThousandthNumber(item?.description?.remitAmount)}</span>
								<span>
									{formatThousandthNumber(
										item?.description?.remitAmount - item?.description?.totalDiscount
									)}
								</span>
								<span>{item?.status}</span>
							</div>
						);
					})}
				</div>
			</div>
		</FleetPrompt>
	);
}

export default HistoryDuplicate