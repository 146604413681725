import { ApiParameter, RequestApiMapType } from "@/api"
import request from "@/service"

import { saveMovementCommissionParams, MovementCommissionParamsType } from "./type"
import { appVersion } from "@/api/constant"

const enum ApiMap {
	saveMovementCommissionParameter
}

export type ApiUrl = keyof typeof ApiMap

const commissionApi: ApiParameter<ApiUrl> = {
	saveMovementCommissionParameter: {
		method: "post",
		url: appVersion + "/move/commission"
	}
}

export const saveMovementCommissionApi = (params: saveMovementCommissionParams) => {
	return request.request({
		...commissionApi.saveMovementCommissionParameter,
		data: params
	})
}

export const commissionApiMap: RequestApiMapType<ApiUrl, MovementCommissionParamsType> = {
	saveMovementCommissionApi
}
