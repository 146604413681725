import { CommonInitItemEvent } from "@/types/event";
import { InvCommonOffHireItemType } from "./type";

export enum OffHireRentMap {
	reletOffHire = "Relet",
	rentOffHire = "Rent"
}

export const initialOffHireItem: CommonInitItemEvent<InvCommonOffHireItemType> = (item) => {
	return {
		voyageId: item?.voyageId ?? null,
		startDatetime: item?.startDatetime ?? null,
		endDatetime: item?.endDatetime ?? null,
		duration: item?.duration ?? "",
		offHireFo: item?.offHireFo ?? 0,
		offHireDo: item?.offHireDo ?? 0,
		remark: item?.remark ?? "",
		invId: item?.invId ?? null,
		period: item?.period ?? 1,
		type: item?.type ?? null,
		ratio: item?.ratio ?? 100,
		finalDuration: item?.finalDuration ?? 0
	};
};

export const tctcTabs: { key: keyof typeof OffHireRentMap; label: "Charter In" | "Relet" }[] = [
	{ key: "rentOffHire", label: "Charter In" },
	{ key: "reletOffHire", label: "Relet" }
];

export const checkKeys: (keyof InvCommonOffHireItemType)[] = ["remark"];