import { MutableRefObject } from "react";

const hifleetSource = {
	"hifleet-tile-source": "hifleet-tile-layer"
};

const useHifleet = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const loadSource = () => {
		mapboxGl?.current?.addSource("hifleet-tile-source", {
			type: "raster",
			// tiles: [
			// 	"https://api.fltins.com/nauticalchart/genc24?z={z}&y={y}&x={x}"
			// 	// `https://api.hifleet.com/nauticalmap/en/token?x={x}&y={y}&z={z}&usertoken=x2mMb6/O16miro8xPfgSLc3PYho+ZgxTmOpqbWIv3/RmBlHEekbncObSruKet0WH`
			// ],
			tiles: ["https://api.fltins.com/nauticalchart/avcsh?z={z}&y={y}&x={x}"],
			tileSize: 256
		});
	};

	const loadLayers = () => {
		mapboxGl?.current?.addLayer(
			{
				id: hifleetSource["hifleet-tile-source"],
				type: "raster",
				source: "hifleet-tile-source",
				paint: {
					"raster-opacity": 0.85
				},
				layout: {
					visibility: "none"
				},
				maxzoom: 16,
				minzoom: 0
			},
			"national-park"
		);
		// mapboxGl?.current?.moveLayer(
		// 	mapboxGl?.current?.getLayer("land")?.id,
		// 	hifleetSource["hifleet-tile-source"]
		// 	// mapboxGl?.current?.getStyle().layers?.[0]?.id
		// );
	};

	const enableLayers = (visibility: "visible" | "none") => {
		console.log(
			"background",
			mapboxGl?.current?.getStyle().layers,
			mapboxGl?.current?.getLayer("land")
		);
		mapboxGl?.current?.setLayoutProperty(
			hifleetSource["hifleet-tile-source"],
			"visibility",
			visibility
		);
	};

	return {
		loadSource,
		loadLayers,
		enableLayers
	};
};

export default useHifleet;
