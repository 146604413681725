import { getAccountGroupAction } from "@/action/dataBase/account";
import useCommonIndex from "../../../hooks/useIndex";
import { CommonAccountItemType, CommonAccountSelectProps } from "../type";
import { useState } from "react";
const useIndex = (
	activeItems: Partial<CommonAccountItemType> | Partial<CommonAccountItemType>[],
	showKey: keyof CommonAccountItemType,
	onFinish: (newItem: CommonAccountItemType, operateType: "add" | "del") => void,
	primaryKey: keyof CommonAccountItemType = "id",
	disabled: boolean,
	accountActions?: CommonAccountSelectProps["accountActions"]
) => {
	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	} = useCommonIndex<CommonAccountItemType, keyof CommonAccountItemType>(
		activeItems,
		showKey,
		onFinish,
		getAccountGroupAction,
		primaryKey,
		disabled
	);

	const handleClear = () => {
		if (!activeItems[primaryKey]) return;
		onFinish?.(null, "del");
	};

	const [hide, setHide] = useState(true);
	const handleAccountAdd = (items: CommonAccountItemType[]) => {
		accountActions?.onConfirm?.(items);
		setHide(true);
	};

	return {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender,
		handleClear,
		hide,
		setHide,
		handleAccountAdd
	};
};

export default useIndex;
