import { useEffect, useRef } from "react"
import { dateToInvoiceTime } from "@/tools/date"
import { formatThousandthNumber, getRetainDecimalsNumber } from "@/tools/amount"
import { useInvTemplate } from "../../../../../common"
import {
	InvStatementTemplateDataSourceType,
	InvStatementTemplateProps,
	InvStatementTemplateReSourceType
} from "../type"
import { FleetNumType } from "@/types/common"
import { getInvPeriod } from "@/tools/inv"
import { InvStatementOffHireInitItemType } from "../../descrition/components/offHire/components"

const useIndex = (
	canvasRef: InvStatementTemplateProps["canvasRef"],
	dataSource: InvStatementTemplateProps["dataSource"],
	activeTab: InvStatementTemplateProps["activeTab"]
) => {
	const {
		markRef,
		ctxPosition,
		getFontInfo,
		drawLine,
		drawHeader,
		drawSpecialText,
		drawText,
		markRefInit,
		drawFooter,
		splitWords
	} = useInvTemplate()

	const resource = useRef<InvStatementTemplateReSourceType>({
		ownerFields: [],
		plusItems: [],
		lessItems: []
	})

	const initDataSource = () => {
		const { basicsFieldItems, counterPartyFieldItems, baseItem, otherItem, otherExpenses } =
			dataSource

		const ownerFields: InvStatementTemplateReSourceType["ownerFields"] = [
			...counterPartyFieldItems,
			{ fieldName: "Beneficiary Name", fieldVal: baseItem?.beneficiaryName },
			{ fieldName: "Beneficiary Bank", fieldVal: baseItem?.beneficiaryBank },
			{ fieldName: "Company Address", fieldVal: baseItem?.beneficiaryAddress },
			{ fieldName: "Swift Code", fieldVal: baseItem?.swiftCode },
			{ fieldName: "Bank Code", fieldVal: baseItem?.bankCode },
			{ fieldName: "Account No", fieldVal: baseItem?.accountNo }
		]

		let lessItems: InvStatementTemplateReSourceType["lessItems"] = [],
			plusItems: InvStatementTemplateReSourceType["lessItems"] = []

		if (otherItem?.addOther) {
			otherExpenses?.forEach((expenseItem) => {
				switch (true) {
					case +expenseItem?.fieldVal > 0:
						plusItems?.push({
							fieldName: expenseItem?.fieldName,
							fieldVal: expenseItem?.fieldVal,
							description: expenseItem?.description
						})
						break
					default:
						lessItems?.push({
							fieldName: expenseItem?.fieldName,
							fieldVal: expenseItem?.fieldVal,
							description: expenseItem?.description
						})
						break
				}
			})
		}
		console.log("otherItem?.addHire", otherItem?.addHire)
		if (otherItem?.addHire) {
			plusItems?.push({
				fieldName: "C/V/E",
				fieldVal: dataSource?.hireItem?.hireCve,
				description: ""
			})
		}

		ctxPosition.current = {
			currentX: 66,
			currentY: 52
		}

		resource.current = {
			ownerFields,
			plusItems,
			lessItems
		}
	}

	const drawHireItem = (
		currentWidth: number,
		form: string | number,
		to: string | number,
		totalDays: number,
		isOff?: boolean,
		remarks?: string
	) => {
		const ctx = canvasRef?.current?.getContext("2d")
		let currentY = ctxPosition.current.currentY,
			currentX = currentWidth,
			occupyZone
		ctx.save()
		ctx.font = "400 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		const {
			suffix: formSuffix,
			year: formYear,
			month: formMonth,
			days: formDays,
			hours: formHours,
			minutes: formMinutes
		} = dateToInvoiceTime(form)

		const {
			suffix: toSuffix,
			year: toYear,
			month: toMonth,
			days: toDays,
			hours: toHours,
			minutes: toMinutes
		} = dateToInvoiceTime(to)

		if (form) {
			occupyZone = drawText(ctx, "From", currentX, currentY)
			currentWidth += occupyZone.occupyWidth + 8
			occupyZone = drawText(
				ctx,
				`${formDays}-${formMonth}-${formYear} ${formHours}:${formMinutes}`,
				currentWidth,
				currentY
			)
			currentWidth += occupyZone.occupyWidth + 10

			occupyZone = drawText(ctx, "GMT", currentWidth, currentY)
			currentWidth += occupyZone.occupyWidth + 138

			occupyZone = drawText(ctx, "To", currentWidth, currentY)
			currentWidth += occupyZone.occupyWidth + 16
		}

		if (to) {
			occupyZone = drawText(
				ctx,
				`${toDays}-${toMonth}-${toYear} ${toHours}:${toMinutes}`,
				currentWidth,
				currentY
			)
			currentWidth += occupyZone.occupyWidth + 12

			occupyZone = drawText(ctx, "GMT", currentWidth, currentY)
		}

		ctx.textAlign = "right"
		occupyZone = drawText(
			ctx,
			`${totalDays} Days`,
			canvasRef.current.width - ctxPosition.current.currentX,
			currentY
		)
		if (!isOff) {
			ctx.textAlign = "left"
			drawText(
				ctx,
				"=",
				canvasRef.current.width - ctxPosition.current.currentX - occupyZone.occupyWidth - 9,
				currentY
			)
			ctxPosition.current.currentY += occupyZone.occupyHeight + 10.5
		} else {
			drawSpecialText(ctx, canvasRef, remarks, "prefix", currentY)
			ctxPosition.current.currentY += occupyZone.occupyHeight + 5
		}
		ctx.restore()
	}

	// 绘制 hire 发票主体部分
	const drawHireBody = () => {
		const { baseItem, hireItem, otherItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.font = "700 14px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		const {
			day: cpDay,
			suffix: cpSuffix,
			month: cpMonth,
			year: cpYear
		} = dateToInvoiceTime(baseItem?.cpDate)
		const {
			day: startDay,
			suffix: startSuffix,
			month: startMonth,
			year: startYear
		} = dateToInvoiceTime(hireItem?.onHireStartDay)
		const {
			day: endDay,
			suffix: endSuffix,
			month: endMonth,
			year: endYear
		} = dateToInvoiceTime(hireItem?.onHireEndDay)

		let hireBodyHeader = getFontInfo(
			ctx,
			`MV ${baseItem?.vesselName} - CP DATED ${cpDay}${cpSuffix} ${cpMonth} ${cpYear} - ${otherItem?.invFinal ? `Final` : `${baseItem?.period} ${getInvPeriod(baseItem?.period)}`} HIRE (${startDay}/${startMonth} - ${endDay}/${endMonth}, ${endYear})`
		)

		let occupyZone = drawText(
			ctx,
			`MV ${baseItem?.vesselName} - CP DATED ${cpDay}${cpSuffix} ${cpMonth} ${cpYear} - ${otherItem?.invFinal ? `Final` : `${baseItem?.period} ${getInvPeriod(baseItem?.period)}`} HIRE (${startDay}/${startMonth} - ${endDay}/${endMonth}, ${endYear})`,
			Math.floor((canvasRef?.current?.width - hireBodyHeader?.width) / 2),
			currentY
		) // 绘制文字 To

		currentY += occupyZone?.occupyHeight + 13

		occupyZone = drawText(ctx, `TO: ${baseItem?.ownerName}`, currentX, currentY)

		currentY += occupyZone.occupyHeight + 2

		occupyZone = drawText(ctx, `Business registration address: `, currentX, currentY)

		const { lines } = splitWords(ctx, baseItem?.address, 930 - 65 * 2 - occupyZone.occupyWidth)

		for (let line of lines) {
			const _occupyZone = drawText(ctx, line, currentX + occupyZone.occupyWidth, currentY)
			currentY += _occupyZone.occupyHeight + 1
		}

		if (lines?.length <= 0) {
			currentY += occupyZone.occupyHeight
		}

		currentY += 13

		drawLine(
			ctx,
			ctxPosition.current.currentX,
			currentY,
			canvasRef.current.width - ctxPosition.current.currentX,
			currentY
		)

		currentY += 1 + 9.5

		ctx.font = "600 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		ctxPosition.current.currentY = currentY

		if (otherItem?.addHire) {
			occupyZone = drawText(ctx, "On-Hire:", currentX, currentY) // 绘制文字
			currentX += occupyZone.occupyWidth + 14

			// occupyZone = drawText(
			// 	ctx,
			// 	"Off-Hire:",
			// 	currentX - (occupyZone.occupyWidth + 14),
			// 	ctxPosition.current.currentY + occupyZone.occupyHeight + 10.5
			// ); // 绘制文字 To
			ctx.font = "400 12px Arial"
			drawHireItem(
				currentX,
				hireItem?.onHireStartDay,
				hireItem?.onHireEndDay ?? "",
				hireItem?.ttlOnHireDay
			)

			currentY = ctxPosition.current.currentY + 3

			drawLine(ctx, currentX, currentY, currentX + 735, currentY, 1, "rgba(0, 0, 0, 0.88)")

			currentY += 1 + 8

			// drawText(ctx, "Total On-Hire Period:", currentX, currentY); // 绘制文字 整个可以收租的租金期间
		}

		ctx.restore()

		ctxPosition.current.currentY = currentY
	}

	const drawInvoiceType = () => {
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.font = "400 24px Arial"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.textAlign = "left"
		let occupyZone = drawText(ctx, "Statement of Hire", currentX + 133.5 + 150, currentY)
		currentY += occupyZone.occupyHeight + 4

		drawLine(
			ctx,
			currentX + 133.5 + 150,
			currentY,
			currentX + 133.5 + 150 + occupyZone.occupyWidth,
			currentY,
			1.5,
			"#000"
		)
		currentY += 1.5 + 11
		ctxPosition.current.currentY = currentY
		ctx.restore()
	}

	const drawTtlHireBody = () => {
		const { otherItem, hireItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.font = "700 14px Arial"
		ctx.textAlign = "right"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		drawSpecialText(ctx, canvasRef, "$", "prefix", currentY, true)
		currentY += drawSpecialText(ctx, canvasRef, "$", "suffix", currentY, true) + 9

		if (otherItem?.addHire) {
			ctx.font = "700 12px Arial"
			ctx.textAlign = "left"

			let occupyZone = drawText(ctx, "TTL Hire:", currentX, currentY)
			currentX += occupyZone.occupyWidth + 10

			ctx.font = "400 12px Arial"

			occupyZone = drawText(
				ctx,
				`${formatThousandthNumber(hireItem?.ttlOnHireDay)} Days  x  ${formatThousandthNumber(+hireItem?.dailyHire)} $/Day`,
				currentX,
				currentY
			)

			drawSpecialText(
				ctx,
				canvasRef,
				`${formatThousandthNumber(+hireItem?.dailyHire * hireItem?.ttlOnHireDay)}`,
				"suffix",
				currentY
			)
			currentY += occupyZone.occupyHeight + 10
			ctx.restore()
		}
		ctxPosition.current.currentY = currentY
	}

	const drawBodOrBorItem = (
		type: InvStatementTemplateDataSourceType["bunkerPriceItems"][number]["type"],
		oil: InvStatementTemplateDataSourceType["bunkerPriceItems"][number]["bunkerType"],
		consumption: number,
		price: number
	) => {
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "400 12px Arial"
		ctx.textAlign = "left"
		currentX += 63

		let occupyZone = drawText(ctx, `${oil} ${consumption} MT`, currentX, currentY)
		currentX += occupyZone.occupyWidth + 8

		occupyZone = drawText(ctx, "x", currentX, currentY)
		currentX += occupyZone.occupyWidth + 7

		occupyZone = drawText(ctx, `${price} $/MT`, currentX, currentY)

		drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(consumption * price),
			type === "BOD" ? "suffix" : "prefix",
			currentY
		)
		ctx.restore()
		ctxPosition.current.currentY = currentY + occupyZone.occupyHeight
	}

	const drawBodOrBor = (type: "BOR" | "BOD" | "BOH") => {
		const { otherItem, bunkerPriceItems } = dataSource
		if (!otherItem?.addBunker) return
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.font = "700 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		let occupyZone = drawText(ctx, `Value of ${type}`, currentX, currentY)
		ctxPosition.current.currentY = currentY + occupyZone.occupyHeight + 9
		ctx.restore()

		if (type === "BOD") {
			const bodItems = bunkerPriceItems?.filter((item) => item?.type === "BOD")
			for (let bodItem of bodItems) {
				drawBodOrBorItem(bodItem?.type, bodItem?.bunkerType, +bodItem?.consumption, +bodItem?.price)
				ctxPosition.current.currentY += 5
			}
		}

		if (type === "BOR") {
			const borItems = bunkerPriceItems?.filter((item) => item?.type === "BOR")
			for (let borItem of borItems) {
				drawBodOrBorItem(borItem?.type, borItem?.bunkerType, +borItem?.consumption, +borItem?.price)
				ctxPosition.current.currentY += 5
			}
		}

		ctxPosition.current.currentY += 11
	}

	const drawPlusItem = (
		text: string,
		price: number,
		currentWidth: number,
		type: "suffix" | "prefix" = "suffix"
	) => {
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = currentWidth,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.font = "400 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		let occupyZone = drawText(ctx, text, currentX, currentY)

		drawSpecialText(ctx, canvasRef, formatThousandthNumber(price), type, currentY)

		ctx.restore()

		ctxPosition.current.currentY = currentY + occupyZone.occupyHeight
	}

	const drawPlus = () => {
		const { otherExpenses, otherItem } = dataSource
		const { plusItems } = resource?.current
		if (!otherItem?.addOther && !otherItem?.addFixed && !otherItem?.addHire) return
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY,
			occupyZone
		ctx.save()
		ctx.font = "700 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		// let occupyZone = drawText(ctx, "Plus:", currentX, currentY);

		// currentX += occupyZone.occupyWidth + 33;

		for (let i = 0; i < plusItems?.length; i++) {
			const currentItem = plusItems?.[i]
			occupyZone = drawText(ctx, currentItem?.fieldName, currentX, ctxPosition.current.currentY)
			drawPlusItem(
				`${currentItem?.description}`,
				+currentItem?.fieldVal,
				currentX + occupyZone.occupyWidth + 22
			)
			if (i !== otherExpenses?.length - 1) {
				ctxPosition.current.currentY += 5
			}
		}
		ctx.restore()
		ctxPosition.current.currentY += 5
	}

	const drawLessItem = (
		type: "comisson" | "brokerage",
		rate: FleetNumType,
		totalPrice: number,
		currentWidth: number
	) => {
		const { hireItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = currentWidth,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.font = "700 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		let occupyZone = drawText(
			ctx,
			type === "brokerage" ? "Brokerage" : "Add Comm",
			currentX,
			currentY
		)
		currentX += occupyZone?.occupyWidth + 22

		ctx.font = "400 12px Arial"

		occupyZone = drawText(ctx, `${hireItem?.totalHire} x ${rate || 0}%`, currentX, currentY)

		drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(Math.abs(totalPrice)),
			"prefix",
			currentY
		)

		ctx.restore()

		ctxPosition.current.currentY = currentY + occupyZone.occupyHeight
	}

	const drawLessBody = () => {
		const { hireItem, otherItem } = dataSource
		const { lessItems } = resource?.current
		if (!otherItem?.addOther && !otherItem?.addHire) return
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY,
			occupyZone
		ctx.save()
		ctx.font = "700 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		// let occupyZone = drawText(ctx, "Less:", currentX, currentY);
		// currentX += occupyZone?.occupyWidth + 31;

		if (otherItem?.addHire) {
			drawLessItem("comisson", hireItem?.addCommRate, hireItem?.addComm, currentX)
			ctxPosition.current.currentY += 5
			// drawLessItem("brokerage", hireItem?.brokerageRate, hireItem?.brokerage, currentX);
			// ctxPosition.current.currentY += 5;
		}

		for (let i = 0; i < lessItems?.length; i++) {
			const currentItem = lessItems?.[i]
			occupyZone = drawText(ctx, currentItem?.fieldName, currentX, ctxPosition.current.currentY)
			drawPlusItem(
				`${currentItem?.description}`,
				Math.abs(+currentItem?.fieldVal),
				currentX + occupyZone.occupyWidth + 22,
				"prefix"
			)
			if (i !== lessItems?.length - 1) {
				ctxPosition.current.currentY += 5
			}
		}

		ctxPosition.current.currentY += 5

		ctx.restore()
	}

	const drawSecondLessItem = (period: number, remitAmount: number, currentWidth: number) => {
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = currentWidth,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.font = "400 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		let occupyZone = drawText(ctx, `${period} Hire Paid`, currentX, currentY)

		drawSpecialText(ctx, canvasRef, formatThousandthNumber(remitAmount), "prefix", currentY)
		ctxPosition.current.currentY += occupyZone.occupyHeight
	}

	const drawSecondLessBody = () => {
		const { otherItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY,
			occupyZone
		ctx.save()
		ctx.font = "700 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		if (otherItem?.pastList?.length > 0) {
			occupyZone = drawText(ctx, "Less:", currentX, currentY)
			currentX += occupyZone?.occupyWidth + 30
		}

		for (let i = 0; i < otherItem?.pastList?.length; i++) {
			const currentItem = otherItem?.pastList?.[i]
			drawSecondLessItem(currentItem?.period, currentItem?.actualAmount, currentX)
			if (i !== otherItem?.pastList?.length - 1) {
				ctxPosition.current.currentY += 5
			}
		}

		ctxPosition.current.currentY += 10.5
		drawLine(
			ctx,
			ctxPosition.current.currentX,
			ctxPosition.current.currentY,
			canvasRef.current.width - ctxPosition.current.currentX,
			ctxPosition.current.currentY
		)

		ctx.restore()
		ctxPosition.current.currentY += 8.5 + 1
	}

	const drawOffItems = (
		initCurrentX: number,
		items: InvStatementOffHireInitItemType["moveOffHireList"]
	) => {
		const ctx = canvasRef?.current?.getContext("2d")
		let currentY = ctxPosition.current.currentY,
			currentX = initCurrentX,
			occupyZone

		for (let offItem of items) {
			currentX = initCurrentX
			if (offItem?.remark) {
				occupyZone = drawText(ctx, `${offItem?.remark}`, currentX, currentY)
				currentX += occupyZone.occupyWidth + 22
			}
			if (offItem?.startDatetime && offItem?.endDatetime) {
				const startTime = dateToInvoiceTime(offItem?.startDatetime),
					endTime = dateToInvoiceTime(offItem?.endDatetime)
				occupyZone = drawText(
					ctx,
					`${startTime?.days}-${startTime?.month}-${startTime?.year} ${startTime?.hours}:${startTime?.minutes}  To  ${endTime?.days}-${endTime?.month}-${endTime?.year} ${endTime?.hours}:${endTime?.minutes}`,
					currentX,
					currentY
				)
				currentY += occupyZone.occupyHeight
			} else if (offItem?.finalDuration) {
				occupyZone = drawText(ctx, `${offItem?.finalDuration} Days`, currentX, currentY)
				currentY += occupyZone.occupyHeight
			}
		}
		ctxPosition.current.currentY = currentY + 6
	}

	const drawOffGroup = () => {
		const { otherItem, offGroup } = dataSource
		if (!otherItem?.addOffHire) return
		const ctx = canvasRef?.current?.getContext("2d")
		let occupyZone
		for (let i = 0; i < offGroup?.length; i++) {
			ctx.font = "700 12px Arial"
			ctxPosition.current.currentX = 65
			occupyZone = drawText(
				ctx,
				`OFF HIRE PARTIES -${i + 1}`,
				ctxPosition.current.currentX,
				ctxPosition.current.currentY
			)

			ctxPosition.current.currentY += occupyZone.occupyHeight + 8
			ctx.font = "400 12px Arial"
			ctx.textAlign = "left"
			ctxPosition.current.currentX += 63
			drawOffItems(occupyZone.occupyWidth, offGroup?.[i]?.moveOffHireList)
			if (offGroup?.[i]?.moveOffHireList?.length > 0) {
				drawLine(ctx, 128, ctxPosition.current.currentY, 560, ctxPosition.current.currentY)
			}
			// ctxPosition.current.currentY += 6;
			occupyZone = drawText(
				ctx,
				"Off hire",
				ctxPosition.current.currentX,
				ctxPosition.current.currentY
			)
			occupyZone = drawText(
				ctx,
				`${offGroup?.[i]?.offHireTtlDays} Days`,
				ctxPosition.current.currentX + 250,
				ctxPosition.current.currentY
			)
			occupyZone = drawText(
				ctx,
				`${formatThousandthNumber(+offGroup?.[i]?.offHireDaily)} $/Day`,
				ctxPosition.current.currentX + 350,
				ctxPosition.current.currentY
			)
			drawSpecialText(
				ctx,
				canvasRef,
				`${formatThousandthNumber(offGroup?.[i]?.offHireAmount, 3, 3)}`,
				"prefix",
				ctxPosition.current.currentY
			)
			ctxPosition.current.currentY += 6 + occupyZone.occupyHeight
			ctxPosition.current.currentX = 128
			occupyZone = drawText(
				ctx,
				"Off hire add. common.",
				ctxPosition.current.currentX,
				ctxPosition.current.currentY
			)
			occupyZone = drawText(
				ctx,
				`${offGroup?.[i]?.addCommRation}%`,
				ctxPosition.current.currentX + 250,
				ctxPosition.current.currentY
			)
			ctxPosition.current.currentX = 65
			drawSpecialText(
				ctx,
				canvasRef,
				`${formatThousandthNumber(offGroup?.[i]?.addComm, 3, 3)}`,
				"suffix",
				ctxPosition.current.currentY
			)
			ctxPosition.current.currentY += 6 + occupyZone.occupyHeight
			ctxPosition.current.currentX = 128
			occupyZone = drawText(
				ctx,
				"Off hire C/V/E",
				ctxPosition.current.currentX,
				ctxPosition.current.currentY
			)
			occupyZone = drawText(
				ctx,
				`${offGroup?.[i]?.offHireTtlDays} Days`,
				ctxPosition.current.currentX + 250,
				ctxPosition.current.currentY
			)
			const rate =
				offGroup?.[i]?.unit === "PM"
					? (12 * +offGroup?.[i]?.cveRatio) / 365
					: +offGroup?.[i]?.cveRatio / 365
			occupyZone = drawText(
				ctx,
				`${formatThousandthNumber(rate, 3, 3)} $/Day`,
				ctxPosition.current.currentX + 350,
				ctxPosition.current.currentY
			)
			drawSpecialText(
				ctx,
				canvasRef,
				`${formatThousandthNumber(rate * +offGroup?.[i]?.offHireTtlDays, 3, 3)}`,
				"prefix",
				ctxPosition.current.currentY
			)
			ctxPosition.current.currentY += 6 + occupyZone.occupyHeight
			ctxPosition.current.currentX = 128
			for (let bunkerItem of offGroup?.[i]?.bunkerPriceList) {
				occupyZone = drawText(
					ctx,
					`${bunkerItem?.bunkerType}`,
					ctxPosition.current.currentX,
					ctxPosition.current.currentY
				)
				occupyZone = drawText(
					ctx,
					`${bunkerItem?.consumption} MT  x  ${bunkerItem?.price} $/MT`,
					ctxPosition.current.currentX + 35 + occupyZone.occupyWidth,
					ctxPosition.current.currentY
				)
				drawSpecialText(
					ctx,
					canvasRef,
					`${formatThousandthNumber(bunkerItem?.total, 3, 3)}`,
					"prefix",
					ctxPosition.current.currentY
				)
				ctxPosition.current.currentX = 128
				ctxPosition.current.currentY += 6 + occupyZone.occupyHeight
			}
			for (let otherItem of offGroup?.[i]?.otherCalDetailList) {
				occupyZone = drawText(
					ctx,
					`${otherItem?.title}`,
					ctxPosition.current.currentX,
					ctxPosition.current.currentY
				)
				occupyZone = drawText(
					ctx,
					`${otherItem?.remark}`,
					ctxPosition.current.currentX + 35 + occupyZone.occupyWidth,
					ctxPosition.current.currentY
				)
				drawSpecialText(
					ctx,
					canvasRef,
					`${formatThousandthNumber(+otherItem?.totalAmount, 3, 3)}`,
					"prefix",
					ctxPosition.current.currentY
				)
				ctxPosition.current.currentX = 128
				ctxPosition.current.currentY += 6 + occupyZone.occupyHeight
			}
		}

		ctxPosition.current.currentX = 65
		// ctx.font = "400 12px Arial";
		// ctxPosition.current.currentY = currentY;
		ctx.restore()
	}

	const drawSummary = () => {
		const { summary, otherItem } = dataSource

		const alreadyPayment = otherItem?.pastList?.reduce((prev, curr) => prev + curr?.actualAmount, 0)

		const diffRate = summary?.plus - summary?.less - alreadyPayment
		console.log("summary", summary)
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "400 12px Arial"
		drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(summary?.less + alreadyPayment, 3, 3),
			"prefix",
			currentY
		)
		let occupyHeight = drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(summary?.plus, 3, 3),
			"suffix",
			currentY
		)
		currentY += occupyHeight + 5

		ctx.font = "700 12px Arial"
		drawText(ctx, "Balance in Owner's Favour", currentX, currentY)

		let textHeight = drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(diffRate, 3, 3),
			"prefix",
			currentY
		)

		currentY += textHeight + 8.5
		drawLine(
			ctx,
			ctxPosition.current.currentX,
			currentY,
			canvasRef.current.width - ctxPosition.current.currentX,
			currentY
		)
		currentY += 8.5

		ctx.font = "400 12px Arial"
		drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(+diffRate + +summary?.less + alreadyPayment, 3, 3),
			"prefix",
			currentY
		)
		textHeight = drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(summary?.plus, 3, 3),
			"suffix",
			currentY
		)

		ctx.restore()
		ctxPosition.current.currentY = currentY + 19.5 + textHeight
	}

	const drawInvoices = () => {
		const { baseItem, otherItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		// drawHeader(ctx, [], beneficiaryName ?? "Company Name", invDate, `Ref No. ${invNo}`);
		drawHeader(ctx, [], otherItem?.companyName, baseItem?.invDate, `Ref No. ${baseItem?.invNo}`)
		drawInvoiceType()

		drawHireBody()
		drawTtlHireBody()
		drawBodOrBor("BOD")
		drawBodOrBor("BOR")
		drawOffGroup()
		// drawBodOrBor("BOH");
		drawPlus()
		drawLessBody()
		drawSecondLessBody()
		drawSummary()
		drawFooter(ctx, "statement", resource?.current?.ownerFields)
	}

	useEffect(() => {
		if (activeTab === "form") return
		const ctx = canvasRef?.current?.getContext("2d")
		const markCallback = markRefInit()
		ctx?.clearRect(0, 0, canvasRef.current?.width, canvasRef?.current?.height)
		initDataSource()
		drawInvoices()
		return markCallback
	}, [activeTab])

	return {
		markRef
	}
}

export default useIndex
