import { useAppDispatch } from "@/hook"
import useReminder from "@/hook/useReminder"
import { useNavigate } from "react-router-dom"
import { AparFinancialItemType, AparFinancialProps } from "../type"
import { useEffect, useRef, useState } from "react"
import { onRequest } from "@/action"
import { checkKeys, initialFinancialItem } from "../source"
import { CommonChangeEvent } from "@/types/event"
import { RcFile } from "antd/es/upload"
import { commonCheck } from "@/tools/check"
import { useTranslation } from "react-i18next"

const useIndex = (
	hide: AparFinancialProps["hide"],
	invoice: AparFinancialProps["invoice"],
	onConfirm: AparFinancialProps["onConfirm"]
) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()
	const { t } = useTranslation()

	const [loading, setLoading] = useState(false)
	const [dataSource, setDataSource] = useState<AparFinancialItemType>(initialFinancialItem())

	const [fileList, setFileList] = useState<RcFile[]>([])

	const uploadStatus = useRef<"uploading" | "static">("static")

	const handleUpload = (files: RcFile[]) => {
		uploadStatus.current = "uploading"
		setFileList((prev) => [...prev, ...files])
		requestIdleCallback(() => {
			uploadStatus.current = "static"
		})
	}

	console.log("file", fileList)

	const handleDelete = (selectItem: AparFinancialItemType["attachment"][number] & RcFile) => {
		if (selectItem?.id) {
			onRequest(
				"deleteFinancialAttachApi",
				{ id: selectItem?.id },
				getFinancialInfoFront,
				(response) => {
					setDataSource((prev) => {
						const currentIndex = prev?.attachment?.findIndex((item) => item?.id === selectItem?.id)
						prev?.attachment?.splice(currentIndex, 1)
						return { ...prev, attachment: [...prev?.attachment] }
					})
					setLoading(false)
				},
				getFinancialInfoFailed,
				dispatch,
				navigate
			)
			return
		}
		setFileList((prev) => {
			const currentIndex = prev?.findIndex((item) => item?.uid === selectItem?.uid)
			prev?.splice(currentIndex, 1)
			return [...prev]
		})
	}

	const getAparFilePathSuccess = (response) => {
		setLoading(false)
		window.open(response?.data)
	}

	const handleDownload = (selectItem: AparFinancialItemType["attachment"][number] & RcFile) => {
		if (!selectItem?.id) return
		onRequest(
			"getAparFilePathApi",
			{
				path: selectItem?.filePath
			},
			getFinancialInfoFront,
			getAparFilePathSuccess,
			getFinancialInfoFailed,
			dispatch,
			navigate
		)
	}

	const change: CommonChangeEvent<AparFinancialItemType> = (key, value) => {
		setDataSource((prev) => ({ ...prev, [key]: value }))
	}

	const deleteFinancialAttachSuccess = (response) => {
		setDataSource((prev) => ({ ...prev }))
		setLoading(false)
	}

	const getFinancialInfoFront = () => {
		setLoading(true)
	}

	const getFinancialInfoSuccess = (response) => {
		setDataSource(initialFinancialItem(response?.data))
		setFileList([])
		setLoading(false)
	}

	const getFinancialInfoFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
		setLoading(false)
	}

	const getFinancialInfo = () => {
		onRequest(
			"getFinancialInfoApi",
			{
				voyageId: invoice?.voyageId,
				dataId: invoice?.dataId,
				dataType: invoice?.dataType,
				stageType: invoice?.stageType
			},
			getFinancialInfoFront,
			getFinancialInfoSuccess,
			getFinancialInfoFailed,
			dispatch,
			navigate
		)
	}

	const updateFinancialInfoSuccess = (response) => {
		setLoading(false)
		onConfirm?.()
	}

	const handleConfirm = () => {
		const { checkKey, checked } = commonCheck(dataSource, checkKeys)
		if (!checked) {
			reminder("error", `Field ${t(`voyageManagement.apAr.${checkKey}`)} is Required`)
			return
		}
		const formData = new FormData()
		formData.append("voyageId", invoice?.voyageId)
		formData.append("voyageType", invoice?.voyageType)
		formData.append("dataId", invoice?.dataId?.toString())
		formData.append("dataType", invoice?.dataType)
		formData.append("amount", dataSource?.amount?.toString())
		formData.append("latestFinancialDate", dataSource?.latestFinancialDate)
		formData.append("status", dataSource?.status)
		formData.append("arap", dataSource?.arAp)
		formData.append("stageType", invoice?.stageType)
		for (let file of fileList) {
			formData.append("file", file)
		}
		onRequest(
			"updateFinancialInfoApi",
			formData,
			getFinancialInfoFront,
			updateFinancialInfoSuccess,
			getFinancialInfoFailed,
			dispatch,
			navigate
		)
	}

	useEffect(() => {
		if (hide) return
		getFinancialInfo()
	}, [hide])

	return {
		loading,
		dataSource,
		fileList,
		uploadStatus,
		change,
		handleUpload,
		handleDelete,
		handleConfirm,
		handleDownload
	}
}

export default useIndex
