import React, { useMemo } from "react";
import style from "./index.module.less";
import { InvoicesViewsListProps } from "./type";
import { FleetLoading } from "@/components";
import { InvoicesItem, InvoicesListFilter } from "./components";

const InvoicesViewsList: React.FC<InvoicesViewsListProps> = ({
	loading,
	items,
	onItemSelect,
	activeIndex,
	activeType,
	onTypeSelect,
	onAdd
}) => {
	const isFinal = useMemo(() => {
		return !!items?.find((item) => item?.invFinal === true);
	}, [items]);
	return (
		<div className={style["invoicesViews-list"]}>
			<InvoicesListFilter
				activeType={activeType}
				onSelect={onTypeSelect}
				onAdd={onAdd}
				disabled={isFinal}
			/>
			<div className="invoicesList-scrollArea">
				<FleetLoading loading={loading} />
				{items?.map((item, idx) => {
					return (
						<InvoicesItem
							item={item}
							key={idx}
							onSelect={onItemSelect}
							currentIndex={idx}
							activeIndex={activeIndex}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default InvoicesViewsList;
