import React, { FC } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import {
	FleetFormItem,
	FleetRangePicker,
	FleetSelect,
	FleetTable,
	FleetVerifyInput
} from "@/components";
import { columns, departmentOptions, sortOptions, isFinishOptions } from "./source";
import { Button } from "@/_components";
import useIndex from "./hooks/useIndex";
import { Dayjs } from "dayjs";
import TreeSelect from "antd/es/tree-select";
import { FileDownload } from "../../components";

const BillOfLading: FC<{}> = (props) => {
	const {
		loading,
		pageSize,
		pageNum,
		total,
		filter,
		dataSource,
		search,
		treeData,
		operatorData,
		charterData,
		opereteColumns,
		hide,
		record,
		btnLoading,
		handlePageChange,
		handleSearch,
		handleFilterChange,
		handleClose,
		handleDownload
	} = useIndex();
	return (
		<div className={classNames("statement-common", style["statement-billOfLading"])}>
			<div className="billOfLading-search">
				<div className="search-container">
					<FleetVerifyInput
						label="Search"
						value={search}
						placeholder="Please enter"
						prefix="LayoutSearchIcon"
						onChange={handleSearch}
					/>
					<FleetFormItem label="Department">
						<TreeSelect
							className="filter-select"
							treeData={treeData}
							dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
							placeholder="Please select"
							allowClear
							treeDefaultExpandAll
							value={filter.deptId}
							onChange={(val: string) => handleFilterChange?.("deptId", val)}
						/>
					</FleetFormItem>
					<FleetFormItem label="Redelivery Date">
						<FleetRangePicker
							className="date-filter"
							value={filter.redeliveryDate}
							allowClear
							onChange={(vals: [Dayjs, Dayjs]) => {
								handleFilterChange?.("redeliveryDate", vals ?? null);
							}}
						/>
					</FleetFormItem>
					<FleetFormItem label="Operated By">
						<FleetSelect
							className="filter-select"
							options={operatorData}
							value={filter.operatedBy}
							allowClear
							onChange={(val: string) => handleFilterChange?.("operatedBy", val)}
						/>
					</FleetFormItem>
					<FleetFormItem label="Charterer">
						<FleetSelect
							className="filter-select"
							options={charterData}
							value={filter.chartererName}
							allowClear
							onChange={(val: string) => handleFilterChange?.("chartererName", val)}
						/>
					</FleetFormItem>
					<FleetFormItem label="Completed">
						<FleetSelect
							className="filter-select"
							options={isFinishOptions}
							value={filter.isFinish}
							allowClear
							onChange={(val: 0 | 1) => handleFilterChange?.("isFinish", val)}
						/>
					</FleetFormItem>
					<FleetFormItem label="Sort Rules">
						<FleetSelect
							className="filter-select"
							options={sortOptions}
							value={filter.sortRule}
							allowClear
							onChange={(val: number) => handleFilterChange?.("sortRule", val)}
						/>
					</FleetFormItem>
				</div>
				<div className="toolbar-container">
					<Button prefixIcon="fleet-download" loading={btnLoading} onClick={handleDownload}>
						Download
					</Button>
				</div>
			</div>
			<FleetTable
				columns={[...columns, ...opereteColumns]}
				className="billOfLading-table"
				dataSource={dataSource}
				loading={loading}
				id="id"
				pagination={{
					pageSize: pageSize,
					current: pageNum,
					total: total,
					onChange: handlePageChange
				}}
			/>
			<FileDownload hide={hide} onClose={handleClose} data={record.current} type="bl" />
		</div>
	);
};

export default BillOfLading;
