import {
	deleteBlPortFileAction,
	uploadBlPortFileAction,
	downloadBlPortFileAction
} from "@/action/voyageManage/movement"
import { useAppDispatch } from "@/hook"
import useReminder from "@/hook/useReminder"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { MovementBillOfLadingProps } from "../type"
import { PortDetailType } from "../../../type"

const useIndex = (
	dataSource: MovementBillOfLadingProps["dataSource"],
	onChangeHide: MovementBillOfLadingProps["onChangeHide"],
	onPortDetailChange: MovementBillOfLadingProps["onPortDetailChange"]
) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()
	const [loading, setLoading] = useState<boolean>(false)

	const handleUpload = (
		files: File[],
		index: number,
		type: 0 | 1,
		dataId: string,
		voyageId: string
	) => {
		if (!voyageId || loading || files?.length <= 0) {
			onChangeHide(index, true)
			return
		}
		const formData = new FormData()
		for (let file of files) {
			formData.append("files", file)
		}
		uploadBlPortFileAction(
			{
				voyageId,
				type,
				files: formData,
				dataId
			},
			uploadBillOfLadingDetailFront,
			(res) => uploadBlPortFileActionSuccess(res, index),
			uploadBillOfLadingDetailFailed,
			dispatch,
			navigate
		)
	}

	const uploadBlPortFileActionSuccess = (response, index: number) => {
		setLoading(false)
		reminder("success", "File uploaded successfully")
		onChangeHide(index, true)
		onPortDetailChange(index, "files", [
			...(dataSource.dischargePortDetaliList[index]["files"] || []),
			...response.data
		])
	}

	const handleDelete = (id: string, index: number) => {
		deleteBlPortFileAction(
			{
				id
			},
			uploadBillOfLadingDetailFront,
			(res) => deleteBlPortFileActionSuccess(res, id, index),
			uploadBillOfLadingDetailFailed,
			dispatch,
			navigate
		)
	}

	const deleteBlPortFileActionSuccess = (res, id: string, index: number) => {
		const currentIndex = dataSource?.dischargePortDetaliList[index]?.files?.findIndex(
			(selectItem) => selectItem.id === id
		)
		dataSource?.dischargePortDetaliList[index]?.files?.splice(currentIndex, 1)
		onPortDetailChange(index, "files", [...dataSource?.dischargePortDetaliList[index]?.files])
		reminder("success", "File deleted successfully")
		setLoading(false)
	}

	const handlePreview = (type: "preview" | "download", item: PortDetailType["files"][number]) => {
		downloadBlPortFileAction(
			{
				path: item?.filePath
			},
			uploadBillOfLadingDetailFront,
			(response) => {
				setLoading(false)
				if (type === "download") {
					fetch(response.data)
						.then((data) => {
							return data.arrayBuffer()
						})
						.then((buffer) => {
							const blob = new Blob([buffer], { type: "application/octet-stream" })
							const link = document.createElement("a")
							link.href = URL.createObjectURL(blob)
							link.download = item.fileName
							link.click()
							URL.revokeObjectURL(link.href)
						})
				} else {
					window.open(response?.data)
				}
			},
			uploadBillOfLadingDetailFailed,
			dispatch,
			navigate
		)
	}

	const uploadBillOfLadingDetailFront = () => {
		setLoading(true)
	}

	const uploadBillOfLadingDetailFailed = (error) => {
		setLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	const handleUploadOpen = (index: number) => {
		onChangeHide(index, false)
	}

	const handleClose = (index: number) => {
		onChangeHide(index, true)
	}

	return {
		loading,
		handleUpload,
		handleDelete,
		handlePreview,
		handleUploadOpen,
		handleClose
	}
}

export default useIndex
