import React, { useMemo } from "react";
import style from "./index.module.less";
import { InvoicesListFilterProps, InvoicesType } from "./type";
import { FleetSelect } from "@/components";
import { Button } from "@/_components";
import { CommonRoleWrap } from "@/common";
import { invoicesTctcTypeOptions, invoicesTcvcTypeOptions } from "./source";

const InvoicesListFilter: React.FC<InvoicesListFilterProps> = ({
	onAdd,
	activeType,
	disabled,
	onSelect
}) => {
	const invoicesTypeOptions = useMemo(() => {
		if (["rent", "relet"]?.includes(activeType)) return invoicesTctcTypeOptions;
		return invoicesTcvcTypeOptions;
	}, [activeType]);

	return (
		<div className={style["invoicesList-filter"]}>
			<span className="filter-title">Invoices</span>
			<div className="filter-container">
				<FleetSelect
					className="filter-select"
					value={[activeType]}
					options={invoicesTypeOptions}
					onChange={(val: InvoicesType) => {
						onSelect(val);
					}}
				/>
				<CommonRoleWrap roleStr="invoices:detail:add">
					<Button
						disabled={disabled}
						onClick={() => {
							if (disabled) return;
							onAdd?.(activeType);
						}}
						type="icon"
						size="small"
					>
						<span className="font_family fleet-plus filter-add"></span>
					</Button>
				</CommonRoleWrap>
			</div>
		</div>
	);
};

export default InvoicesListFilter;
