import { useEffect, useMemo, useRef, useState } from "react"
import { BlTableDataSourceType, FilterType, ParamsType } from "../type"
import { debounce } from "lodash"
import {
	getBlTableDataAction,
	getCharterListAction,
	getDepartmentDataAction,
	getOperatorListAction,
	downloadBlTableDataAction
} from "@/action/voyageManage/movement"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import useReminder from "@/hook/useReminder"
import { FleetOptionType } from "@/types/advancedType"
import dayjs from "dayjs"
import React from "react"
import { Button } from "@/_components"

const useIndex = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()
	const [pageNum, setPageNum] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(20)
	const [loading, setLoading] = useState<boolean>(false)
	const [btnLoading, setBtnLoading] = useState<boolean>(false)
	const [filter, setFilter] = useState<FilterType>({
		sortRule: null,
		isFinish: null,
		redeliveryDate: null,
		deptId: "",
		operatedBy: "",
		chartererName: ""
	})
	const [dataSource, setDataSource] = useState<BlTableDataSourceType[]>([])
	const [total, setToal] = useState<number>(0)
	const [search, setSearch] = useState<string>("")
	const [treeData, setTreeData] = useState([])
	const [operatorData, setOperatorData] = useState<FleetOptionType<string, string>[]>([])
	const [charterData, setCharterData] = useState<FleetOptionType<string, string>[]>([])
	const record = useRef<BlTableDataSourceType>()
	const [hide, setHide] = useState<boolean>(true)
	const params = useRef<ParamsType>()

	const handlePageChange = (page: number, size: number) => {
		if (dataSource?.length >= total) return
		setPageNum(page)
		getBlTableData()
	}

	const handleFilterChange = (key: keyof FilterType, value) => {
		setFilter((prev) => ({ ...prev, [key]: value }))
		if (key === "deptId") {
			getOperatorList(value)
		}
	}

	const getOperatorList = (id: string) => {
		getOperatorListAction(
			{ deptId: id ? id : undefined },
			() => {},
			getOperatorListSuccess,
			getDepartmentDataFailed,
			dispatch,
			navigate
		)
	}

	const getOperatorListSuccess = (response) => {
		const options = response.data?.map((item) => {
			return { label: item.userName, value: item.userId }
		})
		setOperatorData(options)
	}

	const handleSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			setSearch(val)
		}
		return debounce(loadOptions, 100)
	}, [])

	const getDepartmentData = () => {
		getDepartmentDataAction(
			null,
			() => {},
			getDepartmentDataSuccess,
			getDepartmentDataFailed,
			dispatch,
			navigate
		)
	}

	const getCharterList = () => {
		getCharterListAction(
			null,
			() => {},
			getCharterSuccess,
			getDepartmentDataFailed,
			dispatch,
			navigate
		)
	}

	const getCharterSuccess = (response) => {
		const options = response.data?.map((item) => {
			return { label: item, value: item }
		})
		setCharterData(options)
	}

	const getDepartmentDataSuccess = (response) => {
		setLoading(false)
		setTreeData(transformDepartments(response.data))
		console.log("test", response)
	}

	const transformDepartments = (departments) => {
		return departments.map((dept) => {
			// 重命名 deptId 为 value，deptName 为 title
			const transformedDept = {
				value: dept.deptId,
				title: dept.deptName,
				parentId: dept.parentId,
				children: []
			}
			// 递归处理 children
			if (dept.children && dept.children.length > 0) {
				transformedDept.children = transformDepartments(dept.children)
			}
			// 如果 children 为空数组，则不添加 children 字段
			else {
				delete transformedDept.children
			}
			return transformedDept
		})
	}

	const getDepartmentDataFailed = (error) => {
		setLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	const getBlTableData = () => {
		params.current = {
			pageSize: pageSize,
			pageNum: pageNum,
			keyword: search,
			startRedeliveryDate: filter.redeliveryDate
				? new Date(dayjs(filter.redeliveryDate[0])?.format("YYYY-MM-DD")).getTime()
				: null,
			endRedeliveryDate: filter.redeliveryDate
				? new Date(dayjs(filter.redeliveryDate[1])?.format("YYYY-MM-DD")).getTime()
				: null,
			sortRule: filter.sortRule,
			isFinish: filter.isFinish,
			deptId: filter.deptId,
			operatedBy: filter.operatedBy,
			chartererName: filter.chartererName
		}
		getBlTableDataAction(
			params.current,
			getBlTableDataFront,
			getBlTableDataSuccess,
			getDepartmentDataFailed,
			dispatch,
			navigate
		)
	}

	const getBlTableDataFront = () => {
		setLoading(true)
	}

	const getBlTableDataSuccess = (response) => {
		setLoading(false)
		setToal(response.data.total)
		setDataSource(response.data.list)
	}

	const handleClose = () => {
		setHide(true)
	}

	const opereteColumns = useMemo(() => {
		return [
			{
				dataIndex: "action",
				width: 170,
				title: "Action",
				key: "action",
				align: "center",
				fixed: "right",
				ellipsis: true,
				render: (value, record, index) => {
					return (
						<div className="flex justify-center items-center">
							<Button
								type="secondary"
								size="minuscule"
								prefixIcon="fleet-download"
								onClick={() => {
									handleOpen(record)
								}}
							>
								File Download
							</Button>
						</div>
					)
				}
			}
		]
	}, [record])

	const handleOpen = (item: BlTableDataSourceType) => {
		setHide(false)
		record.current = item
	}

	const handleDownload = () => {
		downloadBlTableDataAction(
			params.current,
			downloadBlTableDataFront,
			downloadBlTableDataSuccess,
			downloadBlTableDataFailed,
			dispatch,
			navigate
		)
	}

	const downloadBlTableDataFront = () => {
		setBtnLoading(true)
	}

	const downloadBlTableDataSuccess = () => {
		setBtnLoading(false)
	}

	const downloadBlTableDataFailed = (error) => {
		setBtnLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	useEffect(() => {
		getDepartmentData()
		getOperatorList(null)
		getCharterList()
	}, [])

	useEffect(() => {
		getBlTableData()
	}, [filter, search])

	return {
		loading,
		pageSize,
		pageNum,
		total,
		filter,
		dataSource,
		search,
		treeData,
		operatorData,
		charterData,
		opereteColumns,
		hide,
		record,
		btnLoading,
		handlePageChange,
		handleSearch,
		handleFilterChange,
		handleClose,
		handleDownload
	}
}

export default useIndex
