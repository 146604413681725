import { useMemo, useRef, useState } from "react"
import { InvStatementBunkerPriceProps } from "../type"
import { initialBunkerItem } from "../../../common"
import { allBunkers, checkKeys, initialBunkerPrice } from "../source"
import { InvStatementOtherItemType } from "../../../../../type"
import { commonItemsCheck } from "@/tools/check"

const useIndex = (otherItem: Pick<InvStatementOtherItemType, "addBunker" | "id" | "voyageId">) => {
	const [dataSource, setDataSource] = useState<InvStatementBunkerPriceProps["items"]>([])
	const deleteIds = useRef<string[]>([])
	const disableBunkers = useMemo(() => {
		return [...new Set(dataSource?.map((item) => item?.bunkerType))]
	}, [dataSource])

	const add = () => {
		if (disableBunkers?.length >= 4) return
		const otherBunkers = allBunkers?.filter((bunker) => !disableBunkers?.includes(bunker))
		setDataSource((prev) => {
			prev?.push(
				initialBunkerItem({
					type: "BOD",
					bunkerType: otherBunkers?.[0],
					voyageId: otherItem?.voyageId,
					invId: otherItem?.id
				}),
				initialBunkerItem({
					type: "BOR",
					bunkerType: otherBunkers?.[0],
					voyageId: otherItem?.voyageId,
					invId: otherItem?.id
				})
			)
			return [...prev]
		})
	}

	const commit: InvStatementBunkerPriceProps["onCommit"] = (current, item) => {
		if (item?.bunkerType) {
			setDataSource((prev) => {
				let currentIndex = prev?.findIndex(
					(selectItem) =>
						selectItem?.bunkerType === current?.bunkerType && selectItem?.type === "BOD"
				)
				prev?.splice(currentIndex, 1, {
					...prev?.[currentIndex],
					...item
				})
				currentIndex = prev?.findIndex(
					(selectItem) =>
						selectItem?.bunkerType === current?.bunkerType && selectItem?.type === "BOR"
				)
				prev?.splice(currentIndex, 1, {
					...prev?.[currentIndex],
					...item
				})
				return [...prev]
			})
			return
		}
		if (current?.type === "BOD") {
			setDataSource((prev) => {
				let currentIndex = prev?.findIndex(
					(selectItem) =>
						selectItem?.bunkerType === current?.bunkerType && selectItem?.type === "BOD"
				)
				prev?.splice(currentIndex, 1, {
					...prev?.[currentIndex],
					...item
				})
				currentIndex = prev?.findIndex(
					(selectItem) =>
						selectItem?.bunkerType === current?.bunkerType && selectItem?.type === "BOR"
				)
				const borItem = prev?.[currentIndex]
				const borTotal = item?.hasOwnProperty("consumption")
					? +item?.consumption * +borItem?.price
					: +borItem?.consumption * +item?.price
				prev?.splice(currentIndex, 1, {
					...borItem,
					...item,
					total: borTotal
				})
				return [...prev]
			})
			return
		}
		setDataSource((prev) => {
			const currentIndex = prev?.findIndex(
				(selectItem) =>
					selectItem?.bunkerType === current?.bunkerType && selectItem?.type === current?.type
			)
			prev?.splice(currentIndex, 1, {
				...prev?.[currentIndex],
				...item
			})
			return [...prev]
		})
	}

	const remove: InvStatementBunkerPriceProps["onDelete"] = (item) => {
		setDataSource((prev) => {
			let currentIndex = prev?.findIndex(
				(selectItem) => selectItem?.bunkerType === item?.bunkerType && selectItem?.type === "BOD"
			)
			prev?.[currentIndex]?.id && deleteIds.current.push(prev?.[currentIndex]?.id)
			prev?.splice(currentIndex, 1)
			currentIndex = prev?.findIndex(
				(selectItem) => selectItem?.bunkerType === item?.bunkerType && selectItem?.type === "BOR"
			)
			prev?.[currentIndex]?.id && deleteIds.current.push(prev?.[currentIndex]?.id)
			prev?.splice(currentIndex, 1)
			return [...prev]
		})
	}

	const init = (type: "init" | "details", items: InvStatementBunkerPriceProps["items"]) => {
		console.log("items", items)
		setDataSource(
			(!items || items?.length <= 0
				? [
						initialBunkerPrice("init", {
							type: "BOD",
							bunkerType: "HSFO",
							voyageId: otherItem?.voyageId,
							invId: otherItem?.id
						}),
						initialBunkerPrice("init", {
							type: "BOR",
							bunkerType: "HSFO",
							voyageId: otherItem?.voyageId,
							invId: otherItem?.id
						})
					]
				: items
			)?.map((item) => initialBunkerPrice(type, item))
		)
		deleteIds.current = []
	}

	const handleBunkerPriceCombineParams = (): {
		bunkerPrice: InvStatementBunkerPriceProps["items"]
		delIds: string[]
	} => {
		return {
			delIds: deleteIds?.current,
			bunkerPrice: dataSource
		}
	}

	const summary = useMemo(() => {
		if (!otherItem?.addBunker)
			return {
				less: 0,
				plus: 0
			}
		return dataSource?.reduce(
			(prev, curr) => {
				return {
					plus: curr?.type === "BOD" ? prev?.plus + curr?.total : prev?.plus,
					less: curr?.type === "BOR" ? prev?.less + curr?.total : prev?.less
				}
			},
			{
				less: 0,
				plus: 0
			}
		)
	}, [dataSource, otherItem?.addBunker])

	const check = () => {
		return otherItem?.addBunker
			? commonItemsCheck(dataSource, checkKeys)
			: {
					checked: true,
					checkKey: ""
				}
	}

	return {
		summary,
		dataSource,
		commit,
		remove,
		add,
		init,
		disableBunkers,
		deleteIds,
		handleBunkerPriceCombineParams,
		check
	}
}

export default useIndex
