import { ParameterType } from "@/api";
import { useAppDispatch } from "@/hook";
import { FleetPageParams } from "@/types/common";
import classnames from "classnames";
import debounce from "lodash/debounce";
import React from "react";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const useIndex = <T extends { id?: number }, K extends keyof T>(
	activeItems: Partial<T> | Partial<T>[],
	showKey: K,
	onFinish: (newItem: T, operateType: "add" | "del") => void,
	onRequest: ParameterType<FleetPageParams & Record<string, string | number>>,
	primaryKey: K | "id" = "id",
	disabled: boolean,
	params?: Record<string, string | number>
) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const [list, setList] = useState<T[]>([]);
	const [loading, setLoading] = useState(true);
	const [searchValue, setSearchVal] = useState("");
	const page = useRef<FleetPageParams>({
		pageNum: 1,
		pageSize: 10
	});

	const selectValue = useMemo(() => {
		if (Array?.isArray(activeItems)) {
			return activeItems?.map((item) => item?.[primaryKey]);
		}
		return (activeItems as T)?.[showKey] ? [(activeItems as T)?.[showKey]] : [];
	}, [activeItems]);

	const queryGroupFront = () => {
		setLoading(true);
	};

	const queryGroupSuccess = (response) => {
		const data = response?.data?.list ?? response?.data?.items ?? response?.data ?? [];
		setLoading(false);
		setList(data);
	};

	const loadMoreSuccess = (response) => {
		const data = response?.data?.list ?? response?.data?.items ?? response?.data ?? [];
		setList((prev) => [...prev, ...data]);
		setLoading(false);
	};

	const queryGroupError = (error) => {
		setLoading(false);
	};

	const focusPopup = (loadMore?: boolean, searchVal?: string) => {
		onRequest(
			{
				...params,
				keyword: (searchVal ?? searchValue)?.trim(),
				pageNum: page.current?.pageNum,
				pageSize: page.current?.pageSize
			},
			queryGroupFront,
			loadMore ? loadMoreSuccess : queryGroupSuccess,
			queryGroupError,
			dispatch,
			navigate
		);
	};

	const closePopup = (open: boolean) => {
		if (!open) {
			// setSearchVal("");
			page.current.pageNum = 1;
		}
		setOpen(open);
	};

	const loadMore = () => {
		page.current = {
			...page.current,
			pageNum: page.current.pageNum + 1
		};
		focusPopup(true);
	};

	const handleItemSelect = (item: T) => {
		if (!activeItems) return;
		if (Array.isArray(activeItems)) {
			const index = activeItems?.findIndex(
				(activeItem) => activeItem[primaryKey] === item[primaryKey]
			);
			onFinish?.(item, index === -1 ? "add" : "del");
		} else {
			const isExist = activeItems[primaryKey] === item[primaryKey];
			onFinish?.(isExist ? null : item, isExist ? "del" : "add");
			setOpen(false);
		}
	};

	const handleSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			debugger;
			page.current.pageNum = 1;
			setList([]);
			setSearchVal(val);
			focusPopup(false, val);
		};
		return debounce(loadOptions, 800);
	}, [focusPopup]);

	const tagRender = (props) => {
		const tagItem: any = (activeItems as T[]).find(
			(activeItem) => activeItem?.[primaryKey] == props.value
		);
		console.log("activeItem", {
			tagItem,
			activeItems,
			label: props?.label,
			value: props?.value
		});
		return (
			<div className="fleet-selectTag">
				<span className="selectTag-name">{props?.value ? tagItem?.[showKey] : props?.label}</span>
				{props?.value && (
					<span
						className={classnames("font_family fleet-close selectTag-close", {
							"fleet-disabled": disabled
						})}
						onClick={() => {
							if (disabled) return;
							handleItemSelect?.(tagItem);
						}}
					></span>
				)}
			</div>
		);
	};

	return {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		setOpen,
		setList,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	};
};

export default useIndex;
