import React, { FC } from "react";
import { CommonContactSelectProps } from "./type";
import useIndex from "./hooks/useIndex";
import { FleetSelect } from "@/components";
import { CommonContactPopup } from "./components";
import classnames from "classnames";
import style from "./index.module.less";
import { CommonNewlyContact } from "@/common/Newly";

const CommonContactSelect: FC<CommonContactSelectProps> = ({
	activeItems,
	contactParmas,
	contactActions,
	onFinish,
	disabled,
	className,
	showCount,
	placeholder,
	primaryKey = "id",
	showKey
}) => {
	const {
		open,
		loadMore,
		loading,
		list,
		tagRender,
		handleItemSelect,
		handleSearch,
		focusPopup,
		selectValue,
		closePopup,
		hide,
		newlyLoading,
		setHide,
		handleContactAdd
	} = useIndex(activeItems, showKey, onFinish, primaryKey, disabled, contactParmas, contactActions);
	return (
		<div
			className={classnames(style["common-select--contact"], {
				[style["common-select--contact--newly"]]: contactActions
			})}
		>
			<FleetSelect
				value={selectValue}
				showSearch
				placeholder={placeholder}
				showCount={showCount}
				popupMatchSelectWidth={false}
				className={classnames("contact-select--container", className)}
				onDropdownVisibleChange={closePopup}
				disabled={disabled}
				mode={Array?.isArray(activeItems) ? "multiple" : undefined}
				open={open}
				onSearch={handleSearch}
				onFocus={() => focusPopup()}
				tagRender={tagRender}
				dropdownRender={() => (
					<CommonContactPopup
						loadMore={loadMore}
						list={list}
						loading={loading}
						onSelect={handleItemSelect}
					/>
				)}
			/>
			{contactActions && (
				<>
					<span
						className="font_family fleet-plus contanct-select--newly"
						onClick={() => {
							setHide(false);
						}}
					></span>
					<CommonNewlyContact
						hide={hide}
						loading={newlyLoading}
						onClose={() => {
							setHide(true);
						}}
						onConfirm={handleContactAdd}
						contanctParams={{ businessType: contactParmas?.businessType }}
					/>
				</>
			)}
		</div>
	);
};

export default CommonContactSelect;
