import { useCallback, useMemo, useRef, useState } from "react"
import { StowagePlanTableDataDisabledType } from "../type"
import useReminder from "@/hook/useReminder"

const useIndex = (onChange) => {
	const [stowagePlanTableDisabled, setStowagePlanTableDisabled] =
		useState<StowagePlanTableDataDisabledType>([])
	const [first, setFirst] = useState(true) //记录是否第一次进入
	const onMouse = (index, prop, type) => {
		if (type == "enter") {
			const newDisabled = JSON.parse(JSON.stringify(stowagePlanTableDisabled))
			newDisabled.splice(index, 1, {
				...stowagePlanTableDisabled[index],
				[prop]: false
			})
			setStowagePlanTableDisabled(newDisabled)
		} else {
			const newDisabled = JSON.parse(JSON.stringify(stowagePlanTableDisabled))
			newDisabled.splice(index, 1, {
				...stowagePlanTableDisabled[index],
				[prop]: true
			})
			setStowagePlanTableDisabled(newDisabled)
		}
	}
	const cellValue = (row, prop, index) => {
		if (prop == "plans") return null
		if (prop == "ttl") {
			let ttl = 0
			for (const [k, v] of Object.entries(row)) {
				if (k.indexOf("-") != -1) ttl += Number(v)
			}
			return ttl?.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,") || null
		} else if (prop == "cgo") {
			let ttl = 0
			for (const [k, v] of Object.entries(row)) {
				if (k.indexOf("-") != -1) ttl += Number(v)
			}
			return (row.stowagePlan - ttl)?.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,") || null
		} else {
			if (prop.indexOf("-") != -1 || prop == "stowagePlan") {
				return row[prop] ? row[prop]?.toFixed(2)?.replace(/(\d)(?=(\d{3})+\.)/g, "$1,") : null
			} else {
				return row[prop] || null
			}
		}
	}
	return {
		stowagePlanTableDisabled,
		onMouse,
		cellValue,
		first,
		setFirst,
		setStowagePlanTableDisabled
	}
}
export default useIndex
