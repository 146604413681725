import React from "react";
import i18n from "@/locale";
import { MovementCommonTableColumnsType } from "../../common/table/type";
import { MovementCargoActualItemType, MovementCargoEstItemType, MovementCargoProps } from "./type";
import { FleetDatePicker, FleetInput, FleetSelect } from "@/components";
import dayjs from "dayjs";
import { CommonContactSelect } from "@/common/Select";
import { CargoUnitType, FreightType } from "@/types/common";
import { FleetOptionType } from "@/types/advancedType";
import { IconProps } from "@/_components/Icon/interface";
import { Icon } from "@/_components";

const cargoUnitOptions: FleetOptionType<CargoUnitType, CargoUnitType>[] = [
	{ label: "MT", value: "MT" },
	{ label: "CBM", value: "CBM" }
];

const freightTypeOptions: FleetOptionType<FreightType, FreightType>[] = [
	{ label: "FR", value: "FR" },
	{ label: "LP", value: "LP" }
];

export const actualColumns: MovementCommonTableColumnsType<
	MovementCargoActualItemType,
	keyof MovementCargoActualItemType,
	{
		onChange: MovementCargoProps["onActualChange"];
		onCommit?: MovementCargoProps["onActualCommit"];
	}
>[] = [
	{
		dataIndex: "id",
		width: 60,
		label: "No.",
		fixed: "left",
		render: (value, record, index) => {
			return <span>{index + 1}</span>;
		}
	},
	{
		dataIndex: "cargoName",
		width: 162,
		label: i18n.t("common.cargoName"),
		fixed: "left"
	},
	{
		dataIndex: "cargoQuantity",
		width: 120,
		label: i18n.t("common.cargoQuantity"),
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "cargoQuantity", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "cargoUnit",
		width: 95,
		label: i18n.t("common.cargoUnit"),
		render: (value, record, index, actions) => {
			return (
				<FleetSelect
					value={[value]}
					options={cargoUnitOptions}
					className="port-select"
					onChange={(val: CargoUnitType) => {
						actions?.onChange?.(index, "cargoUnit", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "freightRate",
		width: 100,
		label: i18n.t("common.freightRate"),
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "freightRate", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "freightType",
		width: 70,
		label: i18n.t("common.freightType"),
		render: (value, record, index, actions) => {
			return (
				<FleetSelect
					value={[value]}
					options={freightTypeOptions}
					className="port-select"
					onChange={(val: FreightType) => {
						actions?.onChange?.(index, "freightType", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "brokerName",
		width: 150,
		label: i18n.t("common.brokerName"),
		render: (value, record, index, actions) => {
			return (
				<CommonContactSelect
					showKey="companyName"
					className="td-select"
					activeItems={{
						id: record?.brokerId,
						companyName: record?.brokerName
					}}
					onFinish={(newItem, type) => {
						actions?.onCommit?.(index, {
							brokerId: newItem?.id ?? null,
							brokerName: newItem?.companyName ?? ""
						});
					}}
					contactParmas={{ businessType: "Broker" }}
					contactActions={{
						onConfirm: (newItem) => {
							actions?.onCommit?.(index, {
								brokerId: newItem?.id ?? null,
								brokerName: newItem?.companyName ?? ""
							});
						}
					}}
				/>
			);
		}
	},
	{
		dataIndex: "commission",
		width: 100,
		label: i18n.t("common.commission"),
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "commission", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "brokerage",
		width: 100,
		label: i18n.t("common.brokerage"),
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "brokerage", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "layCanFrom",
		width: 195,
		label: i18n.t("common.layCanFrom"),
		render: (value, record, index, actions) => {
			return (
				<FleetDatePicker
					value={value ? dayjs(value) : null}
					className="date-picker"
					showTime={{
						format: "HH:mm"
					}}
					format={(value) => value?.format("YYYY-MM-DD HH:mm")}
					showHour
					showMinute
					onChange={(val, timeStramp) => {
						const currentTime = timeStramp?.startOf?.("minutes")?.valueOf?.();
						actions?.onChange?.(index, "layCanFrom", currentTime);
					}}
					disabledDate={(current) => {
						return record?.layCanTo && current > dayjs(record?.layCanTo).endOf("minutes");
					}}
				/>
			);
		}
	},
	{
		dataIndex: "layCanTo",
		width: 195,
		label: i18n.t("common.layCanTo"),
		render: (value, record, index, actions) => {
			return (
				<FleetDatePicker
					allowClear
					value={value ? dayjs(value) : null}
					className="date-picker"
					showTime={{
						defaultValue: dayjs().hour(23).minute(59)
					}}
					format="YYYY-MM-DD HH:mm"
					showHour
					showMinute
					onChange={(val, timeStramp) => {
						const currentTime = timeStramp?.startOf?.("minutes")?.valueOf?.();
						actions?.onChange?.(index, "layCanTo", currentTime);
					}}
					disabledDate={(current) => {
						return record?.layCanFrom && current < dayjs(record?.layCanFrom).endOf("minutes");
					}}
				/>
			);
		}
	}
];

export const estColumns: MovementCommonTableColumnsType<
	MovementCargoEstItemType,
	keyof MovementCargoEstItemType,
	{
		onChange: MovementCargoProps["onEstChange"];
		onCommit?: MovementCargoProps["onEstCommit"];
	}
>[] = [
	{
		dataIndex: "id",
		width: 60,
		label: "No.",
		fixed: "left",
		render: (value, record, index) => {
			return <span>{index + 1}</span>;
		}
	},
	{
		dataIndex: "cargoName",
		width: 162,
		label: i18n.t("common.cargoName"),
		fixed: "left"
	},
	{
		dataIndex: "cargoQuantity",
		width: 120,
		label: i18n.t("common.cargoQuantity"),
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "cargoQuantity", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "cargoQuantityDiscrepancy",
		width: 100,
		label: i18n.t("common.cargoQuantityDiscrepancy"),
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "cargoQuantityDiscrepancy", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "cargoUnit",
		width: 95,
		label: i18n.t("common.cargoUnit"),
		render: (value, record, index, actions) => {
			return (
				<FleetSelect
					value={[value]}
					options={cargoUnitOptions}
					className="port-select"
					onChange={(val: CargoUnitType) => {
						actions?.onChange?.(index, "cargoUnit", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "freightRate",
		width: 100,
		label: i18n.t("common.freightRate"),
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "freightRate", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "freightType",
		width: 70,
		label: i18n.t("common.freightType"),
		render: (value, record, index, actions) => {
			return (
				<FleetSelect
					value={[value]}
					options={freightTypeOptions}
					className="port-select"
					onChange={(val: FreightType) => {
						actions?.onChange?.(index, "freightType", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "commission",
		width: 100,
		label: i18n.t("common.commission"),
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "commission", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "brokerage",
		width: 100,
		label: i18n.t("common.brokerage"),
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "brokerage", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "layCanFrom",
		width: 195,
		label: i18n.t("common.layCanFrom"),
		render: (value, record, index, actions) => {
			return (
				<FleetDatePicker
					value={value ? dayjs(value) : null}
					className="date-picker"
					showTime={{
						format: "HH:mm"
					}}
					showHour
					showMinute
					format={(value) => value?.format("YYYY-MM-DD HH:mm")}
					onChange={(val, timeStramp) => {
						const currentTime = timeStramp?.startOf?.("minutes")?.valueOf?.();
						actions?.onChange?.(index, "layCanFrom", currentTime);
					}}
					disabledDate={(current) => {
						return record?.layCanTo && current > dayjs(record?.layCanTo).endOf("minutes");
					}}
				/>
			);
		}
	},
	{
		dataIndex: "layCanTo",
		width: 195,
		label: i18n.t("common.layCanTo"),
		render: (value, record, index, actions) => {
			return (
				<FleetDatePicker
					allowClear
					value={value ? dayjs(value) : null}
					className="date-picker"
					showTime={{
						defaultValue: dayjs().hour(23).minute(59)
					}}
					showHour
					showMinute
					format="YYYY-MM-DD HH:mm"
					onChange={(val, timeStramp) => {
						const currentTime = timeStramp?.startOf?.("minutes")?.valueOf?.();
						actions?.onChange?.(index, "layCanTo", currentTime);
					}}
					disabledDate={(current) => {
						return record?.layCanFrom && current < dayjs(record?.layCanFrom).endOf("minutes");
					}}
				/>
			);
		}
	}
];
