import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import PortFilter from "@/featrue/database/port/filter";
import { FleetButton, FleetTable } from "@/components";
import useGroup from "@/hook/database/port/useGroup";
import DatabasePortAdd from "@/featrue/database/port/add";
import DatabasePortEdit from "@/featrue/database/port/edit";
import { Button } from "@/_components";
import { CommonRoleWrap } from "@/common";

const DatabasePort: React.FC<{}> = (props) => {
  const {
    loading,
    hideMap,
    record,
    onHideChange,
    columns,
    dataSource,
    pager,
    onPageChange,
    selectedRowKeys,
    onSelectChange,
    onSearch,
    onReset,
    filter,
    onFilterChange,
    onRow,
  } = useGroup();
  return (
		<div className={classnames(style["database-port"], "database-common")}>
			<div className="database-toolbar port-toolbar">
				<PortFilter item={filter} onChange={onFilterChange} onSearch={onSearch} />
				<CommonRoleWrap roleStr="port:list:add">
					<Button size="small" onClick={() => onHideChange("add", false)} prefixIcon="fleet-add">
						Add Port
					</Button>
				</CommonRoleWrap>
			</div>
			<FleetTable
				columns={columns}
				className="database-table"
				dataSource={dataSource}
				loading={loading}
				id="id"
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
				rowSelection={{
					columnWidth: 64,
					selectedRowKeys,
					onChange: onSelectChange
				}}
				onRow={onRow}
			/>
			<DatabasePortAdd
				hide={hideMap?.add}
				onReset={onReset}
				onClose={() => onHideChange("add", true)}
			/>
			<DatabasePortEdit
				hide={hideMap?.edit}
				portId={record?.current?.id}
				onReset={onReset}
				item={record?.current}
				onClose={() => onHideChange("edit", true)}
			/>
		</div>
	);
};

export default DatabasePort;
