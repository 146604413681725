import React from "react"
import { InvFreightTemplateProps } from "./type"
import { InvTemplateStyle } from "../../../../common"
import useIndex from "./hooks/useIndex"
import style from "./index.module.less"
import classNames from "classnames"
// import useIndex from "./hooks/useIndex";

const InvFreightTemplate: React.FC<InvFreightTemplateProps> = ({
	canvasRef,
	dataSource,
	activeTab
}) => {
	useIndex(canvasRef, dataSource, activeTab)
	return (
		<div
			className={classNames(
				InvTemplateStyle["invoices-template-common"],
				style["invFreight-template"]
			)}
		>
			<canvas width="930" height="1364" id="invoices-template" ref={canvasRef}></canvas>
		</div>
	)
}

export default InvFreightTemplate
