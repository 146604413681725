import React, { useState } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import CargoTableStyle from "../cargoBook/index.module.less";
import { FleetButton, FleetRangePicker, FleetInput, FleetTable, FleetTooltip } from "@/components";
import { CommonRoleWrap, FleetCategory, FleetToast } from "@/common";
import useGroup from "@/hook/voyageManage/headFixture/useGroup";
import { HFGroupOptions } from "@/types/voyageManage/headFixtureList/list";
import dayjs from "dayjs";
import NewHeadFixture from "@/featrue/voyagemanage/headFixture/newAdd";
import EditHeadFixture from "@/featrue/voyagemanage/headFixture/editFixture";
import { Button, Popover } from "@/_components";

const VoyagemanageFixtureList: React.FC<{}> = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const {
		checkGroup,
		layCan,
		ids,
		selectedRowKeys,
		pageNum,
		pageSize,
		columns,
		dataSource,
		loading,
		total,
		hide,
		editHide,
		setHide,
		setEditHide,
		onLayCanChange,
		handleReset,
		handleChange,
		onPageChange,
		onSelectChange,
		onDeleteItems,
		onSearch,
		onRow,
		recordId,
		onReset,
		onIdsChange
	} = useGroup();

	return (
		<div
			className={classnames(
				CargoTableStyle["voyagemanage-cargoBook"],
				style["voyagemanage-fixtureList"],
				"voyagemanage-common"
			)}
		>
			<div className="cargoBook-toolbar">
				<div className="toolbar-filter">
					<div className="filter">
						<span className="filter-label">Search</span>
						<FleetInput
							className="input-filter"
							value={undefined}
							placeholder="Please enter"
							prefix="LayoutSearchIcon"
							onChange={onSearch}
						/>
					</div>
					<div className="filter category-filter">
						<Popover
							open={isOpen}
							onOpenChange={setIsOpen}
							buttonProps={{
								children: "Filter",
								size: "small"
							}}
							content={
								<div className={style["filter-content"]}>
									<FleetCategory
										options={HFGroupOptions}
										checkGroup={checkGroup}
										onChange={handleChange}
										onReset={handleReset}
									/>
								</div>
							}
						></Popover>
					</div>
					<div className="filter">
						<span className="filter-label">Contract Date</span>
						<FleetRangePicker
							className="date-filter"
							value={layCan}
							onChange={onLayCanChange}
							disabledDate={(current) => {
								return current && current < dayjs().endOf("day");
							}}
						/>
					</div>
				</div>
				<div className="toolbar-actions">
					<CommonRoleWrap roleStr="hf:list:batchdelete">
						<Popover
							buttonProps={{
								className: "toolbar-action",
								disabled: selectedRowKeys?.length === 0,
								children: <span className="font_family fleet-delete"></span>,
								type: "icon"
							}}
							disabled={selectedRowKeys?.length === 0}
							render={(setIsOpen) => {
								return (
									<FleetToast
										label="Are you sure delete this item?"
										onCancel={() => {
											setIsOpen(false);
										}}
										onConfirm={() => {
											onDeleteItems?.("all");
											setIsOpen(false);
										}}
									/>
								);
							}}
						/>
					</CommonRoleWrap>
					<CommonRoleWrap roleStr="hf:list:download">
						<Button
							size="small"
							disabled={selectedRowKeys?.length === 0}
							className="toolbar-action"
							prefixIcon="fleet-download"
						>
							Download
						</Button>
					</CommonRoleWrap>
					<CommonRoleWrap roleStr="hf:list:add">
						<Button size="small" onClick={() => setHide(false)} prefixIcon="fleet-add">
							New Head Fixture
						</Button>
					</CommonRoleWrap>
				</div>
			</div>
			<FleetTable
				columns={columns}
				className="cargoBook-table"
				dataSource={dataSource}
				loading={loading}
				id="id"
				pagination={{
					pageSize: pageSize,
					current: pageNum,
					total: total,
					onChange: onPageChange
				}}
				rowSelection={{
					columnWidth: 64,
					selectedRowKeys,
					onChange: onSelectChange
				}}
				onRow={onRow}
			/>
			<NewHeadFixture onClose={() => setHide(true)} hide={hide} onReset={onReset} />
			<EditHeadFixture
				hide={editHide}
				onClose={() => setEditHide(true)}
				recordId={recordId}
				onReset={onReset}
			/>
		</div>
	);
};

export default VoyagemanageFixtureList;
