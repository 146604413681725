import {
	getInsureFormDataParams,
	getInsureTableDataParams,
	saveInsureFormDataParams
} from "./type";
import request from "@/service";
import { type ApiParameter } from "@/api";

export const appVersion =
	process.env.NODE_ENV === "development" ? "/gateway/voyage/v1" : "/gateway/voyage/v1";
const enum ApiMap {
	getInsureFormDataParameter,
	saveInsureFormDataParameter,
	getInsureTableDataParameter,
	downloadInsureTableDataParameter
}
type ApiUrl = keyof typeof ApiMap;

const insureApi: ApiParameter<ApiUrl> = {
	getInsureFormDataParameter: {
		method: "get",
		url: appVersion + "/move/insure/detail"
	},
	saveInsureFormDataParameter: {
		method: "put",
		url: appVersion + "/move/insure/detail"
	},
	getInsureTableDataParameter: {
		method: "get",
		url: appVersion + "/move/insure/list"
	},
	downloadInsureTableDataParameter: {
		method: "post",
		url: appVersion + "/move/insure/download"
	}
};

export const getInsureFormDataApi = (params: getInsureFormDataParams) => {
	return request.request({
		...insureApi.getInsureFormDataParameter,
		url: `${insureApi.getInsureFormDataParameter.url}/${params?.voyageId}`
	});
};

export const saveInsureFormDataApi = (params: saveInsureFormDataParams) => {
	return request.request({
		...insureApi.saveInsureFormDataParameter,
		data: params
	});
};

export const getInsureTableDataApi = (params: getInsureTableDataParams) => {
	return request.request({
		...insureApi.getInsureTableDataParameter,
		params
	});
};

export const downloadInsureTableDataApi = (params: getInsureTableDataParams) => {
	return request.request({
		...insureApi.downloadInsureTableDataParameter,
		data: params,
		responseType: "blob"
	});
};
