import { FleetOptionType } from "@/types/advancedType"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import React from "react"
import classnames from "classnames"
import { dateToUtcString } from "@/tools/date"

export const departmentOptions: FleetOptionType<"All", "all">[] = [
	{
		value: "all",
		label: "All"
	}
]

export const isFinishOptions: FleetOptionType<"Yes" | "No", 1 | 0>[] = [
	{
		value: 1,
		label: "Yes"
	},
	{
		value: 0,
		label: "No"
	}
]

export const sortOptions: FleetOptionType<
	| "Voyage No(A〜Z)"
	| "Voyage No(Z〜A)"
	| "Vessel Name(A〜Z)"
	| "Vessel Name(Z〜A)"
	| "Redelivery Date(A〜Z)"
	| "Redelivery Date(Z〜A)"
	| "Department(A〜Z)"
	| "Department(Z〜A)"
	| "Discharge Port(A〜Z)"
	| "Discharge Port(Z〜A)",
	1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
>[] = [
	{
		value: 1,
		label: "Voyage No(A〜Z)"
	},
	{
		value: 2,
		label: "Voyage No(Z〜A)"
	},
	{
		value: 3,
		label: "Vessel Name(A〜Z)"
	},
	{
		value: 4,
		label: "Vessel Name(Z〜A)"
	},
	{
		value: 5,
		label: "Redelivery Date(A〜Z)"
	},
	{
		value: 6,
		label: "Redelivery Date(Z〜A)"
	},
	{
		value: 7,
		label: "Department(A〜Z)"
	},
	{
		value: 8,
		label: "Department(Z〜A)"
	},
	{
		value: 9,
		label: "Discharge Port(A〜Z)"
	},
	{
		value: 10,
		label: "Discharge Port(Z〜A)"
	}
]

export const columns = [
	{
		dataIndex: "department",
		width: 130,
		title: "Department",
		key: "department",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "vesselName",
		width: 130,
		title: "Vessel Name",
		key: "vesselName",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "voyageNo",
		width: 120,
		title: "Voyage No.",
		key: "voyageNo",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "redeliveryDate",
		width: 150,
		title: "Redelivery Date",
		key: "redeliveryDate",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{value ? dayjs(value)?.format("YYYY-MM-DD") : null}</span>
		}
	},
	{
		dataIndex: "dischargePortName",
		width: 180,
		title: "Discharge Port",
		key: "dischargePortName",
		align: "center",
		ellipsis: false
	},
	{
		dataIndex: "quayWharf",
		width: 150,
		title: "Quay / Wharf",
		key: "quayWharf",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "tradingChain",
		width: 150,
		title: "Trade Chain",
		key: "tradingChain",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "chartererName",
		width: 150,
		title: "Charterer",
		key: "chartererName",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "operatedByName",
		width: 150,
		title: "Operated By",
		key: "operatedByName",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "isFinish",
		width: 150,
		title: "Completed",
		key: "isFinish",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return (
				<span className={classnames("movement-isFinish", value ? "yes" : "no")}>
					{value ? "Yes" : "No"}
				</span>
			)
		}
	},
	{
		dataIndex: "remarks",
		width: 150,
		title: "Remarks",
		key: "remarks",
		align: "center",
		ellipsis: true
	}
]
