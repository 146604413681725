import { CommonChangeEvent } from "@/types/event"
import { useEffect, useState } from "react"
import { InsureFormDataType } from "../type"
import useReminder from "@/hook/useReminder"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import { getInsureFormDataAction, saveInsureFormDataAction } from "@/action/voyageManage/movement"

const useIndex = (
	hide: boolean,
	voyageId: string,
	onClose: () => void,
	onGetPniTotal: (totalInsure: number) => void
) => {
	const { reminder } = useReminder()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [loading, setLoading] = useState<boolean>(false)
	const [dataSource, setDataSource] = useState<InsureFormDataType>()

	const getInsureFormData = () => {
		getInsureFormDataAction(
			{ voyageId: voyageId },
			getBillDetailDataFront,
			getBillDetailDataSuccess,
			getBillDetailDataError,
			dispatch,
			navigate
		)
	}

	const getBillDetailDataFront = () => {
		setLoading(true)
	}

	const getBillDetailDataSuccess = (response) => {
		setDataSource({
			...response.data,
			total: Number(
				(
					(response.data?.cl || 0) * (response.data?.cargoQuantity || 0) +
					((response.data?.fdd || 0) / 365) *
						(response.data?.duration && Number(response.data?.duration) > response.data?.fddDay
							? Number(response.data?.duration || 0)
							: response.data?.fddDay || 0)
				).toFixed(3)
			)
		})
		setLoading(false)
	}

	const getBillDetailDataError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
		setLoading(false)
	}

	const handleChange: CommonChangeEvent<InsureFormDataType> = (key, value) => {
		setDataSource((prev) => {
			const newData = { ...prev, [key]: value }
			return {
				...newData,
				total: Number(
					(
						(newData.cl || 0) * (newData.cargoQuantity || 0) +
						((newData?.fdd || 0) / 365) *
							(newData?.duration && Number(newData?.duration) > newData?.fddDay
								? Number(newData?.duration || 0)
								: newData?.fddDay || 0)
					).toFixed(3)
				)
			}
		})
	}

	const handleConfirm = () => {
		saveInsureFormDataAction(
			dataSource,
			getBillDetailDataFront,
			saveBillDetailDataSuccess,
			getBillDetailDataError,
			dispatch,
			navigate
		)
	}

	const saveBillDetailDataSuccess = () => {
		reminder("success", "Save successfully")
		onGetPniTotal(dataSource?.total)
		setLoading(false)
		onClose()
	}

	useEffect(() => {
		if (hide) return
		getInsureFormData()
	}, [hide])
	return {
		loading,
		dataSource,
		handleChange,
		handleConfirm
	}
}

export default useIndex
