import { CommonCommitEvent } from "@/types/event";
import { TimeAxisControlProps, TimeAxisTimeLineProps, TimeAxisTimeLineRef } from "../components";
import { useRef, useState } from "react";
import { useRouteCommonContext } from "@/pages/routeCommon/store";
import dayjs from "dayjs";
import { RouteCommonTimeAxisProps } from "../type";
import { getLastDate } from "../source";
import { getScheduleTime } from "../tools";

const useIndex = (
	onMeteoTimeChange: RouteCommonTimeAxisProps["onMeteoTimeChange"],
	onRun?: RouteCommonTimeAxisProps["onRun"]
) => {
	const { getMeteoCalendarEndTime } = useRouteCommonContext();
	const [activeOption, setActiveOption] = useState<TimeAxisControlProps["item"]>({
		currentTime: dayjs(),
		hours: 3,
		runState: false
	});

	const commit: CommonCommitEvent<TimeAxisControlProps["item"]> = (item) => {
		setActiveOption((prev) => ({ ...prev, ...item }));
	};

	const timeLineRef = useRef<TimeAxisTimeLineRef>(null);

	const handleNext: TimeAxisControlProps["onNext"] = () => {
		const currentHours = activeOption?.currentTime?.get("hour"),
			currentMinute = activeOption?.currentTime?.get("minute"),
			finalHours = getScheduleTime(currentHours + currentMinute / 60);
		console.log("next", {
			currentHours,
			currentMinute,
			finalHours
		});
		const currentTime = activeOption?.currentTime
			?.set("hour", finalHours)
			.add(activeOption?.hours, "h")
			?.startOf("hours");
		console.log("currentTime", currentTime);
		commit({ currentTime: currentTime });
		timeLineRef?.current?.onReset?.(currentTime);
		onMeteoTimeChange?.(currentTime?.valueOf());
	};

	const handleRun = () => {
		if (activeOption?.runState) {
			commit?.({ runState: false });
			onRun?.("stop");
			return;
		}
		commit?.({ runState: true });
		const currentHours = activeOption?.currentTime?.get("hour"),
			currentMinute = activeOption?.currentTime?.get("minute"),
			finalHours = getScheduleTime(currentHours + currentMinute / 60);
				onRun?.(
					"start",
					activeOption?.currentTime?.set("hour", finalHours)?.startOf("hours"),
					(value) => {
						debugger;
						const endTime = getMeteoCalendarEndTime();
						const lastDate = getLastDate();
						console.log("getMeteoCalendarEndTime", {
							endTime,
							value,
							lastDate
						});
						if (
							value?.startOf("hour")?.isAfter(endTime) ||
							value?.startOf("hour")?.isSame(endTime)
						) {
							timeLineRef?.current?.onReset?.(endTime);
							commit?.({ currentTime: endTime, runState: false });
							return;
						}
						if (
							value?.startOf("hour")?.isAfter(lastDate) ||
							value?.startOf("hour")?.isSame(lastDate)
						) {
							timeLineRef?.current?.onReset?.(lastDate);
							commit?.({ currentTime: lastDate, runState: false });
							return;
						}
						commit?.({ currentTime: value });
						timeLineRef?.current?.onReset?.(value);
					},
					activeOption?.hours
				);
	};

	const handleTimeLineChange: TimeAxisTimeLineProps["onChange"] = (value) => {
		if (activeOption?.runState) {
			handleRun?.();
		}
		commit({ currentTime: value });
		onMeteoTimeChange?.(value?.valueOf());
	};

	return {
		activeOption,
		commit,
		handleNext,
		handleRun,
		timeLineRef,
		handleTimeLineChange
	};
};

export default useIndex;
