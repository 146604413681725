import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetTable } from "@/components";
import { columns } from "./source";
import useIndex from "./hooks/useIndex";
import { AccountFilter } from "./components";
import { Button } from "@/_components";
import { CommonRoleWrap } from "@/common";
import { CommonNewlyAccount } from "@/common/Newly";

const DatabaseAccount: React.FC<{}> = (props) => {
	const {
		loading,
		hide,
		record,
		opereteColumns,
		dataSource,
		editType,
		pager,
		company,
		selectedRowKeys,
		handleCompanySelect,
		onPageChange,
		setHide,
		onSelectChange,
		onSearch,
		handleReset,
		deleteAccount,
		handleEdit
	} = useIndex();
	return (
		<div className={classnames("database-common", style["database-account"])}>
			<div className="database-toolbar account-toolbar">
				<AccountFilter
					item={{ company }}
					onSearch={onSearch}
					onCompanySelect={handleCompanySelect}
				/>
				<div className="toolbar-actions">
					<CommonRoleWrap roleStr="account:list:batchDelete">
						<Button
							size="small"
							disabled={selectedRowKeys?.length === 0}
							onClick={() => deleteAccount("all")}
							prefixIcon="fleet-delete"
						>
							Delete
						</Button>
					</CommonRoleWrap>
					<CommonRoleWrap roleStr="account:list:add">
						<Button size="small" prefixIcon="fleet-add" onClick={() => handleEdit("add", null)}>
							Add Account
						</Button>
					</CommonRoleWrap>
				</div>
			</div>
			<FleetTable
				columns={[...columns, ...opereteColumns]}
				className="database-table"
				dataSource={dataSource}
				loading={loading}
				id="id"
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
				rowSelection={{
					columnWidth: 64,
					selectedRowKeys,
					onChange: onSelectChange
				}}
			/>
			<CommonNewlyAccount
				activeItem={{
					companyId: record?.current?.companyId,
					companyName: record?.current?.companyName
				}}
				hide={hide}
				onClose={() => setHide(true)}
				onConfirm={handleReset}
				type={editType}
			/>
		</div>
	);
};

export default DatabaseAccount;
