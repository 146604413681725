import React, { FC } from "react"
import style from "./index.module.less"
import { AparFinancialItemType, AparFinancialProps } from "./type"
import { FleetPrompt, FleetToast } from "@/common"
import { Button, Icon, Popover } from "@/_components"
import useIndex from "./hooks/useIndex"
import {
	FleetDatePicker,
	FleetFormItem,
	FleetImage,
	FleetInput,
	FleetLoading,
	FleetUpload
} from "@/components"
import dayjs from "dayjs"
import { FleetTabbar } from "@/components/Tabs"
import { RcFile } from "antd/es/upload"
import { useAppSelector } from "@/hook"
import { FleetUserRoleKeys } from "@/types/role"

const AparFinancial: FC<AparFinancialProps> = ({ hide, invoice, onClose, onConfirm }) => {
	const {
		loading,
		dataSource,
		fileList,
		uploadStatus,
		change,
		handleUpload,
		handleDelete,
		handleConfirm,
		handleDownload
	} = useIndex(hide, invoice, onConfirm)
	const { roles } = useAppSelector((selector) => selector.UserStore)
	console.log(
		"roles",
		roles,
		dataSource,
		dataSource?.status === "Settled" &&
			(!roles?.includes("teamleader") || !roles?.includes("admin"))
	)
	return (
		<FleetPrompt hide={hide}>
			<div className={style["apar-financial"]}>
				<FleetLoading loading={loading} />
				<div className="prompt-header--container">
					<span className="header-title">Financial Amount</span>
					<Icon type="fleet-close" onClick={onClose} />
				</div>
				<div className="financial-container">
					<div className="financial-info">
						<FleetFormItem label="Invoice Amount">
							<FleetInput
								value={dataSource?.invoiceAmount}
								onChange={(value: number) => {
									change("invoiceAmount", value)
								}}
								type="number"
								disabled
							/>
						</FleetFormItem>
						<FleetFormItem label="Financial Amount" needVerity>
							<FleetInput
								value={dataSource?.amount}
								onChange={(value: number) => {
									change("amount", value)
								}}
								type="number"
							/>
						</FleetFormItem>
						<FleetFormItem label="Difference">
							<FleetInput
								value={+dataSource?.amount - +dataSource?.invoiceAmount}
								disabled
								type="number"
							/>
						</FleetFormItem>
						<FleetFormItem label="Latest Financial Date" needVerity>
							<FleetDatePicker
								value={
									dataSource?.latestFinancialDate ? dayjs(dataSource?.latestFinancialDate) : null
								}
								format="YYYY-MM-DD"
								showTime={false}
								onChange={(_, timeStramp) => {
									change("latestFinancialDate", timeStramp?.format("YYYY-MM-DD"))
								}}
							/>
						</FleetFormItem>
						<FleetFormItem label="Status">
							<FleetTabbar
								list={["Inprogress", "Settled"]}
								value={dataSource?.status}
								onChange={(value: AparFinancialItemType["status"]) => {
									change("status", value)
								}}
								disabled={
									dataSource?.status === "Settled" &&
									!["admin", "teamleader"]?.some((role: FleetUserRoleKeys) => roles?.includes(role))
								}
							/>
						</FleetFormItem>
					</div>
					<FleetUpload
						type="dragger"
						multiple
						onUpload={handleUpload}
						showFileList={false}
						uploadStatus={uploadStatus}
						fileList={fileList}
						disabled={loading}
					/>
					<div className="financial-upload-container">
						{[...dataSource?.attachment, ...fileList]?.map(
							(item: AparFinancialItemType["attachment"][number] & RcFile, index) => {
								return (
									<div className="upload-item" key={index}>
										<div className="uploadfile-info">
											<FleetImage className="attach-icon" />
											<span>{item?.fileName ?? item?.name}</span>
										</div>
										<div className="upload-actions">
											<Icon
												size={14}
												type="fleet-download"
												disabled={!item?.id}
												onClick={() => {
													handleDownload?.(item)
												}}
											/>
											<Popover
												trigger="click"
												placement="left-start"
												children={
													<Icon className="uploadfile-remove" size={14} type="fleet-delete" />
												}
												render={(setIsOpen) => {
													return (
														<FleetToast
															label="That file will be deleted. Are you sure?"
															onCancel={() => {
																setIsOpen(false)
															}}
															onConfirm={() => {
																setIsOpen(false)
																// onDelete?.(item?.id);
																handleDelete(item)
															}}
														/>
													)
												}}
											/>
										</div>
									</div>
								)
							}
						)}
					</div>
				</div>
				<div className="prompt-footer--container">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" type="primary" onClick={handleConfirm}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	)
}

export default AparFinancial
