import React, { FC } from "react"
import style from "./index.module.less"
import classnames from "classnames"
import {
	FleetDatePicker,
	FleetFormItem,
	FleetImage,
	FleetLoading,
	FleetTooltip,
	FleetUpload,
	FleetVerifyInput
} from "@/components"
import { FleetCustomRadio } from "@/components/Radio"
import useIndex from "./hooks/useIndex"
import { FleetToast } from "@/common"
import { MovementBillOfLadingProps } from "./type"
import { MovementUpload } from "../../../upload"
import { Button } from "@/_components"
import dayjs from "dayjs"
import { dateToUtcString } from "@/tools/date"

const MovementBillOfLading: FC<MovementBillOfLadingProps> = ({
	dataSource,
	uploadHide,
	onChangeHide,
	onChange,
	onPortDetailChange
}) => {
	const { loading, handleUpload, handleDelete, handlePreview, handleUploadOpen, handleClose } =
		useIndex(dataSource, onChangeHide, onPortDetailChange)
	return (
		<div className={classnames("billOfLading-commom", style["billOfLading-contain"])}>
			<FleetLoading loading={loading} />
			<div className="billOfLading-basics">
				<span className="header--title">Basics</span>
				<div className="flex-container">
					<FleetVerifyInput
						label="Department"
						value={dataSource?.department}
						onChange={(val: string) => {
							onChange("department", val)
						}}
						disabled
					/>
					<FleetVerifyInput label="Team" value={dataSource?.team} disabled />
					<FleetVerifyInput
						label="Operated By"
						value={dataSource?.operatedByName}
						disabled
						suffix="fleet-user"
					/>
				</div>
			</div>
			<div className="billOfLading-information">
				<span className="header--title">Voyage Information</span>
				<div className="flex-container">
					<FleetVerifyInput label="Voyage No." value={dataSource?.voyageNo} disabled />
					<FleetVerifyInput label="Vessel Name" value={dataSource?.vesselName} disabled />
					<FleetFormItem label="Redelivery Date">
						<FleetDatePicker
							className="w-52"
							value={dataSource?.redeliveryDate ? dayjs(dataSource?.redeliveryDate) : null}
							disabled
						/>
					</FleetFormItem>
					<FleetVerifyInput
						label="Charterer"
						value={dataSource?.chartererName}
						disabled
						suffix="fleet-user"
					/>
				</div>
				<FleetVerifyInput
					label="Trading Chain"
					value={dataSource?.tradingChain}
					onChange={(val: string) => {
						onChange("tradingChain", val)
					}}
				/>
				<FleetVerifyInput
					label="Remarks"
					value={dataSource?.remarks}
					onChange={(val: string) => {
						onChange("remarks", val)
					}}
				/>
			</div>
			<div className="billOfLading-port">
				<span className="header--title">Discharge Port</span>
				<div className="discharge-port-container">
					{dataSource?.dischargePortDetaliList?.map((item, index) => {
						return (
							<div className="discharge-port-box">
								<div className="box-header">Details</div>
								<div className="port-details">
									<FleetVerifyInput
										label="Discharge Port"
										value={item?.dischargePortName}
										disabled
									/>
									<FleetVerifyInput
										label="Quay / Wharf"
										value={item?.quayWharf}
										onChange={(val: string) => {
											onPortDetailChange(index, "quayWharf", val)
										}}
									/>
									<span>
										<span className="radio-label">Bill of Lading</span>
										<span className="flex mt-1">
											<FleetCustomRadio
												value={item?.isBillLading === 1}
												label={"Yes"}
												className="!h-fit"
												onChange={() => {
													onPortDetailChange?.(index, "isBillLading", 1)
												}}
											/>
											<FleetCustomRadio
												value={item?.isBillLading === 0}
												label={"No"}
												className="!h-fit !ml-2"
												onChange={() => {
													onPortDetailChange?.(index, "isBillLading", 0)
												}}
											/>
										</span>
									</span>
									<span className="ml-6">
										<span className="radio-label">B/L based on</span>
										<span className="flex mt-1">
											<FleetCustomRadio
												value={item?.blBaseOn === 0}
												label={"LOI"}
												className="!h-fit"
												disabled={item?.isBillLading === 0}
												onChange={() => {
													onPortDetailChange?.(index, "blBaseOn", 0)
												}}
											/>
											<FleetCustomRadio
												value={item?.blBaseOn === 1}
												label={"OBL"}
												className="!h-fit !ml-2"
												disabled={item?.isBillLading === 0}
												onChange={() => {
													onPortDetailChange?.(index, "blBaseOn", 1)
												}}
											/>
										</span>
									</span>
								</div>
								{item.files?.length > 0 &&
									item.files?.map((fitem, findex) => {
										return (
											<div className="uploadfile-item" key={findex}>
												<div className="uploadfile-info">
													<FleetImage className="img-icon" />
													<span>{fitem?.fileName}</span>
												</div>
												<div className="uploadfile-tool">
													<span
														className="font_family fleet-download  uploadfile-icon"
														onClick={() => {
															handlePreview?.("download", fitem)
														}}
													></span>
													<span
														className="font_family fleet-show uploadfile-icon"
														onClick={() => {
															handlePreview?.("preview", fitem)
														}}
													></span>
													<FleetTooltip
														outerLayer={
															<span className="font_family fleet-delete  uploadfile-icon"></span>
														}
														render={(setIsOpen) => {
															return (
																<FleetToast
																	label="That file will be deleted. Are you sure?"
																	onCancel={() => {
																		setIsOpen(false)
																	}}
																	onConfirm={() => {
																		setIsOpen(false)
																		handleDelete?.(fitem?.id, index)
																	}}
																/>
															)
														}}
													/>
												</div>
											</div>
										)
									})}
								<Button
									type="secondary"
									prefixIcon="fleet-update1"
									size="small"
									disabled={loading}
									onClick={() => handleUploadOpen(index)}
								>
									Click to Upload
								</Button>
								<MovementUpload
									key={index}
									loading={loading}
									items={item.files}
									hide={uploadHide[index]}
									onConfirm={(file: any) =>
										handleUpload(file, index, 0, item.id, dataSource?.voyageId)
									}
									onDelete={(id: string) => {
										handleDelete?.(id, index)
									}}
									onClose={() => handleClose(index)}
								/>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default MovementBillOfLading
