import React, { FC } from "react";
import { FleetSelect } from "@/components";
import { CommonDivisionSelectProps } from "./type";
import useIndex from "./hooks/useIndex";
import { cn } from "@/tools";
import { CommonDivisionPopup } from "./components";
// import { CommonDivisionPopup } from "./components";

const CommonDivisionSelect: FC<CommonDivisionSelectProps> = ({
	onFinish,
	activeItems,
	showKey,
	showCount,
	className,
	disabled,
	placeholder,
	primaryKey = "id",
	onClear
}) => {
	const {
		open,
		loadMore,
		loading,
		list,
		tagRender,
		handleItemSelect,
		handleSearch,
		focusPopup,
		selectValue,
		closePopup,
		setOpen
	} = useIndex(activeItems, showKey, onFinish, primaryKey, disabled);

	return (
		<FleetSelect
			value={selectValue}
			showSearch
			popupMatchSelectWidth={false}
			onDropdownVisibleChange={closePopup}
			mode={Array?.isArray(activeItems) ? "multiple" : undefined}
			open={open}
			allowClear
			onClear={onClear}
			placeholder={placeholder}
			onSearch={handleSearch}
			onFocus={() => focusPopup()}
			className={cn(className, "w-[150px]")}
			disabled={disabled}
			showCount={showCount}
			tagRender={tagRender}
			dropdownRender={() => (
				<CommonDivisionPopup list={list} onSelect={handleItemSelect} loading={loading} />
			)}
		/>
	);
};

export default CommonDivisionSelect;
