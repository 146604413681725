import { useMemo, useRef, useState } from "react"
import { InvStatementOffHireInitItemType, initialOffHireGroupItem } from "../components"
import { InvStatementOtherItemType } from "../../../../../type"
import { checkBunkerKeys, checkOtherKeys, initialBunkerItem, initialDelGroup } from "../source"
import { allBunkers } from "../../_bunkerPrice/source"
import { initialOtherCalculate } from "../components/otherCalculate/source"
import { initialOffHireItem } from "../components/timeCalculate/source"
import { InvStatementOffHireGroupParams, InvStatementOffHireGroupProps } from "../type"
import { initialUppercase } from "@/tools/string"
import { InvCommonOffHireRefType } from "../../../../../../../common"
import { InvoicesType } from "@/pages/voyagemanage/business/invoices/components/views/components/list/module"
import { ExtendsKeys } from "@/types"
import { commonItemsCheck } from "@/tools/check"

const useIndex = (
	otherItem: Pick<InvStatementOtherItemType, "voyageId" | "id" | "addOffHire">,
	activeInvType: ExtendsKeys<InvoicesType, "relet" | "rent" | "statement">
) => {
	const [dataSource, setDataSource] = useState<InvStatementOffHireInitItemType[]>([])
	const activeIndex = useRef<number>(null)
	const deleteIds = useRef<string[]>([])
	const delGroupMap = useRef<{ bunker: string[]; others: string[]; offHire: string[] }[]>([])

	const init = (type: "init" | "details", items: InvStatementOffHireInitItemType[]) => {
		setDataSource((items ?? [])?.map((item) => initialOffHireGroupItem(type, item)))
		delGroupMap.current = Array(items?.length)?.fill(initialDelGroup())
		deleteIds.current = []
	}

	const commit: InvStatementOffHireGroupProps["onCommit"] = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex]
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...item
			})
			return [...prev]
		})
	}

	const addDeleteIdsToGroup = (
		currentIndex: number,
		type: "bunker" | "others" | "offHire",
		delId: string
	) => {
		const currentIds = delGroupMap.current[currentIndex]?.[type]
		currentIds?.push(delId)
		delGroupMap.current?.splice(currentIndex, 1, {
			...delGroupMap.current?.[currentIndex],
			[type]: currentIds
		})
	}

	const remove: InvStatementOffHireGroupProps["onDelete"] = (currentIndex) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex]
			if (currentItem?.id) {
				deleteIds?.current?.push(currentItem?.id)
				delGroupMap?.current?.splice(currentIndex, 1)
			}
			prev?.splice(currentIndex, 1)
			return [...prev]
		})
	}

	const add = () => {
		debugger
		setDataSource((prev) => [
			...prev,
			initialOffHireGroupItem("details", { voyageId: otherItem?.voyageId, invId: otherItem?.id })
		])
	}

	const handleGroupAdd: InvStatementOffHireGroupProps["onGroupAdd"] = (
		type,
		currentIndex: number
	) => {
		const currentGroupItem = dataSource?.[currentIndex],
			currentItems = [...currentGroupItem?.[type]]
		switch (type) {
			case "bunkerPriceList":
				const disableBunkers = [
					...new Set(
						(currentItems as InvStatementOffHireInitItemType["bunkerPriceList"])?.map(
							(item) => item?.bunkerType
						)
					)
				]
				if (disableBunkers?.length >= 4) return
				const otherBunkers = allBunkers?.filter((bunker) => !disableBunkers?.includes(bunker))
				currentItems?.push(
					initialBunkerItem({
						bunkerType: otherBunkers?.[0],
						type: "BOH",
						voyageId: otherItem?.voyageId,
						invId: otherItem?.id,
						groupId: currentGroupItem?.id
					})
				)
				break
			case "moveOffHireList":
				currentItems?.push(
					initialOffHireItem("details", {
						voyageId: otherItem?.voyageId,
						invId: otherItem?.id,
						groupId: currentGroupItem?.id
					})
				)
				break
			case "otherCalDetailList":
				currentItems?.push(
					initialOtherCalculate({
						voyageId: otherItem?.voyageId,
						invId: otherItem?.id,
						groupId: currentGroupItem?.id
					})
				)
				break
			default:
				return
		}

		commit(currentIndex, { [type]: currentItems })
	}

	const handleGroupDelete: InvStatementOffHireGroupProps["onGroupDelete"] = (
		type,
		currentIndex,
		index
	) => {
		const currentGroupItem = dataSource?.[currentIndex],
			currentItems = [...currentGroupItem?.[type]],
			currentItem = currentItems?.[index],
			groupType =
				type === "bunkerPriceList" ? "bunker" : type === "moveOffHireList" ? "offHire" : "others"
		if (currentItem?.id) {
			addDeleteIdsToGroup(currentIndex, groupType, currentItem?.id)
		}
		currentItems?.splice(index, 1)
		commit(currentIndex, { [type]: currentItems })
	}

	const handleGroupCommit: InvStatementOffHireGroupProps["onGroupCommit"] = (
		type,
		currentIndex,
		index,
		item
	) => {
		const currentGroupItem = dataSource?.[currentIndex],
			currentItems = [...currentGroupItem?.[type]],
			currentItem = currentItems?.[index]

		currentItems?.splice(index, 1, {
			...currentItem,
			...item
		})
		commit(currentIndex, { [type]: currentItems })
	}

	const handleOffHireOpen = (
		currentIndex: number,
		callback: InvCommonOffHireRefType["openModal"]
	) => {
		activeIndex.current = currentIndex
		const currentGroupItem = dataSource?.[currentIndex],
			currentItems = currentGroupItem?.moveOffHireList
		callback?.(
			activeInvType as "Rent" | "Relet",
			[...currentItems],
			delGroupMap?.current?.[currentIndex]?.offHire
		)
	}

	const handleOffHireConfirm: InvStatementOffHireGroupProps["onOffHireConfirm"] = (
		items,
		delIds
	) => {
		delGroupMap.current?.splice(activeIndex.current, 1, {
			...delGroupMap.current?.[activeIndex.current],
			offHire: delIds
		})
		const offHireTtlDays = items?.reduce((prev, curr) => prev + +curr?.finalDuration, 0)
		commit(activeIndex.current, {
			moveOffHireList: items,
			offHireTtlDays: offHireTtlDays
		})
	}

	const handleGroupCombineParams = (): InvStatementOffHireGroupParams[] => {
		return dataSource?.map((item, currentIndex) => {
			const { moveOffHireList, otherCalDetailList, bunkerPriceList, ...restItem } = item
			return {
				...restItem,
				moveOffHire: {
					type:
						activeInvType === "statement"
							? null
							: (initialUppercase(activeInvType) as "Rent" | "Relet"),
					offHireVoList: moveOffHireList,
					deleteIds: delGroupMap?.current?.[currentIndex]?.offHire ?? []
				},
				bunkerPrice: {
					bunkerPrice: bunkerPriceList,
					delIds: delGroupMap?.current?.[currentIndex]?.bunker ?? []
				},
				otherCalDetail: {
					invOtherCalDetailList: otherCalDetailList,
					delIds: delGroupMap?.current?.[currentIndex]?.others ?? []
				}
			}
		})
	}

	const summary = useMemo(() => {
		if (!otherItem?.addOffHire) {
			return {
				less: 0,
				plus: 0
			}
		}
		return dataSource?.reduce(
			(prev, curr) => {
				const bunkerSummary = curr?.bunkerPriceList?.reduce((prevBunker, currBunker) => {
					return prevBunker + currBunker?.total
				}, 0)
				const otherSummary = curr?.otherCalDetailList?.reduce((prevOther, currOther) => {
					return prevOther + +currOther?.totalAmount
				}, 0)
				return {
					less:
						prev.less +
						curr?.offHireAmount +
						curr?.cve +
						bunkerSummary +
						Math.abs(Math.max(0, otherSummary)),
					plus: prev.plus + curr?.addComm + Math.abs(Math.min(0, otherSummary))
				}
			},
			{
				less: 0,
				plus: 0
			}
		)
	}, [dataSource, otherItem?.addOffHire])

	const check = () => {
		if (!otherItem?.addOffHire) {
			return {
				checked: true,
				checkKey: null
			}
		}
		for (let item of dataSource) {
			const { checkKey: checkBunkerKey, checked: bunkerChecked } = commonItemsCheck(
				item?.bunkerPriceList,
				checkBunkerKeys
			)

			if (!bunkerChecked)
				return {
					checkKey: `off-hire group.bunker.${checkBunkerKey}`,
					checked: bunkerChecked
				}
			const { checkKey: checkOtherKey, checked: otherChecked } = commonItemsCheck(
				item?.otherCalDetailList,
				checkOtherKeys
			)
			if (!otherChecked)
				return {
					checkKey: `off-hire group.other.${checkOtherKey}`,
					checked: otherChecked
				}
		}

		return {
			checked: true,
			checkKey: null
		}
	}

	return {
		dataSource,
		summary,
		deleteIds,
		commit,
		add,
		remove,
		init,
		check,
		handleOffHireOpen,
		handleOffHireConfirm,
		handleGroupAdd,
		handleGroupCommit,
		handleGroupDelete,
		handleGroupCombineParams
	}
}

export default useIndex
