import {
	deleteBlPortFileParams,
	getBillDetailDataParams,
	saveBillDetailDataParams,
	uploadBlPortFileParams,
	downloadBlPortFileParams,
	getOperatorListParams,
	getBlTableDataParams
} from "./type";
import request from "@/service";
import { type ApiParameter } from "@/api";

export const appVersion =
	process.env.NODE_ENV === "development" ? "/gateway/voyage/v1" : "/gateway/voyage/v1";
const enum ApiMap {
	getBillDetailDataParameter,
	uploadBlPortFileParameter,
	deleteBlPortFileParameter,
	saveBillDetailDataParameter,
	downloadBlPortFileParameter,
	getDepartmentDataParameter,
	getOperatorListParameter,
	getCharterListParameter,
	getBlTableDataParameter,
	downloadBlTableDataParameter
}
type ApiUrl = keyof typeof ApiMap;
const blApi: ApiParameter<ApiUrl> = {
	getBillDetailDataParameter: {
		method: "get",
		url: appVersion + "/move/bill/detail"
	},
	saveBillDetailDataParameter: {
		method: "put",
		url: appVersion + "/move/bill/detail"
	},
	uploadBlPortFileParameter: {
		method: "post",
		url: appVersion + "/move/attach"
	},
	deleteBlPortFileParameter: {
		method: "delete",
		url: appVersion + "/move/attach"
	},
	downloadBlPortFileParameter: {
		method: "get",
		url: appVersion + "/move/voyage/url"
	},
	getDepartmentDataParameter: {
		method: "get",
		url: appVersion + "/move/bill/dept/tree"
	},
	getOperatorListParameter: {
		method: "get",
		url: appVersion + "/move/bill/operator/list"
	},
	getCharterListParameter: {
		method: "get",
		url: appVersion + "/move/bill/charter/list"
	},
	getBlTableDataParameter: {
		method: "get",
		url: appVersion + "/move/bill/list"
	},
	downloadBlTableDataParameter: {
		method: "post",
		url: appVersion + "/move/bill/download"
	}
};

export const getBillDetailDataApi = (params: getBillDetailDataParams) => {
	return request.request({
		...blApi.getBillDetailDataParameter,
		url: `${blApi.getBillDetailDataParameter.url}/${params?.voyageId}`
	});
};

export const saveBillDetailDataApi = (params: saveBillDetailDataParams) => {
	return request.request({
		...blApi.saveBillDetailDataParameter,
		data: params
	});
};

export const uploadBlPortFileApi = (params: uploadBlPortFileParams) => {
	const { voyageId, files, type, dataId } = params;
	return request.request({
		...blApi.uploadBlPortFileParameter,
		url: `${blApi.uploadBlPortFileParameter.url}/${voyageId}`,
		params: { type, dataId },
		data: files
	});
};

export const deleteBlPortFileApi = (params: deleteBlPortFileParams) => {
	return request.request({
		...blApi.deleteBlPortFileParameter,
		url: `${blApi.deleteBlPortFileParameter.url}/${params?.id}`
	});
};

export const downloadBlPortFileApi = (params: downloadBlPortFileParams) => {
	return request.request({
		...blApi.downloadBlPortFileParameter,
		params
	});
};

export const getDepartmentDataApi = () => {
	return request.request({
		...blApi.getDepartmentDataParameter
	});
};

export const getOperatorListApi = (params: getOperatorListParams) => {
	return request.request({
		...blApi.getOperatorListParameter,
		params
	});
};

export const getCharterListApi = () => {
	return request.request({
		...blApi.getCharterListParameter
	});
};

export const getBlTableDataApi = (params: getBlTableDataParams) => {
	return request.request({
		...blApi.getBlTableDataParameter,
		params
	});
};

export const downloadBlTableDataApi = (params: getBlTableDataParams) => {
	return request.request({
		...blApi.downloadBlTableDataParameter,
		data: params,
		responseType: "blob"
	});
};
