export function getInvPeriod(period: number) {
	if (period >= 11 && period <= 13) return "TH";
	switch (period % 10) {
		case 1:
			return "ST";
		case 2:
			return "ND";
		case 3:
			return "RD";
		default:
			return "TH";
	}
}
