import { useMemo } from "react"
import { MovementTctcVesselBasicsItemType } from "../components"
import { MovementTctcOtherItemType } from "../type"
import { MovementFinancialDataItemType, initialFinacialItem } from "../../../common"
import { getInvPeriod } from "@/tools/inv"

const useEstimation = (
	rentVesselItem: MovementTctcVesselBasicsItemType,
	reletVesselItem: MovementTctcVesselBasicsItemType,
	otherItem: MovementTctcOtherItemType
) => {
	const otherExpenses = useMemo(() => {
		return otherItem?.otherExpense?.reduce(
			(prev, curr) => {
				prev.total += +curr?.netExpense
				prev.ownersCount += +curr?.ownerAccount
				prev.calcuate?.push(
					initialFinacialItem({
						desc: curr?.itemName,
						actual: +curr?.netExpense,
						posted: curr?.status === "Settled" ? curr?.settledAmount : null,
						diffPosted:
							curr?.status === "Settled" && !!curr?.settledAmount
								? (curr?.settledAmount - +curr?.netExpense) / +curr?.netExpense
								: 0,
						time: curr?.status === "Settled" ? curr?.latestFinancialDate : null
					})
				)
				return prev
			},
			{
				total: 0,
				ownersCount: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.otherExpense])

	const otherCommission = useMemo(() => {
		return otherItem?.commissionList?.reduce(
			(prev, curr) => {
				prev.total += +curr?.itemValue
				prev.calcuate?.push(
					initialFinacialItem({
						desc: curr?.itemName,
						actual: +curr?.itemValue,
						status: curr?.status,
						diffPosted:
							curr?.status === "Settled" && curr?.settledAmount
								? (+curr?.itemValue - curr?.settledAmount) / +curr?.itemValue
								: 0,
						time: curr?.status === "Settled" ? curr?.latestFinancialDate : null,
						posted: curr?.status === "Settled" ? curr?.settledAmount : 0,
						payerName: curr?.status === "Settled" ? curr?.payeeName : null
					})
				)
				return prev
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.commissionList])

	const rentHireExpense = useMemo(() => {
		return otherItem?.rentHireList?.reduce(
			(prev, curr, index, arr) => {
				prev.total += curr?.status === "Settled" ? curr?.amount : 0
				prev?.calcuate?.push(
					initialFinacialItem({
						desc: curr?.invFinal
							? `Final Hire`
							: `${curr?.period} ${getInvPeriod(curr?.period)} Hire`,
						actual: curr?.amount,
						payerName: curr?.payerName ?? "",
						time: curr?.status === "Settled" ? curr?.latestFinancialDate : null,
						posted: curr?.status === "Settled" ? curr?.settledAmount : null
					})
				)
				return prev
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.rentHireList])

	const reletHireExpense = useMemo(() => {
		return otherItem?.reletHireList?.reduce(
			(prev, curr, index, arr) => {
				prev.total += curr?.status === "Settled" ? curr?.amount : 0
				prev?.calcuate?.push(
					initialFinacialItem({
						desc: curr?.invFinal
							? `Final Hire`
							: `${curr?.period} ${getInvPeriod(curr?.period)} Hire`,
						actual: curr?.amount,
						payerName: curr?.payerName ?? "",
						time: curr?.status === "Settled" ? curr?.latestFinancialDate : null,
						posted: curr?.status === "Settled" ? curr?.settledAmount : null
					})
				)
				return prev
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.reletHireList])

	const disputeCharges = useMemo(() => {
		return otherItem?.disputeChargeList?.reduce(
			(prev, curr) => {
				prev.total += +curr?.finalAmount
				prev.calcuate?.push(
					initialFinacialItem({
						desc: curr?.itemName,
						actual: +curr?.finalAmount
					})
				)
				return prev
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.disputeChargeList])

	const rentIfoCost = useMemo(() => {
		return (
			otherItem?.rentBunkerPlan?.hsfoTotalPrice +
			otherItem?.rentBunkerPlan?.lsfoTotalPrice +
			+otherItem?.rentBunkerPlan?.scrubberTotalPrice
		)
	}, [otherItem?.rentBunkerPlan])

	const rentMdoCost = useMemo(() => {
		return otherItem?.rentBunkerPlan?.mdoTotalPrice + otherItem?.rentBunkerPlan?.mgoTotalPrice
	}, [otherItem?.rentBunkerPlan])

	const rentTotalBunkerCost = useMemo(() => {
		return rentIfoCost + rentMdoCost
	}, [rentIfoCost, rentMdoCost])

	const rentOperationCost = useMemo(() => {
		return rentTotalBunkerCost + otherExpenses?.total
	}, [rentTotalBunkerCost, otherExpenses])

	// const rentHireCost = useMemo(() => {
	// 	const totalDays =
	// 		((rentVesselItem?.estRedeliveryDate ?? 0) - (rentVesselItem?.deliveryTime ?? 0)) /
	// 		(1000 * 60 * 60 * 24)
	// 	return +rentVesselItem.dailyHire * totalDays
	// 	// return +vesselItem.dailyHire * (portCalcutor.ballastSeaDays + portCalcutor.ladenSeaDays + portCalcutor.ballastPortTotalDays + portCalcutor.ladenPortTotalDays)
	// }, [rentVesselItem.dailyHire, rentVesselItem?.estRedeliveryDate, rentVesselItem?.deliveryTime])

	const rentHireCost = useMemo(() => {
		return otherItem?.rentHireList?.reduce((prev, curr) => prev + +curr?.amount, 0)
	}, [otherItem?.rentHireList])
	const rentCommission = useMemo(() => {
		return (rentHireCost * +rentVesselItem.commission) / 100
	}, [rentHireCost, rentVesselItem.commission])

	const rentBrokerage = useMemo(() => {
		return (rentHireCost * +rentVesselItem.brokerage) / 100
	}, [rentHireCost, rentVesselItem.brokerage])

	const rentTotalHireCost = useMemo(() => {
		// return rentHireCost - (rentCommission + rentBrokerage)
		return rentHireCost - -otherCommission?.total
	}, [rentHireCost, -otherCommission?.total])

	const reletTotalBunkerCost = useMemo(() => {
		return (
			otherItem?.reletBunkerPlan?.hsfoTotalPrice +
			otherItem?.reletBunkerPlan?.lsfoTotalPrice +
			+otherItem?.reletBunkerPlan?.scrubberTotalPrice +
			otherItem?.reletBunkerPlan?.mdoTotalPrice +
			+otherItem?.reletBunkerPlan?.mgoTotalPrice
		)
	}, [otherItem?.reletBunkerPlan])

	// const reletHireCost = useMemo(() => {
	// 	const totalDays =
	// 		((reletVesselItem?.estRedeliveryDate ?? 0) - (reletVesselItem?.deliveryTime ?? 0)) /
	// 		(1000 * 60 * 60 * 24)
	// 	return +reletVesselItem.dailyHire * totalDays
	// 	// return +vesselItem.dailyHire * (portCalcutor.ballastSeaDays + portCalcutor.ladenSeaDays + portCalcutor.ballastPortTotalDays + portCalcutor.ladenPortTotalDays)
	// }, [reletVesselItem.dailyHire, reletVesselItem?.estRedeliveryDate, reletVesselItem?.deliveryTime])

	const reletHireCost = useMemo(() => {
		return otherItem?.reletHireList?.reduce((prev, curr) => prev + +curr?.amount, 0)
	}, [otherItem?.reletHireList])

	const reletCommission = useMemo(() => {
		return (reletHireCost * +reletVesselItem.commission) / 100
	}, [reletHireCost, reletVesselItem.commission])

	const reletBrokerage = useMemo(() => {
		return (reletHireCost * +reletVesselItem.brokerage) / 100
	}, [reletHireCost, reletVesselItem.brokerage])

	const reletTotalHireCost = useMemo(() => {
		// return reletHireCost - (reletCommission + reletBrokerage)
		return reletHireCost
	}, [reletHireCost])

	const revenues = useMemo(() => {
		return reletTotalHireCost + otherItem?.otherIncome + reletTotalBunkerCost
	}, [reletTotalHireCost, otherItem?.otherIncome])

	const totalCost = useMemo(() => {
		return rentOperationCost + rentTotalHireCost
	}, [rentOperationCost, rentTotalHireCost])

	const profit = useMemo(() => {
		return revenues - totalCost
	}, [revenues, totalCost])

	const tce = useMemo(() => {
		const totalDays =
			((reletVesselItem?.estRedeliveryDate ?? 0) - (reletVesselItem?.deliveryTime ?? 0)) /
			(1000 * 60 * 60 * 24)
		return totalDays ? (revenues - rentOperationCost) / totalDays : 0
	}, [revenues, rentOperationCost])

	return {
		totalProfit: profit,
		tce,
		totalRevenue: revenues,
		totalCost,
		rentTotalHireCost,
		reletTotalHireCost,
		otherExpenses,
		rentCommission,
		rentBrokerage,
		reletCommission,
		reletBrokerage,
		rentHireCost,
		reletHireCost,
		rentHireExpense,
		rentTotalBunkerCost,
		reletTotalBunkerCost,
		reletHireExpense,
		disputeCharges,
		otherCommission
	}
}

export default useEstimation
