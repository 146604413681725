import React, { FC } from "react";
import commonNewlyStyle from "../../index.module.less";
import style from "./index.module.less";
import { CommonNewlyCompanyProps } from "./type";
import { FleetPrompt } from "@/common";
import classnames from "classnames";
import { FleetLoading, FleetVerifyInput } from "@/components";
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";
import { initialUppercase } from "@/tools/string";

const CommonNewlyCompany: FC<CommonNewlyCompanyProps> = ({
	id,
	hide,
	type,
	onClose,
	onConfirm
}) => {
	const {
		loading,
		dataSource,
		change: handleChange,
		updateCompany
	} = useIndex(hide, onConfirm, type, id);
	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={classnames(commonNewlyStyle["common-newly"], style["common-newly--company"])}>
				<FleetLoading loading={loading} />
				<div className="newly-header">
					<span className="header-title">{initialUppercase(type)} Company</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<div className="newly-container grid gap-[12px]">
					<FleetVerifyInput
						needVerity
						label="Company Name"
						value={dataSource?.companyName}
						placeholder="Please enter the company name."
						disabled={!!id}
						onChange={(val: string) => {
							if (!!id) return;
							handleChange("companyName", val);
						}}
					/>
					<FleetVerifyInput
						label="Company Website"
						value={dataSource?.companyWebsite}
						placeholder="Please enter the company website."
						onChange={(val: string) => {
							handleChange("companyWebsite", val);
						}}
					/>
					<FleetVerifyInput
						label="Company Address"
						value={dataSource?.companyAddress}
						placeholder="Please enter the company address."
						onChange={(val: string) => {
							handleChange("companyAddress", val);
						}}
					/>
				</div>
				<div className="newly-footer">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={updateCompany} disabled={loading}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default CommonNewlyCompany;
