import React, { FC } from "react";
import style from "./index.module.less";
import { BunkerPlanProps, BunkerPlanType } from "./type";
import { FleetFormItem, FleetRangePicker, FleetTextarea, FleetVerifyInput } from "@/components";
import dayjs, { Dayjs } from "dayjs";
import { CommonContactSelect, CommonPortSelect } from "@/common";

const HandoverFormBunkerPlan: FC<BunkerPlanProps> = ({ item, onChange, onCommit }) => {
	return (
		<div className={style["handoverForm-bunkerPlan"]}>
			<span className="handoverForm--common--header secondary">Bunkerage</span>
			<div className="bunkerPlan-container">
				<FleetFormItem label="Bunkering Port">
					<CommonPortSelect
						activeItems={{
							portCode: item?.bunkeringPortCode,
							portName: item?.bunkeringPortName
						}}
						showKey="portName"
						primaryKey="portCode"
						onFinish={(newItem, type) => {
							onCommit?.({
								bunkeringPortCode: newItem?.portCode ?? "",
								bunkeringPortName: newItem?.portName ?? ""
							});
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Physical Supplier">
					<CommonContactSelect
						showKey="companyName"
						primaryKey="id"
						activeItems={{
							id: item?.physicalSupplierId,
							companyName: item?.physicalSupplierName
						}}
						onFinish={(newNoItem, type) => {
							onCommit?.({
								physicalSupplierId: newNoItem?.id ?? null,
								physicalSupplierName: newNoItem?.companyName ?? ""
							});
						}}
						contactParmas={{ businessType: "Physical Supplier" }}
						contactActions={{
							onConfirm: (newNoItem) => {
								onCommit?.({
									physicalSupplierId: newNoItem?.id ?? null,
									physicalSupplierName: newNoItem?.companyName ?? ""
								});
							}
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					value={item?.bunkerTimeBar}
					type="number"
					label="Shipowner’s Bunker Time Bar"
					onChange={(val: number) => {
						onChange?.("bunkerTimeBar", val);
					}}
				/>
			</div>
			<div className="bunkerPlan-container time-select">
				<FleetFormItem label="Order Period">
					<FleetRangePicker
						allowClear
						value={[
							item?.orderPeriodStart ? dayjs(item?.orderPeriodStart) : null,
							item?.orderPeriodEnd ? dayjs(item?.orderPeriodEnd) : null
						]}
						onChange={(vals: [Dayjs, Dayjs]) => {
							const ranges: Pick<BunkerPlanType, "orderPeriodStart" | "orderPeriodEnd"> = {
								orderPeriodStart: null,
								orderPeriodEnd: null
							};
							if (vals) {
								ranges.orderPeriodStart = vals?.[0]?.format("YYYY-MM-DD");
								ranges.orderPeriodEnd = vals?.[1]?.format("YYYY-MM-DD");
							}
							onCommit?.({ ...ranges });
						}}
						showHour={false}
						showMinute={false}
						showTime={false}
					/>
				</FleetFormItem>
				<FleetFormItem label="Bunkering Period">
					<FleetRangePicker
						allowClear
						value={[
							item?.bunkeringPeriodStart ? dayjs(item?.bunkeringPeriodStart) : null,
							item?.bunkeringPeriodEnd ? dayjs(item?.bunkeringPeriodEnd) : null
						]}
						onChange={(vals: [Dayjs, Dayjs]) => {
							const ranges: Pick<BunkerPlanType, "bunkeringPeriodStart" | "bunkeringPeriodEnd"> = {
								bunkeringPeriodStart: null,
								bunkeringPeriodEnd: null
							};
							if (vals) {
								ranges.bunkeringPeriodStart = vals?.[0]?.format("YYYY-MM-DD");
								ranges.bunkeringPeriodEnd = vals?.[1]?.format("YYYY-MM-DD");
							}
							onCommit?.({ ...ranges });
						}}
						showHour={false}
						showMinute={false}
						showTime={false}
					/>
				</FleetFormItem>
			</div>
			<FleetFormItem label="Bunker Clause Special Remarks">
				<FleetTextarea
					placeholder=""
					className="bunkerPlan-remark--textarea"
					value={item?.bunkerClauseSpecialRemark}
					onChange={(val: string) => {
						onChange?.("bunkerClauseSpecialRemark", val);
					}}
				/>
			</FleetFormItem>
		</div>
	);
};

export default HandoverFormBunkerPlan;
