import { CommonInitItemEvent } from "@/types/event";
import { InvStatementOffHireInitItemType } from "./type";
import { initialOffHireGroup } from "../timeCalculate/source";
import { InvCommonFieldInitEvent } from "@/pages/voyagemanage/business/invoices/module";

export const initialBunkerPrice: InvCommonFieldInitEvent<
	InvStatementOffHireInitItemType["bunkerPriceList"][number]
> = (type, item) => {
	return {
		id: type === "details" && item?.id ? item?.id : null,
		invId: type === "details" && item?.invId ? item?.invId : null,
		voyageId: item?.voyageId ?? null,
		bunkerType: item?.bunkerType ?? "HSFO",
		type: item?.type ?? "BOH",
		consumption: item?.consumption ?? "",
		price: item?.price ?? "",
		total: item?.total ?? 0,
		groupId: type === "details" && item?.groupId ? item?.groupId : null
	};
};

export const initialOtherCalculate: InvCommonFieldInitEvent<
	InvStatementOffHireInitItemType["otherCalDetailList"][number]
> = (type, item) => {
	return {
		id: type === "details" && item?.id ? item?.id : null,
		invId: type === "details" && item?.invId ? item?.invId : null,
		groupId: type === "details" && item?.groupId ? item?.groupId : null,
		voyageId: item?.voyageId ?? null,
		title: item?.title ?? "",
		totalAmount: item?.totalAmount ?? "",
		remark: item?.remark ?? ""
	};
};

export const initialOffHireGroupItem: InvCommonFieldInitEvent<InvStatementOffHireInitItemType> = (
	type,
	item
) => {
	return {
		...initialOffHireGroup(type, item),
		bunkerPriceList: (item?.bunkerPriceList ?? [])?.map((bunkerItem) =>
			initialBunkerPrice(type, bunkerItem)
		),
		otherCalDetailList: (item?.otherCalDetailList ?? [])?.map((otherItem) =>
			initialOtherCalculate(type, otherItem)
		)
	};
};
