import { downloadBlPortFileAction } from "@/action/voyageManage/movement";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { PortDetailType } from "@/pages/voyagemanage/business/movement/details/business/tcvc/components/billOfLading/type";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useIndex = (hide: boolean) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);

	const handlePreview = (type: "preview" | "download", item: PortDetailType["files"][number]) => {
		downloadBlPortFileAction(
			{
				path: item?.filePath
			},
			downloadBlPortFileFront,
			(response) => {
				setLoading(false);
				if (type === "download") {
					fetch(response.data)
						.then((data) => {
							return data.arrayBuffer();
						})
						.then((buffer) => {
							const blob = new Blob([buffer], { type: "application/octet-stream" });
							const link = document.createElement("a");
							link.href = URL.createObjectURL(blob);
							link.download = item.fileName;
							link.click();
							URL.revokeObjectURL(link.href);
						});
				} else {
					window.open(response?.data);
				}
			},
			downloadBlPortFileFailed,
			dispatch,
			navigate
		);
	};

	const downloadBlPortFileFront = () => {
		setLoading(true);
	};

	const downloadBlPortFileFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};
	useEffect(() => {
		if (hide) return;
	}, [hide]);
	return {
		loading,
		handlePreview
	};
};

export default useIndex;
