import { useMemo } from "react"
import { InvStatementHireItemType, InvStatementHireProps } from "../type"
import { checkKeys, initialStatementHireItem } from "../source"
import { useCommonIndex } from "@/hook"

const useIndex = (otherItem: Pick<InvStatementHireProps["otherItem"], "addHire">) => {
	const {
		dataSource,
		setDataSource,
		commit,
		check: commonCheck
	} = useCommonIndex<InvStatementHireItemType>(
		(item: InvStatementHireItemType) => initialStatementHireItem("init", item),
		checkKeys
	)

	const summary = useMemo(() => {
		if (!otherItem?.addHire)
			return {
				plus: 0,
				less: 0
			}
		return {
			plus: dataSource?.ttlOnHireDay * +dataSource?.dailyHire + dataSource?.hireCve,
			// less: dataSource?.addComm + dataSource?.brokerage
			less: dataSource?.addComm
		}
	}, [dataSource, otherItem?.addHire])

	const init = (type: "init" | "details", item: Partial<InvStatementHireItemType>) => {
		setDataSource(initialStatementHireItem(type, item))
	}

	const check = () => {
		return otherItem?.addHire
			? commonCheck()
			: {
					checked: true,
					checkKey: ""
				}
	}

	return {
		dataSource: dataSource,
		summary,
		commit,
		handleHireCheck: check,
		init
	}
}

export default useIndex
