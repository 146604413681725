import React, { useState } from "react";
import classnames from "classnames";
import {
  FleetButton,
  FleetInput,
  FleetTable,
  FleetTooltip,
  FleetRangePicker,
} from "@/components";
import { CommonRoleWrap, FleetCategory, FleetToast } from "@/common";
import style from "./index.module.less";
import NewCargoBook from "@/featrue/voyagemanage/cargoBook/newAdd";
import useGroup from "@/hook/voyageManage/cargoBook/useGroup";
import { CBGroupOptions } from "@/types/voyageManage/cargoBook/list";
import dayjs from "dayjs";
import EditCargoBook from "@/featrue/voyagemanage/cargoBook/editCargoBook";
import { Button, Popover } from "@/_components";

const VoyagemanageCargoBook: React.FC<{}> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    checkGroup,
    layCan,
    ids,
    selectedRowKeys,
    pageNum,
    pageSize,
    columns,
    dataSource,
    loading,
    total,
    hide,
    editHide,
    recordId,
    setHide,
    setEditHide,
    onLayCanChange,
    handleReset,
    handleChange,
    onPageChange,
    onSelectChange,
    onDeleteItems,
    onSearch,
    onRow,
    onReset,
    onIdsChange,
  } = useGroup();
  return (
		<div className={classnames(style["voyagemanage-cargoBook"], "voyagemanage-common")}>
			<div className="cargoBook-toolbar">
				<div className="toolbar-filter">
					<div className="filter">
						<span className="filter-label">Search</span>
						<FleetInput
							className="input-filter"
							value={undefined}
							placeholder="Please enter"
							prefix="LayoutSearchIcon"
							onChange={onSearch}
						/>
					</div>
					<div className="filter category-filter">
						<Popover
							buttonProps={{
								children: "Filter",
								size: "small"
							}}
							open={isOpen}
							onOpenChange={setIsOpen}
							content={
								<div className={style["filter-content"]}>
									<FleetCategory
										options={CBGroupOptions}
										checkGroup={checkGroup}
										onChange={handleChange}
										onReset={handleReset}
									/>
								</div>
							}
						></Popover>
					</div>
					<div className="filter">
						<span className="filter-label">Contract Date</span>
						<FleetRangePicker
							className="date-filter"
							value={layCan}
							onChange={onLayCanChange}
							disabledDate={(current) => {
								return current && current < dayjs().endOf("day");
							}}
						/>
					</div>
				</div>
				<div className="toolbar-actions">
					<CommonRoleWrap roleStr="cargobook:list:batchDelete">
						<Popover
							buttonProps={{
								className: "toolbar-action",
								type: "icon",
								disabled: selectedRowKeys?.length === 0,
								children: <span className="font_family fleet-delete"></span>
							}}
							disabled={selectedRowKeys?.length === 0}
							render={(setIsOpen) => {
								return (
									<FleetToast
										label="Are you sure delete this item?"
										onCancel={() => {
											setIsOpen(false);
										}}
										onConfirm={() => {
											onDeleteItems?.("all");
											setIsOpen(false);
										}}
									/>
								);
							}}
						/>
					</CommonRoleWrap>
					<CommonRoleWrap roleStr="cargobook:list:download">
						<Button
							size="small"
							disabled={selectedRowKeys?.length === 0}
							className="toolbar-action"
							prefixIcon="fleet-download"
						>
							Download
						</Button>
					</CommonRoleWrap>
					<CommonRoleWrap roleStr="cargobook:list:add">
						<Button size="small" onClick={() => setHide(false)} prefixIcon="fleet-add">
							New Cargo Book
						</Button>
					</CommonRoleWrap>
				</div>
			</div>
			<FleetTable
				columns={columns}
				className="cargoBook-table"
				dataSource={dataSource}
				loading={loading}
				id="id"
				pagination={{
					pageSize: pageSize,
					current: pageNum,
					total: total,
					onChange: onPageChange
				}}
				onRow={onRow}
				rowSelection={{
					columnWidth: 64,
					selectedRowKeys,
					onChange: onSelectChange
				}}
			/>
			<NewCargoBook onClose={() => setHide(true)} hide={hide} onReset={onReset} />
			<EditCargoBook
				hide={editHide}
				onClose={() => setEditHide(true)}
				recordId={recordId}
				onReset={onReset}
			/>
		</div>
	);
};

export default VoyagemanageCargoBook;
