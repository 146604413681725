import { CommonInitItemEvent } from "@/types/event";
import { InvStatementOtherCalculateItemType } from "./type";

export const initialOtherCalculate: CommonInitItemEvent<InvStatementOtherCalculateItemType> = (
	item
) => {
	return {
		id: item?.id ?? null,
		invId: item?.invId ?? null,
		voyageId: item?.voyageId ?? null,
		groupId: item?.groupId ?? null,
		title: item?.title ?? "",
		totalAmount: item?.totalAmount ?? "",
		remark: item?.remark ?? ""
	};
};
