import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import style from "./index.module.less";
import { useAppSelector } from "@/hook";
import FleetTabs from "@/components/Tabs";

const Statement: React.FC<{}> = (props) => {
	const { firstSelected } = useAppSelector((state) => state.GlobalStore);
	const { userInfo } = useAppSelector((state) => state.UserStore);

	const tabs = useMemo(() => {
		const children = userInfo?.menus?.find((menu) => menu.key === "/layout/statement")?.children;
		return children
			?.filter((item) => {
				return !item?.hidden;
			})
			?.map((item) => {
				return {
					...item,
					label: item?.meta?.title
				};
			});
	}, [userInfo]);
	return (
		<div className={style["fleet-statement"]}>
			<div className="statement-header">
				{/* @ts-ignore */}
				<FleetTabs className="statement-header-tabs" list={tabs} value={firstSelected} />
				<span className="statement-header-line"></span>
			</div>
			<Outlet />
		</div>
	);
};

export default Statement;
