import React, { FC } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import {
	FleetFormItem,
	FleetRangePicker,
	FleetSelect,
	FleetTable,
	FleetVerifyInput
} from "@/components";
import { columns, charterOptions, sortOptions, b2bOptions } from "./source";
import { Button } from "@/_components";
import useIndex from "./hooks/useIndex";
import { Dayjs } from "dayjs";
import { filter } from "lodash";
import { FileDownload } from "../../components";

const CharterersLiability: FC<{}> = (props) => {
	const {
		loading,
		btnLoading,
		pageSize,
		pageNum,
		total,
		search,
		filter,
		handleSearch,
		dataSource,
		hide,
		record,
		opereteColumns,
		handlePageChange,
		handleClose,
		handleFilterChange,
		handleDownload
	} = useIndex();
	return (
		<div className={classNames("statement-common", style["statement-charterersLiability"])}>
			<div className="charterersLiability-search">
				<div className="search-container">
					<FleetVerifyInput
						label="Search"
						value={search}
						placeholder="Please enter"
						prefix="LayoutSearchIcon"
						onChange={handleSearch}
					/>

					<FleetFormItem label="Delivery Date">
						<FleetRangePicker
							className="date-filter"
							value={filter.deliveryDate}
							allowClear
							onChange={(vals: [Dayjs, Dayjs]) => {
								handleFilterChange?.("deliveryDate", vals ?? null);
							}}
						/>
					</FleetFormItem>
					<FleetFormItem label="Redelivery Date">
						<FleetRangePicker
							className="date-filter"
							value={filter.redeliveryDate}
							allowClear
							onChange={(vals: [Dayjs, Dayjs]) => {
								handleFilterChange?.("redeliveryDate", vals ?? null);
							}}
						/>
					</FleetFormItem>
					<FleetFormItem label="Types of Charter">
						<FleetSelect
							className="filter-select"
							options={charterOptions}
							value={filter.charter}
							allowClear
							onChange={(val: number) => handleFilterChange?.("charter", val)}
						/>
					</FleetFormItem>
					<FleetFormItem label="Back-to-Back">
						<FleetSelect
							className="filter-select"
							options={b2bOptions}
							value={filter.isBack}
							allowClear
							onChange={(val: number) => handleFilterChange?.("isBack", val)}
						/>
					</FleetFormItem>
					<FleetFormItem label="Sort Rules">
						<FleetSelect
							className="filter-select"
							options={sortOptions}
							value={filter.sortRule}
							allowClear
							onChange={(val: number) => handleFilterChange?.("sortRule", val)}
						/>
					</FleetFormItem>
				</div>
				<div className="toolbar-container">
					<Button prefixIcon="fleet-download" loading={btnLoading} onClick={handleDownload}>
						Download
					</Button>
				</div>
			</div>
			<FleetTable
				columns={[...columns, ...opereteColumns]}
				className="charterersLiability-table"
				dataSource={dataSource}
				loading={loading}
				id="id"
				pagination={{
					pageSize: pageSize,
					current: pageNum,
					total: total,
					onChange: handlePageChange
				}}
			/>
			<FileDownload hide={hide} onClose={handleClose} data={record.current} type="insure" />
		</div>
	);
};

export default CharterersLiability;
