import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	RouteCommonRoutePlanParamsType,
	getRouteCommonRoutePlanParams,
	deleteRouteCommonRoutePlanParams,
	getRouteCommonRoutePlanDetailsParams,
	getRouteCommonRoutePlanLinesParams,
	getRouteCommonRoutePlanPointsParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonRoutePlanParameter,
	deleteRouteCommonRoutePlanParameter,
	getRouteCommonRoutePlanDetailsParameter,
	getRouteCommonRoutePlanLinesParameter,
	getRouteCommonRoutePlanPointsParameter
}

export type ApiUrl = keyof typeof ApiMap;

const routePlanApi: ApiParameter<ApiUrl> = {
	getRouteCommonRoutePlanParameter: {
		method: "get",
		url: appVersion + "/moni/route-plan/list"
	},
	deleteRouteCommonRoutePlanParameter: {
		method: "delete",
		url: appVersion + "/moni/route-plan"
	},
	getRouteCommonRoutePlanDetailsParameter: {
		method: "get",
		url: appVersion + "/moni/route-plan/detail"
	},
	getRouteCommonRoutePlanLinesParameter: {
		method: "get",
		url: appVersion + "/moni/route-plan/lines"
	},
	getRouteCommonRoutePlanPointsParameter: {
		method: "get",
		url: appVersion + "/moni/route-plan/trajectory"
	}
};

export const getRouteCommonRoutePlanApi = (params: getRouteCommonRoutePlanParams) => {
	return request.request({
		...routePlanApi.getRouteCommonRoutePlanParameter,
		params
	});
};

export const deleteRouteCommonRoutePlanApi = (params: deleteRouteCommonRoutePlanParams) => {
	return request.request({
		...routePlanApi.deleteRouteCommonRoutePlanParameter,
		url: `${routePlanApi.deleteRouteCommonRoutePlanParameter.url}/${params?.id}`
	});
};

export const getRouteCommonRoutePlanDetailsApi = (params: getRouteCommonRoutePlanDetailsParams) => {
	return request.request({
		...routePlanApi.getRouteCommonRoutePlanDetailsParameter,
		url: `${routePlanApi.getRouteCommonRoutePlanDetailsParameter.url}/${params?.id}`
	});
};

export const getRouteCommonRoutePlanLinesApi = (params: getRouteCommonRoutePlanLinesParams) => {
	return request.request({
		...routePlanApi.getRouteCommonRoutePlanLinesParameter,
		params
	});
};

export const getRouteCommonRoutePlanPointsApi = (params: getRouteCommonRoutePlanPointsParams) => {
	return request.request({
		...routePlanApi.getRouteCommonRoutePlanPointsParameter,
		params
	});
};

export const routePlanApiMap: RequestApiMapType<ApiUrl, RouteCommonRoutePlanParamsType> = {
	getRouteCommonRoutePlanApi,
	deleteRouteCommonRoutePlanApi,
	getRouteCommonRoutePlanDetailsApi,
	getRouteCommonRoutePlanLinesApi,
	getRouteCommonRoutePlanPointsApi
};
