import { FleetOptionType } from "@/types/advancedType"
import { VoyageManageContactItemType } from "./type"

export enum VoyageManageAttachAndContactTitleMap {
	"head_fixture" = "Head Fixture",
	"fixture_notes" = "Fixture Notes",
	"port" = "Port Details",
	"other" = "Other Attachments & Contacts",
	"tctc_rent_head_fixture" = "Charter In",
	"tctc_relet_head_fixture" = "Relet",
	"tctc_other" = "Other Attachments & Contacts"
}

export const contactOptions: FleetOptionType<
	"Contact Name" | "Company Name" | "Email",
	keyof Pick<
		VoyageManageContactItemType,
		"companyName" | "name" | "mobilePhone1" | "landline1" | "email"
	>
>[] = [
	{ label: "Company Name", value: "companyName" },
	{ label: "Contact Name", value: "name" },
	// { label: "MOB", value: "mobilePhone1" },
	// { label: "TEL", value: "landline1" },
	{ label: "Email", value: "email" }
]
