import React from "react";
import { createRoot } from "react-dom/client"
import App from './App';

import init from "space-gl-lib"

init()

const Root = createRoot(
  document.getElementById('app') as HTMLElement
);
Root.render(
  <App />
);