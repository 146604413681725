import { useRef, useState } from "react";
import { InvStatementOtherCalculateProps, InvStatementOtherCalculateItemType } from "../type";
import { initialOtherCalculate } from "../source";

const useIndex = (
	otherItem: Pick<InvStatementOtherCalculateItemType, "groupId" | "invId" | "voyageId">
) => {
	const [dataSource, setDataSource] = useState<InvStatementOtherCalculateProps["items"]>([]);
	const deleteIds = useRef<string[]>([]);
	const add = () => {
		setDataSource((prev) => [...prev, initialOtherCalculate({ ...otherItem })]);
	};

	const change: InvStatementOtherCalculateProps["onChange"] = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const remove: InvStatementOtherCalculateProps["onDelete"] = (currentIndex) => {
		const currentItem = dataSource?.[currentIndex];
		if (currentItem?.id) {
			deleteIds?.current?.push(currentItem?.id);
		}
		setDataSource((prev) => {
			prev?.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const init = (items: InvStatementOtherCalculateItemType[]) => {
		setDataSource(items ?? []);
		deleteIds.current = [];
	};

	return {
		dataSource,
		deleteIds,
		change,
		remove,
		add,
		init
	};
};

export default useIndex;
