import React, { FC } from "react";
import style from "./index.module.less";
import { HandoverFormBunkerAndPerformanceProps } from "./type";
import classnames from "classnames";
import {
	HandoverFormBunkerClause,
	HandoverFormBunkerPlan,
	HandoverFormPerformance
} from "./components";

const HandoverFormBunkerAndPerformance: FC<HandoverFormBunkerAndPerformanceProps> = ({
	currentId,
	otherItem,
	bunkerClauseItem,
	performanceItem,
	onBunkerClauseChange,
	onBunkerClauseCommit,
	onBunkerPlanChange,
	onBunkerPlanCommit,
	onPerformanceChange,
	onPerformanceCommit,
	voyageType
}) => {
	return (
		<div
			id={currentId}
			className={classnames("handoverForm--common", style["handoverForm-bunkerAndPerformance"])}
		>
			<span className="handoverForm--common--header">Bunker & Vessel Performance</span>
			<HandoverFormBunkerClause
				item={bunkerClauseItem}
				onChange={onBunkerClauseChange}
				onCommit={onBunkerClauseCommit}
			/>
			{voyageType === "TCVC" && (
				<HandoverFormBunkerPlan
					item={otherItem}
					onChange={onBunkerPlanChange}
					onCommit={onBunkerPlanCommit}
				/>
			)}
			<HandoverFormPerformance
				item={performanceItem}
				onChange={onPerformanceChange}
				onCommit={onPerformanceCommit}
			/>
		</div>
	);
};

export default HandoverFormBunkerAndPerformance;
