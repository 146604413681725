import { InvStatementBunkerProps } from "../../common";

export const getBunkerSummary = (
	bodItems: InvStatementBunkerProps["items"],
	borItems: InvStatementBunkerProps["items"]
) => {
	const result: InvStatementBunkerProps["items"] = [],
		total = 0
	for (let borItem of borItems) {
		const bodItem = bodItems?.find((item) => item?.bunkerType === borItem?.bunkerType)
		if (!bodItem) continue
		result?.push({
			...borItem,
			total: bodItem?.total - borItem?.total
		})
	}
	return result
}